export const ENTITIES = [
  {
    "id": "804252",
    "type": "symbol",
    "attributes": {
      "tex": "$predicate$",
      "mathml": "<mi>predicate</mi>",
      "nicknames": [
        "embeddings",
        "representations"
      ],
      "snippets": [
        "The size of ${\\htmlClass{match-highlight}{predicate}}$ and $role$ representations and the representation used for joint part-of-speech/predicate classification is 200."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 2,
          "left": 0.310255,
          "top": 0.684507,
          "width": 0.0687706,
          "height": 0.0126204
        }
      ],
      "diagram_label": null,
      "is_definition": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": [],
      "mathml_near_matches": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": null
      },
      "parent": {
        "type": "symbol",
        "id": null
      },
      "sentence": {
        "type": "sentence",
        "id": "804312"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804312"
        },
        {
          "type": "sentence",
          "id": "804636"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804636"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804253",
    "type": "symbol",
    "attributes": {
      "tex": "$role$",
      "mathml": "<mi>role</mi>",
      "nicknames": [
        "representations",
        "embeddings"
      ],
      "snippets": [
        "Contextually encoded tokens are projected to distinct \\emph{predicate} and **\\emph{role}** embeddings (\\S\\ref{sec:srl}), and each predicted predicate is scored with the sequence's role representations using a bilinear model.",
        "First, we project each token representation $s_t^{(J)}$ to a predicate-specific representation $s_t^{pred}$ and a role-specific representation $s_t^{{\\htmlClass{match-highlight}{role}}}$.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_t^{{\\htmlClass{match-highlight}{role}}}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{{\\htmlClass{match-highlight}{role}}}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{{\\htmlClass{match-highlight}{role}}}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "The size of $predicate$ and ${\\htmlClass{match-highlight}{role}}$ representations and the representation used for joint part-of-speech/predicate classification is 200."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 2,
          "left": 0.423123,
          "top": 0.684135,
          "width": 0.0283482,
          "height": 0.0115068
        }
      ],
      "diagram_label": null,
      "is_definition": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": [],
      "mathml_near_matches": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": null
      },
      "parent": {
        "type": "symbol",
        "id": null
      },
      "sentence": {
        "type": "sentence",
        "id": "804312"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804636"
        },
        {
          "type": "sentence",
          "id": "804312"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804312"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804636"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804254",
    "type": "sentence",
    "attributes": {
      "text": "11pt,a4paperarticle hyperrefemnlp2018 times latexsym multirow mathtools floatrow subcaption url",
      "tex": "11pt,a4paper]{article}\n\\usepackage[hyperref]{emnlp2018}\n\\usepackage{times}\n\\usepackage{latexsym}\n\\usepackage{multirow}\n\\usepackage{mathtools}\n\\usepackage{floatrow}\n\\usepackage{subcaption}\n\\usepackage{url",
      "tex_start": 551,
      "tex_end": 754,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804255",
    "type": "sentence",
    "attributes": {
      "text": "1redTODO: #1 1bluepat: #1",
      "tex": "1]{\\textcolor{red}{TODO: #1}}\n\\newcommand{\\pat}[1]{\\textcolor{blue}{pat: #1",
      "tex_start": 1098,
      "tex_end": 1173,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804256",
    "type": "sentence",
    "attributes": {
      "text": "Linguistically-Informed Self-Attention for Semantic Role Labeling",
      "tex": "Linguistically-Informed Self-Attention for Semantic Role Labeling",
      "tex_start": 1184,
      "tex_end": 1249,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.159664,
          "top": 0.0855107,
          "width": 0.684034,
          "height": 0.0154394
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804257",
    "type": "sentence",
    "attributes": {
      "text": "Emma Strubell<<equation-0>>, Patrick Verga<<equation-1>>, Daniel Andor<<equation-2>>, David Weiss<<equation-3>> and Andrew McCallum<<equation-4>>",
      "tex": "Emma Strubell$^1$, Patrick Verga$^1$, Daniel Andor$^2$, David Weiss$^2$ and Andrew McCallum$^1$",
      "tex_start": 1260,
      "tex_end": 1355,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.131092,
          "top": 0.12114,
          "width": 0.744538,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804258",
    "type": "sentence",
    "attributes": {
      "text": "<<equation-5>>College of Information and Computer Sciences",
      "tex": "$^1$College of Information and Computer Sciences",
      "tex_start": 1361,
      "tex_end": 1409,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.314286,
          "top": 0.137767,
          "width": 0.383193,
          "height": 0.0142518
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804259",
    "type": "sentence",
    "attributes": {
      "text": "University of Massachusetts Amherst",
      "tex": "University of Massachusetts Amherst",
      "tex_start": 1417,
      "tex_end": 1452,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.356303,
          "top": 0.155582,
          "width": 0.297479,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804260",
    "type": "sentence",
    "attributes": {
      "text": "\\{strubell, pat, mccallum\\}@cs.umass.edu",
      "tex": "\\{strubell, pat, mccallum\\}@cs.umass.edu",
      "tex_start": 1465,
      "tex_end": 1505,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.280672,
          "top": 0.171021,
          "width": 0.448739,
          "height": 0.0142518
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804261",
    "type": "sentence",
    "attributes": {
      "tex_end": 1534,
      "text": "<<equation-6>>Google AI Language",
      "tex": "$^2$Google AI Language",
      "tex_start": 1512,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.415126,
          "top": 0.186461,
          "width": 0.178151,
          "height": 0.0154394
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804262",
    "type": "sentence",
    "attributes": {
      "text": "New York, NY",
      "tex": "New York, NY",
      "tex_start": 1540,
      "tex_end": 1552,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.445378,
          "top": 0.205463,
          "width": 0.117647,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804263",
    "type": "sentence",
    "attributes": {
      "text": "\\{andor, djweiss\\}@google.com",
      "tex": "\\{andor, djweiss\\}@google.com",
      "tex_start": 1562,
      "tex_end": 1591,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.347899,
          "top": 0.220903,
          "width": 0.315966,
          "height": 0.0142518
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804264",
    "type": "sentence",
    "attributes": {
      "text": "Current state-of-the-art semantic role labeling (SRL) uses a deep neural network with no explicit linguistic features.",
      "tex": "Current state-of-the-art semantic role labeling (SRL) uses a deep neural network with no explicit linguistic features.",
      "tex_start": 1649,
      "tex_end": 1767,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.319477,
          "width": 0.184874,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.305226,
          "width": 0.309244,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.290974,
          "width": 0.309244,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804265",
    "type": "sentence",
    "attributes": {
      "text": "However, prior work has shown that gold syntax trees can dramatically improve SRL decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
      "tex": "However, prior work has shown that gold syntax trees can dramatically improve SRL decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
      "tex_start": 1769,
      "tex_end": 1943,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.375297,
          "width": 0.176471,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.361045,
          "width": 0.309244,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.347981,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.333729,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.352941,
          "top": 0.319477,
          "width": 0.105882,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804266",
    "type": "sentence",
    "attributes": {
      "text": "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
      "tex": "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
      "tex_start": 1944,
      "tex_end": 2183,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.446556,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.432304,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.418052,
          "width": 0.305882,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.4038,
          "width": 0.309244,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.389549,
          "width": 0.305882,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.342857,
          "top": 0.375297,
          "width": 0.115966,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804267",
    "type": "sentence",
    "attributes": {
      "text": "Unlike previous models which require significant pre-processing to prepare linguistic features, LISA can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
      "tex": "Unlike previous models which require significant pre-processing to prepare linguistic features, LISA can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
      "tex_start": 2185,
      "tex_end": 2467,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.546318,
          "width": 0.154622,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.532067,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.517815,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.503563,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.489311,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.475059,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.460808,
          "width": 0.309244,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804268",
    "type": "sentence",
    "attributes": {
      "text": "Syntax is incorporated by training one attention head to attend to syntactic parents for each token.",
      "tex": "Syntax is incorporated by training one attention head to attend to syntactic parents for each token.",
      "tex_start": 2468,
      "tex_end": 2568,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.574822,
          "width": 0.25042,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.56057,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.32605,
          "top": 0.546318,
          "width": 0.132773,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804269",
    "type": "sentence",
    "attributes": {
      "text": "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our SRL model.",
      "tex": "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our SRL model.",
      "tex_start": 2569,
      "tex_end": 2714,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.617577,
          "width": 0.309244,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.603325,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.589074,
          "width": 0.309244,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.416807,
          "top": 0.574822,
          "width": 0.0420168,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804270",
    "type": "sentence",
    "attributes": {
      "text": "In experiments on CoNLL-2005 SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
      "tex": "In experiments on CoNLL-2005 SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
      "tex_start": 2715,
      "tex_end": 3020,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.732779,
          "width": 0.0201681,
          "height": 0.00593824
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.716152,
          "width": 0.309244,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.7019,
          "width": 0.309244,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.687648,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.674584,
          "width": 0.309244,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.660333,
          "width": 0.309244,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.646081,
          "width": 0.309244,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.631829,
          "width": 0.307563,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804271",
    "type": "sentence",
    "attributes": {
      "text": "On ConLL-2012 English SRL we also show an improvement of more than 2.5 F1.",
      "tex": "On ConLL-2012 English SRL we also show an improvement of more than 2.5 F1.",
      "tex_start": 3021,
      "tex_end": 3095,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.744656,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.186555,
          "top": 0.730404,
          "width": 0.272269,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804272",
    "type": "sentence",
    "attributes": {
      "text": "LISA also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
      "tex": "LISA also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
      "tex_start": 3096,
      "tex_end": 3263,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.801663,
          "width": 0.267227,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.787411,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.151261,
          "top": 0.773159,
          "width": 0.307563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.758907,
          "width": 0.309244,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804273",
    "type": "sentence",
    "attributes": {
      "text": "Introduction.",
      "tex": "\n\n\\section{Introduction}\n",
      "tex_start": 3278,
      "tex_end": 3303,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.12437,
          "top": 0.839667,
          "width": 0.134454,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804274",
    "type": "sentence",
    "attributes": {
      "text": "Semantic role labeling (SRL) extracts a high-level representation of meaning from a sentence, labeling e.g. who did what to whom.",
      "tex": "Semantic role labeling (SRL) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
      "tex_start": 3303,
      "tex_end": 3454,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.122689,
          "top": 0.882423,
          "width": 0.242017,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.121008,
          "top": 0.865796,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.122689,
          "top": 0.849169,
          "width": 0.363025,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804275",
    "type": "sentence",
    "attributes": {
      "text": "Explicit representations of such semantic information have been shown to improve results in challenging downstream tasks such as dialog systems Citation (tur2005semi,chen2013unsupervised), machine reading Citation (berant2014modeling, wang2015machine) and translation Citation (liu2010semantic,bazrafshan2013semantic).",
      "tex": "Explicit representations of such semantic information have been shown to improve results in challenging downstream tasks such as dialog systems \\citep{tur2005semi,chen2013unsupervised}, machine reading \\citep{berant2014modeling, wang2015machine} and translation \\citep{liu2010semantic,bazrafshan2013semantic}.",
      "tex_start": 3455,
      "tex_end": 3764,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.122689,
          "top": 0.897862,
          "width": 0.364706,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.379832,
          "top": 0.882423,
          "width": 0.107563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.598319,
          "top": 0.334917,
          "width": 0.27563,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.552941,
          "top": 0.317102,
          "width": 0.304202,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.554622,
          "top": 0.301663,
          "width": 0.278992,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.285036,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.269596,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804276",
    "type": "sentence",
    "attributes": {
      "text": "Though syntax was long considered an obvious prerequisite for SRL systems Citation (levin1993english,punyakanok2008importance), recently deep neural network architectures have surpassed syntactically-informed models Citation (zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly), achieving state-of-the art SRL performance with no explicit modeling of syntax.",
      "tex": "Though syntax was long considered an obvious prerequisite for SRL systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art SRL performance with no explicit modeling of syntax.",
      "tex_start": 3767,
      "tex_end": 4144,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.463183,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.586555,
          "top": 0.446556,
          "width": 0.295798,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.594958,
          "top": 0.438242,
          "width": 0.285714,
          "height": 0.0023753
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.640336,
          "top": 0.433492,
          "width": 0.240336,
          "height": 0.00118765
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.414489,
          "width": 0.287395,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.39905,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.626891,
          "top": 0.382423,
          "width": 0.255462,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.365796,
          "width": 0.32437,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.534454,
          "top": 0.350356,
          "width": 0.347899,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804277",
    "type": "sentence",
    "attributes": {
      "text": "An additional benefit of these end-to-end models is that they require just raw tokens and (usually) detected predicates as input, whereas richer linguistic features typically require extraction by an auxiliary pipeline of models.",
      "tex": "An additional benefit of these end-to-end models is that they require just raw tokens and (usually) detected predicates as input, whereas richer linguistic features typically require extraction by an auxiliary pipeline of models.",
      "tex_start": 4146,
      "tex_end": 4375,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.542755,
          "width": 0.210084,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.527316,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.510689,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.495249,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.478622,
          "width": 0.364706,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804278",
    "type": "sentence",
    "attributes": {
      "text": "Still, recent work Citation (roth2016neural,he2017deep,marcheggiani2017encoding) indicates that neural network models could see even higher accuracy gains by leveraging syntactic information rather than ignoring it.",
      "tex": "Still, recent work \\citep{roth2016neural,he2017deep,marcheggiani2017encoding} indicates that neural network models could see even higher accuracy gains by leveraging syntactic information rather than ignoring it.",
      "tex_start": 4377,
      "tex_end": 4589,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.624703,
          "width": 0.240336,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.608076,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.592637,
          "width": 0.366387,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.554622,
          "top": 0.57601,
          "width": 0.327731,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.536134,
          "top": 0.56057,
          "width": 0.317647,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804279",
    "type": "sentence",
    "attributes": {
      "text": "Citation (he2017deep) indicate that many of the errors made by a syntax-free neural network on SRL are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in SRL accuracy, providing a gold-quality parse leads to substantial gains.",
      "tex": "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on SRL are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in SRL accuracy, providing a gold-quality parse leads to substantial gains.",
      "tex_start": 4590,
      "tex_end": 4964,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.752969,
          "width": 0.12437,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.73753,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.720903,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.704276,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.688836,
          "width": 0.366387,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.672209,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.65677,
          "width": 0.364706,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.640143,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.835294,
          "top": 0.624703,
          "width": 0.0470588,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804280",
    "type": "sentence",
    "attributes": {
      "text": "Citation (marcheggiani2017encoding) incorporate syntax from a high-quality parser Citation (kiperwasser2016simple) using graph convolutional neural networks Citation (kipf2017semi), but like Citation (he2017deep) they attain only small increases over a model with no syntactic parse, and even perform worse than a syntax-free model on out-of-domain data.",
      "tex": "\\citet{marcheggiani2017encoding} incorporate syntax from a high-quality parser \\citep{kiperwasser2016simple} using graph convolutional neural networks \\citep{kipf2017semi}, but like \\citet{he2017deep} they attain only small increases over a model with no syntactic parse, and even perform worse than a syntax-free model on out-of-domain data.",
      "tex_start": 4965,
      "tex_end": 5307,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.865796,
          "width": 0.223529,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.849169,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.833729,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.552941,
          "top": 0.817102,
          "width": 0.329412,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.801663,
          "width": 0.366387,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.785036,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.769596,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.835294,
          "top": 0.752969,
          "width": 0.0470588,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804281",
    "type": "sentence",
    "attributes": {
      "text": "These works suggest that though syntax has the potential to improve neural network SRL models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
      "tex": "These works suggest that though syntax has the potential to improve neural network SRL models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
      "tex_start": 5308,
      "tex_end": 5508,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.897862,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.882423,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.751261,
          "top": 0.865796,
          "width": 0.131092,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.0950119,
          "width": 0.309244,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.0795724,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804282",
    "type": "sentence",
    "attributes": {
      "text": "In response, we propose linguistically-informed self-attention (LISA): a model that combines multi-task learning Citation (caruana1993multitask) with stacked layers of multi-head self-attention Citation (vaswani2017attention); the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
      "tex": "In response, we propose \\emph{linguistically-informed self-attention} (LISA): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
      "tex_start": 5511,
      "tex_end": 5921,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.226841,
          "width": 0.294118,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.211401,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.194774,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.157983,
          "top": 0.179335,
          "width": 0.329412,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.162708,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.147268,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.130641,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.141176,
          "top": 0.115202,
          "width": 0.347899,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804283",
    "type": "sentence",
    "attributes": {
      "text": "Whereas prior work typically requires separate models to provide linguistic analysis, including most syntax-free neural models which still rely on external predicate detection, our model is truly end-to-end: earlier layers are trained to predict prerequisite parts-of-speech and predicates, the latter of which are supplied to later layers for scoring.",
      "tex": "Whereas prior work typically requires separate models to provide linguistic analysis, including most syntax-free neural models which still rely on external predicate detection, our model is truly end-to-end: earlier layers are trained to predict prerequisite parts-of-speech and predicates, the latter of which are supplied to later layers for scoring.",
      "tex_start": 5922,
      "tex_end": 6274,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.339667,
          "width": 0.295798,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.324228,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.307601,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.292162,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.275534,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.260095,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.243468,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.42521,
          "top": 0.226841,
          "width": 0.0621849,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804284",
    "type": "sentence",
    "attributes": {
      "text": "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform SRL, we more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
      "tex": "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform SRL,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
      "tex_start": 6275,
      "tex_end": 6667,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.452494,
          "width": 0.248739,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.437055,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.420428,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.4038,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.388361,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.371734,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.356295,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.430252,
          "top": 0.339667,
          "width": 0.0571429,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804285",
    "type": "sentence",
    "attributes": {
      "text": "The model is trained such that, as syntactic parsing models improve, providing high-quality parses at test time will improve its performance, allowing the model to leverage updated parsing models without requiring re-training.",
      "tex": "The model is trained such that, as syntactic parsing models improve, providing high-quality parses at test time will improve its performance, allowing the model to leverage updated parsing models without requiring re-training.",
      "tex_start": 6668,
      "tex_end": 6894,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.533254,
          "width": 0.137815,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.516627,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.501188,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.484561,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.469121,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.386555,
          "top": 0.452494,
          "width": 0.10084,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804286",
    "type": "sentence",
    "attributes": {
      "text": "In experiments on the CoNLL-2005 and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
      "tex": "In experiments on the CoNLL-2005 and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
      "tex_start": 7089,
      "tex_end": 7239,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.60095,
          "width": 0.226891,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.584323,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.568884,
          "width": 0.368067,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.141176,
          "top": 0.552257,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804287",
    "type": "sentence",
    "attributes": {
      "tex": "On CoNLL-2005 with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
      "text": "On CoNLL-2005 with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
      "tex_start": 7240,
      "tex_end": 7422,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.665083,
          "width": 0.215126,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.648456,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.633017,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.61639,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.359664,
          "top": 0.60095,
          "width": 0.127731,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804288",
    "type": "sentence",
    "attributes": {
      "text": "On the challenging out-of-domain Brown test set, our model improves substantially over the previous state-of-the-art by more than 3.5 F1, a nearly 10\\% reduction in error.",
      "tex": "On the challenging out-of-domain Brown test set, our model improves substantially over the previous state-of-the-art by more than 3.5 F1, a nearly 10\\% reduction in error.",
      "tex_start": 7423,
      "tex_end": 7594,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.712589,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.69715,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.680523,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.346218,
          "top": 0.665083,
          "width": 0.141176,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804289",
    "type": "sentence",
    "attributes": {
      "text": "On CoNLL-2012, our model gains more than 2.5 F1 absolute over the previous state-of-the-art.",
      "tex": "On CoNLL-2012, our model gains more than 2.5 F1 absolute over the previous state-of-the-art.",
      "tex_start": 7595,
      "tex_end": 7687,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.122689,
          "top": 0.745843,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.729216,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804290",
    "type": "sentence",
    "attributes": {
      "text": "Model.",
      "tex": "\n\n\\section{Model}\n\n\\begin{figure}[",
      "tex_start": 8065,
      "tex_end": 8099,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804291",
    "type": "sentence",
    "attributes": {
      "text": "t  scale=.8no_words_simpler_compact-srl-model.pdf",
      "tex": "t]\n\\begin{center}\n\\includegraphics[scale=.8]{no_words_simpler_compact-srl-model.pdf",
      "tex_start": 8099,
      "tex_end": 8182,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804292",
    "type": "sentence",
    "attributes": {
      "text": "Word embeddings are input to <<equation-7>> layers of multi-head self-attention.",
      "tex": "Word embeddings are input to $J$ layers of multi-head self-attention.",
      "tex_start": 8193,
      "tex_end": 8262,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.515966,
          "top": 0.306413,
          "width": 0.184874,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.584874,
          "top": 0.289786,
          "width": 0.29916,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804293",
    "type": "sentence",
    "attributes": {
      "text": "In layer <<equation-8>> one attention head is trained to attend to parse parents (Figure (Ref attention-fig)).",
      "tex": "In layer $p$ one attention head is trained to attend to parse parents (Figure \\ref{attention-fig}).",
      "tex_start": 8263,
      "tex_end": 8362,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.52605,
          "top": 0.339667,
          "width": 0.00840336,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.321853,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.712605,
          "top": 0.306413,
          "width": 0.169748,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804294",
    "type": "sentence",
    "attributes": {
      "text": "Layer <<equation-9>> is input for a joint predicate/POS classifier.",
      "tex": "Layer $r$ is input for a joint predicate/POS classifier.",
      "tex_start": 8363,
      "tex_end": 8419,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.355107,
          "width": 0.0369748,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.542857,
          "top": 0.33848,
          "width": 0.339496,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804295",
    "type": "sentence",
    "attributes": {
      "text": "Representations from layer <<equation-10>> corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token SRL predictions with respect to each predicted predicate.",
      "tex": "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token SRL predictions with respect to each predicted predicate.",
      "tex_start": 8420,
      "tex_end": 8655,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.41924,
          "width": 0.292437,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.515966,
          "top": 0.402613,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.515966,
          "top": 0.387173,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.370546,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.571429,
          "top": 0.355107,
          "width": 0.310924,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804296",
    "type": "sentence",
    "attributes": {
      "text": "(Label architecture-fig)",
      "tex": "\\label{architecture-fig}}",
      "tex_start": 8655,
      "tex_end": 8680,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804297",
    "type": "sentence",
    "attributes": {
      "text": "t  scale=.24attention-keynote",
      "tex": "t]\n\\begin{center}\n\\includegraphics[scale=.24]{attention-keynote",
      "tex_start": 8723,
      "tex_end": 8786,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804298",
    "type": "sentence",
    "attributes": {
      "text": "Syntactically-informed self-attention for the query word sloth.",
      "tex": "Syntactically-informed self-attention for the query word \\emph{sloth}.",
      "tex_start": 8797,
      "tex_end": 8867,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.337292,
          "width": 0.161345,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.191597,
          "top": 0.320665,
          "width": 0.297479,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804299",
    "type": "sentence",
    "attributes": {
      "text": "Attention weights <<equation-11>> heavily weight the token's syntactic governor, saw, in a weighted average over the token values <<equation-12>>.",
      "tex": "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
      "tex_start": 8868,
      "tex_end": 9013,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.385986,
          "width": 0.0823529,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.121008,
          "top": 0.369359,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.353919,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.29916,
          "top": 0.337292,
          "width": 0.186555,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804300",
    "type": "sentence",
    "attributes": {
      "text": "The other attention heads act as usual, and the attended representations from all heads are concatenated and projected through a feed-forward layer to produce the syntactically-informed representation for sloth.",
      "tex": "The other attention heads act as usual, and the attended representations from all heads are concatenated and projected through a feed-forward layer to produce the syntactically-informed representation for \\emph{sloth}.",
      "tex_start": 9014,
      "tex_end": 9232,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.450119,
          "width": 0.243697,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.433492,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.418052,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.401425,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.228571,
          "top": 0.385986,
          "width": 0.258824,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804301",
    "type": "sentence",
    "attributes": {
      "tex_end": 9255,
      "text": "(Label attention-fig)",
      "tex": "\\label{attention-fig}}",
      "tex_start": 9233,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804302",
    "type": "sentence",
    "attributes": {
      "text": "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end SRL.",
      "tex": "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end SRL.",
      "tex_start": 9283,
      "tex_end": 9443,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.539192,
          "width": 0.0857143,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.515966,
          "top": 0.523753,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.515966,
          "top": 0.507126,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.515966,
          "top": 0.491686,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804303",
    "type": "sentence",
    "attributes": {
      "text": "LISA achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
      "tex": "LISA achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
      "tex_start": 9444,
      "tex_end": 9618,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.587886,
          "width": 0.243697,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.515966,
          "top": 0.572447,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.555819,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.611765,
          "top": 0.539192,
          "width": 0.270588,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804304",
    "type": "sentence",
    "attributes": {
      "text": "Figure (Ref architecture-fig) depicts the overall architecture of our model.",
      "tex": "Figure~\\ref{architecture-fig} depicts the overall architecture of our model.",
      "tex_start": 9820,
      "tex_end": 9896,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.515966,
          "top": 0.62114,
          "width": 0.0504202,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.536134,
          "top": 0.605701,
          "width": 0.347899,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804305",
    "type": "sentence",
    "attributes": {
      "text": "The basis for our model is the Transformer encoder introduced by Citation (vaswani2017attention): we transform word embeddings into contextually-encoded token representations using stacked multi-head self-attention and feed-forward layers ((Ref sec:self-attn)).",
      "tex": "The basis for our model is the Transformer encoder introduced by \\citet{vaswani2017attention}: we transform word embeddings into contextually-encoded token representations using stacked multi-head self-attention and feed-forward layers (\\S\\ref{sec:self-attn}).",
      "tex_start": 9897,
      "tex_end": 10157,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.700713,
          "width": 0.156303,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.686461,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.515966,
          "top": 0.669834,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.654394,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.637767,
          "width": 0.236975,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.584874,
          "top": 0.62114,
          "width": 0.297479,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804306",
    "type": "sentence",
    "attributes": {
      "text": "To incorporate syntax, one self-attention head is trained to attend to each token's syntactic parent, allowing the model to use this attention head as an oracle for syntactic dependencies.",
      "tex": "To incorporate syntax, one self-attention head is trained to attend to each token's syntactic parent, allowing the model to use this attention head as an oracle for syntactic dependencies.",
      "tex_start": 10267,
      "tex_end": 10455,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.515966,
          "top": 0.768409,
          "width": 0.30084,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.515966,
          "top": 0.751781,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.735154,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.534454,
          "top": 0.719715,
          "width": 0.347899,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804307",
    "type": "sentence",
    "attributes": {
      "text": "We introduce this syntactically-informed self-attention (Figure (Ref attention-fig)) in more detail in (Ref sec:syntax-attn).",
      "tex": "We introduce this \\emph{syntactically-informed self-attention} (Figure \\ref{attention-fig}) in more detail in \\S\\ref{sec:syntax-attn}.",
      "tex_start": 10456,
      "tex_end": 10590,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.799287,
          "width": 0.236975,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.783848,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.833613,
          "top": 0.768409,
          "width": 0.0487395,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804308",
    "type": "sentence",
    "attributes": {
      "text": "Our model is designed for the more realistic setting in which gold predicates are not provided at test-time.",
      "tex": "Our model is designed for the more realistic setting in which gold predicates are not provided at test-time.",
      "tex_start": 11420,
      "tex_end": 11528,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.849169,
          "width": 0.0655462,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.833729,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.534454,
          "top": 0.817102,
          "width": 0.347899,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804309",
    "type": "sentence",
    "attributes": {
      "text": "Our model predicts predicates and integrates part-of-speech (POS) information into earlier layers by re-purposing representations closer to the input to predict predicate and POS tags using hard parameter sharing ((Ref sec:MTL)).",
      "tex": "Our model predicts predicates and integrates part-of-speech (POS) information into earlier layers by re-purposing representations closer to the input to predict predicate and POS tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
      "tex_start": 11529,
      "tex_end": 11760,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.897862,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.882423,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.515966,
          "top": 0.865796,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.591597,
          "top": 0.849169,
          "width": 0.290756,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804310",
    "type": "sentence",
    "attributes": {
      "text": "We simplify optimization and benefit from shared statistical strength derived from highly correlated POS and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of POS and predicate labels.",
      "tex": "We simplify optimization and benefit from shared statistical strength derived from highly correlated POS and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of POS and predicate labels.",
      "tex_start": 11761,
      "tex_end": 12048,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.589074,
          "width": 0.184874,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.572447,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.557007,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.121008,
          "top": 0.54038,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.524941,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.121008,
          "top": 0.508314,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.396639,
          "top": 0.492874,
          "width": 0.0907563,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804311",
    "type": "sentence",
    "attributes": {
      "text": "Though typical models, which re-encode the sentence for each predicate, can simplify SRL to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
      "tex": "Though typical models, which re-encode the sentence for each predicate, can simplify SRL to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
      "tex_start": 12051,
      "tex_end": 12258,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.669834,
          "width": 0.131092,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.121008,
          "top": 0.654394,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.637767,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.622328,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.139496,
          "top": 0.605701,
          "width": 0.347899,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804312",
    "type": "sentence",
    "attributes": {
      "text": "Contextually encoded tokens are projected to distinct predicate and role embeddings ((Ref sec:srl)), and each predicted predicate is scored with the sequence's role representations using a bilinear model (Eqn.",
      "tex": "Contextually encoded tokens are projected to distinct \\emph{predicate} and \\emph{role} embeddings (\\S\\ref{sec:srl}), and each predicted predicate is scored with the sequence's role representations using a bilinear model (Eqn.",
      "tex_start": 12259,
      "tex_end": 12484,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.733967,
          "width": 0.189916,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.718527,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.700713,
          "width": 0.364706,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.121008,
          "top": 0.686461,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.268908,
          "top": 0.669834,
          "width": 0.218487,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804313",
    "type": "sentence",
    "attributes": {
      "text": "(Ref eqn:bilinear)), producing per-label scores for BIO-encoded semantic role labels for each token and each semantic frame.",
      "tex": "\\ref{eqn:bilinear}), producing per-label scores for BIO-encoded semantic role labels for each token and each semantic frame.",
      "tex_start": 12485,
      "tex_end": 12609,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.121008,
          "top": 0.766033,
          "width": 0.268908,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.750594,
          "width": 0.366387,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.327731,
          "top": 0.733967,
          "width": 0.157983,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804314",
    "type": "sentence",
    "attributes": {
      "text": "The model is trained end-to-end by maximum likelihood using stochastic gradient descent ((Ref sec:train-opt)).",
      "tex": "The model is trained end-to-end by maximum likelihood using stochastic gradient descent (\\S\\ref{sec:train-opt}).",
      "tex_start": 12612,
      "tex_end": 12724,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.7981,
          "width": 0.364706,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.139496,
          "top": 0.783848,
          "width": 0.347899,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804315",
    "type": "sentence",
    "attributes": {
      "text": "Self-attention token encoder \\label{sec:self-attn.",
      "tex": " \n\n\\subsection{Self-attention token encoder \\label{sec:self-attn}}",
      "tex_start": 12724,
      "tex_end": 12790,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.121008,
          "top": 0.826603,
          "width": 0.262185,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804316",
    "type": "sentence",
    "attributes": {
      "text": "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on SRL Citation (tan2018deep), and which provides a natural mechanism for incorporating syntax, as described in (Ref sec:syntax-attn).",
      "tex": "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on SRL \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
      "tex_start": 12791,
      "tex_end": 13049,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.157983,
          "top": 0.897862,
          "width": 0.329412,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.882423,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.121008,
          "top": 0.865796,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.121008,
          "top": 0.849169,
          "width": 0.366387,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.0783848,
          "width": 0.331092,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804317",
    "type": "sentence",
    "attributes": {
      "text": "Our implementation replicates Citation (vaswani2017attention).",
      "tex": "Our implementation replicates \\citet{vaswani2017attention}.",
      "tex_start": 13050,
      "tex_end": 13109,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.0950119,
          "width": 0.352941,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.855462,
          "top": 0.0795724,
          "width": 0.0285714,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804318",
    "type": "sentence",
    "attributes": {
      "text": "The input to the network is a sequence <<equation-13>> of <<equation-14>> token representations <<equation-15>>.",
      "tex": "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_t$.",
      "tex_start": 13112,
      "tex_end": 13200,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.128266,
          "width": 0.183193,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.534454,
          "top": 0.111639,
          "width": 0.34958,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804319",
    "type": "sentence",
    "attributes": {
      "text": "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other SRL models Citation (peters2018deep).",
      "tex": "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other SRL models \\citep{peters2018deep}.",
      "tex_start": 13201,
      "tex_end": 13522,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.552941,
          "top": 0.241093,
          "width": 0.010084,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.224466,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.207838,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.192399,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.175772,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.160333,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.143705,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.715966,
          "top": 0.128266,
          "width": 0.166387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804320",
    "type": "sentence",
    "attributes": {
      "text": "For experiments with gold predicates, we concatenate a predicate indicator embedding <<equation-16>> following previous work Citation (he2017deep).",
      "tex": "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_t$ following previous work \\citep{he2017deep}.",
      "tex_start": 13523,
      "tex_end": 13657,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.271971,
          "width": 0.305882,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.256532,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.57479,
          "top": 0.239905,
          "width": 0.309244,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804321",
    "type": "sentence",
    "attributes": {
      "text": "We projectAll linear projections include bias terms, which we omit in this exposition for the sake of clarity.",
      "tex": "We project\\footnote{All linear projections include bias terms, which we omit in this exposition for the sake of clarity.",
      "tex_start": 14889,
      "tex_end": 15009,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.86342,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.847981,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.831354,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.815915,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.795724,
          "width": 0.363025,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.775534,
          "width": 0.364706,
          "height": 0.0178147
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.756532,
          "width": 0.366387,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.73753,
          "width": 0.363025,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.723278,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.707838,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.536134,
          "top": 0.688836,
          "width": 0.346218,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.536134,
          "top": 0.288599,
          "width": 0.0806723,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804322",
    "type": "sentence",
    "attributes": {
      "text": "these input embeddings to a representation that is the same size as the output of the self-attention layers.",
      "tex": "these input embeddings to a representation that is the same size as the output of the self-attention layers.",
      "tex_start": 15011,
      "tex_end": 15119,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.320665,
          "width": 0.17479,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.305226,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.633613,
          "top": 0.288599,
          "width": 0.248739,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804323",
    "type": "sentence",
    "attributes": {
      "text": "We then add a positional encoding vector computed as a deterministic sinusoidal function of <<equation-17>>, since the self-attention has no innate notion of token position.",
      "tex": "We then add a positional encoding vector computed as a deterministic sinusoidal function of $t$, since the self-attention has no innate notion of token position.",
      "tex_start": 15120,
      "tex_end": 15281,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.369359,
          "width": 0.226891,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.352732,
          "width": 0.364706,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.337292,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.702521,
          "top": 0.320665,
          "width": 0.178151,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804324",
    "type": "sentence",
    "attributes": {
      "text": "We feed this token representation as input to a series of <<equation-18>> residual multi-head self-attention layers with feed-forward connections.",
      "tex": "We feed this token representation as input to a series of $J$ residual multi-head self-attention layers with feed-forward connections.",
      "tex_start": 17953,
      "tex_end": 18087,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.416865,
          "width": 0.258824,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.401425,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.536134,
          "top": 0.384798,
          "width": 0.347899,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804325",
    "type": "sentence",
    "attributes": {
      "text": "Denoting the <<equation-19>>th self-attention layer as <<equation-20>>, the output of that layer <<equation-21>>, and <<equation-22>> layer normalization, the following recurrence applied to initial input <<equation-23>>: <<equation-24>> gives our final token representations <<equation-25>>.",
      "tex": "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
      "tex_start": 18088,
      "tex_end": 18508,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.526128,
          "width": 0.314286,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.57479,
          "top": 0.495249,
          "width": 0.307563,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.465558,
          "width": 0.352941,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.447743,
          "width": 0.366387,
          "height": 0.0154394
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.431116,
          "width": 0.368067,
          "height": 0.0142518
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.788235,
          "top": 0.416865,
          "width": 0.0957983,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804326",
    "type": "sentence",
    "attributes": {
      "text": "Each <<equation-26>> consists of:",
      "tex": "Each $T^{(j)}(\\cdot)$ consists of:",
      "tex_start": 18509,
      "tex_end": 18543,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.543943,
          "width": 0.142857,
          "height": 0.0142518
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.84874,
          "top": 0.530879,
          "width": 0.0336134,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804327",
    "type": "sentence",
    "attributes": {
      "text": "(a) multi-head self-attention and",
      "tex": "(a) multi-head self-attention and",
      "tex_start": 18544,
      "tex_end": 18577,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.562945,
          "width": 0.0268908,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.67395,
          "top": 0.546318,
          "width": 0.208403,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804328",
    "type": "sentence",
    "attributes": {
      "text": "(b) a feed-forward projection.",
      "tex": "(b) a feed-forward projection.",
      "tex_start": 18578,
      "tex_end": 18608,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.54958,
          "top": 0.562945,
          "width": 0.213445,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804329",
    "type": "sentence",
    "attributes": {
      "text": "The multi-head self attention consists of <<equation-27>> attention heads, each of which learns a distinct attention function to attend to all of the tokens in the sequence.",
      "tex": "The multi-head self attention consists of $H$ attention heads, each of which learns a distinct attention function to attend to all of the tokens in the sequence.",
      "tex_start": 18610,
      "tex_end": 18771,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.627078,
          "width": 0.097479,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.611639,
          "width": 0.364706,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.595012,
          "width": 0.364706,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.534454,
          "top": 0.579572,
          "width": 0.347899,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804330",
    "type": "sentence",
    "attributes": {
      "text": "This self-attention is performed for each token for each head, and the results of the <<equation-28>> self-attentions are concatenated to form the final self-attended representation for each token.",
      "tex": "This self-attention is performed for each token for each head, and the results of the $H$ self-attentions are concatenated to form the final self-attended representation for each token.",
      "tex_start": 18772,
      "tex_end": 18957,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.675772,
          "width": 0.314286,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.659145,
          "width": 0.363025,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.643705,
          "width": 0.366387,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.623529,
          "top": 0.627078,
          "width": 0.260504,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804331",
    "type": "sentence",
    "attributes": {
      "text": "Specifically, consider the matrix <<equation-29>> of <<equation-30>> token representations at layer <<equation-31>>.",
      "tex": "Specifically, consider the matrix $S^{(j-1)}$ of $T$ token representations at layer $j-1$.",
      "tex_start": 18960,
      "tex_end": 19050,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.707838,
          "width": 0.248739,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.536134,
          "top": 0.688836,
          "width": 0.346218,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804332",
    "type": "sentence",
    "attributes": {
      "text": "For each attention head <<equation-32>>, we project this matrix into distinct key, value and query representations <<equation-33>>, <<equation-34>> and <<equation-35>> of dimensions <<equation-36>>, <<equation-37>>, and <<equation-38>>, respectively.",
      "tex": "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
      "tex_start": 19051,
      "tex_end": 19271,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.780285,
          "width": 0.0907563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.756532,
          "width": 0.366387,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.73753,
          "width": 0.363025,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.723278,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.77479,
          "top": 0.707838,
          "width": 0.107563,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804333",
    "type": "sentence",
    "attributes": {
      "text": "We can then multiply <<equation-39>> by <<equation-40>> to obtain a <<equation-41>> matrix of attention weights <<equation-42>> between each pair of tokens in the sentence.",
      "tex": "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
      "tex_start": 19272,
      "tex_end": 19428,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.815915,
          "width": 0.32605,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.795724,
          "width": 0.363025,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.618487,
          "top": 0.775534,
          "width": 0.262185,
          "height": 0.0178147
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804334",
    "type": "sentence",
    "attributes": {
      "text": "Following Citation (vaswani2017attention) we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence: <<equation-43>> These attention weights are then multiplied by <<equation-44>> for each token to obtain the self-attended token representations <<equation-45>>: <<equation-46>> Row <<equation-47>> of <<equation-48>>, the self-attended representation for token <<equation-49>> at layer <<equation-50>>, is thus the weighted sum with respect to <<equation-51>> (with weights given by <<equation-52>>) over the token representations in <<equation-53>>.",
      "tex": "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
      "tex_start": 19429,
      "tex_end": 20277,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.86342,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.847981,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.517647,
          "top": 0.831354,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.853782,
          "top": 0.815915,
          "width": 0.0285714,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.326603,
          "width": 0.215126,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.307601,
          "width": 0.366387,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.293349,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.273159,
          "width": 0.366387,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.242017,
          "top": 0.238717,
          "width": 0.245378,
          "height": 0.0178147
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.206651,
          "width": 0.186555,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.186461,
          "width": 0.364706,
          "height": 0.0178147
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.173397,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.179832,
          "top": 0.135392,
          "width": 0.307563,
          "height": 0.02019
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.111639,
          "width": 0.189916,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.0950119,
          "width": 0.364706,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.0795724,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804335",
    "type": "sentence",
    "attributes": {
      "tex": "The outputs of all attention heads for each token are concatenated, and this representation is passed to the feed-forward layer, which consists of two linear projections each followed by leaky ReLU activations \\citep{maas2012rectifier}.",
      "text": "The outputs of all attention heads for each token are concatenated, and this representation is passed to the feed-forward layer, which consists of two linear projections each followed by leaky ReLU activations Citation (maas2012rectifier).",
      "tex_start": 20280,
      "tex_end": 20516,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.412114,
          "width": 0.231933,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.395487,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.37886,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.36342,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.139496,
          "top": 0.346793,
          "width": 0.347899,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804336",
    "type": "sentence",
    "attributes": {
      "text": "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer <<equation-54>>, as in Eqn.",
      "tex": "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer $j$, as in Eqn.",
      "tex_start": 20517,
      "tex_end": 20679,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.45962,
          "width": 0.302521,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.444181,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.427553,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.368067,
          "top": 0.412114,
          "width": 0.119328,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804337",
    "type": "sentence",
    "attributes": {
      "text": "(Ref eqn:overall).",
      "tex": "\\ref{eqn:overall}.",
      "tex_start": 20680,
      "tex_end": 20698,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.442017,
          "top": 0.466746,
          "width": 0.00168067,
          "height": 0.00118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804338",
    "type": "sentence",
    "attributes": {
      "text": "Syntactically-informed self-attention \\label{sec:syntax-attn.",
      "tex": "\n\n\\subsection{Syntactically-informed self-attention \\label{sec:syntax-attn}}",
      "tex_start": 20698,
      "tex_end": 20774,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.485748,
          "width": 0.32605,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804339",
    "type": "sentence",
    "attributes": {
      "text": "Typically, neural attention mechanisms are left on their own to learn to attend to relevant inputs.",
      "tex": "Typically, neural attention mechanisms are left on their own to learn to attend to relevant inputs.",
      "tex_start": 20775,
      "tex_end": 20874,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.524941,
          "width": 0.334454,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.508314,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804340",
    "type": "sentence",
    "attributes": {
      "text": "Instead, we propose training the self-attention to attend to specific tokens corresponding to the syntactic structure of the sentence as a mechanism for passing linguistic knowledge to later layers.",
      "tex": "Instead, we propose training the self-attention to attend to specific tokens corresponding to the syntactic structure of the sentence as a mechanism for passing linguistic knowledge to later layers.",
      "tex_start": 20875,
      "tex_end": 21073,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.589074,
          "width": 0.319328,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.573634,
          "width": 0.366387,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.557007,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.54038,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.467227,
          "top": 0.524941,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804341",
    "type": "sentence",
    "attributes": {
      "text": "Specifically, we replace one attention head with the deep bi-affine model of Citation (dozat2017deep), trained to predict syntactic dependencies.",
      "tex": "Specifically, we replace one attention head with the deep bi-affine model of \\citet{dozat2017deep}, trained to predict syntactic dependencies.",
      "tex_start": 21076,
      "tex_end": 21218,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.637767,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.62114,
          "width": 0.206723,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.141176,
          "top": 0.605701,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804342",
    "type": "sentence",
    "attributes": {
      "text": "Let <<equation-55>> be the parse attention weights, at layer <<equation-56>>.",
      "tex": "Let $A_{parse}$ be the parse attention weights, at layer $i$.",
      "tex_start": 21219,
      "tex_end": 21280,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.672209,
          "width": 0.010084,
          "height": 0.00593824
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.12437,
          "top": 0.669834,
          "width": 0.00168067,
          "height": 0.00118765
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.653207,
          "width": 0.366387,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804343",
    "type": "sentence",
    "attributes": {
      "text": "Its input is the matrix of token representations <<equation-57>>.",
      "tex": "Its input is the matrix of token representations $S^{(i-1)}$.",
      "tex_start": 21281,
      "tex_end": 21342,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.684085,
          "width": 0.0487395,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.142857,
          "top": 0.669834,
          "width": 0.344538,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804344",
    "type": "sentence",
    "attributes": {
      "text": "As with the other attention heads, we project <<equation-58>> into key, value and query representations, denoted <<equation-59>>, <<equation-60>>, <<equation-61>>.",
      "tex": "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, $V_{parse}$.",
      "tex_start": 21343,
      "tex_end": 21490,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.71734,
          "width": 0.292437,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.699525,
          "width": 0.366387,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.191597,
          "top": 0.686461,
          "width": 0.295798,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804345",
    "type": "sentence",
    "attributes": {
      "tex_end": 21740,
      "text": "Here the key and query projections correspond to <<equation-62>> and <<equation-63>> representations of the tokens, and we allow their dimensions to differ from the rest of the attention heads to more closely follow the implementation of Citation (dozat2017deep).",
      "tex": "Here the key and query projections correspond to $parent$ and $dependent$ representations of the tokens, and we allow their dimensions to differ from the rest of the attention heads to more closely follow the implementation of \\citet{dozat2017deep}.",
      "tex_start": 21491,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.7981,
          "width": 0.327731,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.78266,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.766033,
          "width": 0.366387,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.749406,
          "width": 0.366387,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.733967,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.426891,
          "top": 0.718527,
          "width": 0.0605042,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804346",
    "type": "sentence",
    "attributes": {
      "text": "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between <<equation-64>> and <<equation-65>> using a bi-affine operator <<equation-66>> to obtain attention weights: <<equation-67>> These attention weights are used to compose a weighted average of the value representations <<equation-68>> as in the other attention heads.",
      "tex": "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
      "tex_start": 21741,
      "tex_end": 22179,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.144538,
          "top": 0.889549,
          "width": 0.342857,
          "height": 0.0178147
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.86342,
          "width": 0.317647,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.845606,
          "width": 0.364706,
          "height": 0.0142518
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.831354,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.814727,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.460504,
          "top": 0.7981,
          "width": 0.0268908,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.111639,
          "width": 0.270588,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.0950119,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.0795724,
          "width": 0.368067,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804347",
    "type": "sentence",
    "attributes": {
      "text": "We apply auxiliary supervision at this attention head to encourage it to attend to each token's parent in a syntactic dependency tree, and to encode information about the token's dependency label.",
      "tex": "We apply auxiliary supervision at this attention head to encourage it to attend to each token's parent in a syntactic dependency tree, and to encode information about the token's dependency label.",
      "tex_start": 22181,
      "tex_end": 22377,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.175772,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.160333,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.143705,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.536134,
          "top": 0.128266,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804348",
    "type": "sentence",
    "attributes": {
      "text": "Denoting the attention weight from token <<equation-69>> to a candidate head <<equation-70>> as <<equation-71>>, we model the probability of token <<equation-72>> having parent <<equation-73>> as: <<equation-74>> using the attention weights <<equation-75>> as the distribution over possible heads for token <<equation-76>>.",
      "tex": "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
      "tex_start": 22378,
      "tex_end": 22708,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.299287,
          "width": 0.282353,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.28266,
          "width": 0.364706,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.571429,
          "top": 0.252969,
          "width": 0.310924,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.224466,
          "width": 0.297479,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.206651,
          "width": 0.368067,
          "height": 0.0142518
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.192399,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804349",
    "type": "sentence",
    "attributes": {
      "text": "We define the root token as having a self-loop.",
      "tex": "We define the root token as having a self-loop.",
      "tex_start": 22709,
      "tex_end": 22756,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.315914,
          "width": 0.272269,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.810084,
          "top": 0.299287,
          "width": 0.0739496,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804350",
    "type": "sentence",
    "attributes": {
      "text": "This attention head thus emits a directed graphUsually the head emits a tree, but we do not enforce it here.",
      "tex": "This attention head thus emits a directed graph\\footnote{Usually the head emits a tree, but we do not enforce it here.",
      "tex_start": 22757,
      "tex_end": 22875,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.862233,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.846793,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.830166,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.814727,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.7981,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.78266,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.766033,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.750594,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.733967,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.718527,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.7019,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.679335,
          "width": 0.193277,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.653207,
          "width": 0.305882,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.637767,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.62114,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.605701,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.589074,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.573634,
          "width": 0.364706,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.557007,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.541568,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.524941,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.509501,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.534454,
          "top": 0.492874,
          "width": 0.34958,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.620168,
          "top": 0.476247,
          "width": 0.171429,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.460808,
          "width": 0.315966,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.440618,
          "width": 0.366387,
          "height": 0.0154394
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.428741,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.410926,
          "width": 0.366387,
          "height": 0.0142518
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.396675,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.536134,
          "top": 0.380048,
          "width": 0.346218,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.36342,
          "width": 0.248739,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.347981,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.331354,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.801681,
          "top": 0.315914,
          "width": 0.0806723,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804351",
    "type": "sentence",
    "attributes": {
      "text": "where each token's parent is the token to which the attention <<equation-77>> assigns the highest weight.",
      "tex": "where each token's parent is the token to which the attention $A_{parse}$ assigns the highest weight.",
      "tex_start": 22877,
      "tex_end": 22978,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.36342,
          "width": 0.248739,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.347981,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.801681,
          "top": 0.331354,
          "width": 0.0806723,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804352",
    "type": "sentence",
    "attributes": {
      "text": "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations <<equation-78>> and <<equation-79>> to produce per-label scores, with locally normalized probabilities over dependency labels <<equation-80>> given by the softmax function.",
      "tex": "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
      "tex_start": 22981,
      "tex_end": 23257,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.460808,
          "width": 0.152941,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.440618,
          "width": 0.366387,
          "height": 0.0154394
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.428741,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.410926,
          "width": 0.366387,
          "height": 0.0142518
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.396675,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.536134,
          "top": 0.380048,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804353",
    "type": "sentence",
    "attributes": {
      "text": "We refer the reader to Citation (dozat2017deep) for more details.",
      "tex": "We refer the reader to \\citet{dozat2017deep} for more details.",
      "tex_start": 23258,
      "tex_end": 23320,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.620168,
          "top": 0.476247,
          "width": 0.171429,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.678992,
          "top": 0.460808,
          "width": 0.154622,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804354",
    "type": "sentence",
    "attributes": {
      "text": "This attention head now becomes an oracle for syntax, denoted <<equation-81>>, providing a dependency parse to downstream layers.",
      "tex": "This attention head now becomes an oracle for syntax, denoted $\\mathcal{P}$, providing a dependency parse to downstream layers.",
      "tex_start": 23322,
      "tex_end": 23449,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.524941,
          "width": 0.163025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.509501,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.534454,
          "top": 0.492874,
          "width": 0.34958,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804355",
    "type": "sentence",
    "attributes": {
      "text": "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting <<equation-82>> to the parse parents produced by e.g. a state-of-the-art parser.",
      "tex": "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{parse}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
      "tex_start": 23450,
      "tex_end": 23672,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.589074,
          "width": 0.27563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.573634,
          "width": 0.364706,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.557007,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.541568,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.692437,
          "top": 0.524941,
          "width": 0.189916,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804356",
    "type": "sentence",
    "attributes": {
      "text": "In this way, our model can benefit from improved, external parsing models without re-training.",
      "tex": "In this way, our model can benefit from improved, external parsing models without re-training.",
      "tex_start": 23673,
      "tex_end": 23767,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.62114,
          "width": 0.263866,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.605701,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.8,
          "top": 0.589074,
          "width": 0.0823529,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804357",
    "type": "sentence",
    "attributes": {
      "text": "Unlike typical multi-task models, ours maintains the ability to leverage external syntactic information.",
      "tex": "Unlike typical multi-task models, ours maintains the ability to leverage external syntactic information.",
      "tex_start": 23973,
      "tex_end": 24077,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.653207,
          "width": 0.305882,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.637767,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.793277,
          "top": 0.62114,
          "width": 0.0890756,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804358",
    "type": "sentence",
    "attributes": {
      "tex_end": 25773,
      "text": "Multi-task learning \\label{sec:MTL.",
      "tex": "\n\\subsection{Multi-task learning \\label{sec:MTL}}",
      "tex_start": 25724,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.679335,
          "width": 0.193277,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804359",
    "type": "sentence",
    "attributes": {
      "text": "We also share the parameters of lower layers in our model to predict POS tags and predicates.",
      "tex": "We also share the parameters of lower layers in our model to predict POS tags and predicates.",
      "tex_start": 25774,
      "tex_end": 25867,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.718527,
          "width": 0.322689,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.7019,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804360",
    "type": "sentence",
    "attributes": {
      "text": "Following Citation (he2017deep), we focus on the end-to-end setting, where predicates must be predicted on-the-fly.",
      "tex": "Following \\citet{he2017deep}, we focus on the end-to-end setting, %which most closely resembles SRL ``in the wild,'' \nwhere predicates must be predicted on-the-fly.",
      "tex_start": 25868,
      "tex_end": 26032,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.766033,
          "width": 0.0756303,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.750594,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.733967,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.853782,
          "top": 0.718527,
          "width": 0.0285714,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804361",
    "type": "sentence",
    "attributes": {
      "text": "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of POS information.",
      "tex": "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of POS information.",
      "tex_start": 26033,
      "tex_end": 26162,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.7981,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.78266,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.615126,
          "top": 0.766033,
          "width": 0.267227,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804362",
    "type": "sentence",
    "attributes": {
      "text": "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach Citation (caruana1993multitask), sharing the parameters of earlier layers in our SRL model with a joint POS and predicate detection objective.",
      "tex": "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint POS and predicate detection objective.",
      "tex_start": 26163,
      "tex_end": 26477,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.111639,
          "width": 0.12437,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.0950119,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.157983,
          "top": 0.0795724,
          "width": 0.329412,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.862233,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.846793,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.830166,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.814727,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804363",
    "type": "sentence",
    "attributes": {
      "text": "Since POS is a strong predictor of predicatesAll predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
      "tex": "Since POS is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
      "tex_start": 26478,
      "tex_end": 26617,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.86342,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.846793,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.831354,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.814727,
          "width": 0.366387,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.799287,
          "width": 0.364706,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.776722,
          "width": 0.107563,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.751781,
          "width": 0.317647,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.735154,
          "width": 0.366387,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.719715,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.141176,
          "top": 0.703088,
          "width": 0.346218,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.685273,
          "width": 0.228571,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.669834,
          "width": 0.363025,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.654394,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.638955,
          "width": 0.366387,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.622328,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.226891,
          "top": 0.590261,
          "width": 0.260504,
          "height": 0.0178147
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.562945,
          "width": 0.342857,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.548694,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.532067,
          "width": 0.364706,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.516627,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.369748,
          "top": 0.5,
          "width": 0.117647,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.128266,
          "width": 0.121008,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.262185,
          "top": 0.111639,
          "width": 0.22521,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804364",
    "type": "sentence",
    "attributes": {
      "text": "and the complexity of training a multi-task model increases with the number of tasks, we combine POS tagging and predicate detection into a joint label space: For each POS tag tag which is observed co-occurring with a predicate, we add a label of the form tag:predicate.",
      "tex": "and the complexity of training a multi-task model increases with the number of tasks, we combine POS tagging and predicate detection into a joint label space: For each POS tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
      "tex_start": 26620,
      "tex_end": 26902,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.207838,
          "width": 0.363025,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.192399,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.175772,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.160333,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.143705,
          "width": 0.368067,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.258824,
          "top": 0.128266,
          "width": 0.228571,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804365",
    "type": "sentence",
    "attributes": {
      "text": "Specifically, we feed the representation <<equation-83>> from a layer <<equation-84>> preceding the syntactically-informed layer <<equation-85>> to a linear classifier to produce per-class scores <<equation-86>> for token <<equation-87>>.",
      "tex": "Specifically, we feed the representation $s_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
      "tex_start": 27024,
      "tex_end": 27212,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.271971,
          "width": 0.263866,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.256532,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.239905,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.141176,
          "top": 0.219715,
          "width": 0.342857,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804366",
    "type": "sentence",
    "attributes": {
      "text": "We compute locally-normalized probabilities using the softmax function: <<equation-88>>, where <<equation-89>> is a label in the joint space.",
      "tex": "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
      "tex_start": 27213,
      "tex_end": 27384,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.320665,
          "width": 0.166387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.30285,
          "width": 0.364706,
          "height": 0.0142518
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.288599,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.396639,
          "top": 0.271971,
          "width": 0.0907563,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804367",
    "type": "sentence",
    "attributes": {
      "text": "Predicting semantic roles \\label{sec:srl.",
      "tex": "\n\\subsection{Predicting semantic roles \\label{sec:srl}}",
      "tex_start": 27543,
      "tex_end": 27598,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.346793,
          "width": 0.236975,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804368",
    "type": "sentence",
    "attributes": {
      "text": "Our final goal is to predict semantic roles for each predicate in the sequence.",
      "tex": "Our final goal is to predict semantic roles for each predicate in the sequence.",
      "tex_start": 27599,
      "tex_end": 27678,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.384798,
          "width": 0.184874,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.368171,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804369",
    "type": "sentence",
    "attributes": {
      "tex_end": 28081,
      "text": "We score each predicate against each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles <<equation-90>> and <<equation-91>>.",
      "tex": "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{V}$ and $\\mathcal{P}$.",
      "tex_start": 27679,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.448931,
          "width": 0.231933,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.432304,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.416865,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.400238,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.314286,
          "top": 0.384798,
          "width": 0.173109,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804370",
    "type": "sentence",
    "attributes": {
      "text": "First, we project each token representation <<equation-92>> to a predicate-specific representation <<equation-93>> and a role-specific representation <<equation-94>>.",
      "tex": "First, we project each token representation $s_t^{(J)}$ to a predicate-specific representation $s_t^{pred}$ and a role-specific representation $s_t^{role}$.",
      "tex_start": 28084,
      "tex_end": 28240,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.497625,
          "width": 0.240336,
          "height": 0.0142518
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.47981,
          "width": 0.364706,
          "height": 0.0154394
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.141176,
          "top": 0.461995,
          "width": 0.342857,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804371",
    "type": "sentence",
    "attributes": {
      "text": "We then provide these representations to a bilinear transformation <<equation-95>> for scoring.",
      "tex": "We then provide these representations to a bilinear transformation $U$ for scoring.",
      "tex_start": 28396,
      "tex_end": 28479,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.532067,
          "width": 0.10084,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.516627,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.369748,
          "top": 0.5,
          "width": 0.117647,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804372",
    "type": "sentence",
    "attributes": {
      "text": "So, the role label scores <<equation-96>> for the token at index <<equation-97>> with respect to the predicate at index <<equation-98>> (i.e. token <<equation-99>> and frame <<equation-100>>) are given by: <<equation-101>> which can be computed in parallel across all semantic frames in an entire minibatch.",
      "tex": "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
      "tex_start": 28480,
      "tex_end": 28798,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.638955,
          "width": 0.267227,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.622328,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.226891,
          "top": 0.590261,
          "width": 0.260504,
          "height": 0.0178147
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.562945,
          "width": 0.342857,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.548694,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.233613,
          "top": 0.532067,
          "width": 0.253782,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804373",
    "type": "sentence",
    "attributes": {
      "text": "We calculate a locally normalized distribution over role labels for token <<equation-102>> in frame <<equation-103>> using the softmax function: <<equation-104>>.",
      "tex": "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
      "tex_start": 28799,
      "tex_end": 28999,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.685273,
          "width": 0.228571,
          "height": 0.0166271
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.669834,
          "width": 0.363025,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.654394,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.396639,
          "top": 0.638955,
          "width": 0.0907563,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804374",
    "type": "sentence",
    "attributes": {
      "text": "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores <<equation-105>> and the transition probabilities given by the training data.",
      "tex": "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores $s_{ft}$ and the transition probabilities given by the training data.",
      "tex_start": 29001,
      "tex_end": 29200,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.751781,
          "width": 0.317647,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.735154,
          "width": 0.366387,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.719715,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.141176,
          "top": 0.703088,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804375",
    "type": "sentence",
    "attributes": {
      "text": "Training \\label{sec:train-opt.",
      "tex": "\\subsection{Training \\label{sec:train-opt}}",
      "tex_start": 29265,
      "tex_end": 29308,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.776722,
          "width": 0.107563,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804376",
    "type": "sentence",
    "attributes": {
      "text": "We maximize the sum of the likelihoods of the individual tasks.",
      "tex": "We maximize the sum of the likelihoods of the individual tasks.",
      "tex_start": 29309,
      "tex_end": 29372,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.814727,
          "width": 0.105882,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.799287,
          "width": 0.364706,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804377",
    "type": "sentence",
    "attributes": {
      "text": "In order to maximize our model's ability to leverage syntax, during training we clamp <<equation-106>> to the gold parse (<<equation-107>>) and <<equation-108>> to gold predicates <<equation-109>> when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence <<equation-110>>.",
      "tex": "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
      "tex_start": 29532,
      "tex_end": 29913,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.86342,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.846793,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.121008,
          "top": 0.831354,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.238655,
          "top": 0.814727,
          "width": 0.248739,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.111639,
          "width": 0.332773,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.0950119,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.0795724,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804378",
    "type": "sentence",
    "attributes": {
      "text": "The overall objective is thus: <<equation-111>> where <<equation-112>> and <<equation-113>> are penalties on the syntactic attention loss.",
      "tex": "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
      "tex_start": 29914,
      "tex_end": 30674,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.296912,
          "width": 0.0857143,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.280285,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.62521,
          "top": 0.24228,
          "width": 0.257143,
          "height": 0.0225653
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.62521,
          "top": 0.22209,
          "width": 0.181513,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.62521,
          "top": 0.203088,
          "width": 0.132773,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.563025,
          "top": 0.155582,
          "width": 0.27395,
          "height": 0.04038
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.128266,
          "width": 0.176471,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.855462,
          "top": 0.111639,
          "width": 0.0285714,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804379",
    "type": "sentence",
    "attributes": {
      "text": "We train the model using Nadam Citation (dozat2016incorporating) SGD combined with the learning rate schedule in Citation (vaswani2017attention).",
      "tex": "We train the model using Nadam \\citep{dozat2016incorporating} SGD combined with the learning rate schedule in \\citet{vaswani2017attention}.",
      "tex_start": 31024,
      "tex_end": 31163,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.623529,
          "top": 0.344418,
          "width": 0.0504202,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.328979,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.536134,
          "top": 0.312352,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804380",
    "type": "sentence",
    "attributes": {
      "text": "In addition to MTL, we regularize our model using dropout Citation (srivastava2014dropout).",
      "tex": "In addition to MTL, we regularize our model using dropout \\citep{srivastava2014dropout}.",
      "tex_start": 31164,
      "tex_end": 31252,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.552941,
          "top": 0.377672,
          "width": 0.010084,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.361045,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.682353,
          "top": 0.344418,
          "width": 0.2,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804381",
    "type": "sentence",
    "attributes": {
      "text": "We use gradient clipping to avoid exploding gradients Citation (bengio1994learning, pascanu2013on).",
      "tex": "We use gradient clipping to avoid exploding gradients \\citep{bengio1994learning, pascanu2013on}.",
      "tex_start": 31253,
      "tex_end": 31349,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.552941,
          "top": 0.410926,
          "width": 0.010084,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.393112,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.573109,
          "top": 0.376485,
          "width": 0.309244,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804382",
    "type": "sentence",
    "attributes": {
      "text": "Additional details on optimization and hyperparameters are included in Appendix (Ref sec:supplemental).",
      "tex": "Additional details on optimization and hyperparameters are included in Appendix \\ref{sec:supplemental}.",
      "tex_start": 31350,
      "tex_end": 31453,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.425178,
          "width": 0.315966,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.571429,
          "top": 0.409739,
          "width": 0.310924,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804383",
    "type": "sentence",
    "attributes": {
      "text": "Related work.",
      "tex": "\n\\section{Related work}\n\n% Semantic role labeling\n%The CoNLL-2005 shared task \\citep{carreras2005introduction} spearheaded machine learning approaches to SRL by providing a relatively large corpus annotated with predicate-argument structure in the style of PropBank \\citep{palmer2005proposition}. \n",
      "tex_start": 33273,
      "tex_end": 33571,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.452494,
          "width": 0.144538,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804384",
    "type": "sentence",
    "attributes": {
      "text": "Early approaches to SRL Citation (pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global) focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP Citation (punyakanok2008importance).",
      "tex": "Early approaches to SRL \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
      "tex_start": 33571,
      "tex_end": 33874,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.554622,
          "top": 0.577197,
          "width": 0.0537815,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.559382,
          "width": 0.27395,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.543943,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.527316,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.554622,
          "top": 0.511876,
          "width": 0.327731,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.636975,
          "top": 0.502375,
          "width": 0.245378,
          "height": 0.0023753
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.689076,
          "top": 0.497625,
          "width": 0.00168067,
          "height": 0.00118765
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.47981,
          "width": 0.363025,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804385",
    "type": "sentence",
    "attributes": {
      "text": "Citation (tackstrom2015efficient) showed that constraints could be enforced more efficiently using a clever dynamic program for exact inference.",
      "tex": "\\citet{tackstrom2015efficient} showed that constraints could be enforced more efficiently using a clever dynamic program for exact inference.",
      "tex_start": 33875,
      "tex_end": 34016,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.608076,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.591449,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.742857,
          "top": 0.57601,
          "width": 0.139496,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804386",
    "type": "sentence",
    "attributes": {
      "text": "Citation (sutton2005joint) modeled syntactic parsing and SRL jointly, and Citation (lewis2015joint) jointly modeled SRL and CCG parsing.",
      "tex": "\\citet{sutton2005joint} modeled syntactic parsing and SRL jointly, and \\citet{lewis2015joint} jointly modeled SRL and CCG parsing.",
      "tex_start": 34087,
      "tex_end": 34217,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.514286,
          "top": 0.65677,
          "width": 0.290756,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.640143,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.692437,
          "top": 0.623515,
          "width": 0.189916,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804387",
    "type": "sentence",
    "attributes": {
      "text": "Citation (collobert2011natural) were among the first to use a neural network model for SRL, a CNN over word embeddings which failed to out-perform non-neural models.",
      "tex": "\\citet{collobert2011natural} were among the first to use a neural network model for SRL, a CNN over word embeddings which failed to out-perform non-neural models.",
      "tex_start": 34937,
      "tex_end": 35099,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.720903,
          "width": 0.142857,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.704276,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.688836,
          "width": 0.366387,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.655462,
          "top": 0.672209,
          "width": 0.226891,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804388",
    "type": "sentence",
    "attributes": {
      "text": "Citation (fitzgerald2015semantic) successfully employed neural networks by embedding lexicalized features and providing them as factors in the model of Citation (tackstrom2015efficient).",
      "tex": "\\citet{fitzgerald2015semantic} successfully employed neural networks by embedding lexicalized features and providing them as factors in the model of \\citet{tackstrom2015efficient}.",
      "tex_start": 35242,
      "tex_end": 35422,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.769596,
          "width": 0.344538,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.752969,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.736342,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.8,
          "top": 0.720903,
          "width": 0.0823529,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804389",
    "type": "sentence",
    "attributes": {
      "tex_start": 35572,
      "tex_end": 35614,
      "text": "More recent neural models are syntax-free.",
      "tex": "More recent neural models are syntax-free.",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.536134,
          "top": 0.785036,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804390",
    "type": "sentence",
    "attributes": {
      "text": "Citation (zhou2015end), Citation (marcheggiani2017simple) and Citation (he2017deep) all use variants of deep LSTMs with constrained decoding, while Citation (tan2018deep) apply self-attention to obtain state-of-the-art SRL with gold predicates.",
      "tex": "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art SRL with gold predicates.",
      "tex_start": 35615,
      "tex_end": 35847,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.865796,
          "width": 0.243697,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.557983,
          "top": 0.849169,
          "width": 0.32437,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.833729,
          "width": 0.327731,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.817102,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.62521,
          "top": 0.801663,
          "width": 0.257143,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804391",
    "type": "sentence",
    "attributes": {
      "text": "Like this work, Citation (he2017deep) present end-to-end experiments, predicting predicates using an LSTM, and Citation (he2018jointly) jointly predict SRL spans and predicates in a model based on that of Citation (lee2017end), obtaining state-of-the-art predicted predicate SRL.",
      "tex": "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict SRL spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate SRL.",
      "tex_start": 35848,
      "tex_end": 36118,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.897862,
          "width": 0.302521,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.589916,
          "top": 0.882423,
          "width": 0.292437,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.773109,
          "top": 0.865796,
          "width": 0.109244,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.111639,
          "width": 0.322689,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.0950119,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.0795724,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804392",
    "type": "sentence",
    "attributes": {
      "text": "Concurrent to this work, Citation (peters2018deep) and Citation (he2018jointly) report significant gains on PropBank SRL by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
      "tex": "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank SRL by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
      "tex_start": 36119,
      "tex_end": 36432,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.224466,
          "width": 0.092437,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.207838,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.192399,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.175772,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.160333,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.164706,
          "top": 0.143705,
          "width": 0.322689,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.128266,
          "width": 0.337815,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.45042,
          "top": 0.111639,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804393",
    "type": "sentence",
    "attributes": {
      "text": "We find that LISA obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
      "tex": "We find that LISA obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
      "tex_start": 36433,
      "tex_end": 36565,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.256532,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.239905,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.221849,
          "top": 0.224466,
          "width": 0.265546,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804394",
    "type": "sentence",
    "attributes": {
      "text": "Some work has incorporated syntax into neural models for SRL.",
      "tex": "Some work has incorporated syntax into neural models for SRL.",
      "tex_start": 36764,
      "tex_end": 36825,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.293349,
          "width": 0.151261,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.141176,
          "top": 0.276722,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804395",
    "type": "sentence",
    "attributes": {
      "text": "Citation (roth2016neural) incorporate syntax by embedding dependency paths, and similarly Citation (marcheggiani2017encoding) encode syntax using a graph CNN over a predicted syntax tree, out-performing models without syntax on CoNLL-2009.",
      "tex": "\\citet{roth2016neural} incorporate syntax by embedding dependency paths, and similarly \\citet{marcheggiani2017encoding} encode syntax using a graph CNN over a predicted syntax tree, out-performing models without syntax on CoNLL-2009.",
      "tex_start": 36826,
      "tex_end": 37059,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.374109,
          "width": 0.218487,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.357482,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.342043,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.325416,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.308789,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.413445,
          "top": 0.293349,
          "width": 0.0739496,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804396",
    "type": "sentence",
    "attributes": {
      "text": "These works are limited to incorporating partial dependency paths between tokens whereas our technique incorporates the entire parse.",
      "tex": "These works are limited to incorporating partial dependency paths between tokens whereas our technique incorporates the entire parse.",
      "tex_start": 37060,
      "tex_end": 37193,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.421615,
          "width": 0.156303,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.406176,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.389549,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.359664,
          "top": 0.374109,
          "width": 0.127731,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804397",
    "type": "sentence",
    "attributes": {
      "text": "Additionally, Citation (marcheggiani2017encoding) report that their model does not out-perform syntax-free models on out-of-domain data, a setting in which our technique excels.",
      "tex": "Additionally, \\citet{marcheggiani2017encoding} report that their model does not out-perform syntax-free models on out-of-domain data, a setting in which our technique excels.",
      "tex_start": 37194,
      "tex_end": 37368,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.470309,
          "width": 0.327731,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.453682,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.2,
          "top": 0.438242,
          "width": 0.287395,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.287395,
          "top": 0.421615,
          "width": 0.092437,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804398",
    "type": "sentence",
    "attributes": {
      "text": "MTL Citation (caruana1993multitask) is popular in NLP, and others have proposed MTL models which incorporate subsets of the tasks we do Citation (collobert2011natural, zhang2016stack, hashimoto2017joint, peng2017deep, swayamdipta2017), and we build off work that investigates where and when to combine different tasks to achieve the best results Citation (sogaard2016deep, bingel2017identifying, alonso2017when).",
      "tex": "MTL \\citep{caruana1993multitask} is popular in NLP, and others have proposed MTL models which incorporate subsets of the tasks we do \\citep{collobert2011natural, zhang2016stack, hashimoto2017joint, peng2017deep, swayamdipta2017}, and we build off work that investigates where and when to combine different tasks to achieve the best results \\citep{sogaard2016deep, bingel2017identifying, alonso2017when}.",
      "tex_start": 37970,
      "tex_end": 38373,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.242017,
          "top": 0.62114,
          "width": 0.243697,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.603325,
          "width": 0.322689,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.587886,
          "width": 0.364706,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.571259,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.159664,
          "top": 0.557007,
          "width": 0.32605,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.159664,
          "top": 0.546318,
          "width": 0.32605,
          "height": 0.0023753
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.159664,
          "top": 0.541568,
          "width": 0.189916,
          "height": 0.00118765
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.523753,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.507126,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.141176,
          "top": 0.490499,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804399",
    "type": "sentence",
    "attributes": {
      "text": "Our specific method of incorporating supervision into self-attention is most similar to the concurrent work of Citation (liu2018learning), who use edge marginals produced by the matrix-tree algorithm as attention weights for document classification and natural language inference.",
      "tex": "Our specific method of incorporating supervision into self-attention is most similar to the concurrent work of \\citet{liu2018learning}, who use edge marginals produced by the matrix-tree algorithm as attention weights for document classification and natural language inference.",
      "tex_start": 38374,
      "tex_end": 38651,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.716152,
          "width": 0.228571,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.700713,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.684085,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.667458,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.652019,
          "width": 0.364706,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.635392,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804400",
    "type": "sentence",
    "attributes": {
      "text": "The question of training on gold versus predicted labels is closely related to learning to search Citation (daume2009search,ross2011reduction,chang2015learning) and scheduled sampling Citation (bengio2015scheduled), with applications in NLP to sequence labeling and transition-based parsing Citation (choi2011getting, goldberg2012dynamic,ballesteros2016training).",
      "tex": "The question of training on gold versus predicted labels is closely related to learning to search \\citep{daume2009search,ross2011reduction,chang2015learning} and scheduled sampling \\citep{bengio2015scheduled}, with applications in NLP to sequence labeling and transition-based parsing \\citep{choi2011getting, goldberg2012dynamic,ballesteros2016training}.",
      "tex_start": 39758,
      "tex_end": 40112,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.203361,
          "top": 0.850356,
          "width": 0.0571429,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.17479,
          "top": 0.840855,
          "width": 0.253782,
          "height": 0.0023753
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.221849,
          "top": 0.836105,
          "width": 0.206723,
          "height": 0.00118765
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.817102,
          "width": 0.294118,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.157983,
          "top": 0.801663,
          "width": 0.329412,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.164706,
          "top": 0.785036,
          "width": 0.268908,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.769596,
          "width": 0.309244,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.752969,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.139496,
          "top": 0.73753,
          "width": 0.347899,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804401",
    "type": "sentence",
    "attributes": {
      "text": "Our approach may be interpreted as an extension of teacher forcing Citation (williams1989learning) to MTL.",
      "tex": "Our approach may be interpreted as an extension of teacher forcing \\citep{williams1989learning} to MTL.",
      "tex_start": 40113,
      "tex_end": 40216,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.2,
          "top": 0.882423,
          "width": 0.112605,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.865796,
          "width": 0.29916,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.27563,
          "top": 0.849169,
          "width": 0.211765,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804402",
    "type": "sentence",
    "attributes": {
      "text": "We leave exploration of more advanced scheduled sampling techniques to future work.",
      "tex": "We leave exploration of more advanced scheduled sampling techniques to future work.",
      "tex_start": 40217,
      "tex_end": 40300,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.897862,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.317647,
          "top": 0.882423,
          "width": 0.171429,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.0795724,
          "width": 0.087395,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804403",
    "type": "sentence",
    "attributes": {
      "text": "Experimental results.",
      "tex": "\n\\section{Experimental results}\n\n\\begin{table*}[",
      "tex_start": 40738,
      "tex_end": 40786,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804404",
    "type": "sentence",
    "attributes": {
      "text": "t! llllllllllll & 3cDev && 3cWSJ Test && 3cBrown Test",
      "tex": "t!]\n% \\begin{tabular}{llll}\n% WSJ Dev & P & R & F1 \\\\ \\hline \\hline\n% % \\citet{he2017deep} single & 80.3 & 80.4 & 80.3 \\\\\n% \\citet{he2017deep} PoE & 81.8 &  81.2 & 81.5  \\\\ \n% \\citet{he2018jointly} & 81.3 & 81.9 & 81.6 \\\\ \\hline\n% % \\citet{he2018jointly}+ELMo & -- & -- & 85.3 \\\\ \\hline\n% SA &  83.52 & 81.28 & 82.39 \\\\ %79.70 &  78.59 &  79.14 \\\\\n% LISA &  83.06\t& 81.42\t& 82.23 \\\\\n% \\ \\ \\ \\ +D\\&M & {\\bf 84.25} & {\\bf 82.53} &\t{\\bf 83.38} \\\\\n% \\ \\ \\ \\ \\emph{+Gold} & \\emph{87.44} & \\emph{85.41} & \\emph{86.41} \\\\\n% & & & \\\\ \n% WSJ Test & P & R & F1 \\\\ \\hline \\hline\n% % \\citet{he2017deep} single & 80.2 & 82.3 & 81.2 \\\\\n% \\citet{he2017deep} PoE & 82.0 & 83.4 & 82.7 \\\\\n% \\citet{he2018jointly} & 81.2 & 83.9 & 82.5 \\\\ \\hline\n% % \\citet{he2018jointly}+ELMo & 84.8 & 87.2 & 86.0 \\\\ \\hline\n% SA &  84.17 &\t83.28 &\t83.72  \\\\\n% LISA & 83.81 & 83.03 &\t83.42 \\\\ \n% \\ \\ \\ \\ +D\\&M & {\\bf 85.38} & {\\bf 84.46} &\t{\\bf 84.92} \\\\ %{\\bf 83.71} &  {\\bf 83.69} &  {\\bf 83.70} \\\\\n% % & \\multicolumn{3}{c}{Brown Test} \\\\ \\cline{2-4} \n% & & & \\\\ \n% Brown Test &  P & R & F1 \\\\ \\hline \\hline\n% % \\citet{he2017deep} single & 67.6&  69.6 & 68.5\\\\\n% \\citet{he2017deep} PoE & 69.7&  70.5 & 70.1\\\\\n% \\citet{he2018jointly} & 69.7 & 71.9 & 70.8 \\\\ \\hline\n% % \\citet{he2018jointly}+ELMo & 73.9 & 78.4 & 76.1 \\\\ \\hline\n% SA &  72.98 & 70.1 & 71.51 \\\\ %70.10  & 66.01  & 67.99  \\\\\n% LISA & 72.93 &\t70.79 &\t71.84 \\\\ % 71.93  & 69.45 &  70.67 \\\\\n% \\ \\ \\ \\ +D\\&M & {\\bf 75.05} & {\\bf 72.81} & {\\bf 73.91} \\\\ % {\\bf 72.60} &  69.73 &  {\\bf 71.13} \\\\ \n% \\end{tabular}\n\\begin{tabular}{llllllllllll}\n& \\multicolumn{3}{c}{Dev} && \\multicolumn{3}{c}{WSJ Test} && \\multicolumn{3}{c}{Brown Test",
      "tex_start": 40786,
      "tex_end": 42440,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804405",
    "type": "sentence",
    "attributes": {
      "text": "2-4 6-8 10-12 GloVe & P & R & F1 && P & R & F1 && P & R & F1",
      "tex": "2-4} \\cline{6-8} \\cline{10-12}\nGloVe & P & R & F1 && P & R & F1 && P & R & F1",
      "tex_start": 42452,
      "tex_end": 42529,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804406",
    "type": "sentence",
    "attributes": {
      "text": "Citation (he2017deep) PoE & 81.8 &  81.2 & 81.5 & & 82.0 & 83.4 & 82.7 && 69.7 &  70.5 & 70.1",
      "tex": "\\citet{he2017deep} PoE & 81.8 &  81.2 & 81.5 & & 82.0 & 83.4 & 82.7 && 69.7 &  70.5 & 70.1",
      "tex_start": 42546,
      "tex_end": 42636,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.198319,
          "top": 0.112827,
          "width": 0.662185,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804407",
    "type": "sentence",
    "attributes": {
      "text": "Citation (he2018jointly) & 81.3 & 81.9 & 81.6 & & 81.2 & 83.9 & 82.5 && 69.7 & 71.9 & 70.8",
      "tex": "\\citet{he2018jointly} & 81.3 & 81.9 & 81.6 & & 81.2 & 83.9 & 82.5 && 69.7 & 71.9 & 70.8",
      "tex_start": 42641,
      "tex_end": 42728,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.198319,
          "top": 0.128266,
          "width": 0.665546,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804408",
    "type": "sentence",
    "attributes": {
      "text": "SA &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
      "tex": "SA &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
      "tex_start": 42738,
      "tex_end": 42815,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.132773,
          "top": 0.144893,
          "width": 0.737815,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804409",
    "type": "sentence",
    "attributes": {
      "text": "LISA &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
      "tex": "LISA &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
      "tex_start": 42820,
      "tex_end": 42899,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.132773,
          "top": 0.16152,
          "width": 0.737815,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804410",
    "type": "sentence",
    "attributes": {
      "text": "+D\\&M & 84.59 & 82.59 &\t83.58 && 85.53 & 84.45 & 84.99 && 75.8 & 73.54 & 74.66",
      "tex": "+D\\&M & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
      "tex_start": 42914,
      "tex_end": 43045,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.14958,
          "top": 0.17696,
          "width": 0.72437,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804411",
    "type": "sentence",
    "attributes": {
      "tex_start": 43065,
      "tex_end": 43154,
      "text": "+Gold & 87.91 & 85.73 & 86.81 && --- & --- & --- && --- & --- & ---",
      "tex": "+Gold} & \\emph{87.91} & \\emph{85.73} & \\emph{86.81} && --- & --- & --- && --- & --- & ---",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.151261,
          "top": 0.193587,
          "width": 0.70084,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804412",
    "type": "sentence",
    "attributes": {
      "text": "& & & && & & && & &",
      "tex": "& & & && & & && & &",
      "tex_start": 43158,
      "tex_end": 43177,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804413",
    "type": "sentence",
    "attributes": {
      "text": "ELMo & & & && & & && & &",
      "tex": "ELMo & & & && & & && & &",
      "tex_start": 43181,
      "tex_end": 43205,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.132773,
          "top": 0.225653,
          "width": 0.0453782,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804414",
    "type": "sentence",
    "attributes": {
      "text": "Citation (he2018jointly) & 84.9 & 85.7 & 85.3 & & 84.8 & 87.2 & 86.0 && 73.9 & 78.4 & 76.1",
      "tex": "\\citet{he2018jointly} & 84.9 & {\\bf 85.7} & 85.3 & & 84.8 & {\\bf 87.2} & 86.0 && 73.9 & {\\bf 78.4} & 76.1",
      "tex_start": 43223,
      "tex_end": 43328,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.198319,
          "top": 0.244656,
          "width": 0.662185,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804415",
    "type": "sentence",
    "attributes": {
      "text": "SA &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
      "tex": "SA &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
      "tex_start": 43338,
      "tex_end": 43415,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.132773,
          "top": 0.261283,
          "width": 0.741176,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804416",
    "type": "sentence",
    "attributes": {
      "text": "LISA &  86.07 & 84.64 & 85.35 && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
      "tex": "LISA &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
      "tex_start": 43420,
      "tex_end": 43511,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.132773,
          "top": 0.27791,
          "width": 0.741176,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804417",
    "type": "sentence",
    "attributes": {
      "text": "+D\\&M &85.83 &\t84.51 &\t85.17 && 87.13 & 86.67 & 86.90 && 79.02 & 77.49 & 78.25",
      "tex": "+D\\&M &85.83 &\t84.51 &\t85.17 && {\\bf 87.13} & 86.67 & {\\bf 86.90} && {\\bf 79.02} & 77.49 & {\\bf 78.25",
      "tex_start": 43526,
      "tex_end": 43627,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.14958,
          "top": 0.293349,
          "width": 0.72437,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804418",
    "type": "sentence",
    "attributes": {
      "text": "+Gold & 88.51 & 86.77 & 87.63 && --- & --- & --- && --- & --- & ---  Precision, recall and F1 on the CoNLL-2005 development and test sets.",
      "tex": "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets.",
      "tex_start": 43647,
      "tex_end": 44003,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.151261,
          "top": 0.309976,
          "width": 0.70084,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804419",
    "type": "sentence",
    "attributes": {
      "text": "(Label tab:conll05-results)",
      "tex": "\\label{tab:conll05-results}}",
      "tex_start": 44004,
      "tex_end": 44032,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804420",
    "type": "sentence",
    "attributes": {
      "text": "We present results on the CoNLL-2005 shared task Citation (carreras2005introduction) and the CoNLL-2012 English subset of OntoNotes 5.0 Citation (pradhan2013towards), achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
      "tex": "We present results on the CoNLL-2005 shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
      "tex_start": 44047,
      "tex_end": 44304,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.218527,
          "width": 0.122689,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.203088,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.186461,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.171021,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.154394,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.138955,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804421",
    "type": "sentence",
    "attributes": {
      "text": "We experiment with both standard pre-trained GloVe word embeddings Citation (pennington2014glove) and pre-trained ELMo representations with fine-tuned task-specific parameters Citation (peters2018deep) in order to best compare to prior work.",
      "tex": "We experiment with both standard pre-trained GloVe word embeddings \\citep{pennington2014glove} and pre-trained ELMo representations with fine-tuned task-specific parameters \\citep{peters2018deep} in order to best compare to prior work.",
      "tex_start": 44305,
      "tex_end": 44540,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.299287,
          "width": 0.104202,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.283848,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.267221,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.618487,
          "top": 0.251781,
          "width": 0.263866,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.235154,
          "width": 0.332773,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.652101,
          "top": 0.218527,
          "width": 0.230252,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804422",
    "type": "sentence",
    "attributes": {
      "text": "Hyperparameters that resulted in the best performance on the validation set were selected via a small grid search, and models were trained for a maximum of 4 days on one TitanX GPU using early stopping on the validation set.",
      "tex": "Hyperparameters that resulted in the best performance on the validation set were selected via a small grid search, and models were trained for a maximum of 4 days on one TitanX GPU using early stopping on the validation set.",
      "tex_start": 44541,
      "tex_end": 44765,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.36342,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.347981,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.331354,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.315914,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.636975,
          "top": 0.299287,
          "width": 0.245378,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804423",
    "type": "sentence",
    "attributes": {
      "text": "We convert constituencies to dependencies using the Stanford head rules v3.5 Citation (deMarneffe2008).",
      "tex": "We convert constituencies to dependencies using the Stanford head rules v3.5 \\citep{deMarneffe2008}.",
      "tex_start": 45129,
      "tex_end": 45229,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.584874,
          "top": 0.413302,
          "width": 0.0537815,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.395487,
          "width": 0.235294,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.380048,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804424",
    "type": "sentence",
    "attributes": {
      "text": "A detailed description of hyperparameter settings and data pre-processing can be found in Appendix (Ref sec:supplemental).",
      "tex": "A detailed description of hyperparameter settings and data pre-processing can be found in Appendix~\\ref{sec:supplemental}.",
      "tex_start": 45230,
      "tex_end": 45352,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.444181,
          "width": 0.157983,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.428741,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.64874,
          "top": 0.412114,
          "width": 0.233613,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804425",
    "type": "sentence",
    "attributes": {
      "text": "We compare our LISA models to four strong baselines: For experiments using predicted predicates, we compare to Citation (he2018jointly) and the ensemble model (PoE) from Citation (he2017deep), as well as a version of our own self-attention model which does not incorporate syntactic information (SA).",
      "tex": "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
      "tex_start": 45492,
      "tex_end": 45804,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.541568,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.526128,
          "width": 0.366387,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.509501,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.494062,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.477435,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.536134,
          "top": 0.461995,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804426",
    "type": "sentence",
    "attributes": {
      "text": "To compare to more prior work, we present additional results on CoNLL-2005 with models given gold predicates at test time.",
      "tex": "To compare to more prior work, we present additional results on CoNLL-2005 with models given gold predicates at test time.",
      "tex_start": 45805,
      "tex_end": 45927,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.590261,
          "width": 0.203361,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.573634,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.558195,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804427",
    "type": "sentence",
    "attributes": {
      "text": "In these experiments we also compare to Citation (tan2018deep), the previous state-of-the art SRL model using gold predicates and standard embeddings.",
      "tex": "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art SRL model using gold predicates and standard embeddings.",
      "tex_start": 45928,
      "tex_end": 46075,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.638955,
          "width": 0.189916,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.622328,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.605701,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.731092,
          "top": 0.590261,
          "width": 0.151261,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804428",
    "type": "sentence",
    "attributes": {
      "text": "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time (+D\\&M) by fixing the attention to parses predicted by Citation (dozat2017deep), the winner of the 2017 CoNLL shared task Citation (zeman2017conll) which we re-train using ELMo embeddings.",
      "tex": "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf +D\\&M}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
      "tex_start": 46077,
      "tex_end": 46366,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.552941,
          "top": 0.736342,
          "width": 0.329412,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.719715,
          "width": 0.366387,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.704276,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.687648,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.672209,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.536134,
          "top": 0.655582,
          "width": 0.346218,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804429",
    "type": "sentence",
    "attributes": {
      "text": "In all cases, using these parses at test time improves performance.",
      "tex": "In all cases, using these parses at test time improves performance.",
      "tex_start": 46367,
      "tex_end": 46434,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.768409,
          "width": 0.14958,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.751781,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804430",
    "type": "sentence",
    "attributes": {
      "text": "We also evaluate our model using the gold syntactic parse at test time (+Gold), to provide an upper bound for the benefit that syntax could have for SRL using LISA.",
      "tex": "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using LISA.",
      "tex_start": 46437,
      "tex_end": 46607,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.833729,
          "width": 0.14958,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.817102,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.801663,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.536134,
          "top": 0.785036,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804431",
    "type": "sentence",
    "attributes": {
      "text": "These experiments show that despite LISA's strong performance, there remains substantial room for improvement.",
      "tex": "These experiments show that despite LISA's strong performance, there remains substantial room for improvement.",
      "tex_start": 46608,
      "tex_end": 46718,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.865796,
          "width": 0.25042,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.849169,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.672269,
          "top": 0.833729,
          "width": 0.210084,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804432",
    "type": "sentence",
    "attributes": {
      "text": "In (Ref sec:analysis) we perform further analysis comparing SRL models using gold and predicted parses.",
      "tex": "In \\S\\ref{sec:analysis} we perform further analysis comparing SRL models using gold and predicted parses.",
      "tex_start": 46719,
      "tex_end": 46824,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.897862,
          "width": 0.216807,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.882423,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.776471,
          "top": 0.864608,
          "width": 0.105882,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804433",
    "type": "sentence",
    "attributes": {
      "text": "llll WSJ Test & P & R & F1",
      "tex": "llll}\nWSJ Test & P & R & F1",
      "tex_start": 46958,
      "tex_end": 46985,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804434",
    "type": "sentence",
    "attributes": {
      "tex_start": 47049,
      "text": "Citation (he2018jointly) & 84.2 & 83.7 & 83.9",
      "tex": "\\citet{he2018jointly} & 84.2 & 83.7 & 83.9",
      "tex_end": 47091,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.220168,
          "top": 0.396675,
          "width": 0.226891,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804435",
    "type": "sentence",
    "attributes": {
      "text": "Citation (tan2018deep) & 84.5 & 85.2 & 84.8",
      "tex": "\\citet{tan2018deep} & 84.5 & 85.2 & 84.8",
      "tex_start": 47145,
      "tex_end": 47185,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.22521,
          "top": 0.412114,
          "width": 0.221849,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804436",
    "type": "sentence",
    "attributes": {
      "text": "SA & 84.7 & 84.24 & 84.47",
      "tex": "SA & 84.7 & 84.24 & 84.47",
      "tex_start": 47259,
      "tex_end": 47284,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.152941,
          "top": 0.428741,
          "width": 0.302521,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804437",
    "type": "sentence",
    "attributes": {
      "text": "LISA & 84.72 &\t84.57\t& 84.64",
      "tex": "LISA & 84.72 &\t84.57\t& 84.64",
      "tex_start": 47288,
      "tex_end": 47316,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.152941,
          "top": 0.445368,
          "width": 0.302521,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804438",
    "type": "sentence",
    "attributes": {
      "text": "+D\\&M & 86.02 &\t86.05 &\t86.04",
      "tex": "+D\\&M & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
      "tex_start": 47354,
      "tex_end": 47400,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.169748,
          "top": 0.460808,
          "width": 0.285714,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804439",
    "type": "sentence",
    "attributes": {
      "text": "& & &",
      "tex": "& & &",
      "tex_start": 47406,
      "tex_end": 47411,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804440",
    "type": "sentence",
    "attributes": {
      "text": "Brown Test &  P & R & F1",
      "tex": "Brown Test &  P & R & F1",
      "tex_start": 47415,
      "tex_end": 47439,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.152941,
          "top": 0.492874,
          "width": 0.277311,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804441",
    "type": "sentence",
    "attributes": {
      "text": "Citation (he2018jointly) & 74.2 & 73.1 & 73.7",
      "tex": "\\citet{he2018jointly} & 74.2 & 73.1 & 73.7",
      "tex_start": 47549,
      "tex_end": 47591,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.220168,
          "top": 0.511876,
          "width": 0.226891,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804442",
    "type": "sentence",
    "attributes": {
      "text": "Citation (tan2018deep) & 73.5 & 74.6 & 74.1",
      "tex": "\\citet{tan2018deep} & 73.5 & 74.6 & 74.1",
      "tex_start": 47595,
      "tex_end": 47635,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.22521,
          "top": 0.528504,
          "width": 0.218487,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804443",
    "type": "sentence",
    "attributes": {
      "text": "SA & 73.89 & 72.39 & 73.13",
      "tex": "SA & 73.89 & 72.39 & 73.13",
      "tex_start": 47703,
      "tex_end": 47729,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.152941,
          "top": 0.545131,
          "width": 0.302521,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804444",
    "type": "sentence",
    "attributes": {
      "text": "LISA & 74.77 & 74.32 &\t74.55",
      "tex": "LISA & 74.77 & 74.32 &\t74.55",
      "tex_start": 47733,
      "tex_end": 47761,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.152941,
          "top": 0.56057,
          "width": 0.302521,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804445",
    "type": "sentence",
    "attributes": {
      "text": "+D\\&M & 76.65 & 76.44 & 76.54",
      "tex": "+D\\&M & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
      "tex_start": 47800,
      "tex_end": 47846,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.169748,
          "top": 0.577197,
          "width": 0.285714,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804446",
    "type": "sentence",
    "attributes": {
      "text": "Precision, recall and F1 on CoNLL-2005 with gold predicates.",
      "tex": "Precision, recall and F1 on CoNLL-2005 with gold predicates.",
      "tex_start": 47900,
      "tex_end": 47960,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.122689,
          "top": 0.62114,
          "width": 0.14958,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.184874,
          "top": 0.604513,
          "width": 0.302521,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804447",
    "type": "sentence",
    "attributes": {
      "text": "(Label tab:conll05-gold-pred)",
      "tex": "\\label{tab:conll05-gold-pred}}",
      "tex_start": 47961,
      "tex_end": 47991,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804448",
    "type": "sentence",
    "attributes": {
      "text": "Semantic role labeling \\label{sec:conll05.",
      "tex": "\n\n\\subsection{Semantic role labeling \\label{sec:conll05}}",
      "tex_start": 48003,
      "tex_end": 48060,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.121008,
          "top": 0.663895,
          "width": 0.213445,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804449",
    "type": "sentence",
    "attributes": {
      "text": "Table (Ref tab:conll05-results) lists precision, recall and F1 on the CoNLL-2005 development and test sets using predicted predicates.",
      "tex": "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the CoNLL-2005 development and test sets using predicted predicates.",
      "tex_start": 48062,
      "tex_end": 48196,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.121008,
          "top": 0.720903,
          "width": 0.12605,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.121008,
          "top": 0.704276,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.121008,
          "top": 0.688836,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804450",
    "type": "sentence",
    "attributes": {
      "text": "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
      "tex": "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
      "tex_start": 48197,
      "tex_end": 48343,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.122689,
          "top": 0.769596,
          "width": 0.163025,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.121008,
          "top": 0.752969,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.121008,
          "top": 0.73753,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.255462,
          "top": 0.720903,
          "width": 0.231933,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804451",
    "type": "sentence",
    "attributes": {
      "text": "LISA with its own parses performs comparably to SA, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
      "tex": "LISA with its own parses performs comparably to SA, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
      "tex_start": 48344,
      "tex_end": 48496,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.122689,
          "top": 0.817102,
          "width": 0.242017,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.122689,
          "top": 0.801663,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.121008,
          "top": 0.785036,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.294118,
          "top": 0.769596,
          "width": 0.193277,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804452",
    "type": "sentence",
    "attributes": {
      "text": "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses LISA performs exceptionally well, more than 3.5 F1 points higher than Citation (he2018jointly).",
      "tex": "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
      "tex_start": 48497,
      "tex_end": 48729,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.122689,
          "top": 0.897862,
          "width": 0.161345,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.121008,
          "top": 0.882423,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.121008,
          "top": 0.865796,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.121008,
          "top": 0.849169,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.121008,
          "top": 0.833729,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.378151,
          "top": 0.817102,
          "width": 0.109244,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804453",
    "type": "sentence",
    "attributes": {
      "text": "Incorporating ELMo embeddings improves all scores.",
      "tex": "Incorporating ELMo embeddings improves all scores.",
      "tex_start": 48730,
      "tex_end": 48780,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.29916,
          "top": 0.897862,
          "width": 0.188235,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.37886,
          "width": 0.223529,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804454",
    "type": "sentence",
    "attributes": {
      "text": "The gap in SRL F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see (Ref sec:parse-pos-results)), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
      "tex": "The gap in SRL F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
      "tex_start": 48781,
      "tex_end": 49096,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.475059,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.45962,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.442993,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.426366,
          "width": 0.366387,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.410926,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.395487,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.757983,
          "top": 0.37886,
          "width": 0.12437,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804455",
    "type": "sentence",
    "attributes": {
      "text": "In both settings LISA leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
      "tex": "In both settings LISA leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
      "tex_start": 49097,
      "tex_end": 49281,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.54038,
          "width": 0.255462,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.523753,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.508314,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.491686,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804456",
    "type": "sentence",
    "attributes": {
      "text": "To compare to more prior work we also evaluate our models in the artificial setting where gold predicates are provided at test time.",
      "tex": "To compare to more prior work we also evaluate our models in the artificial setting where gold predicates are provided at test time.",
      "tex_start": 49283,
      "tex_end": 49415,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.590261,
          "width": 0.260504,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.573634,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.534454,
          "top": 0.558195,
          "width": 0.347899,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804457",
    "type": "sentence",
    "attributes": {
      "text": "For fair comparison we use GloVe embeddings, provide predicate indicator embeddings on the input and re-encode the sequence relative to each gold predicate.",
      "tex": "For fair comparison we use GloVe embeddings, provide predicate indicator embeddings on the input and re-encode the sequence relative to each gold predicate.",
      "tex_start": 49416,
      "tex_end": 49572,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.655582,
          "width": 0.0336134,
          "height": 0.00712589
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.638955,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.622328,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.606888,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.786555,
          "top": 0.590261,
          "width": 0.0957983,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804458",
    "type": "sentence",
    "attributes": {
      "text": "Here LISA still excels: with D\\&M parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
      "tex": "Here LISA still excels: with D\\&M parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
      "tex_start": 49573,
      "tex_end": 49703,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.686461,
          "width": 0.29916,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.671021,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.563025,
          "top": 0.654394,
          "width": 0.319328,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804459",
    "type": "sentence",
    "attributes": {
      "text": "Table (Ref tab:conll12-results) reports precision, recall and F1 on the CoNLL-2012 test set.",
      "tex": "Table~\\ref{tab:conll12-results} reports precision, recall and F1 on the CoNLL-2012 test set.",
      "tex_start": 49706,
      "tex_end": 49798,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.720903,
          "width": 0.196639,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.534454,
          "top": 0.704276,
          "width": 0.347899,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804460",
    "type": "sentence",
    "attributes": {
      "text": "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our SA baseline already out-performs Citation (he2018jointly) by nearly 1.5 F1.",
      "tex": "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our SA baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
      "tex_start": 49799,
      "tex_end": 49961,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.519328,
          "top": 0.785036,
          "width": 0.0537815,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.769596,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.752969,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.73753,
          "width": 0.364706,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.736134,
          "top": 0.720903,
          "width": 0.146218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804461",
    "type": "sentence",
    "attributes": {
      "text": "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
      "tex": "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
      "tex_start": 49962,
      "tex_end": 50138,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.849169,
          "width": 0.0218487,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.833729,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.817102,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.801663,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.6,
          "top": 0.785036,
          "width": 0.282353,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804462",
    "type": "sentence",
    "attributes": {
      "text": "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
      "tex": "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
      "tex_start": 50139,
      "tex_end": 50267,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.882423,
          "width": 0.337815,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.515966,
          "top": 0.865796,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.547899,
          "top": 0.849169,
          "width": 0.334454,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804463",
    "type": "sentence",
    "attributes": {
      "text": "On this dataset ELMo also substantially narrows the difference between models with- and without syntactic information.",
      "tex": "On this dataset ELMo also substantially narrows the difference between models with- and without syntactic information.",
      "tex_start": 50268,
      "tex_end": 50386,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.122689,
          "top": 0.638955,
          "width": 0.131092,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.121008,
          "top": 0.623515,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.897862,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.860504,
          "top": 0.882423,
          "width": 0.0218487,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804464",
    "type": "sentence",
    "attributes": {
      "text": "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the D\\&M parses.",
      "tex": "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the D\\&M parses.",
      "tex_start": 50387,
      "tex_end": 50510,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.122689,
          "top": 0.672209,
          "width": 0.329412,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.122689,
          "top": 0.655582,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.260504,
          "top": 0.638955,
          "width": 0.226891,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804465",
    "type": "sentence",
    "attributes": {
      "text": "Yet, higher accuracy parses could still yield improvements since providing gold parses increases F1 by 4 points even with ELMo embeddings.",
      "tex": "Yet, higher accuracy parses could still yield improvements since providing gold parses increases F1 by 4 points even with ELMo embeddings.",
      "tex_start": 50511,
      "tex_end": 50649,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.121008,
          "top": 0.719715,
          "width": 0.282353,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.121008,
          "top": 0.704276,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.122689,
          "top": 0.687648,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.460504,
          "top": 0.672209,
          "width": 0.0252101,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804466",
    "type": "sentence",
    "attributes": {
      "text": "llll Dev & P & R & F1",
      "tex": "llll}\nDev & P & R & F1",
      "tex_start": 50681,
      "tex_end": 50703,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804467",
    "type": "sentence",
    "attributes": {
      "text": "4cGloVe",
      "tex": "4}{c}{GloVe",
      "tex_start": 50734,
      "tex_end": 50745,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804468",
    "type": "sentence",
    "attributes": {
      "text": "Citation (he2018jointly) & 79.2 & 79.7 & 79.4",
      "tex": "\\citet{he2018jointly} & 79.2 & 79.7 & 79.4",
      "tex_start": 50858,
      "tex_end": 50900,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.221849,
          "top": 0.112827,
          "width": 0.221849,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804469",
    "type": "sentence",
    "attributes": {
      "text": "SA & 82.32 & 79.76 & 81.02",
      "tex": "SA & 82.32 & 79.76 & 81.02",
      "tex_start": 50961,
      "tex_end": 50987,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.129454,
          "width": 0.297479,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804470",
    "type": "sentence",
    "attributes": {
      "text": "LISA & 81.77 & 79.65 & 80.70",
      "tex": "LISA & 81.77 & 79.65 & 80.70",
      "tex_start": 51022,
      "tex_end": 51050,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.146081,
          "width": 0.297479,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804471",
    "type": "sentence",
    "attributes": {
      "text": "+D\\&M & 82.97 & 81.14 &\t82.05",
      "tex": "+D\\&M & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
      "tex_start": 51062,
      "tex_end": 51108,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.173109,
          "top": 0.16152,
          "width": 0.280672,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804472",
    "type": "sentence",
    "attributes": {
      "text": "+Gold & 87.57 & 85.32 & 86.43",
      "tex": "+Gold} & \\emph{87.57} & \\emph{85.32} & \\emph{86.43",
      "tex_start": 51127,
      "tex_end": 51177,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.17479,
          "top": 0.178147,
          "width": 0.278992,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804473",
    "type": "sentence",
    "attributes": {
      "text": "& & &",
      "tex": "& & &",
      "tex_start": 51191,
      "tex_end": 51196,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804474",
    "type": "sentence",
    "attributes": {
      "text": "4cELMo",
      "tex": "4}{c}{ELMo",
      "tex_start": 51213,
      "tex_end": 51223,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804475",
    "type": "sentence",
    "attributes": {
      "text": "Citation (he2018jointly) & 82.1 & 84.0 & 83.0",
      "tex": "\\citet{he2018jointly} & 82.1 & {\\bf 84.0} & 83.0",
      "tex_start": 51235,
      "tex_end": 51283,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.221849,
          "top": 0.226841,
          "width": 0.221849,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804476",
    "type": "sentence",
    "attributes": {
      "text": "SA & 84.35 & 82.14 & 83.23",
      "tex": "SA & 84.35 & 82.14 & 83.23",
      "tex_start": 51294,
      "tex_end": 51320,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.243468,
          "width": 0.297479,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804477",
    "type": "sentence",
    "attributes": {
      "text": "LISA & 84.19 & 82.56 & 83.37",
      "tex": "LISA & {\\bf 84.19} & 82.56 & {\\bf 83.37",
      "tex_start": 51355,
      "tex_end": 51394,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.260095,
          "width": 0.297479,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804478",
    "type": "sentence",
    "attributes": {
      "tex_end": 51436,
      "text": "+D\\&M & 84.09 & 82.65 & 83.36",
      "tex": "+D\\&M & 84.09 & 82.65 & 83.36",
      "tex_start": 51407,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.173109,
          "top": 0.275534,
          "width": 0.280672,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804479",
    "type": "sentence",
    "attributes": {
      "text": "+Gold & 88.22 & 86.53 & 87.36",
      "tex": "+Gold} & \\emph{88.22} & \\emph{86.53} & \\emph{87.36",
      "tex_start": 51454,
      "tex_end": 51504,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.17479,
          "top": 0.292162,
          "width": 0.278992,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804480",
    "type": "sentence",
    "attributes": {
      "text": "& & &",
      "tex": "& & &",
      "tex_start": 51511,
      "tex_end": 51516,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804481",
    "type": "sentence",
    "attributes": {
      "text": "Test &  P & R & F1",
      "tex": "Test &  P & R & F1",
      "tex_start": 51520,
      "tex_end": 51538,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.154622,
          "top": 0.324228,
          "width": 0.27395,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804482",
    "type": "sentence",
    "attributes": {
      "text": "4cGloVe",
      "tex": "4}{c}{GloVe",
      "tex_start": 51569,
      "tex_end": 51580,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804483",
    "type": "sentence",
    "attributes": {
      "text": "Citation (he2018jointly) & 79.4 & 80.1 & 79.8",
      "tex": "\\citet{he2018jointly} & 79.4 & 80.1 & 79.8",
      "tex_start": 51691,
      "tex_end": 51733,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.221849,
          "top": 0.359857,
          "width": 0.221849,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804484",
    "type": "sentence",
    "attributes": {
      "text": "SA & 82.55 & 80.02 & 81.26",
      "tex": "SA & 82.55 & 80.02 & 81.26",
      "tex_start": 51804,
      "tex_end": 51830,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.376485,
          "width": 0.297479,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804485",
    "type": "sentence",
    "attributes": {
      "text": "LISA &  81.86 &\t79.56 &\t80.70",
      "tex": "LISA &  81.86 &\t79.56 &\t80.70",
      "tex_start": 51864,
      "tex_end": 51893,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.391924,
          "width": 0.297479,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804486",
    "type": "sentence",
    "attributes": {
      "text": "+D\\&M & 83.3 & 81.38 &\t82.33",
      "tex": "+D\\&M & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
      "tex_start": 51934,
      "tex_end": 51979,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.173109,
          "top": 0.408551,
          "width": 0.280672,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804487",
    "type": "sentence",
    "attributes": {
      "text": "& & &",
      "tex": "& & &",
      "tex_start": 52037,
      "tex_end": 52042,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804488",
    "type": "sentence",
    "attributes": {
      "text": "4cELMo",
      "tex": "4}{c}{ELMo",
      "tex_start": 52059,
      "tex_end": 52069,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804489",
    "type": "sentence",
    "attributes": {
      "text": "Citation (he2018jointly) & 81.9 & 84.0 & 82.9",
      "tex": "\\citet{he2018jointly} & 81.9 & {\\bf 84.0} & 82.9",
      "tex_start": 52081,
      "tex_end": 52129,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.221849,
          "top": 0.457245,
          "width": 0.221849,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804490",
    "type": "sentence",
    "attributes": {
      "text": "SA & 84.39 & 82.21 & 83.28",
      "tex": "SA & {\\bf 84.39} & 82.21 & 83.28",
      "tex_start": 52140,
      "tex_end": 52172,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.473872,
          "width": 0.297479,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804491",
    "type": "sentence",
    "attributes": {
      "text": "LISA & 83.97 & 82.29 & 83.12",
      "tex": "LISA & 83.97 & 82.29 & 83.12",
      "tex_start": 52207,
      "tex_end": 52235,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.490499,
          "width": 0.297479,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804492",
    "type": "sentence",
    "attributes": {
      "text": "+D\\&M & 84.14 & 82.64 & 83.38",
      "tex": "+D\\&M & 84.14 & 82.64 & {\\bf 83.38",
      "tex_start": 52247,
      "tex_end": 52281,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.173109,
          "top": 0.505938,
          "width": 0.280672,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804493",
    "type": "sentence",
    "attributes": {
      "text": "Precision, recall and F1 on the CoNLL-2012 development and test sets.",
      "tex": "Precision, recall and F1 on the CoNLL-2012 development and test sets.",
      "tex_start": 52311,
      "tex_end": 52380,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.121008,
          "top": 0.551069,
          "width": 0.242017,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.188235,
          "top": 0.534442,
          "width": 0.29916,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804494",
    "type": "sentence",
    "attributes": {
      "text": "Italics indicate a synthetic upper bound obtained by providing a gold parse at test time.",
      "tex": "Italics indicate a synthetic upper bound obtained by providing a gold parse at test time.",
      "tex_start": 52381,
      "tex_end": 52470,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.121008,
          "top": 0.583135,
          "width": 0.164706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.121008,
          "top": 0.566508,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.379832,
          "top": 0.551069,
          "width": 0.107563,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804495",
    "type": "sentence",
    "attributes": {
      "text": "(Label tab:conll12-results)",
      "tex": "\\label{tab:conll12-results}}",
      "tex_start": 52470,
      "tex_end": 52498,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804496",
    "type": "sentence",
    "attributes": {
      "text": "Parsing, POS and predicate detection \\label{sec:parse-pos-results.",
      "tex": "\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}",
      "tex_start": 52607,
      "tex_end": 52687,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.121008,
          "top": 0.744656,
          "width": 0.331092,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804497",
    "type": "sentence",
    "attributes": {
      "text": "llrrr \t\t Data & Model & POS & UAS & LAS",
      "tex": "llrrr} \t\t\nData & Model & POS & UAS & LAS",
      "tex_start": 52719,
      "tex_end": 52759,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804498",
    "type": "sentence",
    "attributes": {
      "tex_end": 52832,
      "text": "3*WSJ & D\\&M<<equation-114>> & --- & 96.48 & 94.40",
      "tex": "3}{*}{WSJ} & D\\&M$_{E}$ & --- & 96.48 & 94.40",
      "tex_start": 52787,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804499",
    "type": "sentence",
    "attributes": {
      "text": "& LISA<<equation-115>> & 96.92 & 94.92 & 91.87",
      "tex": "& LISA$_{G}$ & 96.92 & 94.92 & 91.87",
      "tex_start": 52836,
      "tex_end": 52872,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.631933,
          "top": 0.112827,
          "width": 0.238655,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804500",
    "type": "sentence",
    "attributes": {
      "text": "& LISA<<equation-116>> & 97.80 & 96.28 & 93.65",
      "tex": "& LISA$_{E}$ & 97.80 & 96.28 & 93.65",
      "tex_start": 52877,
      "tex_end": 52913,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.631933,
          "top": 0.128266,
          "width": 0.238655,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804501",
    "type": "sentence",
    "attributes": {
      "text": "3*Brown & D\\&M<<equation-117>> & --- & 92.56 & 88.52",
      "tex": "3}{*}{Brown} & D\\&M$_{E}$ & --- & 92.56 & 88.52",
      "tex_start": 52948,
      "tex_end": 52995,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804502",
    "type": "sentence",
    "attributes": {
      "text": "& LISA<<equation-118>> & 94.26 & 90.31 & 85.82",
      "tex": "& LISA$_{G}$ & 94.26 & 90.31 & 85.82",
      "tex_start": 52999,
      "tex_end": 53035,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.631933,
          "top": 0.16152,
          "width": 0.238655,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804503",
    "type": "sentence",
    "attributes": {
      "text": "& LISA<<equation-119>> & 95.77 & 93.36 & 88.75",
      "tex": "& LISA$_{E}$ & 95.77 & 93.36 & 88.75",
      "tex_start": 53040,
      "tex_end": 53076,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.631933,
          "top": 0.17696,
          "width": 0.238655,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804504",
    "type": "sentence",
    "attributes": {
      "text": "3*CoNLL-12 & D\\&M<<equation-120>> & --- & 94.99 & 92.59",
      "tex": "3}{*}{CoNLL-12} & D\\&M$_{E}$ & --- & 94.99 & 92.59",
      "tex_start": 53110,
      "tex_end": 53160,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804505",
    "type": "sentence",
    "attributes": {
      "text": "& LISA<<equation-121>> & 96.81 & 93.35 & 90.42",
      "tex": "& LISA$_{G}$ & 96.81 & 93.35 & 90.42",
      "tex_start": 53164,
      "tex_end": 53200,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.631933,
          "top": 0.210214,
          "width": 0.238655,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804506",
    "type": "sentence",
    "attributes": {
      "text": "& LISA<<equation-122>> & 98.11 & 94.84 & 92.23",
      "tex": "& LISA$_{E}$ & 98.11 & 94.84 & 92.23",
      "tex_start": 53204,
      "tex_end": 53240,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.631933,
          "top": 0.226841,
          "width": 0.238655,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804507",
    "type": "sentence",
    "attributes": {
      "text": "(Label parsing-numbers)",
      "tex": "\\label{parsing-numbers}",
      "tex_start": 53267,
      "tex_end": 53290,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804508",
    "type": "sentence",
    "attributes": {
      "text": "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in SRL experiments on test datasets.",
      "tex": "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in SRL experiments on test datasets.",
      "tex_start": 53291,
      "tex_end": 53417,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.286223,
          "width": 0.315966,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.515966,
          "top": 0.270784,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.593277,
          "top": 0.254157,
          "width": 0.289076,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804509",
    "type": "sentence",
    "attributes": {
      "text": "Subscript <<equation-123>> denotes GloVe and <<equation-124>> ELMo embeddings.",
      "tex": "Subscript $G$ denotes GloVe and $E$ ELMo embeddings.",
      "tex_start": 53418,
      "tex_end": 53470,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.301663,
          "width": 0.364706,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.85042,
          "top": 0.286223,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804510",
    "type": "sentence",
    "attributes": {
      "text": "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table (Ref parsing-numbers)) with GloVe (<<equation-125>>) and ELMo (<<equation-126>>) embeddings.",
      "tex": "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
      "tex_start": 53485,
      "tex_end": 53688,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.121008,
          "top": 0.814727,
          "width": 0.305882,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.122689,
          "top": 0.799287,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.121008,
          "top": 0.78266,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.122689,
          "top": 0.767221,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804511",
    "type": "sentence",
    "attributes": {
      "text": "D\\&M achieves the best scores.",
      "tex": "D\\&M achieves the best scores.",
      "tex_start": 53689,
      "tex_end": 53719,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.121008,
          "top": 0.831354,
          "width": 0.191597,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.445378,
          "top": 0.815915,
          "width": 0.0420168,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804512",
    "type": "sentence",
    "attributes": {
      "text": "The difference in parse accuracy between LISA<<equation-127>> and D\\&M likely explains the large increase in SRL performance we see from decoding with D\\&M parses in that setting.",
      "tex": "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in SRL performance we see from decoding with D\\&M parses in that setting.",
      "tex_start": 54010,
      "tex_end": 54177,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.567696,
          "width": 0.238655,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.515966,
          "top": 0.552257,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.535629,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.573109,
          "top": 0.52019,
          "width": 0.309244,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804513",
    "type": "sentence",
    "attributes": {
      "text": "5pt lllllll   & Model & P & R & F1",
      "tex": "5pt}\n\\begin{tabular}{lllllll}\n% &\\multicolumn{3}{c}{WSJ Test} & \\multicolumn{3}{c}{Brown Test} \\\\  \\cline{2-4} \\cline{5-7}\n%  & P & R & F1 & P & R & F1\\\\ \\hline \\hline\n% \\citet{he2017deep} & 94.5 & {\\bf 98.5} & 96.4 & 89.3 & {\\bf 95.7} & 92.4 \\\\ %\\hline\n% % SA &  98.3 &  98.1 &  {\\bf 98.2} & & {\\bf 94.7}  & 92.9 &  {\\bf 93.8}\\\\\n% % LISA$_{D}$ &  {\\bf 98.3} & 98.1 & 98.2 & & 94.5 & 92.5 & 93.5  \\\\\n% LISA & {\\bf 98.91} &  97.82 &  {\\bf 98.36} &  {\\bf 96.34} &  91.79 &  {\\bf 94.01} \\\\\n% &\\multicolumn{3}{c}{WSJ Test} & \\multicolumn{3}{c}{Brown Test} \\\\  \\cline{2-4} \\cline{5-7}\n% & Model & P & R & F1 \\\\ \\hline \\hline\n% \\multirow{2}{*}{WSJ} & \\citet{he2017deep} & 94.5 & 98.5 & 96.4  \\\\\n% & LISA & 98.87 &  97.85 & 98.36 \\\\ \\hline\n\n% \\multirow{2}{*}{Brown} & \\citet{he2017deep} & 89.3 & 95.7 & 92.4 \\\\ \n% & LISA & 95.48 &  91.92 &  93.66 \\\\ \\hline\n\n% CoNLL-12 & LISA & 99.83 & 95.23 & 97.48 \\\\ \n& Model & P & R & F1",
      "tex_start": 54216,
      "tex_end": 55133,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804514",
    "type": "sentence",
    "attributes": {
      "text": "2*WSJ & Citation (he2017deep) & 94.5 & 98.5 & 96.4",
      "tex": "2}{*}{WSJ} & \\citet{he2017deep} & 94.5 & 98.5 & 96.4",
      "tex_start": 55161,
      "tex_end": 55213,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804515",
    "type": "sentence",
    "attributes": {
      "text": "& LISA & 98.9 &  97.9 & 98.4",
      "tex": "& LISA & 98.9 &  97.9 & 98.4",
      "tex_start": 55218,
      "tex_end": 55246,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.62521,
          "top": 0.368171,
          "width": 0.258824,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804516",
    "type": "sentence",
    "attributes": {
      "text": "2*Brown & Citation (he2017deep) & 89.3 & 95.7 & 92.4",
      "tex": "2}{*}{Brown} & \\citet{he2017deep} & 89.3 & 95.7 & 92.4",
      "tex_start": 55268,
      "tex_end": 55322,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804517",
    "type": "sentence",
    "attributes": {
      "text": "& LISA & 95.5 &  91.9 &  93.7",
      "tex": "& LISA & 95.5 &  91.9 &  93.7",
      "tex_start": 55327,
      "tex_end": 55356,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.62521,
          "top": 0.401425,
          "width": 0.258824,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804518",
    "type": "sentence",
    "attributes": {
      "text": "CoNLL-12 & LISA & 99.8 & 94.7 &\t97.2",
      "tex": "CoNLL-12 & LISA & 99.8 & 94.7 &\t97.2",
      "tex_start": 55368,
      "tex_end": 55404,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.52437,
          "top": 0.418052,
          "width": 0.359664,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804519",
    "type": "sentence",
    "attributes": {
      "text": "Predicate detection precision, recall and F1 on CoNLL-2005 and CoNLL-2012 test sets.",
      "tex": "Predicate detection precision, recall and F1 on CoNLL-2005 and CoNLL-2012 test sets.",
      "tex_start": 55432,
      "tex_end": 55516,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.461995,
          "width": 0.346218,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.583193,
          "top": 0.445368,
          "width": 0.29916,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804520",
    "type": "sentence",
    "attributes": {
      "text": "(Label tab:preds)",
      "tex": "\\label{tab:preds}}",
      "tex_start": 55517,
      "tex_end": 55535,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804521",
    "type": "sentence",
    "attributes": {
      "text": "In Table (Ref tab:preds) we present predicate detection precision, recall and F1 on the CoNLL-2005 and 2012 test sets.",
      "tex": "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the CoNLL-2005 and 2012 test sets.",
      "tex_start": 55549,
      "tex_end": 55667,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.515966,
          "top": 0.61639,
          "width": 0.109244,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.515966,
          "top": 0.60095,
          "width": 0.366387,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.536134,
          "top": 0.584323,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804522",
    "type": "sentence",
    "attributes": {
      "text": "SA and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
      "tex": "SA and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
      "tex_start": 55668,
      "tex_end": 55756,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.649644,
          "width": 0.10084,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.633017,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.642017,
          "top": 0.61639,
          "width": 0.240336,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804523",
    "type": "sentence",
    "attributes": {
      "text": "We compare to Citation (he2017deep) on CoNLL-2005, the only cited work reporting comparable predicate detection F1.",
      "tex": "We compare to \\citet{he2017deep} on CoNLL-2005, the only cited work reporting comparable predicate detection F1.",
      "tex_start": 55757,
      "tex_end": 55869,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.515966,
          "top": 0.68171,
          "width": 0.226891,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.515966,
          "top": 0.665083,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.628571,
          "top": 0.649644,
          "width": 0.253782,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804524",
    "type": "sentence",
    "attributes": {
      "text": "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs Citation (he2017deep) by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
      "tex": "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
      "tex_start": 55870,
      "tex_end": 56129,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.761283,
          "width": 0.354622,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.515966,
          "top": 0.745843,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.729216,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.713777,
          "width": 0.285714,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.515966,
          "top": 0.69715,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.752941,
          "top": 0.68171,
          "width": 0.129412,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804525",
    "type": "sentence",
    "attributes": {
      "tex": "lllll}\n& L+/D+ & L--/D+ & L+/D-- & L--/D--",
      "text": "lllll & L+/D+ & L--/D+ & L+/D-- & L--/D--",
      "tex_start": 56273,
      "tex_end": 56315,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804526",
    "type": "sentence",
    "attributes": {
      "text": "Proportion & 26\\% &\t12\\% &\t4\\% &\t56\\%",
      "tex": "Proportion & 26\\% &\t12\\% &\t4\\% &\t56\\%",
      "tex_start": 56333,
      "tex_end": 56370,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.132773,
          "top": 0.0961995,
          "width": 0.337815,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804527",
    "type": "sentence",
    "attributes": {
      "text": "SA & 79.29 & 75.14\t& 75.97 &\t75.08",
      "tex": "SA & 79.29 & 75.14\t& 75.97 &\t75.08",
      "tex_start": 56484,
      "tex_end": 56518,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.132773,
          "top": 0.112827,
          "width": 0.346218,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804528",
    "type": "sentence",
    "attributes": {
      "text": "LISA & 79.51 &\t74.33 &\t79.69 &\t75.00",
      "tex": "LISA & 79.51 &\t74.33 &\t79.69 &\t75.00",
      "tex_start": 56523,
      "tex_end": 56559,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.132773,
          "top": 0.129454,
          "width": 0.346218,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804529",
    "type": "sentence",
    "attributes": {
      "text": "+D\\&M & 79.03 &\t76.96 &\t77.73 &\t76.52",
      "tex": "+D\\&M & 79.03 &\t76.96 &\t77.73 &\t76.52",
      "tex_start": 56571,
      "tex_end": 56608,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.14958,
          "top": 0.144893,
          "width": 0.329412,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804530",
    "type": "sentence",
    "attributes": {
      "text": "+Gold & 79.61 & 78.38 & 81.41 & 80.47",
      "tex": "+Gold} & \\emph{79.61} & \\emph{78.38} & \\emph{81.41} & \\emph{80.47",
      "tex_start": 56626,
      "tex_end": 56691,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.151261,
          "top": 0.16152,
          "width": 0.327731,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804531",
    "type": "sentence",
    "attributes": {
      "text": "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
      "tex": "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
      "tex_start": 57210,
      "tex_end": 57334,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.22209,
          "width": 0.282353,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.122689,
          "top": 0.205463,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.181513,
          "top": 0.188836,
          "width": 0.305882,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804532",
    "type": "sentence",
    "attributes": {
      "text": "(Label tab:parse-srl-by-sents)",
      "tex": "\\label{tab:parse-srl-by-sents}}",
      "tex_start": 57335,
      "tex_end": 57366,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804533",
    "type": "sentence",
    "attributes": {
      "text": "Analysis \\label{sec:analysis.",
      "tex": "\n\n\\subsection{Analysis \\label{sec:analysis}}",
      "tex_start": 57378,
      "tex_end": 57422,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.515966,
          "top": 0.788599,
          "width": 0.107563,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804534",
    "type": "sentence",
    "attributes": {
      "text": "First we assess SRL F1 on sentences divided by parse accuracy.",
      "tex": "First we assess SRL F1 on sentences divided by parse accuracy.",
      "tex_start": 57712,
      "tex_end": 57774,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.515966,
          "top": 0.828979,
          "width": 0.114286,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.811164,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804535",
    "type": "sentence",
    "attributes": {
      "text": "Table (Ref tab:parse-srl-by-sents) lists average SRL F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not (L<<equation-128>>, D<<equation-129>>).",
      "tex": "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
      "tex_start": 57775,
      "tex_end": 57948,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.515966,
          "top": 0.85867,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.84323,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.65042,
          "top": 0.826603,
          "width": 0.228571,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804536",
    "type": "sentence",
    "attributes": {
      "text": "Both parsers are correct on 26\\% of sentences.",
      "tex": "Both parsers are correct on 26\\% of sentences.",
      "tex_start": 57949,
      "tex_end": 57995,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.875297,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804537",
    "type": "sentence",
    "attributes": {
      "text": "Here there is little difference between any of the models, with LISA models tending to perform slightly better than SA.",
      "tex": "Here there is little difference between any of the models, with LISA models tending to perform slightly better than SA.",
      "tex_start": 57996,
      "tex_end": 58115,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.122689,
          "top": 0.524941,
          "width": 0.17479,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.509501,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.122689,
          "top": 0.492874,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804538",
    "type": "sentence",
    "attributes": {
      "text": "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where SA also performs the worst.",
      "tex": "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where SA also performs the worst.",
      "tex_start": 58116,
      "tex_end": 58233,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.557007,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.122689,
          "top": 0.541568,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.307563,
          "top": 0.524941,
          "width": 0.179832,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804539",
    "type": "sentence",
    "attributes": {
      "text": "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
      "tex": "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
      "tex_start": 58234,
      "tex_end": 58510,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.122689,
          "top": 0.654394,
          "width": 0.290756,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.122689,
          "top": 0.637767,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.622328,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.605701,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.589074,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.573634,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804540",
    "type": "sentence",
    "attributes": {
      "text": "Following Citation (he2017deep), we next apply a series of corrections to model predictions in order to understand which error types the gold parse resolves: e.g. Fix Labels fixes labels on spans matching gold boundaries, and Merge Spans merges adjacent predicted spans into a gold span.Refer to Citation (he2017deep) for a detailed explanation of the different error types.",
      "tex": "Following \\citet{he2017deep}, we next apply a series of corrections to model predictions in order to understand which error types the gold parse resolves: e.g. \\emph{Fix Labels} fixes labels on spans matching gold boundaries, and \\emph{Merge Spans} merges adjacent predicted spans into a gold span.\\footnote{Refer to \\citet{he2017deep} for a detailed explanation of the different error types.",
      "tex_start": 58512,
      "tex_end": 58904,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.750594,
          "width": 0.357983,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.122689,
          "top": 0.733967,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.718527,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.7019,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.122689,
          "top": 0.686461,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.141176,
          "top": 0.669834,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804541",
    "type": "sentence",
    "attributes": {
      "text": "scale=0.52errors.pdf",
      "tex": "scale=0.52]{errors.pdf",
      "tex_start": 58939,
      "tex_end": 58961,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804542",
    "type": "sentence",
    "attributes": {
      "text": "Performance of CoNLL-2005 models after performing corrections from Citation (he2017deep).",
      "tex": "Performance of CoNLL-2005 models after performing corrections from \\citet{he2017deep}.",
      "tex_start": 58972,
      "tex_end": 59058,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.122689,
          "top": 0.435867,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.189916,
          "top": 0.41924,
          "width": 0.297479,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804543",
    "type": "sentence",
    "attributes": {
      "text": "(Label errors-fig)",
      "tex": "\\label{errors-fig}}",
      "tex_start": 59059,
      "tex_end": 59078,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804544",
    "type": "sentence",
    "attributes": {
      "text": "In Figure (Ref errors-fig) we see that much of the performance gap between the gold and predicted parses is due to span boundary errors (Merge Spans, Split Spans and Fix Span Boundary), which supports the hypothesis proposed by Citation (he2017deep) that incorporating syntax could be particularly helpful for resolving these errors.",
      "tex": "In Figure \\ref{errors-fig} we see that much of the performance gap between the gold and predicted parses is due to span boundary errors (\\emph{Merge Spans}, \\emph{Split Spans} and \\emph{Fix Span Boundary}), which supports the hypothesis proposed by \\citet{he2017deep} that incorporating syntax could be particularly helpful for resolving these errors.",
      "tex_start": 59093,
      "tex_end": 59444,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.122689,
          "top": 0.86342,
          "width": 0.142857,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.846793,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.831354,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.814727,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.122689,
          "top": 0.799287,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.121008,
          "top": 0.78266,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.141176,
          "top": 0.767221,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804545",
    "type": "sentence",
    "attributes": {
      "text": "Citation (he2017deep) also point out that these errors are due mainly to prepositional phrase (PP) attachment mistakes.",
      "tex": "\\citet{he2017deep} also point out that these errors are due mainly to prepositional phrase (PP) attachment mistakes.",
      "tex_start": 59628,
      "tex_end": 59744,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.337815,
          "top": 0.86342,
          "width": 0.14958,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804546",
    "type": "sentence",
    "attributes": {
      "text": "We also find this to be the case: Figure (Ref fig:phrase-bar) shows a breakdown of split/merge corrections by phrase type.",
      "tex": "We also find this to be the case: Figure \\ref{fig:phrase-bar} shows a breakdown of split/merge corrections by phrase type.",
      "tex_start": 59745,
      "tex_end": 59867,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.342043,
          "width": 0.302521,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.325416,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.786555,
          "top": 0.309976,
          "width": 0.0957983,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804547",
    "type": "sentence",
    "attributes": {
      "text": "Though the number of corrections decreases substantially across phrase types, the proportion of corrections attributed to PPs remains the same (approx.",
      "tex": "Though the number of corrections decreases substantially across phrase types, the proportion of corrections attributed to PPs remains the same (approx.",
      "tex_start": 59868,
      "tex_end": 60019,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.389549,
          "width": 0.319328,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.374109,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.357482,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.826891,
          "top": 0.342043,
          "width": 0.0554622,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804548",
    "type": "sentence",
    "attributes": {
      "text": "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for SRL.",
      "tex": "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for SRL.",
      "tex_start": 60020,
      "tex_end": 60166,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.438242,
          "width": 0.310924,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.422803,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.406176,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.843697,
          "top": 0.389549,
          "width": 0.0386555,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804549",
    "type": "sentence",
    "attributes": {
      "text": "scale=0.55phrase_bar_percent.pdf",
      "tex": "scale=0.55]{phrase_bar_percent.pdf",
      "tex_start": 60211,
      "tex_end": 60245,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804550",
    "type": "sentence",
    "attributes": {
      "text": "Percent and count of split/merge corrections performed in Figure (Ref errors-fig), by phrase type.",
      "tex": "Percent and count of split/merge corrections performed in Figure \\ref{errors-fig}, by phrase type.",
      "tex_start": 60256,
      "tex_end": 60354,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.247031,
          "width": 0.319328,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.586555,
          "top": 0.230404,
          "width": 0.295798,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804551",
    "type": "sentence",
    "attributes": {
      "text": "(Label fig:phrase-bar)",
      "tex": "\\label{fig:phrase-bar}}",
      "tex_start": 60355,
      "tex_end": 60378,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804552",
    "type": "sentence",
    "attributes": {
      "text": "Conclusion.",
      "tex": "\n\n\\section{Conclusion}\n",
      "tex_start": 60391,
      "tex_end": 60414,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.475059,
          "width": 0.12605,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804553",
    "type": "sentence",
    "attributes": {
      "text": "We present linguistically-informed self-attention: a multi-task neural network model that effectively incorporates rich linguistic information for semantic role labeling.",
      "tex": "We present linguistically-informed self-attention: a multi-task neural network model that effectively incorporates rich linguistic information for semantic role labeling.",
      "tex_start": 60414,
      "tex_end": 60584,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.555819,
          "width": 0.119328,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.539192,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.523753,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.507126,
          "width": 0.363025,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804554",
    "type": "sentence",
    "attributes": {
      "text": "LISA out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
      "tex": "LISA out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
      "tex_start": 60585,
      "tex_end": 60679,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.587886,
          "width": 0.137815,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.571259,
          "width": 0.364706,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.64874,
          "top": 0.555819,
          "width": 0.233613,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804555",
    "type": "sentence",
    "attributes": {
      "text": "Future work will explore improving LISA's parsing accuracy, developing better training techniques and adapting to more tasks.",
      "tex": "Future work will explore improving LISA's parsing accuracy, developing better training techniques and adapting to more tasks.",
      "tex_start": 60680,
      "tex_end": 60805,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.619952,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.603325,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.667227,
          "top": 0.587886,
          "width": 0.215126,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804556",
    "type": "sentence",
    "attributes": {
      "text": "Acknowledgments.",
      "tex": "\n\\section*{Acknowledgments}\n",
      "tex_start": 61017,
      "tex_end": 61045,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.659145,
          "width": 0.154622,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804557",
    "type": "sentence",
    "attributes": {
      "text": "We are grateful to Luheng He for helpful discussions and code, Timothy Dozat for sharing his code, and to the NLP reading groups at Google and UMass and the anonymous reviewers for feedback on drafts of this work.",
      "tex": "We are grateful to Luheng He for helpful discussions and code, Timothy Dozat for sharing his code, and to the NLP reading groups at Google and UMass and the anonymous reviewers for feedback on drafts of this work.",
      "tex_start": 61045,
      "tex_end": 61258,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.752969,
          "width": 0.206723,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.73753,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.720903,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.704276,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.688836,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804558",
    "type": "sentence",
    "attributes": {
      "text": "This work was supported in part by an IBM PhD Fellowship Award to E.S., in part by the Center for Intelligent Information Retrieval, and in part by the National Science Foundation under Grant Nos.",
      "tex": "This work was supported in part by an IBM PhD Fellowship Award to E.S., in part by the Center for Intelligent Information Retrieval, and in part by the National Science Foundation under Grant Nos.",
      "tex_start": 61259,
      "tex_end": 61455,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.817102,
          "width": 0.248739,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.801663,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.785036,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.769596,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.734454,
          "top": 0.752969,
          "width": 0.147899,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804559",
    "type": "sentence",
    "attributes": {
      "text": "DMR-1534431 and IIS-1514053.",
      "tex": "DMR-1534431 and IIS-1514053.",
      "tex_start": 61456,
      "tex_end": 61484,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.833729,
          "width": 0.127731,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.773109,
          "top": 0.817102,
          "width": 0.105882,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804560",
    "type": "sentence",
    "attributes": {
      "text": "Any opinions, findings, conclusions or recommendations expressed in this material are those of the authors and do not necessarily reflect those of the sponsor.",
      "tex": "Any opinions, findings, conclusions or recommendations expressed in this material are those of the authors and do not necessarily reflect those of the sponsor.",
      "tex_start": 61485,
      "tex_end": 61644,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.882423,
          "width": 0.2,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.515966,
          "top": 0.865796,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.517647,
          "top": 0.849169,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.652101,
          "top": 0.833729,
          "width": 0.230252,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804561",
    "type": "sentence",
    "attributes": {
      "text": "emnlp2018 acl_natbib_nourl",
      "tex": "emnlp2018}\n\\bibliographystyle{acl_natbib_nourl",
      "tex_start": 61699,
      "tex_end": 61745,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804562",
    "type": "sentence",
    "attributes": {
      "text": "Supplemental Material \\label{sec:supplemental.",
      "tex": "\n\\section{Supplemental Material \\label{sec:supplemental}}",
      "tex_start": 61776,
      "tex_end": 61833,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.488124,
          "width": 0.230252,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804563",
    "type": "sentence",
    "attributes": {
      "text": "Supplemental analysis \\label{app:analysis.",
      "tex": "\n\\subsection{Supplemental analysis \\label{app:analysis}}",
      "tex_start": 61855,
      "tex_end": 61911,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.121008,
          "top": 0.522565,
          "width": 0.220168,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804564",
    "type": "sentence",
    "attributes": {
      "text": "Here we continue the analysis from (Ref sec:analysis).",
      "tex": "Here we continue the analysis from \\S\\ref{sec:analysis}.",
      "tex_start": 61912,
      "tex_end": 61968,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.551069,
          "width": 0.322689,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804565",
    "type": "sentence",
    "attributes": {
      "tex_end": 62070,
      "text": "All experiments in this section are performed on CoNLL-2005 development data unless stated otherwise.",
      "tex": "All experiments in this section are performed on CoNLL-2005 development data unless stated otherwise.",
      "tex_start": 61969,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.121008,
          "top": 0.603325,
          "width": 0.0504202,
          "height": 0.00593824
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.14958,
          "top": 0.60095,
          "width": 0.00168067,
          "height": 0.00118765
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.121008,
          "top": 0.584323,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.121008,
          "top": 0.567696,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.465546,
          "top": 0.552257,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804566",
    "type": "sentence",
    "attributes": {
      "text": "llll CoNLL-2005 & Greedy F1 & Viterbi F1 & <<equation-130>> F1",
      "tex": "llll}\nCoNLL-2005 & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
      "tex_start": 62102,
      "tex_end": 62157,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804567",
    "type": "sentence",
    "attributes": {
      "text": "LISA & 81.99 & 82.24 & +0.25",
      "tex": "LISA & 81.99 & 82.24 & +0.25",
      "tex_start": 62175,
      "tex_end": 62203,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.132773,
          "top": 0.0961995,
          "width": 0.352941,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804568",
    "type": "sentence",
    "attributes": {
      "text": "+D\\&M & 83.37 & 83.58 & +0.21",
      "tex": "+D\\&M & 83.37 & 83.58 & +0.21",
      "tex_start": 62215,
      "tex_end": 62244,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.14958,
          "top": 0.112827,
          "width": 0.332773,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804569",
    "type": "sentence",
    "attributes": {
      "text": "+Gold & 86.57 &\t86.81 &\t+0.24",
      "tex": "+Gold} & \\emph{86.57} &\t\\emph{86.81} &\t\\emph{+0.24",
      "tex_start": 62262,
      "tex_end": 62312,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.151261,
          "top": 0.128266,
          "width": 0.336134,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804570",
    "type": "sentence",
    "attributes": {
      "text": "& & &",
      "tex": "& & &",
      "tex_start": 62317,
      "tex_end": 62322,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804571",
    "type": "sentence",
    "attributes": {
      "text": "CoNLL-2012 & Greedy F1 & Viterbi F1 & <<equation-131>> F1",
      "tex": "CoNLL-2012 & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
      "tex_start": 62326,
      "tex_end": 62375,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.131092,
          "top": 0.160333,
          "width": 0.347899,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804572",
    "type": "sentence",
    "attributes": {
      "text": "LISA & 80.11\t& 80.70\t & +0.59",
      "tex": "LISA & 80.11\t& 80.70\t & +0.59",
      "tex_start": 62393,
      "tex_end": 62422,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.132773,
          "top": 0.179335,
          "width": 0.352941,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804573",
    "type": "sentence",
    "attributes": {
      "text": "+D\\&M & 81.55 &\t82.05 & +0.50",
      "tex": "+D\\&M & 81.55 &\t82.05 & +0.50",
      "tex_start": 62434,
      "tex_end": 62463,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.14958,
          "top": 0.195962,
          "width": 0.336134,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804574",
    "type": "sentence",
    "attributes": {
      "text": "+Gold & 85.94 &\t86.43 &\t+0.49",
      "tex": "+Gold} & \\emph{85.94} &\t\\emph{86.43} &\t\\emph{+0.49",
      "tex_start": 62481,
      "tex_end": 62531,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.151261,
          "top": 0.212589,
          "width": 0.336134,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804575",
    "type": "sentence",
    "attributes": {
      "text": "Comparison of development F1 scores with and without Viterbi decoding at test time.",
      "tex": "Comparison of development F1 scores with and without Viterbi decoding at test time.",
      "tex_start": 62560,
      "tex_end": 62643,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.256532,
          "width": 0.337815,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.191597,
          "top": 0.239905,
          "width": 0.295798,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804576",
    "type": "sentence",
    "attributes": {
      "text": "(Label viterbi-table)",
      "tex": "\\label{viterbi-table}}",
      "tex_start": 62644,
      "tex_end": 62666,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804577",
    "type": "sentence",
    "attributes": {
      "text": "First, we compare the impact of Viterbi decoding with LISA, D\\&M, and gold syntax trees (Table (Ref viterbi-table)), finding the same trends across both datasets.",
      "tex": "First, we compare the impact of Viterbi decoding with LISA, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
      "tex_start": 62680,
      "tex_end": 62842,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.129412,
          "top": 0.653207,
          "width": 0.356303,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.63658,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.141176,
          "top": 0.619952,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804578",
    "type": "sentence",
    "attributes": {
      "text": "We find that Viterbi has nearly the same impact for LISA, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
      "tex": "We find that Viterbi has nearly the same impact for LISA, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
      "tex_start": 62843,
      "tex_end": 63018,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.121008,
          "top": 0.71734,
          "width": 0.183193,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.700713,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.685273,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.668646,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804579",
    "type": "sentence",
    "attributes": {
      "text": "scale=0.52f1_by_sent_len.pdf",
      "tex": "scale=0.52]{f1_by_sent_len.pdf",
      "tex_start": 63053,
      "tex_end": 63083,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804580",
    "type": "sentence",
    "attributes": {
      "text": "F1 score as a function of sentence length.",
      "tex": "F1 score as a function of sentence length.",
      "tex_start": 63094,
      "tex_end": 63136,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.189916,
          "top": 0.43943,
          "width": 0.295798,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804581",
    "type": "sentence",
    "attributes": {
      "text": "(Label fig:length)",
      "tex": "\\label{fig:length}}",
      "tex_start": 63136,
      "tex_end": 63155,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804582",
    "type": "sentence",
    "attributes": {
      "text": "scale=0.52f1_by_pred_dist.pdf",
      "tex": "scale=0.52]{f1_by_pred_dist.pdf",
      "tex_start": 63202,
      "tex_end": 63233,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804583",
    "type": "sentence",
    "attributes": {
      "text": "CoNLL-2005 F1 score as a function of the distance of the predicate from the argument span.",
      "tex": "CoNLL-2005 F1 score as a function of the distance of the predicate from the argument span.",
      "tex_start": 63244,
      "tex_end": 63334,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.517647,
          "top": 0.25772,
          "width": 0.0352941,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.517647,
          "top": 0.238717,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.588235,
          "top": 0.223278,
          "width": 0.295798,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804584",
    "type": "sentence",
    "attributes": {
      "text": "(Label fig:dist)",
      "tex": "\\label{fig:dist}}",
      "tex_start": 63334,
      "tex_end": 63351,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804585",
    "type": "sentence",
    "attributes": {
      "text": "We also assess SRL F1 as a function of sentence length and distance from span to predicate.",
      "tex": "We also assess SRL F1 as a function of sentence length and distance from span to predicate.",
      "tex_start": 63366,
      "tex_end": 63457,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.752969,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.141176,
          "top": 0.73753,
          "width": 0.346218,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804586",
    "type": "sentence",
    "attributes": {
      "text": "In Figure (Ref fig:length) we see that providing LISA with gold parses is particularly helpful for sentences longer than 10 tokens.",
      "tex": "In Figure \\ref{fig:length} we see that providing LISA with gold parses is particularly helpful for sentences longer than 10 tokens.",
      "tex_start": 63458,
      "tex_end": 63589,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.801663,
          "width": 0.110924,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.121008,
          "top": 0.785036,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.769596,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804587",
    "type": "sentence",
    "attributes": {
      "text": "This likely directly follows from the tendency of syntactic parsers to perform worse on longer sentences.",
      "tex": "This likely directly follows from the tendency of syntactic parsers to perform worse on longer sentences.",
      "tex_start": 63590,
      "tex_end": 63695,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.121008,
          "top": 0.833729,
          "width": 0.14958,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.817102,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.243697,
          "top": 0.801663,
          "width": 0.243697,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804588",
    "type": "sentence",
    "attributes": {
      "text": "With respect to distance between arguments and predicates, (Figure (Ref fig:dist)), we do not observe this same trend, with all distances performing better with better parses, and especially gold.",
      "tex": "With respect to distance between arguments and predicates, (Figure \\ref{fig:dist}), we do not observe this same trend, with all distances performing better with better parses, and especially gold.",
      "tex_start": 63696,
      "tex_end": 63892,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.121008,
          "top": 0.897862,
          "width": 0.0352941,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.882423,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.121008,
          "top": 0.865796,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.849169,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.282353,
          "top": 0.833729,
          "width": 0.205042,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804589",
    "type": "sentence",
    "attributes": {
      "text": "lllll & L+/D+ & L-/D+ & L+/D- & L-/D-",
      "tex": "lllll}\n& L+/D+ & L-/D+ & L+/D- & L-/D-",
      "tex_start": 65094,
      "tex_end": 65132,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804590",
    "type": "sentence",
    "attributes": {
      "text": "Proportion & 37\\% &\t10\\% &\t4\\% &\t49\\%",
      "tex": "Proportion & 37\\% &\t10\\% &\t4\\% &\t49\\%",
      "tex_start": 65211,
      "tex_end": 65248,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.529412,
          "top": 0.311164,
          "width": 0.331092,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804591",
    "type": "sentence",
    "attributes": {
      "text": "SA & 76.12 & 75.97 & 82.25 &\t65.78",
      "tex": "SA & 76.12 & 75.97 & 82.25 &\t65.78",
      "tex_start": 65362,
      "tex_end": 65396,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.529412,
          "top": 0.327791,
          "width": 0.341176,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804592",
    "type": "sentence",
    "attributes": {
      "text": "LISA & 76.37 &\t72.38 &\t85.50 &\t65.10",
      "tex": "LISA & 76.37 &\t72.38 &\t85.50 &\t65.10",
      "tex_start": 65401,
      "tex_end": 65437,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.529412,
          "top": 0.34323,
          "width": 0.341176,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804593",
    "type": "sentence",
    "attributes": {
      "text": "+D\\&M & 76.33\t& 79.65 &\t75.62 &\t66.55",
      "tex": "+D\\&M & 76.33\t& 79.65 &\t75.62 &\t66.55",
      "tex_start": 65449,
      "tex_end": 65486,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.546219,
          "top": 0.359857,
          "width": 0.32437,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804594",
    "type": "sentence",
    "attributes": {
      "text": "+Gold & 76.71 & 80.67 & 86.03 & 72.22",
      "tex": "+Gold} & \\emph{76.71} & \\emph{80.67} & \\emph{86.03} & \\emph{72.22",
      "tex_start": 65504,
      "tex_end": 65569,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.547899,
          "top": 0.375297,
          "width": 0.322689,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804595",
    "type": "sentence",
    "attributes": {
      "text": "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
      "tex": "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
      "tex_start": 65597,
      "tex_end": 65709,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.515966,
          "top": 0.435867,
          "width": 0.194958,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.517647,
          "top": 0.41924,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.578151,
          "top": 0.4038,
          "width": 0.304202,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804596",
    "type": "sentence",
    "attributes": {
      "text": "(Label tab:app:parse-srl-by-sents)",
      "tex": "\\label{tab:app:parse-srl-by-sents}}",
      "tex_start": 65710,
      "tex_end": 65745,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804597",
    "type": "sentence",
    "attributes": {
      "tex_end": 65794,
      "text": "Supplemental results.",
      "tex": "\n\n\\subsection{Supplemental results}\n\n",
      "tex_start": 65757,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.121008,
          "top": 0.890736,
          "width": 0.208403,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804598",
    "type": "sentence",
    "attributes": {
      "text": "Due to space constraints in the main paper we list additional experimental results here.",
      "tex": "Due to space constraints in the main paper we list additional experimental results here.",
      "tex_start": 65794,
      "tex_end": 65882,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804599",
    "type": "sentence",
    "attributes": {
      "text": "Table (Ref tab:conll05-gold-pred-dev) lists development scores on the CoNLL-2005 dataset with predicted predicates, which follow the same trends as the test data.",
      "tex": "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the CoNLL-2005 dataset with predicted predicates, which follow the same trends as the test data.",
      "tex_start": 65883,
      "tex_end": 66045,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.517647,
          "top": 0.57601,
          "width": 0.159664,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.517647,
          "top": 0.559382,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.515966,
          "top": 0.542755,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.793277,
          "top": 0.527316,
          "width": 0.0890756,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804600",
    "type": "sentence",
    "attributes": {
      "text": "llll WSJ Dev & P & R & F1",
      "tex": "llll}\nWSJ Dev & P & R & F1",
      "tex_start": 66077,
      "tex_end": 66103,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804601",
    "type": "sentence",
    "attributes": {
      "text": "Citation (he2018jointly) & 84.2 & 83.7 & 83.9",
      "tex": "\\citet{he2018jointly} & 84.2 & 83.7 & 83.9",
      "tex_start": 66121,
      "tex_end": 66163,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.615126,
          "top": 0.768409,
          "width": 0.226891,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804602",
    "type": "sentence",
    "attributes": {
      "text": "Citation (tan2018deep) & 82.6\t& 83.6 &\t83.1",
      "tex": "\\citet{tan2018deep} & 82.6\t& 83.6 &\t83.1",
      "tex_start": 66167,
      "tex_end": 66207,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.620168,
          "top": 0.783848,
          "width": 0.218487,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804603",
    "type": "sentence",
    "attributes": {
      "text": "SA & 83.12 &\t82.81 &\t82.97",
      "tex": "SA & 83.12 &\t82.81 &\t82.97",
      "tex_start": 66218,
      "tex_end": 66244,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.54958,
          "top": 0.800475,
          "width": 0.302521,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804604",
    "type": "sentence",
    "attributes": {
      "text": "LISA & 83.6 &\t83.74\t& 83.67",
      "tex": "LISA & 83.6 &\t83.74\t& 83.67",
      "tex_start": 66248,
      "tex_end": 66275,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.54958,
          "top": 0.817102,
          "width": 0.302521,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804605",
    "type": "sentence",
    "attributes": {
      "text": "+D\\&M & 85.04 &\t85.51 &\t85.27",
      "tex": "+D\\&M & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
      "tex_start": 66287,
      "tex_end": 66333,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.566387,
          "top": 0.832542,
          "width": 0.285714,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804606",
    "type": "sentence",
    "attributes": {
      "text": "+Gold & 89.11 &\t89.38 & \t89.25",
      "tex": "+Gold} & \\emph{89.11} &\t\\emph{89.38} & \t\\emph{89.25",
      "tex_start": 66352,
      "tex_end": 66403,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.568067,
          "top": 0.849169,
          "width": 0.284034,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804607",
    "type": "sentence",
    "attributes": {
      "text": "Precision, recall and F1 on the CoNLL-2005 development set with gold predicates.",
      "tex": "Precision, recall and F1 on the CoNLL-2005 development set with gold predicates.",
      "tex_start": 66428,
      "tex_end": 66508,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.515966,
          "top": 0.893112,
          "width": 0.317647,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.583193,
          "top": 0.876485,
          "width": 0.29916,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804608",
    "type": "sentence",
    "attributes": {
      "text": "(Label tab:conll05-gold-pred-dev)",
      "tex": "\\label{tab:conll05-gold-pred-dev}}",
      "tex_start": 66509,
      "tex_end": 66543,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": []
    },
    "relationships": {}
  },
  {
    "id": "804609",
    "type": "sentence",
    "attributes": {
      "text": "Data and pre-processing details.",
      "tex": "\n\n\\subsection{Data and pre-processing details}\n\n",
      "tex_start": 66555,
      "tex_end": 66603,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.515966,
          "top": 0.611639,
          "width": 0.292437,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804610",
    "type": "sentence",
    "attributes": {
      "text": "We initialize word embeddings with 100d pre-trained GloVe embeddings trained on 6 billion tokens of Wikipedia and Gigaword Citation (pennington2014glove).",
      "tex": "We initialize word embeddings with 100d pre-trained GloVe embeddings trained on 6 billion tokens of Wikipedia and Gigaword \\citep{pennington2014glove}.",
      "tex_start": 66603,
      "tex_end": 66754,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.556303,
          "top": 0.690024,
          "width": 0.0554622,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.517647,
          "top": 0.672209,
          "width": 0.280672,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.517647,
          "top": 0.65677,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.517647,
          "top": 0.640143,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804611",
    "type": "sentence",
    "attributes": {
      "text": "We evaluate the SRL performance of our models using the srl-eval.pl script provided by the CoNLL-2005 shared task,http://www.lsi.upc.es/ srlconll/srl-eval.pl which computes segment-level precision, recall and F1 score.",
      "tex": "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
      "tex_start": 66755,
      "tex_end": 67007,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.515966,
          "top": 0.705463,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.62521,
          "top": 0.688836,
          "width": 0.258824,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.114014,
          "width": 0.0403361,
          "height": 0.00593824
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.0950119,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.0795724,
          "width": 0.366387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804612",
    "type": "sentence",
    "attributes": {
      "text": "We also report the predicate detection scores output by this script.",
      "tex": "We also report the predicate detection scores output by this script.",
      "tex_start": 67008,
      "tex_end": 67076,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.128266,
          "width": 0.211765,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.186555,
          "top": 0.111639,
          "width": 0.30084,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804613",
    "type": "sentence",
    "attributes": {
      "text": "We evaluate parsing using the eval.pl CoNLL script, which excludes punctuation.",
      "tex": "We evaluate parsing using the \\texttt{eval.pl} CoNLL script, which excludes punctuation.",
      "tex_start": 67077,
      "tex_end": 67165,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.160333,
          "width": 0.141176,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.143705,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.352941,
          "top": 0.128266,
          "width": 0.134454,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804614",
    "type": "sentence",
    "attributes": {
      "text": "We train distinct D\\&M parsers for CoNLL-2005 and CoNLL-2012.",
      "tex": "We train distinct D\\&M parsers for CoNLL-2005 and CoNLL-2012.",
      "tex_start": 67167,
      "tex_end": 67228,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.192399,
          "width": 0.181513,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.141176,
          "top": 0.17696,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804615",
    "type": "sentence",
    "attributes": {
      "text": "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
      "tex": "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
      "tex_start": 67229,
      "tex_end": 67448,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.275534,
          "width": 0.0571429,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.152941,
          "top": 0.273159,
          "width": 0.00168067,
          "height": 0.00118765
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.256532,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.241093,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.224466,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.209026,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.317647,
          "top": 0.192399,
          "width": 0.169748,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804616",
    "type": "sentence",
    "attributes": {
      "text": "We use Stanford dependencies v3.5 Citation (deMarneffe2008) and POS tags from the Stanford CoreNLP left3words model Citation (toutanova2003feature).",
      "tex": "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and POS tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}.",
      "tex_start": 67449,
      "tex_end": 67600,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.320665,
          "width": 0.181513,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.305226,
          "width": 0.363025,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.288599,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.205042,
          "top": 0.273159,
          "width": 0.282353,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804617",
    "type": "sentence",
    "attributes": {
      "text": "We use the pre-trained ELMo modelshttps://github.com/allenai/bilm-tf and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the D\\&M parser with otherwise default settings.",
      "tex": "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the D\\&M parser with otherwise default settings.",
      "tex_start": 67601,
      "tex_end": 67867,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.385986,
          "width": 0.32437,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.369359,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.353919,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.337292,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.319328,
          "top": 0.320665,
          "width": 0.168067,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804618",
    "type": "sentence",
    "attributes": {
      "text": "CoNLL-2012.",
      "tex": "\n\n\\subsubsection{CoNLL-2012}\n",
      "tex_start": 67867,
      "tex_end": 67896,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.412114,
          "width": 0.161345,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804619",
    "type": "sentence",
    "attributes": {
      "text": "We follow the CoNLL-2012 split used by Citation (he2018jointly) to evaluate our models, which uses the annotations from herehttp://cemantix.org/data/ontonotes.html but the subset of those documents from the CoNLL-2012 co-reference split described herehttp://conll.cemantix.org/2012/data.html Citation (pradhan2013towards).",
      "tex": "We follow the CoNLL-2012 split used by \\citet{he2018jointly} to evaluate our models, which uses the annotations from here\\footnote{\\protect\\url{http://cemantix.org/data/ontonotes.html}} but the subset of those documents from the CoNLL-2012 co-reference split described here\\footnote{\\protect\\url{http://conll.cemantix.org/2012/data.html}} \\citep{pradhan2013towards}.",
      "tex_start": 67896,
      "tex_end": 68262,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.496437,
          "width": 0.312605,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.47981,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.463183,
          "width": 0.366387,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.447743,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.431116,
          "width": 0.29916,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804620",
    "type": "sentence",
    "attributes": {
      "text": "This dataset is drawn from seven domains: newswire, web, broadcast news and conversation, magazines, telephone conversations, and text from the bible.",
      "tex": "This dataset is drawn from seven domains: newswire, web, broadcast news and conversation, magazines, telephone conversations, and text from the bible.",
      "tex_start": 68263,
      "tex_end": 68413,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.56057,
          "width": 0.0386555,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.543943,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.528504,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.511876,
          "width": 0.364706,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.455462,
          "top": 0.496437,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804621",
    "type": "sentence",
    "attributes": {
      "text": "The text is annotated with gold part-of-speech, syntactic constituencies, named entities, word sense, speaker, co-reference and semantic role labels based on the PropBank guidelines Citation (palmer2005proposition).",
      "tex": "The text is annotated with gold part-of-speech, syntactic constituencies, named entities, word sense, speaker, co-reference and semantic role labels based on the PropBank guidelines \\citep{palmer2005proposition}.",
      "tex_start": 68414,
      "tex_end": 68626,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.624703,
          "width": 0.154622,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.608076,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.592637,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.57601,
          "width": 0.363025,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.178151,
          "top": 0.56057,
          "width": 0.309244,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804622",
    "type": "sentence",
    "attributes": {
      "text": "Propositions may be verbal or nominal, and there are 41 distinct semantic role labels, excluding continuation roles and including the predicate.",
      "tex": "Propositions may be verbal or nominal, and there are 41 distinct semantic role labels, excluding continuation roles and including the predicate.",
      "tex_start": 68627,
      "tex_end": 68771,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.673397,
          "width": 0.0941176,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.65677,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.64133,
          "width": 0.366387,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.287395,
          "top": 0.624703,
          "width": 0.198319,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804623",
    "type": "sentence",
    "attributes": {
      "text": "We convert the semantic proposition and role segmentations to BIO boundary-encoded tags, resulting in 129 distinct BIO-encoded tags (including continuation roles).",
      "tex": "We convert the semantic proposition and role segmentations to BIO boundary-encoded tags, resulting in 129 distinct BIO-encoded tags (including continuation roles).",
      "tex_start": 68772,
      "tex_end": 68935,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.720903,
          "width": 0.220168,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.705463,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.688836,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.22521,
          "top": 0.673397,
          "width": 0.262185,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804624",
    "type": "sentence",
    "attributes": {
      "text": "CoNLL-2005.",
      "tex": " \n\n\\subsubsection{CoNLL-2005}\n",
      "tex_start": 68935,
      "tex_end": 68965,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.747031,
          "width": 0.161345,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804625",
    "type": "sentence",
    "attributes": {
      "text": "The CoNLL-2005 data Citation (carreras2005introduction) is based on the original PropBank corpus Citation (palmer2005proposition), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) Citation (marcus1993building) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus Citation (francis1964manual).",
      "tex": "The CoNLL-2005 data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}.",
      "tex_start": 68965,
      "tex_end": 69330,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.799287,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.157983,
          "top": 0.783848,
          "width": 0.329412,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.767221,
          "width": 0.366387,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.143705,
          "width": 0.203361,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.128266,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.111639,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.0950119,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.0795724,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804626",
    "type": "sentence",
    "attributes": {
      "text": "This dataset contains only verbal predicates, though some are multi-word verbs, and 28 distinct role label types.",
      "tex": "This dataset contains only verbal predicates, though some are multi-word verbs, and 28 distinct role label types.",
      "tex_start": 69331,
      "tex_end": 69444,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.175772,
          "width": 0.329412,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.160333,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.729412,
          "top": 0.143705,
          "width": 0.152941,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804627",
    "type": "sentence",
    "attributes": {
      "text": "We obtain 105 SRL labels including continuations after encoding predicate argument segment boundaries with BIO tags.",
      "tex": "We obtain 105 SRL labels including continuations after encoding predicate argument segment boundaries with BIO tags.",
      "tex_start": 69445,
      "tex_end": 69561,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.224466,
          "width": 0.146218,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.207838,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.192399,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.860504,
          "top": 0.175772,
          "width": 0.0235294,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804628",
    "type": "sentence",
    "attributes": {
      "text": "Optimization and hyperparameters.",
      "tex": "\n\n\\subsection{Optimization and hyperparameters}\n",
      "tex_start": 69561,
      "tex_end": 69609,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.249406,
          "width": 0.322689,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804629",
    "type": "sentence",
    "attributes": {
      "text": "We train the model using the Nadam Citation (dozat2016incorporating) algorithm for adaptive stochastic gradient descent (SGD), which combines Adam Citation (kingma2014adam) SGD with Nesterov momentum Citation (nesterov1983method).",
      "tex": "We train the model using the Nadam \\citep{dozat2016incorporating} algorithm for adaptive stochastic gradient descent (SGD), which combines Adam \\citep{kingma2014adam} SGD with Nesterov momentum \\citep{nesterov1983method}.",
      "tex_start": 69609,
      "tex_end": 69830,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.552941,
          "top": 0.337292,
          "width": 0.010084,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.552941,
          "top": 0.319477,
          "width": 0.329412,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.304038,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.287411,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.271971,
          "width": 0.364706,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804630",
    "type": "sentence",
    "attributes": {
      "text": "We additionally vary the learning rate <<equation-132>> as a function of an initial learning rate <<equation-133>> and the current training step <<equation-134>> as described in Citation (vaswani2017attention) using the following function: <<equation-135>> which increases the learning rate linearly for the first <<equation-136>> training steps, then decays it proportionally to the inverse square root of the step number.",
      "tex": "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number.",
      "tex_start": 69831,
      "tex_end": 70276,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.490499,
          "width": 0.0252101,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.475059,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.458432,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.442993,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.531092,
          "top": 0.410926,
          "width": 0.351261,
          "height": 0.0142518
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.557983,
          "top": 0.384798,
          "width": 0.263866,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.368171,
          "width": 0.29916,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.350356,
          "width": 0.368067,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.576471,
          "top": 0.334917,
          "width": 0.305882,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804631",
    "type": "sentence",
    "attributes": {
      "text": "We found this learning rate schedule essential for training the self-attention model.",
      "tex": "We found this learning rate schedule essential for training the self-attention model.",
      "tex_start": 70277,
      "tex_end": 70362,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.507126,
          "width": 0.265546,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.551261,
          "top": 0.490499,
          "width": 0.329412,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804632",
    "type": "sentence",
    "attributes": {
      "text": "We only update optimization moving-average accumulators for parameters which receive gradient updates at a given step.Also known as lazy or sparse optimizer updates.",
      "tex": "We only update optimization moving-average accumulators for parameters which receive gradient updates at a given step.\\footnote{Also known as \\emph{lazy} or \\emph{sparse} optimizer updates.",
      "tex_start": 70363,
      "tex_end": 70552,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.554632,
          "width": 0.0907563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.539192,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.522565,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.793277,
          "top": 0.507126,
          "width": 0.0890756,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804633",
    "type": "sentence",
    "attributes": {
      "text": "In all of our experiments we used initial learning rate 0.04, <<equation-137>>, <<equation-138>>, <<equation-139>> and dropout rates of 0.1 everywhere.",
      "tex": "In all of our experiments we used initial learning rate 0.04, $\\beta_1=0.9$, $\\beta_2=0.98$, $\\epsilon=1\\times10^{-12}$ and dropout rates of 0.1 everywhere.",
      "tex_start": 70586,
      "tex_end": 70742,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.603325,
          "width": 0.238655,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.585511,
          "width": 0.366387,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.536134,
          "top": 0.571259,
          "width": 0.346218,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804634",
    "type": "sentence",
    "attributes": {
      "text": "We use 10 or 12 self-attention layers made up of 8 attention heads each with embedding dimension 25, with 800d feed-forward projections.",
      "tex": "We use 10 or 12 self-attention layers made up of 8 attention heads each with embedding dimension 25, with 800d feed-forward projections.",
      "tex_start": 70743,
      "tex_end": 70879,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.652019,
          "width": 0.188235,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.635392,
          "width": 0.366387,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.619952,
          "width": 0.364706,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.764706,
          "top": 0.603325,
          "width": 0.117647,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804635",
    "type": "sentence",
    "attributes": {
      "text": "In the syntactically-informed attention head, <<equation-140>> has dimension 500 and <<equation-141>> has dimension 100.",
      "tex": "In the syntactically-informed attention head, $Q_{parse}$ has dimension 500 and $K_{parse}$ has dimension 100.",
      "tex_start": 70880,
      "tex_end": 70990,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.684085,
          "width": 0.268908,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.666271,
          "width": 0.364706,
          "height": 0.0142518
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.729412,
          "top": 0.652019,
          "width": 0.152941,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804636",
    "type": "sentence",
    "attributes": {
      "text": "The size of <<equation-142>> and <<equation-143>> representations and the representation used for joint part-of-speech/predicate classification is 200.",
      "tex": "The size of $predicate$ and $role$ representations and the representation used for joint part-of-speech/predicate classification is 200.",
      "tex_start": 70991,
      "tex_end": 71127,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.731591,
          "width": 0.147899,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.716152,
          "width": 0.368067,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.698337,
          "width": 0.366387,
          "height": 0.0118765
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.798319,
          "top": 0.684085,
          "width": 0.0857143,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804637",
    "type": "sentence",
    "attributes": {
      "text": "We train with <<equation-144>> warmup steps and clip gradient norms to 1.",
      "tex": "We train with $warm=8000$ warmup steps and clip gradient norms to 1.",
      "tex_start": 71128,
      "tex_end": 71196,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.748219,
          "width": 0.327731,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.672269,
          "top": 0.731591,
          "width": 0.210084,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804638",
    "type": "sentence",
    "attributes": {
      "text": "We use batches of approximately 5000 tokens.",
      "tex": "We use batches of approximately 5000 tokens.",
      "tex_start": 71197,
      "tex_end": 71241,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.517647,
          "top": 0.764846,
          "width": 0.309244,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.860504,
          "top": 0.748219,
          "width": 0.0235294,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804639",
    "type": "equation",
    "attributes": {
      "tex": "$^1$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.263866,
          "top": 0.12114,
          "width": 0.00168067,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804640",
    "type": "equation",
    "attributes": {
      "tex": "$^1$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.401681,
          "top": 0.12114,
          "width": 0.00168067,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804641",
    "type": "equation",
    "attributes": {
      "tex": "$^2$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.532773,
          "top": 0.12114,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804642",
    "type": "equation",
    "attributes": {
      "tex": "$^2$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.655462,
          "top": 0.12114,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804643",
    "type": "equation",
    "attributes": {
      "tex": "$^1$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.87395,
          "top": 0.12114,
          "width": 0.00168067,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804644",
    "type": "equation",
    "attributes": {
      "tex": "$^1$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.314286,
          "top": 0.137767,
          "width": 0.00168067,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804645",
    "type": "equation",
    "attributes": {
      "tex": "$^2$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.415126,
          "top": 0.186461,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804646",
    "type": "equation",
    "attributes": {
      "tex": "$J$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.806723,
          "top": 0.289786,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804647",
    "type": "equation",
    "attributes": {
      "tex": "$p$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.773109,
          "top": 0.308789,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804648",
    "type": "equation",
    "attributes": {
      "tex": "$r$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.589916,
          "top": 0.340855,
          "width": 0.00840336,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804649",
    "type": "equation",
    "attributes": {
      "tex": "$r$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.779832,
          "top": 0.357482,
          "width": 0.00840336,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804650",
    "type": "equation",
    "attributes": {
      "tex": "$A_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.440336,
          "top": 0.337292,
          "width": 0.0453782,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804651",
    "type": "equation",
    "attributes": {
      "tex": "$V_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.157983,
          "top": 0.385986,
          "width": 0.0420168,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804652",
    "type": "equation",
    "attributes": {
      "tex": "$\\mathcal{X}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.830252,
          "top": 0.111639,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804653",
    "type": "equation",
    "attributes": {
      "tex": "$T$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.870588,
          "top": 0.111639,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804654",
    "type": "equation",
    "attributes": {
      "tex": "$x_t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.680672,
          "top": 0.130641,
          "width": 0.0151261,
          "height": 0.00712589
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804655",
    "type": "equation",
    "attributes": {
      "tex": "$p_t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.868908,
          "top": 0.258907,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804656",
    "type": "equation",
    "attributes": {
      "tex": "$t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.65042,
          "top": 0.352732,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804657",
    "type": "equation",
    "attributes": {
      "tex": "$J$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.586555,
          "top": 0.401425,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804658",
    "type": "equation",
    "attributes": {
      "tex": "$j$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.435867,
          "width": 0.00672269,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.521008,
          "top": 0.433492,
          "width": 0.00168067,
          "height": 0.00118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804659",
    "type": "equation",
    "attributes": {
      "tex": "$T^{(j)}(\\cdot)$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.719328,
          "top": 0.431116,
          "width": 0.0487395,
          "height": 0.0142518
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804660",
    "type": "equation",
    "attributes": {
      "tex": "$s_t^{(j)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.591597,
          "top": 0.447743,
          "width": 0.0218487,
          "height": 0.0154394
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804661",
    "type": "equation",
    "attributes": {
      "tex": "$LN(\\cdot)$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.657143,
          "top": 0.451306,
          "width": 0.0453782,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804662",
    "type": "equation",
    "attributes": {
      "tex": "$c_t^{(p)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.840336,
          "top": 0.465558,
          "width": 0.0252101,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804663",
    "type": "equation",
    "attributes": {
      "tex": "\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.57479,
          "top": 0.495249,
          "width": 0.25042,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804664",
    "type": "equation",
    "attributes": {
      "tex": "$s_t^{(j)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.801681,
          "top": 0.526128,
          "width": 0.0235294,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804665",
    "type": "equation",
    "attributes": {
      "tex": "$T^{(j)}(\\cdot)$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.543943,
          "width": 0.0487395,
          "height": 0.0142518
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804666",
    "type": "equation",
    "attributes": {
      "tex": "$H$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.843697,
          "top": 0.579572,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804667",
    "type": "equation",
    "attributes": {
      "tex": "$H$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.868908,
          "top": 0.643705,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804668",
    "type": "equation",
    "attributes": {
      "tex": "$S^{(j-1)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.77479,
          "top": 0.688836,
          "width": 0.0436975,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804669",
    "type": "equation",
    "attributes": {
      "tex": "$T$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.845378,
          "top": 0.691211,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804670",
    "type": "equation",
    "attributes": {
      "tex": "$j-1$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.722689,
          "top": 0.707838,
          "width": 0.0369748,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804671",
    "type": "equation",
    "attributes": {
      "tex": "$h$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.596639,
          "top": 0.723278,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804672",
    "type": "equation",
    "attributes": {
      "tex": "$K_h^{(j)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.803361,
          "top": 0.73753,
          "width": 0.0302521,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804673",
    "type": "equation",
    "attributes": {
      "tex": "$V_h^{(j)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.852101,
          "top": 0.73753,
          "width": 0.0285714,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804674",
    "type": "equation",
    "attributes": {
      "tex": "$Q_h^{(j)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.547899,
          "top": 0.756532,
          "width": 0.0285714,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804675",
    "type": "equation",
    "attributes": {
      "tex": "$T\\times d_k$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.689076,
          "top": 0.760095,
          "width": 0.0470588,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804676",
    "type": "equation",
    "attributes": {
      "tex": "$T\\times d_q$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.746219,
          "top": 0.760095,
          "width": 0.0453782,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804677",
    "type": "equation",
    "attributes": {
      "tex": "$T\\times d_v$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.831933,
          "top": 0.760095,
          "width": 0.0453782,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804678",
    "type": "equation",
    "attributes": {
      "tex": "$Q_h^{(j)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.786555,
          "top": 0.775534,
          "width": 0.0285714,
          "height": 0.0178147
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804679",
    "type": "equation",
    "attributes": {
      "tex": "$K_h^{(j)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.85042,
          "top": 0.775534,
          "width": 0.0302521,
          "height": 0.0178147
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804680",
    "type": "equation",
    "attributes": {
      "tex": "$T\\times T$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.598319,
          "top": 0.799287,
          "width": 0.0470588,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804681",
    "type": "equation",
    "attributes": {
      "tex": "$A_h^{(j)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.852101,
          "top": 0.795724,
          "width": 0.0285714,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804682",
    "type": "equation",
    "attributes": {
      "tex": "\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.179832,
          "top": 0.135392,
          "width": 0.248739,
          "height": 0.02019
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804683",
    "type": "equation",
    "attributes": {
      "tex": "$V_h^{(j)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.186461,
          "width": 0.0285714,
          "height": 0.0178147
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804684",
    "type": "equation",
    "attributes": {
      "tex": "$M_h^{(j)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.268908,
          "top": 0.206651,
          "width": 0.0336134,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804685",
    "type": "equation",
    "attributes": {
      "tex": "\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.242017,
          "top": 0.238717,
          "width": 0.122689,
          "height": 0.0178147
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804686",
    "type": "equation",
    "attributes": {
      "tex": "$t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.157983,
          "top": 0.27791,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804687",
    "type": "equation",
    "attributes": {
      "tex": "$M_h^{(j)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.189916,
          "top": 0.273159,
          "width": 0.0336134,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804688",
    "type": "equation",
    "attributes": {
      "tex": "$t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.168067,
          "top": 0.293349,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804689",
    "type": "equation",
    "attributes": {
      "tex": "$j$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.242017,
          "top": 0.295724,
          "width": 0.00672269,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.247059,
          "top": 0.293349,
          "width": 0.00168067,
          "height": 0.00118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804690",
    "type": "equation",
    "attributes": {
      "tex": "$t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.198319,
          "top": 0.311164,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804691",
    "type": "equation",
    "attributes": {
      "tex": "$A_h^{(j)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.386555,
          "top": 0.307601,
          "width": 0.0268908,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804692",
    "type": "equation",
    "attributes": {
      "tex": "$V_h^{(j)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.302521,
          "top": 0.326603,
          "width": 0.0285714,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804693",
    "type": "equation",
    "attributes": {
      "tex": "$j$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.334454,
          "top": 0.461995,
          "width": 0.00672269,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.339496,
          "top": 0.45962,
          "width": 0.00168067,
          "height": 0.00118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804694",
    "type": "equation",
    "attributes": {
      "tex": "$A_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.151261,
          "top": 0.653207,
          "width": 0.0453782,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804695",
    "type": "equation",
    "attributes": {
      "tex": "$i$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.672209,
          "width": 0.00504202,
          "height": 0.00593824
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.12437,
          "top": 0.669834,
          "width": 0.00168067,
          "height": 0.00118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804696",
    "type": "equation",
    "attributes": {
      "tex": "$S^{(i-1)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.684085,
          "width": 0.0420168,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804697",
    "type": "equation",
    "attributes": {
      "tex": "$S^{(i-1)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.178151,
          "top": 0.699525,
          "width": 0.0436975,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804698",
    "type": "equation",
    "attributes": {
      "tex": "$K_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.245378,
          "top": 0.718527,
          "width": 0.0470588,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804699",
    "type": "equation",
    "attributes": {
      "tex": "$Q_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.305882,
          "top": 0.71734,
          "width": 0.0470588,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804700",
    "type": "equation",
    "attributes": {
      "tex": "$V_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.366387,
          "top": 0.718527,
          "width": 0.0420168,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804701",
    "type": "equation",
    "attributes": {
      "tex": "$parent$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.433613,
          "top": 0.733967,
          "width": 0.0537815,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804702",
    "type": "equation",
    "attributes": {
      "tex": "$dependent$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.152941,
          "top": 0.749406,
          "width": 0.0823529,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804703",
    "type": "equation",
    "attributes": {
      "tex": "$K_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.230252,
          "top": 0.846793,
          "width": 0.0470588,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804704",
    "type": "equation",
    "attributes": {
      "tex": "$Q_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.317647,
          "top": 0.845606,
          "width": 0.0453782,
          "height": 0.0142518
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804705",
    "type": "equation",
    "attributes": {
      "tex": "$U_{heads}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.188235,
          "top": 0.86342,
          "width": 0.0453782,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804706",
    "type": "equation",
    "attributes": {
      "tex": "\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.144538,
          "top": 0.889549,
          "width": 0.297479,
          "height": 0.0178147
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804707",
    "type": "equation",
    "attributes": {
      "tex": "$V_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.111639,
          "width": 0.0420168,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804708",
    "type": "equation",
    "attributes": {
      "tex": "$t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.840336,
          "top": 0.192399,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804709",
    "type": "equation",
    "attributes": {
      "tex": "$q$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.638655,
          "top": 0.210214,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804710",
    "type": "equation",
    "attributes": {
      "tex": "$A_{parse}[t,q]$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.680672,
          "top": 0.206651,
          "width": 0.0806723,
          "height": 0.0142518
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804711",
    "type": "equation",
    "attributes": {
      "tex": "$t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.667227,
          "top": 0.224466,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804712",
    "type": "equation",
    "attributes": {
      "tex": "$q$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.781513,
          "top": 0.226841,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804713",
    "type": "equation",
    "attributes": {
      "tex": "\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.571429,
          "top": 0.252969,
          "width": 0.255462,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804714",
    "type": "equation",
    "attributes": {
      "tex": "$A_{parse}[t]$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.722689,
          "top": 0.28266,
          "width": 0.0621849,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804715",
    "type": "equation",
    "attributes": {
      "tex": "$t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.789916,
          "top": 0.299287,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804716",
    "type": "equation",
    "attributes": {
      "tex": "$A_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.36342,
          "width": 0.0453782,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804717",
    "type": "equation",
    "attributes": {
      "tex": "$Q_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.695798,
          "top": 0.410926,
          "width": 0.0453782,
          "height": 0.0142518
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804718",
    "type": "equation",
    "attributes": {
      "tex": "$K_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.779832,
          "top": 0.412114,
          "width": 0.0470588,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804719",
    "type": "equation",
    "attributes": {
      "tex": "$y_t^{dep}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.784874,
          "top": 0.440618,
          "width": 0.0302521,
          "height": 0.0154394
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804720",
    "type": "equation",
    "attributes": {
      "tex": "$\\mathcal{P}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.636975,
          "top": 0.509501,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804721",
    "type": "equation",
    "attributes": {
      "tex": "$A_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.65042,
          "top": 0.573634,
          "width": 0.0453782,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804722",
    "type": "equation",
    "attributes": {
      "tex": "$s_t^{(r)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.462185,
          "top": 0.219715,
          "width": 0.0218487,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804723",
    "type": "equation",
    "attributes": {
      "tex": "$r$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.243697,
          "top": 0.24228,
          "width": 0.00840336,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804724",
    "type": "equation",
    "attributes": {
      "tex": "$p$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.245378,
          "top": 0.258907,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804725",
    "type": "equation",
    "attributes": {
      "tex": "$r_t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.282353,
          "top": 0.274347,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804726",
    "type": "equation",
    "attributes": {
      "tex": "$t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.37479,
          "top": 0.271971,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804727",
    "type": "equation",
    "attributes": {
      "tex": "$P(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.194958,
          "top": 0.30285,
          "width": 0.169748,
          "height": 0.0142518
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804728",
    "type": "equation",
    "attributes": {
      "tex": "$y_t^{prp}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.426891,
          "top": 0.30285,
          "width": 0.0302521,
          "height": 0.0130641
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804729",
    "type": "equation",
    "attributes": {
      "tex": "$\\mathcal{V}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.289076,
          "top": 0.448931,
          "width": 0.010084,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804730",
    "type": "equation",
    "attributes": {
      "tex": "$\\mathcal{P}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.336134,
          "top": 0.448931,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804731",
    "type": "equation",
    "attributes": {
      "tex": "$s_t^{(J)}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.460504,
          "top": 0.461995,
          "width": 0.0235294,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804732",
    "type": "equation",
    "attributes": {
      "tex": "$s_t^{pred}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.405042,
          "top": 0.47981,
          "width": 0.0336134,
          "height": 0.0154394
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804733",
    "type": "equation",
    "attributes": {
      "tex": "$s_t^{role}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.32605,
          "top": 0.497625,
          "width": 0.0302521,
          "height": 0.0142518
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804734",
    "type": "equation",
    "attributes": {
      "tex": "$U$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.532067,
          "width": 0.010084,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804735",
    "type": "equation",
    "attributes": {
      "tex": "$s_{ft}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.413445,
          "top": 0.534442,
          "width": 0.0184874,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804736",
    "type": "equation",
    "attributes": {
      "tex": "$t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.236975,
          "top": 0.548694,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804737",
    "type": "equation",
    "attributes": {
      "tex": "$f$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.168067,
          "top": 0.562945,
          "width": 0.00840336,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804738",
    "type": "equation",
    "attributes": {
      "tex": "$t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.260504,
          "top": 0.564133,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804739",
    "type": "equation",
    "attributes": {
      "tex": "$f$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.352941,
          "top": 0.562945,
          "width": 0.00840336,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804740",
    "type": "equation",
    "attributes": {
      "tex": "\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_t^{role}\n\\end{align}",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.226891,
          "top": 0.590261,
          "width": 0.154622,
          "height": 0.0178147
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804741",
    "type": "equation",
    "attributes": {
      "tex": "$t$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.193277,
          "top": 0.671021,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804742",
    "type": "equation",
    "attributes": {
      "tex": "$f$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.272269,
          "top": 0.669834,
          "width": 0.00840336,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804743",
    "type": "equation",
    "attributes": {
      "tex": "$P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.685273,
          "width": 0.223529,
          "height": 0.0166271
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804744",
    "type": "equation",
    "attributes": {
      "tex": "$s_{ft}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.357983,
          "top": 0.73753,
          "width": 0.0184874,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804745",
    "type": "equation",
    "attributes": {
      "tex": "$\\mathcal{P}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.173109,
          "top": 0.846793,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804746",
    "type": "equation",
    "attributes": {
      "tex": "$\\mathcal{P}_G$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.339496,
          "top": 0.846793,
          "width": 0.0235294,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804747",
    "type": "equation",
    "attributes": {
      "tex": "$\\mathcal{V}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.415126,
          "top": 0.846793,
          "width": 0.010084,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804748",
    "type": "equation",
    "attributes": {
      "tex": "$\\mathcal{V}_G$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.203361,
          "top": 0.86342,
          "width": 0.0201681,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804749",
    "type": "equation",
    "attributes": {
      "tex": "$\\mathcal{X}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.830252,
          "top": 0.111639,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804750",
    "type": "equation",
    "attributes": {
      "tex": "\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.62521,
          "top": 0.24228,
          "width": 0.194958,
          "height": 0.0225653
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.62521,
          "top": 0.22209,
          "width": 0.181513,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.62521,
          "top": 0.203088,
          "width": 0.132773,
          "height": 0.0130641
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.563025,
          "top": 0.155582,
          "width": 0.27395,
          "height": 0.04038
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804751",
    "type": "equation",
    "attributes": {
      "tex": "$\\lambda_1$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.568067,
          "top": 0.280285,
          "width": 0.0134454,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804752",
    "type": "equation",
    "attributes": {
      "tex": "$\\lambda_2$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.623529,
          "top": 0.280285,
          "width": 0.0151261,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804753",
    "type": "equation",
    "attributes": {
      "tex": "$_{E}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.67563,
          "top": 0.10095,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804754",
    "type": "equation",
    "attributes": {
      "tex": "$_{G}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.672269,
          "top": 0.11639,
          "width": 0.010084,
          "height": 0.00712589
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804755",
    "type": "equation",
    "attributes": {
      "tex": "$_{E}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.67395,
          "top": 0.133017,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804756",
    "type": "equation",
    "attributes": {
      "tex": "$_{E}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.67563,
          "top": 0.149644,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804757",
    "type": "equation",
    "attributes": {
      "tex": "$_{G}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.672269,
          "top": 0.165083,
          "width": 0.010084,
          "height": 0.00712589
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804758",
    "type": "equation",
    "attributes": {
      "tex": "$_{E}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.67395,
          "top": 0.18171,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804759",
    "type": "equation",
    "attributes": {
      "tex": "$_{E}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.67563,
          "top": 0.198337,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804760",
    "type": "equation",
    "attributes": {
      "tex": "$_{G}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.672269,
          "top": 0.213777,
          "width": 0.010084,
          "height": 0.00712589
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804761",
    "type": "equation",
    "attributes": {
      "tex": "$_{E}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.67395,
          "top": 0.230404,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804762",
    "type": "equation",
    "attributes": {
      "tex": "$G$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.563025,
          "top": 0.301663,
          "width": 0.0117647,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804763",
    "type": "equation",
    "attributes": {
      "tex": "$E$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.721008,
          "top": 0.30285,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804764",
    "type": "equation",
    "attributes": {
      "tex": "$G$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.183193,
          "top": 0.814727,
          "width": 0.0117647,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804765",
    "type": "equation",
    "attributes": {
      "tex": "$E$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.307563,
          "top": 0.815915,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804766",
    "type": "equation",
    "attributes": {
      "tex": "$_G$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.557983,
          "top": 0.539192,
          "width": 0.010084,
          "height": 0.00712589
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804767",
    "type": "equation",
    "attributes": {
      "tex": "$\\pm$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.818487,
          "top": 0.859857,
          "width": 0.0117647,
          "height": 0.00712589
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804768",
    "type": "equation",
    "attributes": {
      "tex": "$\\pm$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.855462,
          "top": 0.859857,
          "width": 0.0117647,
          "height": 0.00712589
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804769",
    "type": "equation",
    "attributes": {
      "tex": "$\\Delta$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.445378,
          "top": 0.0760095,
          "width": 0.0117647,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804770",
    "type": "equation",
    "attributes": {
      "tex": "$\\Delta$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.445378,
          "top": 0.160333,
          "width": 0.0117647,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804771",
    "type": "equation",
    "attributes": {
      "tex": "$lr$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.868908,
          "top": 0.334917,
          "width": 0.0134454,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804772",
    "type": "equation",
    "attributes": {
      "tex": "$lr_0$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.803361,
          "top": 0.350356,
          "width": 0.0201681,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804773",
    "type": "equation",
    "attributes": {
      "tex": "$step$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.670588,
          "top": 0.368171,
          "width": 0.0302521,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804774",
    "type": "equation",
    "attributes": {
      "tex": "\\begin{align}\nlr = lr_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.531092,
          "top": 0.410926,
          "width": 0.312605,
          "height": 0.0142518
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804775",
    "type": "equation",
    "attributes": {
      "tex": "$warm$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.551261,
          "top": 0.460808,
          "width": 0.0470588,
          "height": 0.00593824
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804776",
    "type": "equation",
    "attributes": {
      "tex": "$\\beta_1=0.9$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.588235,
          "top": 0.585511,
          "width": 0.0655462,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804777",
    "type": "equation",
    "attributes": {
      "tex": "$\\beta_2=0.98$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.663866,
          "top": 0.585511,
          "width": 0.0739496,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804778",
    "type": "equation",
    "attributes": {
      "tex": "$\\epsilon=1\\times10^{-12}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.74958,
          "top": 0.585511,
          "width": 0.102521,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804779",
    "type": "equation",
    "attributes": {
      "tex": "$Q_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.715966,
          "top": 0.666271,
          "width": 0.0470588,
          "height": 0.0142518
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804780",
    "type": "equation",
    "attributes": {
      "tex": "$K_{parse}$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.584874,
          "top": 0.684085,
          "width": 0.0470588,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804781",
    "type": "equation",
    "attributes": {
      "tex": "$predicate$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.698337,
          "width": 0.0739496,
          "height": 0.0118765
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804782",
    "type": "equation",
    "attributes": {
      "tex": "$role$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.631933,
          "top": 0.698337,
          "width": 0.0302521,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804783",
    "type": "equation",
    "attributes": {
      "tex": "$warm=8000$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.77479,
          "top": 0.731591,
          "width": 0.107563,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "804784",
    "type": "symbol",
    "attributes": {
      "tex": "$A$",
      "mathml": "<mi>A</mi>",
      "mathml_near_matches": [
        "<mi>A</mi>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A}}_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A}}_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A}}_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A}}_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Let ${\\htmlClass{match-highlight}{A}}_{parse}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A}}_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A}}_{parse}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A}}_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A}}_{parse}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A}}_{parse}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.440336,
          "top": 0.337292,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804650"
      },
      "parent": {
        "type": "symbol",
        "id": "804827"
      },
      "sentence": {
        "type": "sentence",
        "id": "804299"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804785",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.452101,
          "top": 0.344418,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804650"
      },
      "parent": {
        "type": "symbol",
        "id": "804827"
      },
      "sentence": {
        "type": "sentence",
        "id": "804299"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804786",
    "type": "symbol",
    "attributes": {
      "tex": "$V$",
      "mathml": "<mi>V</mi>",
      "mathml_near_matches": [
        "<mi>V</mi>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V}}_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V}}_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations ${\\htmlClass{match-highlight}{V}}_{parse}$ as in the other attention heads.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ and $\\mathcal{P}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{\\htmlClass{match-highlight}{{V}}}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.156303,
          "top": 0.385986,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804651"
      },
      "parent": {
        "type": "symbol",
        "id": "805586"
      },
      "sentence": {
        "type": "sentence",
        "id": "804299"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804787",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.166387,
          "top": 0.391924,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804651"
      },
      "parent": {
        "type": "symbol",
        "id": "805586"
      },
      "sentence": {
        "type": "sentence",
        "id": "804299"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804788",
    "type": "symbol",
    "attributes": {
      "tex": "$x$",
      "mathml": "<mi>x</mi>",
      "mathml_near_matches": [
        "<mi>x</mi>",
        "<msub><mi>x</mi><mi>t</mi></msub>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations ${\\htmlClass{match-highlight}{x}}_t$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.680672,
          "top": 0.130641,
          "width": 0.010084,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804654"
      },
      "parent": {
        "type": "symbol",
        "id": "804804"
      },
      "sentence": {
        "type": "sentence",
        "id": "804318"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804789",
    "type": "symbol",
    "attributes": {
      "tex": "$T^{(j)}$",
      "mathml": "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
      "mathml_near_matches": [
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>T</mi>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T^{(j)}}}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T^{(j)}}}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T^{(j)}}}(\\cdot)$ consists of:"
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.719328,
          "top": 0.431116,
          "width": 0.0285714,
          "height": 0.0106888
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804659"
      },
      "parent": {
        "type": "symbol",
        "id": "804808"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805591"
        },
        {
          "type": "symbol",
          "id": "804801"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        }
      ],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804790",
    "type": "symbol",
    "attributes": {
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "mathml_near_matches": [
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.591597,
          "top": 0.453682,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804660"
      },
      "parent": {
        "type": "symbol",
        "id": "804820"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804791",
    "type": "symbol",
    "attributes": {
      "tex": "$LN$",
      "mathml": "<mi>LN</mi>",
      "mathml_near_matches": [
        "<mi>LN</mi>",
        "<mrow><mi>LN</mi><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>LN</mi><mo stretchy=\"false\">(</mo><msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup><mo>+</mo><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and ${\\htmlClass{match-highlight}{LN}}(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = {\\htmlClass{match-highlight}{LN}}(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.657143,
          "top": 0.451306,
          "width": 0.0268908,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804661"
      },
      "parent": {
        "type": "symbol",
        "id": "804822"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804792",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input ${\\htmlClass{match-highlight}{c}}_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$."
      ],
      "is_definition": false,
      "tex": "$c$",
      "mathml": "<mi>c</mi>",
      "mathml_near_matches": [
        "<mi>c</mi>",
        "<msubsup><mi>c</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>p</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.840336,
          "top": 0.472684,
          "width": 0.00840336,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804662"
      },
      "parent": {
        "type": "symbol",
        "id": "804817"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804793",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.57479,
          "top": 0.502375,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804818"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804794",
    "type": "symbol",
    "attributes": {
      "tex": "$LN$",
      "mathml": "<mi>LN</mi>",
      "mathml_near_matches": [
        "<mi>LN</mi>",
        "<mrow><mi>LN</mi><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>LN</mi><mo stretchy=\"false\">(</mo><msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup><mo>+</mo><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and ${\\htmlClass{match-highlight}{LN}}(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = {\\htmlClass{match-highlight}{LN}}(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.626891,
          "top": 0.5,
          "width": 0.0252101,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804825"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804795",
    "type": "symbol",
    "attributes": {
      "tex": "$s_t^{(j-1)}$",
      "mathml": "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN({\\htmlClass{match-highlight}{s_t^{(j-1)}}} + T^{(j)}({\\htmlClass{match-highlight}{s_t^{(j-1)}}}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.662185,
          "top": 0.495249,
          "width": 0.0403361,
          "height": 0.0166271
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804825"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804797"
        },
        {
          "type": "symbol",
          "id": "804816"
        },
        {
          "type": "symbol",
          "id": "804800"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804796",
    "type": "symbol",
    "attributes": {
      "tex": "$s_t^{(j-1)}$",
      "mathml": "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN({\\htmlClass{match-highlight}{s_t^{(j-1)}}} + T^{(j)}({\\htmlClass{match-highlight}{s_t^{(j-1)}}}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.769748,
          "top": 0.495249,
          "width": 0.0403361,
          "height": 0.0166271
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804825"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804798"
        },
        {
          "type": "symbol",
          "id": "804812"
        },
        {
          "type": "symbol",
          "id": "804803"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804797",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "mathml_near_matches": [
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.662185,
          "top": 0.502375,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804795"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804798",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.769748,
          "top": 0.502375,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804796"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804799",
    "type": "symbol",
    "attributes": {
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "mathml_near_matches": [
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.801681,
          "top": 0.533254,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804664"
      },
      "parent": {
        "type": "symbol",
        "id": "804819"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804800",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.67563,
          "top": 0.497625,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804795"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804801",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.737815,
          "top": 0.433492,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804659"
      },
      "parent": {
        "type": "symbol",
        "id": "804789"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804802",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.815126,
          "top": 0.528504,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804664"
      },
      "parent": {
        "type": "symbol",
        "id": "804819"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804803",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.781513,
          "top": 0.497625,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804796"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804804",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations ${\\htmlClass{match-highlight}{x_t}}$."
      ],
      "tex": "$x_t$",
      "mathml": "<msub><mi>x</mi><mi>t</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>x</mi><mi>t</mi></msub>",
        "<mi>x</mi>"
      ],
      "is_definition": false,
      "nicknames": [
        "sequence $\\mathcal{X}$ of $T$ token representations"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.680672,
          "top": 0.130641,
          "width": 0.0151261,
          "height": 0.00712589
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804654"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804788"
        },
        {
          "type": "symbol",
          "id": "804811"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804318"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804805",
    "type": "symbol",
    "attributes": {
      "tex": "$p$",
      "mathml": "<mi>p</mi>",
      "mathml_near_matches": [
        "<mi>p</mi>",
        "<msub><mi>p</mi><mi>t</mi></msub>",
        "<msup><mi>p</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msup>"
      ],
      "snippets": [
        "In layer ${\\htmlClass{match-highlight}{p}}$ one attention head is trained to attend to parse parents (Figure \\ref{attention-fig}).",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding ${\\htmlClass{match-highlight}{p}}_t$ following previous work \\citep{he2017deep}.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{({\\htmlClass{match-highlight}{p}})}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Specifically, we feed the representation $s_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer ${\\htmlClass{match-highlight}{p}}$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(ste{\\htmlClass{match-highlight}{p}}^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "syntactically-informed layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.853782,
          "top": 0.467934,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804662"
      },
      "parent": {
        "type": "symbol",
        "id": "804817"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804293"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804293"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804806",
    "type": "symbol",
    "attributes": {
      "tex": "$p$",
      "mathml": "<mi>p</mi>",
      "mathml_near_matches": [
        "<mi>p</mi>",
        "<msub><mi>p</mi><mi>t</mi></msub>",
        "<msup><mi>p</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msup>"
      ],
      "snippets": [
        "In layer ${\\htmlClass{match-highlight}{p}}$ one attention head is trained to attend to parse parents (Figure \\ref{attention-fig}).",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding ${\\htmlClass{match-highlight}{p}}_t$ following previous work \\citep{he2017deep}.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{({\\htmlClass{match-highlight}{p}})}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Specifically, we feed the representation $s_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer ${\\htmlClass{match-highlight}{p}}$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(ste{\\htmlClass{match-highlight}{p}}^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "syntactically-informed layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.773109,
          "top": 0.308789,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804647"
      },
      "sentence": {
        "type": "sentence",
        "id": "804293"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804293"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804293"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804807",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "For experiments with gold predicates, we concatenate a predicate indicator embedding ${\\htmlClass{match-highlight}{p}}_t$ following previous work \\citep{he2017deep}.",
        "In layer ${\\htmlClass{match-highlight}{p}}$ one attention head is trained to attend to parse parents (Figure \\ref{attention-fig}).",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{({\\htmlClass{match-highlight}{p}})}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Specifically, we feed the representation $s_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer ${\\htmlClass{match-highlight}{p}}$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(ste{\\htmlClass{match-highlight}{p}}^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "tex": "$p$",
      "mathml": "<mi>p</mi>",
      "mathml_near_matches": [
        "<mi>p</mi>",
        "<msub><mi>p</mi><mi>t</mi></msub>",
        "<msup><mi>p</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msup>"
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "syntactically-informed layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.868908,
          "top": 0.258907,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804655"
      },
      "parent": {
        "type": "symbol",
        "id": "804821"
      },
      "sentence": {
        "type": "sentence",
        "id": "804320"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804293"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804293"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804808",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mi>T</mi>"
      ],
      "tex": "$T^{(j)}(\\cdot)$",
      "mathml": "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
      "snippets": [
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T^{(j)}(\\cdot)}}$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T^{(j)}(\\cdot)}}$ consists of:"
      ],
      "is_definition": false,
      "definitions": [
        "$j$th attention layer",
        "layer that consists of: (a) multi-head self-attention and (b) a feed-forward projection"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.719328,
          "top": 0.431116,
          "width": 0.0487395,
          "height": 0.0142518
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804659"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804789"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804809",
    "type": "symbol",
    "attributes": {
      "tex": "$r$",
      "mathml": "<mi>r</mi>",
      "mathml_near_matches": [
        "<mi>r</mi>",
        "<msub><mi>r</mi><mn>0</mn></msub>",
        "<msub><mi>r</mi><mi>t</mi></msub>"
      ],
      "snippets": [
        "Layer ${\\htmlClass{match-highlight}{r}}$ is input for a joint predicate/POS classifier.",
        "Representations from layer ${\\htmlClass{match-highlight}{r}}$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token SRL predictions with respect to each predicted predicate.",
        "Specifically, we feed the representation $s_t^{({\\htmlClass{match-highlight}{r}})}$ from a layer ${\\htmlClass{match-highlight}{r}}$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores ${\\htmlClass{match-highlight}{r}}_t$ for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{r}}_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $l{\\htmlClass{match-highlight}{r}}_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = l{\\htmlClass{match-highlight}{r}}_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "layer",
        "layer"
      ],
      "definitions": [
        "input for a joint predicate/POS classifier"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.779832,
          "top": 0.357482,
          "width": 0.00840336,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804649"
      },
      "sentence": {
        "type": "sentence",
        "id": "804295"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "defining_formula_equations": []
    }
  },
  {
    "id": "804810",
    "type": "symbol",
    "attributes": {
      "tex": "$r$",
      "mathml": "<mi>r</mi>",
      "mathml_near_matches": [
        "<mi>r</mi>",
        "<msub><mi>r</mi><mn>0</mn></msub>",
        "<msub><mi>r</mi><mi>t</mi></msub>"
      ],
      "snippets": [
        "Layer ${\\htmlClass{match-highlight}{r}}$ is input for a joint predicate/POS classifier.",
        "Representations from layer ${\\htmlClass{match-highlight}{r}}$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token SRL predictions with respect to each predicted predicate.",
        "Specifically, we feed the representation $s_t^{({\\htmlClass{match-highlight}{r}})}$ from a layer ${\\htmlClass{match-highlight}{r}}$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores ${\\htmlClass{match-highlight}{r}}_t$ for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{r}}_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $l{\\htmlClass{match-highlight}{r}}_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = l{\\htmlClass{match-highlight}{r}}_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "layer",
        "layer"
      ],
      "definitions": [
        "input for a joint predicate/POS classifier"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.589916,
          "top": 0.340855,
          "width": 0.00840336,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804648"
      },
      "sentence": {
        "type": "sentence",
        "id": "804294"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "defining_formula_equations": []
    }
  },
  {
    "id": "804811",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.692437,
          "top": 0.131829,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804654"
      },
      "parent": {
        "type": "symbol",
        "id": "804804"
      },
      "sentence": {
        "type": "sentence",
        "id": "804318"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "804812",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.776471,
          "top": 0.505938,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804796"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "804813",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.877311,
          "top": 0.261283,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804655"
      },
      "parent": {
        "type": "symbol",
        "id": "804821"
      },
      "sentence": {
        "type": "sentence",
        "id": "804320"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "804814",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.598319,
          "top": 0.457245,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804660"
      },
      "parent": {
        "type": "symbol",
        "id": "804820"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "804815",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.581513,
          "top": 0.505938,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804818"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "804816",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.668908,
          "top": 0.505938,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804795"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "804817",
    "type": "symbol",
    "attributes": {
      "tex": "$c_t^{(p)}$",
      "mathml": "<msubsup><mi>c</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>p</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>c</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>p</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>c</mi>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input ${\\htmlClass{match-highlight}{c_t^{(p)}}}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "initial input"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.840336,
          "top": 0.465558,
          "width": 0.0252101,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804662"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804792"
        },
        {
          "type": "symbol",
          "id": "805409"
        },
        {
          "type": "symbol",
          "id": "804805"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804818",
    "type": "symbol",
    "attributes": {
      "tex": "$s_t^{(j)}$",
      "mathml": "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s_t^{(j)}}}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s_t^{(j)}}} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s_t^{(j)}}}$."
      ],
      "defining_formulas": [
        "                   \n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s_t^{(j)}}} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n"
      ],
      "is_definition": true,
      "definitions": [
        "output of layer"
      ],
      "nicknames": [
        "final token representations"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.57479,
          "top": 0.495249,
          "width": 0.0218487,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804793"
        },
        {
          "type": "symbol",
          "id": "804815"
        },
        {
          "type": "symbol",
          "id": "805395"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804663"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "804819",
    "type": "symbol",
    "attributes": {
      "tex": "$s_t^{(j)}$",
      "mathml": "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s_t^{(j)}}}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s_t^{(j)}}} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s_t^{(j)}}}$."
      ],
      "defining_formulas": [
        "                   \n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s_t^{(j)}}} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n"
      ],
      "is_definition": false,
      "definitions": [
        "output of layer"
      ],
      "nicknames": [
        "final token representations"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.801681,
          "top": 0.526128,
          "width": 0.0235294,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804664"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804799"
        },
        {
          "type": "symbol",
          "id": "805410"
        },
        {
          "type": "symbol",
          "id": "804802"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804663"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "804820",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "defining_formulas": [
        "                   \n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s_t^{(j)}}} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n"
      ],
      "tex": "$s_t^{(j)}$",
      "mathml": "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s_t^{(j)}}}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s_t^{(j)}}} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s_t^{(j)}}}$."
      ],
      "definitions": [
        "output of layer"
      ],
      "nicknames": [
        "final token representations"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.591597,
          "top": 0.447743,
          "width": 0.0218487,
          "height": 0.0154394
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804660"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804790"
        },
        {
          "type": "symbol",
          "id": "804814"
        },
        {
          "type": "symbol",
          "id": "805394"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804663"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "804821",
    "type": "symbol",
    "attributes": {
      "tex": "$p_t$",
      "mathml": "<msub><mi>p</mi><mi>t</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>p</mi><mi>t</mi></msub>",
        "<mi>p</mi>",
        "<msup><mi>p</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msup>"
      ],
      "snippets": [
        "For experiments with gold predicates, we concatenate a predicate indicator embedding ${\\htmlClass{match-highlight}{p_t}}$ following previous work \\citep{he2017deep}."
      ],
      "is_definition": false,
      "nicknames": [
        "predicate indicator embedding"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.868908,
          "top": 0.258907,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804655"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804807"
        },
        {
          "type": "symbol",
          "id": "804813"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804320"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804320"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804320"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804822",
    "type": "symbol",
    "attributes": {
      "tex": "$LN(\\cdot)$",
      "mathml": "<mrow><mi>LN</mi><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
      "mathml_near_matches": [
        "<mrow><mi>LN</mi><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>LN</mi><mo stretchy=\"false\">(</mo><msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup><mo>+</mo><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>LN</mi>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and ${\\htmlClass{match-highlight}{LN(\\cdot)}}$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "layer normalization"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.657143,
          "top": 0.451306,
          "width": 0.0453782,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804661"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804791"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804823",
    "type": "symbol",
    "attributes": {
      "tex": "$J$",
      "mathml": "<mi>J</mi>",
      "mathml_near_matches": [
        "<mi>J</mi>"
      ],
      "snippets": [
        "Word embeddings are input to ${\\htmlClass{match-highlight}{J}}$ layers of multi-head self-attention.",
        "We feed this token representation as input to a series of ${\\htmlClass{match-highlight}{J}}$ residual multi-head self-attention layers with feed-forward connections.",
        "First, we project each token representation $s_t^{({\\htmlClass{match-highlight}{J}})}$ to a predicate-specific representation $s_t^{pred}$ and a role-specific representation $s_t^{role}$."
      ],
      "is_definition": false,
      "nicknames": [
        "number of layers of multi-head self-attention",
        "number residual multi-head self-attention layers with feed-forward connections"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.586555,
          "top": 0.401425,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804657"
      },
      "sentence": {
        "type": "sentence",
        "id": "804324"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804292"
        },
        {
          "type": "sentence",
          "id": "804324"
        },
        {
          "type": "sentence",
          "id": "804370"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804292"
        },
        {
          "type": "sentence",
          "id": "804324"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804824",
    "type": "symbol",
    "attributes": {
      "tex": "$J$",
      "mathml": "<mi>J</mi>",
      "mathml_near_matches": [
        "<mi>J</mi>"
      ],
      "snippets": [
        "Word embeddings are input to ${\\htmlClass{match-highlight}{J}}$ layers of multi-head self-attention.",
        "We feed this token representation as input to a series of ${\\htmlClass{match-highlight}{J}}$ residual multi-head self-attention layers with feed-forward connections.",
        "First, we project each token representation $s_t^{({\\htmlClass{match-highlight}{J}})}$ to a predicate-specific representation $s_t^{pred}$ and a role-specific representation $s_t^{role}$."
      ],
      "is_definition": false,
      "nicknames": [
        "number of layers of multi-head self-attention",
        "number residual multi-head self-attention layers with feed-forward connections"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.806723,
          "top": 0.289786,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804646"
      },
      "sentence": {
        "type": "sentence",
        "id": "804292"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804292"
        },
        {
          "type": "sentence",
          "id": "804324"
        },
        {
          "type": "sentence",
          "id": "804370"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804292"
        },
        {
          "type": "sentence",
          "id": "804324"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804825",
    "type": "symbol",
    "attributes": {
      "tex": "$LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)})$",
      "mathml": "<mrow><mi>LN</mi><mo stretchy=\"false\">(</mo><msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup><mo>+</mo><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup><mo stretchy=\"false\">)</mo></mrow>",
      "mathml_near_matches": [
        "<mrow><mi>LN</mi><mo stretchy=\"false\">(</mo><msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup><mo>+</mo><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>LN</mi><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>LN</mi>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = {\\htmlClass{match-highlight}{LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)})}})\n\\end{align}\ngives our final token representations $s_t^{(j)}$."
      ],
      "is_definition": false,
      "diagram_label": "layer normalization",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.626891,
          "top": 0.495249,
          "width": 0.189916,
          "height": 0.0166271
        }
      ],
      "nicknames": [],
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804794"
        },
        {
          "type": "symbol",
          "id": "804795"
        },
        {
          "type": "symbol",
          "id": "804826"
        },
        {
          "type": "symbol",
          "id": "804796"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804826",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T^{(j)}}}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T^{(j)}}}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T^{(j)}}}(\\cdot)$ consists of:"
      ],
      "is_definition": false,
      "tex": "$T^{(j)}$",
      "mathml": "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
      "mathml_near_matches": [
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>T</mi>"
      ],
      "diagram_label": "$j$th self-attention layer",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.727731,
          "top": 0.496437,
          "width": 0.0285714,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804825"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804876"
        },
        {
          "type": "symbol",
          "id": "805391"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        }
      ],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804827",
    "type": "symbol",
    "attributes": {
      "tex": "$A_{parse}$",
      "mathml": "<msub><mi>A</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<mi>A</mi>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A_{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "Let ${\\htmlClass{match-highlight}{A_{parse}}}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A_{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A_{parse}}}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A_{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A_{parse}}}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A_{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n"
      ],
      "is_definition": false,
      "definitions": [
        "parse attention weights at layer $i$",
        "parse parents produced by e.g. a state-of-the-art parser"
      ],
      "nicknames": [
        "attention weights",
        "attention weights",
        "attention weight from token $t$ to a candidate head $q$",
        "attention weights",
        "attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.440336,
          "top": 0.337292,
          "width": 0.0453782,
          "height": 0.0130641
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "children": [
        {
          "type": "symbol",
          "id": "804784"
        },
        {
          "type": "symbol",
          "id": "804785"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804299"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804706"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "equation": {
        "type": "equation",
        "id": "804650"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "804828",
    "type": "symbol",
    "attributes": {
      "tex": "$T^{(j)}$",
      "mathml": "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
      "mathml_near_matches": [
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>T</mi>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T^{(j)}}}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T^{(j)}}}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T^{(j)}}}(\\cdot)$ consists of:"
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.543943,
          "width": 0.0285714,
          "height": 0.0106888
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804665"
      },
      "parent": {
        "type": "symbol",
        "id": "804852"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805600"
        },
        {
          "type": "symbol",
          "id": "805393"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804326"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        }
      ],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804829",
    "type": "symbol",
    "attributes": {
      "tex": "$S$",
      "mathml": "<mi>S</mi>",
      "mathml_near_matches": [
        "<mi>S</mi>",
        "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>i</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>"
      ],
      "snippets": [
        "Specifically, consider the matrix ${\\htmlClass{match-highlight}{S}}^{(j-1)}$ of $T$ token representations at layer $j-1$.",
        "Its input is the matrix of token representations ${\\htmlClass{match-highlight}{S}}^{(i-1)}$.",
        "As with the other attention heads, we project ${\\htmlClass{match-highlight}{S}}^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, $V_{parse}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.77479,
          "top": 0.690024,
          "width": 0.010084,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804668"
      },
      "parent": {
        "type": "symbol",
        "id": "804853"
      },
      "sentence": {
        "type": "sentence",
        "id": "804331"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804343"
        },
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804830",
    "type": "symbol",
    "attributes": {
      "tex": "$K$",
      "mathml": "<mi>K</mi>",
      "mathml_near_matches": [
        "<mi>K</mi>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations ${\\htmlClass{match-highlight}{K}}_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by ${\\htmlClass{match-highlight}{K}}_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{{\\htmlClass{match-highlight}{K}}_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted ${\\htmlClass{match-highlight}{K}}_{parse}$, $Q_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between ${\\htmlClass{match-highlight}{K}}_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} {\\htmlClass{match-highlight}{K}}_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and ${\\htmlClass{match-highlight}{K}}_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, $Q_{parse}$ has dimension 500 and ${\\htmlClass{match-highlight}{K}}_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.803361,
          "top": 0.74228,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804672"
      },
      "parent": {
        "type": "symbol",
        "id": "804864"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804831",
    "type": "symbol",
    "attributes": {
      "tex": "$V$",
      "mathml": "<mi>V</mi>",
      "mathml_near_matches": [
        "<mi>V</mi>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V}}_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V}}_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations ${\\htmlClass{match-highlight}{V}}_{parse}$ as in the other attention heads.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ and $\\mathcal{P}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{\\htmlClass{match-highlight}{{V}}}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.852101,
          "top": 0.74228,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804673"
      },
      "parent": {
        "type": "symbol",
        "id": "804865"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804832",
    "type": "symbol",
    "attributes": {
      "tex": "$Q$",
      "mathml": "<mi>Q</mi>",
      "mathml_near_matches": [
        "<mi>Q</mi>",
        "<msub><mi>Q</mi><mi>parse</mi></msub>",
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}{\\htmlClass{match-highlight}{Q}}_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, ${\\htmlClass{match-highlight}{Q}}_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and ${\\htmlClass{match-highlight}{Q}}_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}({\\htmlClass{match-highlight}{Q}}_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations ${\\htmlClass{match-highlight}{Q}}_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, ${\\htmlClass{match-highlight}{Q}}_{parse}$ has dimension 500 and $K_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.547899,
          "top": 0.760095,
          "width": 0.0117647,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804674"
      },
      "parent": {
        "type": "symbol",
        "id": "804858"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804833",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>d</mi>",
      "mathml_near_matches": [
        "<mi>d</mi>",
        "<msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup>",
        "<msub><mi>d</mi><mi>k</mi></msub>",
        "<msub><mi>d</mi><mi>q</mi></msub>",
        "<msub><mi>d</mi><mi>v</mi></msub>"
      ],
      "tex": "$d$",
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times {\\htmlClass{match-highlight}{d}}_k$, $T\\times {\\htmlClass{match-highlight}{d}}_q$, and $T\\times {\\htmlClass{match-highlight}{d}}_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}({\\htmlClass{match-highlight}{d}}_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.719328,
          "top": 0.760095,
          "width": 0.010084,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804675"
      },
      "parent": {
        "type": "symbol",
        "id": "804856"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804834",
    "type": "symbol",
    "attributes": {
      "tex": "$k$",
      "mathml": "<mi>k</mi>",
      "mathml_near_matches": [
        "<mi>k</mi>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_{\\htmlClass{match-highlight}{k}}$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{\\htmlClass{match-highlight}{{k}}}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.729412,
          "top": 0.766033,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804675"
      },
      "parent": {
        "type": "symbol",
        "id": "804856"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804835",
    "type": "symbol",
    "attributes": {
      "tex": "$d$",
      "mathml": "<mi>d</mi>",
      "mathml_near_matches": [
        "<mi>d</mi>",
        "<msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup>",
        "<msub><mi>d</mi><mi>k</mi></msub>",
        "<msub><mi>d</mi><mi>q</mi></msub>",
        "<msub><mi>d</mi><mi>v</mi></msub>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times {\\htmlClass{match-highlight}{d}}_k$, $T\\times {\\htmlClass{match-highlight}{d}}_q$, and $T\\times {\\htmlClass{match-highlight}{d}}_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}({\\htmlClass{match-highlight}{d}}_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.77479,
          "top": 0.760095,
          "width": 0.010084,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804676"
      },
      "parent": {
        "type": "symbol",
        "id": "804861"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804836",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "tex": "$d$",
      "mathml": "<mi>d</mi>",
      "mathml_near_matches": [
        "<mi>d</mi>",
        "<msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup>",
        "<msub><mi>d</mi><mi>k</mi></msub>",
        "<msub><mi>d</mi><mi>q</mi></msub>",
        "<msub><mi>d</mi><mi>v</mi></msub>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times {\\htmlClass{match-highlight}{d}}_k$, $T\\times {\\htmlClass{match-highlight}{d}}_q$, and $T\\times {\\htmlClass{match-highlight}{d}}_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}({\\htmlClass{match-highlight}{d}}_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.860504,
          "top": 0.760095,
          "width": 0.010084,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804677"
      },
      "parent": {
        "type": "symbol",
        "id": "804857"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804837",
    "type": "symbol",
    "attributes": {
      "tex": "$v$",
      "mathml": "<mi>v</mi>",
      "mathml_near_matches": [
        "<mi>v</mi>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_{\\htmlClass{match-highlight}{v}}$, respectively."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.870588,
          "top": 0.766033,
          "width": 0.00672269,
          "height": 0.00475059
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804677"
      },
      "parent": {
        "type": "symbol",
        "id": "804857"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804838",
    "type": "symbol",
    "attributes": {
      "tex": "$Q$",
      "mathml": "<mi>Q</mi>",
      "mathml_near_matches": [
        "<mi>Q</mi>",
        "<msub><mi>Q</mi><mi>parse</mi></msub>",
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}{\\htmlClass{match-highlight}{Q}}_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, ${\\htmlClass{match-highlight}{Q}}_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and ${\\htmlClass{match-highlight}{Q}}_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}({\\htmlClass{match-highlight}{Q}}_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations ${\\htmlClass{match-highlight}{Q}}_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, ${\\htmlClass{match-highlight}{Q}}_{parse}$ has dimension 500 and $K_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.786555,
          "top": 0.779097,
          "width": 0.0117647,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804678"
      },
      "parent": {
        "type": "symbol",
        "id": "804860"
      },
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804839",
    "type": "symbol",
    "attributes": {
      "tex": "$K$",
      "mathml": "<mi>K</mi>",
      "mathml_near_matches": [
        "<mi>K</mi>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations ${\\htmlClass{match-highlight}{K}}_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by ${\\htmlClass{match-highlight}{K}}_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{{\\htmlClass{match-highlight}{K}}_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted ${\\htmlClass{match-highlight}{K}}_{parse}$, $Q_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between ${\\htmlClass{match-highlight}{K}}_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} {\\htmlClass{match-highlight}{K}}_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and ${\\htmlClass{match-highlight}{K}}_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, $Q_{parse}$ has dimension 500 and ${\\htmlClass{match-highlight}{K}}_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.85042,
          "top": 0.780285,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804679"
      },
      "parent": {
        "type": "symbol",
        "id": "804863"
      },
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804840",
    "type": "symbol",
    "attributes": {
      "tex": "$A$",
      "mathml": "<mi>A</mi>",
      "mathml_near_matches": [
        "<mi>A</mi>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A}}_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A}}_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A}}_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A}}_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Let ${\\htmlClass{match-highlight}{A}}_{parse}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A}}_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A}}_{parse}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A}}_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A}}_{parse}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A}}_{parse}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.852101,
          "top": 0.799287,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804681"
      },
      "parent": {
        "type": "symbol",
        "id": "804851"
      },
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804841",
    "type": "symbol",
    "attributes": {
      "tex": "$\\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)$",
      "mathml": "<mrow><mi>softmax</mi><mo stretchy=\"false\">(</mo><msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup><msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup><mo stretchy=\"false\">)</mo></mrow>",
      "mathml_near_matches": [
        "<mrow><mi>softmax</mi><mo stretchy=\"false\">(</mo><msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup><msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>softmax</mi><mo stretchy=\"false\">(</mo><msub><mi>Q</mi><mi>parse</mi></msub><msub><mi>U</mi><mrow><mi>hea</mi><mi>s</mi></mrow></msub><msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>softmax</mi>"
      ],
      "snippets": [
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = {\\htmlClass{match-highlight}{\\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)}}\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.235294,
          "top": 0.135392,
          "width": 0.193277,
          "height": 0.02019
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804843"
        },
        {
          "type": "symbol",
          "id": "804844"
        },
        {
          "type": "symbol",
          "id": "804859"
        },
        {
          "type": "symbol",
          "id": "804872"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804842",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "tex": "$A$",
      "mathml": "<mi>A</mi>",
      "mathml_near_matches": [
        "<mi>A</mi>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A}}_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A}}_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A}}_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A}}_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Let ${\\htmlClass{match-highlight}{A}}_{parse}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A}}_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A}}_{parse}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A}}_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A}}_{parse}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A}}_{parse}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.179832,
          "top": 0.143705,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804850"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804843",
    "type": "symbol",
    "attributes": {
      "tex": "$\\mathrm{softmax}$",
      "mathml": "<mi>softmax</mi>",
      "mathml_near_matches": [
        "<mi>softmax</mi>",
        "<mrow><mi>softmax</mi><mo stretchy=\"false\">(</mo><msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup><msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>softmax</mi><mo stretchy=\"false\">(</mo><msub><mi>Q</mi><mi>parse</mi></msub><msub><mi>U</mi><mrow><mi>hea</mi><mi>s</mi></mrow></msub><msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = {\\htmlClass{match-highlight}{\\mathrm{softmax}}}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = {\\htmlClass{match-highlight}{\\mathrm{softmax}}}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.235294,
          "top": 0.142518,
          "width": 0.0605042,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804841"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804346"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804844",
    "type": "symbol",
    "attributes": {
      "tex": "$d_{k}$",
      "mathml": "<msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup>",
        "<msub><mi>d</mi><mi>k</mi></msub>",
        "<mi>d</mi>",
        "<msub><mi>d</mi><mi>q</mi></msub>",
        "<msub><mi>d</mi><mi>v</mi></msub>"
      ],
      "snippets": [
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}({\\htmlClass{match-highlight}{d_{k}}}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.304202,
          "top": 0.142518,
          "width": 0.0168067,
          "height": 0.0130641
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804841"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804873"
        },
        {
          "type": "symbol",
          "id": "804874"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804845",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.791597,
          "top": 0.691211,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804668"
      },
      "parent": {
        "type": "symbol",
        "id": "804853"
      },
      "sentence": {
        "type": "sentence",
        "id": "804331"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804846",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$."
      ],
      "is_definition": false,
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.82521,
          "top": 0.739905,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804672"
      },
      "parent": {
        "type": "symbol",
        "id": "804864"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804847",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "tex": "$j$",
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.566387,
          "top": 0.758907,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804674"
      },
      "parent": {
        "type": "symbol",
        "id": "804858"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804848",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.870588,
          "top": 0.77791,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804679"
      },
      "parent": {
        "type": "symbol",
        "id": "804863"
      },
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804849",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.196639,
          "top": 0.14133,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804850"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804850",
    "type": "symbol",
    "attributes": {
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{A_h^{(j)}}} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n"
      ],
      "is_definition": true,
      "tex": "$A_h^{(j)}$",
      "mathml": "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<mi>A</mi>"
      ],
      "snippets": [
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A_h^{(j)}}}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A_h^{(j)}}} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A_h^{(j)}}}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A_h^{(j)}}}$) over the token representations in $V_h^{(j)}$."
      ],
      "nicknames": [
        "attention weights between each pair of tokens in a sentence"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.179832,
          "top": 0.138955,
          "width": 0.0268908,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804842"
        },
        {
          "type": "symbol",
          "id": "804871"
        },
        {
          "type": "symbol",
          "id": "804849"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804682"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804333"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "804851",
    "type": "symbol",
    "attributes": {
      "tex": "$A_h^{(j)}$",
      "snippets": [
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A_h^{(j)}}}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A_h^{(j)}}} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A_h^{(j)}}}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A_h^{(j)}}}$) over the token representations in $V_h^{(j)}$."
      ],
      "mathml": "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<mi>A</mi>"
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{A_h^{(j)}}} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n"
      ],
      "is_definition": false,
      "nicknames": [
        "attention weights between each pair of tokens in a sentence"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.852101,
          "top": 0.795724,
          "width": 0.0285714,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804681"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804840"
        },
        {
          "type": "symbol",
          "id": "805448"
        },
        {
          "type": "symbol",
          "id": "805398"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804682"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804333"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "804852",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "Each ${\\htmlClass{match-highlight}{T^{(j)}(\\cdot)}}$ consists of:",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T^{(j)}(\\cdot)}}$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$."
      ],
      "is_definition": false,
      "tex": "$T^{(j)}(\\cdot)$",
      "mathml": "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
      "mathml_near_matches": [
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mi>T</mi>"
      ],
      "definitions": [
        "$j$th attention layer",
        "layer that consists of: (a) multi-head self-attention and (b) a feed-forward projection"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.543943,
          "width": 0.0487395,
          "height": 0.0142518
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804665"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804828"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804326"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804853",
    "type": "symbol",
    "attributes": {
      "tex": "$S^{(j-1)}$",
      "mathml": "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>",
      "mathml_near_matches": [
        "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mi>S</mi>",
        "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>i</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>"
      ],
      "snippets": [
        "Specifically, consider the matrix ${\\htmlClass{match-highlight}{S^{(j-1)}}}$ of $T$ token representations at layer $j-1$."
      ],
      "is_definition": false,
      "definitions": [
        "matrix of $T$ token representations at layer $j - 1$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.77479,
          "top": 0.688836,
          "width": 0.0436975,
          "height": 0.0106888
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804668"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804829"
        },
        {
          "type": "symbol",
          "id": "804845"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804331"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804854",
    "type": "symbol",
    "attributes": {
      "tex": "$H$",
      "mathml": "<mi>H</mi>",
      "mathml_near_matches": [
        "<mi>H</mi>"
      ],
      "snippets": [
        "The multi-head self attention consists of ${\\htmlClass{match-highlight}{H}}$ attention heads, each of which learns a distinct attention function to attend to all of the tokens in the sequence.",
        "This self-attention is performed for each token for each head, and the results of the ${\\htmlClass{match-highlight}{H}}$ self-attentions are concatenated to form the final self-attended representation for each token."
      ],
      "is_definition": false,
      "nicknames": [
        "number of attention heads",
        "number of self-attentions"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.843697,
          "top": 0.579572,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804666"
      },
      "sentence": {
        "type": "sentence",
        "id": "804329"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804329"
        },
        {
          "type": "sentence",
          "id": "804330"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804329"
        },
        {
          "type": "sentence",
          "id": "804330"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804855",
    "type": "symbol",
    "attributes": {
      "tex": "$H$",
      "mathml": "<mi>H</mi>",
      "mathml_near_matches": [
        "<mi>H</mi>"
      ],
      "snippets": [
        "The multi-head self attention consists of ${\\htmlClass{match-highlight}{H}}$ attention heads, each of which learns a distinct attention function to attend to all of the tokens in the sequence.",
        "This self-attention is performed for each token for each head, and the results of the ${\\htmlClass{match-highlight}{H}}$ self-attentions are concatenated to form the final self-attended representation for each token."
      ],
      "is_definition": false,
      "nicknames": [
        "number of attention heads",
        "number of self-attentions"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.868908,
          "top": 0.643705,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804667"
      },
      "sentence": {
        "type": "sentence",
        "id": "804330"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804329"
        },
        {
          "type": "sentence",
          "id": "804330"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804329"
        },
        {
          "type": "sentence",
          "id": "804330"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804856",
    "type": "symbol",
    "attributes": {
      "tex": "$d_k$",
      "mathml": "<msub><mi>d</mi><mi>k</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>d</mi><mi>k</mi></msub>",
        "<msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup>",
        "<mi>d</mi>",
        "<msub><mi>d</mi><mi>q</mi></msub>",
        "<msub><mi>d</mi><mi>v</mi></msub>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times {\\htmlClass{match-highlight}{d_k}}$, $T\\times d_q$, and $T\\times d_v$, respectively."
      ],
      "is_definition": false,
      "nicknames": [
        "dimension"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.719328,
          "top": 0.760095,
          "width": 0.0168067,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804675"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804833"
        },
        {
          "type": "symbol",
          "id": "804834"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804857",
    "type": "symbol",
    "attributes": {
      "tex": "$d_v$",
      "mathml": "<msub><mi>d</mi><mi>v</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>d</mi><mi>v</mi></msub>",
        "<msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup>",
        "<msub><mi>d</mi><mi>k</mi></msub>",
        "<mi>d</mi>",
        "<msub><mi>d</mi><mi>q</mi></msub>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times {\\htmlClass{match-highlight}{d_v}}$, respectively."
      ],
      "is_definition": false,
      "nicknames": [
        "dimension"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.860504,
          "top": 0.760095,
          "width": 0.0168067,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804677"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804836"
        },
        {
          "type": "symbol",
          "id": "804837"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804858",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and ${\\htmlClass{match-highlight}{Q_h^{(j)}}}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply ${\\htmlClass{match-highlight}{Q_h^{(j)}}}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}{\\htmlClass{match-highlight}{Q_h^{(j)}}}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "mathml_near_matches": [
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>Q</mi>",
        "<msub><mi>Q</mi><mi>parse</mi></msub>"
      ],
      "tex": "$Q_h^{(j)}$",
      "mathml": "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "is_definition": false,
      "nicknames": [
        "query representation of dimensions $T\\times d_v$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.547899,
          "top": 0.756532,
          "width": 0.0285714,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804674"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804832"
        },
        {
          "type": "symbol",
          "id": "804870"
        },
        {
          "type": "symbol",
          "id": "804847"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804859",
    "type": "symbol",
    "attributes": {
      "tex": "$Q_h^{(j)}$",
      "mathml": "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>Q</mi>",
        "<msub><mi>Q</mi><mi>parse</mi></msub>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and ${\\htmlClass{match-highlight}{Q_h^{(j)}}}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply ${\\htmlClass{match-highlight}{Q_h^{(j)}}}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}{\\htmlClass{match-highlight}{Q_h^{(j)}}}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "query representation of dimensions $T\\times d_v$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.346218,
          "top": 0.138955,
          "width": 0.0285714,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804841"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804875"
        },
        {
          "type": "symbol",
          "id": "805449"
        },
        {
          "type": "symbol",
          "id": "805404"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804860",
    "type": "symbol",
    "attributes": {
      "tex": "$Q_h^{(j)}$",
      "mathml": "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>Q</mi>",
        "<msub><mi>Q</mi><mi>parse</mi></msub>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and ${\\htmlClass{match-highlight}{Q_h^{(j)}}}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply ${\\htmlClass{match-highlight}{Q_h^{(j)}}}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}{\\htmlClass{match-highlight}{Q_h^{(j)}}}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "query representation of dimensions $T\\times d_v$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.786555,
          "top": 0.775534,
          "width": 0.0285714,
          "height": 0.0178147
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804678"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804838"
        },
        {
          "type": "symbol",
          "id": "804869"
        },
        {
          "type": "symbol",
          "id": "805397"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804861",
    "type": "symbol",
    "attributes": {
      "tex": "$d_q$",
      "mathml": "<msub><mi>d</mi><mi>q</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>d</mi><mi>q</mi></msub>",
        "<msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup>",
        "<msub><mi>d</mi><mi>k</mi></msub>",
        "<mi>d</mi>",
        "<msub><mi>d</mi><mi>v</mi></msub>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times {\\htmlClass{match-highlight}{d_q}}$, and $T\\times d_v$, respectively."
      ],
      "is_definition": false,
      "nicknames": [
        "dimension"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.77479,
          "top": 0.760095,
          "width": 0.0168067,
          "height": 0.0130641
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804676"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804835"
        },
        {
          "type": "symbol",
          "id": "804862"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804862",
    "type": "symbol",
    "attributes": {
      "tex": "$q$",
      "mathml": "<mi>q</mi>",
      "mathml_near_matches": [
        "<mi>q</mi>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_{\\htmlClass{match-highlight}{q}}$, and $T\\times d_v$, respectively.",
        "Denoting the attention weight from token $t$ to a candidate head ${\\htmlClass{match-highlight}{q}}$ as $A_{parse}[t,{\\htmlClass{match-highlight}{q}}]$, we model the probability of token $t$ having parent ${\\htmlClass{match-highlight}{q}}$ as:\n\\begin{align}\nP({\\htmlClass{match-highlight}{q}}=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, {\\htmlClass{match-highlight}{q}}]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$."
      ],
      "is_definition": false,
      "nicknames": [
        "candidate head",
        "parent"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.784874,
          "top": 0.767221,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804676"
      },
      "parent": {
        "type": "symbol",
        "id": "804861"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804863",
    "type": "symbol",
    "attributes": {
      "tex": "$K_h^{(j)}$",
      "mathml": "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>",
        "<mi>K</mi>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations ${\\htmlClass{match-highlight}{K_h^{(j)}}}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by ${\\htmlClass{match-highlight}{K_h^{(j)}}}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{{\\htmlClass{match-highlight}{K_h^{(j)}}}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "key representation of dimensions $T\\times d_k$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.85042,
          "top": 0.775534,
          "width": 0.0302521,
          "height": 0.0178147
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804679"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804839"
        },
        {
          "type": "symbol",
          "id": "805451"
        },
        {
          "type": "symbol",
          "id": "804848"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804864",
    "type": "symbol",
    "attributes": {
      "tex": "$K_h^{(j)}$",
      "mathml": "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>",
        "<mi>K</mi>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations ${\\htmlClass{match-highlight}{K_h^{(j)}}}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by ${\\htmlClass{match-highlight}{K_h^{(j)}}}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{{\\htmlClass{match-highlight}{K_h^{(j)}}}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "key representation of dimensions $T\\times d_k$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.803361,
          "top": 0.73753,
          "width": 0.0302521,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804672"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804830"
        },
        {
          "type": "symbol",
          "id": "804868"
        },
        {
          "type": "symbol",
          "id": "804846"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804865",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<mi>V</mi>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi mathvariant=\"script\">V</mi>"
      ],
      "tex": "$V_h^{(j)}$",
      "mathml": "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V_h^{(j)}}}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V_h^{(j)}}}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V_h^{(j)}}}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V_h^{(j)}}}$."
      ],
      "is_definition": false,
      "nicknames": [
        "value representation of dimensions $T\\times d_q$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.852101,
          "top": 0.73753,
          "width": 0.0285714,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804673"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804831"
        },
        {
          "type": "symbol",
          "id": "804867"
        },
        {
          "type": "symbol",
          "id": "805396"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804866",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.596639,
          "top": 0.723278,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804671"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804867",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.860504,
          "top": 0.748219,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804673"
      },
      "parent": {
        "type": "symbol",
        "id": "804865"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804868",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.816807,
          "top": 0.748219,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804672"
      },
      "parent": {
        "type": "symbol",
        "id": "804864"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804869",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.8,
          "top": 0.787411,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804678"
      },
      "parent": {
        "type": "symbol",
        "id": "804860"
      },
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804870",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.561345,
          "top": 0.767221,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804674"
      },
      "parent": {
        "type": "symbol",
        "id": "804858"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804871",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.191597,
          "top": 0.149644,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804850"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804872",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "mathml_near_matches": [
        "<mi>K</mi>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>"
      ],
      "tex": "$K_h^{(j)}}^T$",
      "mathml": "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>",
      "snippets": [
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{{\\htmlClass{match-highlight}{K_h^{(j)}}^T}})\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.379832,
          "top": 0.135392,
          "width": 0.0436975,
          "height": 0.02019
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804841"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804910"
        },
        {
          "type": "symbol",
          "id": "805596"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804873",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>d</mi>",
      "tex": "$d$",
      "mathml_near_matches": [
        "<mi>d</mi>",
        "<msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup>",
        "<msub><mi>d</mi><mi>k</mi></msub>",
        "<msub><mi>d</mi><mi>q</mi></msub>",
        "<msub><mi>d</mi><mi>v</mi></msub>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times {\\htmlClass{match-highlight}{d}}_k$, $T\\times {\\htmlClass{match-highlight}{d}}_q$, and $T\\times {\\htmlClass{match-highlight}{d}}_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}({\\htmlClass{match-highlight}{d}}_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.304202,
          "top": 0.142518,
          "width": 0.010084,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804844"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804874",
    "type": "symbol",
    "attributes": {
      "tex": "${k}$",
      "mathml": "<mi>k</mi>",
      "mathml_near_matches": [
        "<mi>k</mi>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_{\\htmlClass{match-highlight}{k}}$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{\\htmlClass{match-highlight}{{k}}}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.314286,
          "top": 0.149644,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804844"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804875",
    "type": "symbol",
    "attributes": {
      "tex": "$Q$",
      "mathml": "<mi>Q</mi>",
      "mathml_near_matches": [
        "<mi>Q</mi>",
        "<msub><mi>Q</mi><mi>parse</mi></msub>",
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}{\\htmlClass{match-highlight}{Q}}_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, ${\\htmlClass{match-highlight}{Q}}_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and ${\\htmlClass{match-highlight}{Q}}_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}({\\htmlClass{match-highlight}{Q}}_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations ${\\htmlClass{match-highlight}{Q}}_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, ${\\htmlClass{match-highlight}{Q}}_{parse}$ has dimension 500 and $K_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.346218,
          "top": 0.142518,
          "width": 0.0117647,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804859"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804876",
    "type": "symbol",
    "attributes": {
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "mathml_near_matches": [
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "is_definition": false,
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.727731,
          "top": 0.5,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804826"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804877",
    "type": "symbol",
    "attributes": {
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "mathml_near_matches": [
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "is_definition": false,
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.845378,
          "top": 0.691211,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804669"
      },
      "sentence": {
        "type": "sentence",
        "id": "804331"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804878",
    "type": "symbol",
    "attributes": {
      "tex": "$K$",
      "mathml": "<mi>K</mi>",
      "mathml_near_matches": [
        "<mi>K</mi>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations ${\\htmlClass{match-highlight}{K}}_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by ${\\htmlClass{match-highlight}{K}}_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{{\\htmlClass{match-highlight}{K}}_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted ${\\htmlClass{match-highlight}{K}}_{parse}$, $Q_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between ${\\htmlClass{match-highlight}{K}}_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} {\\htmlClass{match-highlight}{K}}_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and ${\\htmlClass{match-highlight}{K}}_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, $Q_{parse}$ has dimension 500 and ${\\htmlClass{match-highlight}{K}}_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.379832,
          "top": 0.143705,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804910"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804879",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>V</mi>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>"
      ],
      "snippets": [
        "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V}}_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V}}_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations ${\\htmlClass{match-highlight}{V}}_{parse}$ as in the other attention heads.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ and $\\mathcal{P}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{\\htmlClass{match-highlight}{{V}}}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$."
      ],
      "tex": "$V$",
      "mathml": "<mi>V</mi>",
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.191211,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804683"
      },
      "parent": {
        "type": "symbol",
        "id": "804912"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804880",
    "type": "symbol",
    "attributes": {
      "tex": "$M$",
      "mathml": "<mi>M</mi>",
      "mathml_near_matches": [
        "<mi>M</mi>",
        "<msubsup><mi>M</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations ${\\htmlClass{match-highlight}{M}}_h^{(j)}$:\n\\begin{align}\n{\\htmlClass{match-highlight}{M}}_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of ${\\htmlClass{match-highlight}{M}}_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.268908,
          "top": 0.210214,
          "width": 0.0151261,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804684"
      },
      "parent": {
        "type": "symbol",
        "id": "804907"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804881",
    "type": "symbol",
    "attributes": {
      "tex": "$M$",
      "mathml": "<mi>M</mi>",
      "mathml_near_matches": [
        "<mi>M</mi>",
        "<msubsup><mi>M</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations ${\\htmlClass{match-highlight}{M}}_h^{(j)}$:\n\\begin{align}\n{\\htmlClass{match-highlight}{M}}_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of ${\\htmlClass{match-highlight}{M}}_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.242017,
          "top": 0.243468,
          "width": 0.0151261,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804685"
      },
      "parent": {
        "type": "symbol",
        "id": "804908"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804882",
    "type": "symbol",
    "attributes": {
      "tex": "$A$",
      "mathml": "<mi>A</mi>",
      "mathml_near_matches": [
        "<mi>A</mi>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A}}_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A}}_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A}}_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A}}_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Let ${\\htmlClass{match-highlight}{A}}_{parse}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A}}_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A}}_{parse}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A}}_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A}}_{parse}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A}}_{parse}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.304202,
          "top": 0.243468,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804685"
      },
      "parent": {
        "type": "symbol",
        "id": "804901"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804883",
    "type": "symbol",
    "attributes": {
      "tex": "$V$",
      "mathml": "<mi>V</mi>",
      "mathml_near_matches": [
        "<mi>V</mi>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V}}_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V}}_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations ${\\htmlClass{match-highlight}{V}}_{parse}$ as in the other attention heads.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ and $\\mathcal{P}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{\\htmlClass{match-highlight}{{V}}}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.336134,
          "top": 0.243468,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804685"
      },
      "parent": {
        "type": "symbol",
        "id": "804911"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804884",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>M</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>M</mi>"
      ],
      "mathml": "<mi>M</mi>",
      "tex": "$M$",
      "snippets": [
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations ${\\htmlClass{match-highlight}{M}}_h^{(j)}$:\n\\begin{align}\n{\\htmlClass{match-highlight}{M}}_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of ${\\htmlClass{match-highlight}{M}}_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.189916,
          "top": 0.27791,
          "width": 0.0151261,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804687"
      },
      "parent": {
        "type": "symbol",
        "id": "804909"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804885",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A}}_{parse}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A}}_{parse}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A}}_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A}}_{parse}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A}}_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "Attention weights ${\\htmlClass{match-highlight}{A}}_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A}}_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A}}_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A}}_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Let ${\\htmlClass{match-highlight}{A}}_{parse}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads."
      ],
      "is_definition": false,
      "tex": "$A$",
      "mathml": "<mi>A</mi>",
      "mathml_near_matches": [
        "<mi>A</mi>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.386555,
          "top": 0.311164,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804691"
      },
      "parent": {
        "type": "symbol",
        "id": "804900"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804886",
    "type": "symbol",
    "attributes": {
      "tex": "$V$",
      "mathml": "<mi>V</mi>",
      "mathml_near_matches": [
        "<mi>V</mi>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V}}_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V}}_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations ${\\htmlClass{match-highlight}{V}}_{parse}$ as in the other attention heads.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ and $\\mathcal{P}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{\\htmlClass{match-highlight}{{V}}}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.302521,
          "top": 0.331354,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804692"
      },
      "parent": {
        "type": "symbol",
        "id": "804913"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804887",
    "type": "symbol",
    "attributes": {
      "tex": "$A$",
      "mathml": "<mi>A</mi>",
      "mathml_near_matches": [
        "<mi>A</mi>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A}}_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A}}_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A}}_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A}}_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Let ${\\htmlClass{match-highlight}{A}}_{parse}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A}}_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A}}_{parse}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A}}_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A}}_{parse}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A}}_{parse}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.151261,
          "top": 0.653207,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804694"
      },
      "parent": {
        "type": "symbol",
        "id": "804920"
      },
      "sentence": {
        "type": "sentence",
        "id": "804342"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804888",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.164706,
          "top": 0.660333,
          "width": 0.0319328,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804694"
      },
      "parent": {
        "type": "symbol",
        "id": "804920"
      },
      "sentence": {
        "type": "sentence",
        "id": "804342"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804889",
    "type": "symbol",
    "attributes": {
      "tex": "$S$",
      "mathml": "<mi>S</mi>",
      "mathml_near_matches": [
        "<mi>S</mi>",
        "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>i</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>"
      ],
      "snippets": [
        "Specifically, consider the matrix ${\\htmlClass{match-highlight}{S}}^{(j-1)}$ of $T$ token representations at layer $j-1$.",
        "Its input is the matrix of token representations ${\\htmlClass{match-highlight}{S}}^{(i-1)}$.",
        "As with the other attention heads, we project ${\\htmlClass{match-highlight}{S}}^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, $V_{parse}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.685273,
          "width": 0.010084,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804696"
      },
      "parent": {
        "type": "symbol",
        "id": "804905"
      },
      "sentence": {
        "type": "sentence",
        "id": "804343"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804343"
        },
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804890",
    "type": "symbol",
    "attributes": {
      "tex": "$S$",
      "mathml": "<mi>S</mi>",
      "mathml_near_matches": [
        "<mi>S</mi>",
        "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>i</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>"
      ],
      "snippets": [
        "Specifically, consider the matrix ${\\htmlClass{match-highlight}{S}}^{(j-1)}$ of $T$ token representations at layer $j-1$.",
        "Its input is the matrix of token representations ${\\htmlClass{match-highlight}{S}}^{(i-1)}$.",
        "As with the other attention heads, we project ${\\htmlClass{match-highlight}{S}}^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, $V_{parse}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.178151,
          "top": 0.700713,
          "width": 0.010084,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804697"
      },
      "parent": {
        "type": "symbol",
        "id": "804906"
      },
      "sentence": {
        "type": "sentence",
        "id": "804344"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804343"
        },
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804891",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.356303,
          "top": 0.241093,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804685"
      },
      "parent": {
        "type": "symbol",
        "id": "804911"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804892",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.213445,
          "top": 0.275534,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804687"
      },
      "parent": {
        "type": "symbol",
        "id": "804909"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804893",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.292437,
          "top": 0.209026,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804684"
      },
      "parent": {
        "type": "symbol",
        "id": "804907"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804894",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.265546,
          "top": 0.241093,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804685"
      },
      "parent": {
        "type": "symbol",
        "id": "804908"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804895",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.403361,
          "top": 0.309976,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804691"
      },
      "parent": {
        "type": "symbol",
        "id": "804900"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804896",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.334454,
          "top": 0.45962,
          "width": 0.00672269,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804693"
      },
      "sentence": {
        "type": "sentence",
        "id": "804336"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804897",
    "type": "symbol",
    "attributes": {
      "tex": "$i$",
      "mathml": "<mi>i</mi>",
      "mathml_near_matches": [
        "<mi>i</mi>"
      ],
      "snippets": [
        "Let $A_{parse}$ be the parse attention weights, at layer ${\\htmlClass{match-highlight}{i}}$.",
        "Its input is the matrix of token representations $S^{({\\htmlClass{match-highlight}{i}}-1)}$.",
        "As with the other attention heads, we project $S^{({\\htmlClass{match-highlight}{i}}-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, $V_{parse}$."
      ],
      "is_definition": false,
      "nicknames": [
        "layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.121008,
          "top": 0.669834,
          "width": 0.00504202,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804695"
      },
      "sentence": {
        "type": "sentence",
        "id": "804342"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804343"
        },
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804898",
    "type": "symbol",
    "attributes": {
      "tex": "$i$",
      "mathml": "<mi>i</mi>",
      "mathml_near_matches": [
        "<mi>i</mi>"
      ],
      "snippets": [
        "Let $A_{parse}$ be the parse attention weights, at layer ${\\htmlClass{match-highlight}{i}}$.",
        "Its input is the matrix of token representations $S^{({\\htmlClass{match-highlight}{i}}-1)}$.",
        "As with the other attention heads, we project $S^{({\\htmlClass{match-highlight}{i}}-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, $V_{parse}$."
      ],
      "is_definition": false,
      "nicknames": [
        "layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.139496,
          "top": 0.686461,
          "width": 0.00336134,
          "height": 0.00356295
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804696"
      },
      "parent": {
        "type": "symbol",
        "id": "804905"
      },
      "sentence": {
        "type": "sentence",
        "id": "804343"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804343"
        },
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804899",
    "type": "symbol",
    "attributes": {
      "tex": "$i$",
      "mathml": "<mi>i</mi>",
      "mathml_near_matches": [
        "<mi>i</mi>"
      ],
      "snippets": [
        "Let $A_{parse}$ be the parse attention weights, at layer ${\\htmlClass{match-highlight}{i}}$.",
        "Its input is the matrix of token representations $S^{({\\htmlClass{match-highlight}{i}}-1)}$.",
        "As with the other attention heads, we project $S^{({\\htmlClass{match-highlight}{i}}-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, $V_{parse}$."
      ],
      "is_definition": false,
      "nicknames": [
        "layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.194958,
          "top": 0.7019,
          "width": 0.00336134,
          "height": 0.00356295
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804697"
      },
      "parent": {
        "type": "symbol",
        "id": "804906"
      },
      "sentence": {
        "type": "sentence",
        "id": "804344"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804343"
        },
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804900",
    "type": "symbol",
    "attributes": {
      "tex": "$A_h^{(j)}$",
      "mathml": "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<mi>A</mi>"
      ],
      "snippets": [
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A_h^{(j)}}}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A_h^{(j)}}} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A_h^{(j)}}}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A_h^{(j)}}}$) over the token representations in $V_h^{(j)}$."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{A_h^{(j)}}} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n"
      ],
      "is_definition": false,
      "nicknames": [
        "attention weights between each pair of tokens in a sentence"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.386555,
          "top": 0.307601,
          "width": 0.0268908,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804691"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804885"
        },
        {
          "type": "symbol",
          "id": "805452"
        },
        {
          "type": "symbol",
          "id": "804895"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804682"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804333"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "804901",
    "type": "symbol",
    "attributes": {
      "tex": "$A_h^{(j)}$",
      "mathml": "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<mi>A</mi>"
      ],
      "snippets": [
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A_h^{(j)}}}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A_h^{(j)}}} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A_h^{(j)}}}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A_h^{(j)}}}$) over the token representations in $V_h^{(j)}$."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{A_h^{(j)}}} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n"
      ],
      "is_definition": false,
      "nicknames": [
        "attention weights between each pair of tokens in a sentence"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.304202,
          "top": 0.238717,
          "width": 0.0285714,
          "height": 0.0178147
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804685"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804882"
        },
        {
          "type": "symbol",
          "id": "805450"
        },
        {
          "type": "symbol",
          "id": "805401"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804682"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804333"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "804902",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.168067,
          "top": 0.293349,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804688"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "804903",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.157983,
          "top": 0.27791,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804686"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "804904",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.198319,
          "top": 0.311164,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804690"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "804905",
    "type": "symbol",
    "attributes": {
      "tex": "$S^{(i-1)}$",
      "mathml": "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>i</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>",
      "mathml_near_matches": [
        "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>i</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mi>S</mi>",
        "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>"
      ],
      "snippets": [
        "Its input is the matrix of token representations ${\\htmlClass{match-highlight}{S^{(i-1)}}}$.",
        "As with the other attention heads, we project ${\\htmlClass{match-highlight}{S^{(i-1)}}}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, $V_{parse}$."
      ],
      "is_definition": false,
      "nicknames": [
        "matrix of token representations "
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.684085,
          "width": 0.0420168,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804696"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804889"
        },
        {
          "type": "symbol",
          "id": "804898"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804343"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804343"
        },
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804343"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804906",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "Its input is the matrix of token representations ${\\htmlClass{match-highlight}{S^{(i-1)}}}$.",
        "As with the other attention heads, we project ${\\htmlClass{match-highlight}{S^{(i-1)}}}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, $V_{parse}$."
      ],
      "is_definition": false,
      "mathml_near_matches": [
        "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>i</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mi>S</mi>"
      ],
      "tex": "$S^{(i-1)}$",
      "mathml": "<msup><mi>S</mi><mrow><mo stretchy=\"false\">(</mo><mi>i</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msup>",
      "nicknames": [
        "matrix of token representations "
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.178151,
          "top": 0.699525,
          "width": 0.0436975,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804697"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804890"
        },
        {
          "type": "symbol",
          "id": "804899"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804344"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804343"
        },
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804343"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804907",
    "type": "symbol",
    "attributes": {
      "mathml": "<msubsup><mi>M</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>M</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>M</mi>"
      ],
      "tex": "$M_h^{(j)}$",
      "snippets": [
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations ${\\htmlClass{match-highlight}{M_h^{(j)}}}$:\n\\begin{align}\n{\\htmlClass{match-highlight}{M_h^{(j)}}} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of ${\\htmlClass{match-highlight}{M_h^{(j)}}}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{M_h^{(j)}}} = A_h^{(j)}V_h^{(j)}\n"
      ],
      "is_definition": false,
      "nicknames": [
        "self-attended token representations"
      ],
      "definitions": [
        "self-attended representation for token $t$ at layer $j$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.268908,
          "top": 0.206651,
          "width": 0.0336134,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804684"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804880"
        },
        {
          "type": "symbol",
          "id": "805453"
        },
        {
          "type": "symbol",
          "id": "804893"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804685"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "804908",
    "type": "symbol",
    "attributes": {
      "is_definition": true,
      "tex": "$M_h^{(j)}$",
      "mathml": "<msubsup><mi>M</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>M</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>M</mi>"
      ],
      "snippets": [
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations ${\\htmlClass{match-highlight}{M_h^{(j)}}}$:\n\\begin{align}\n{\\htmlClass{match-highlight}{M_h^{(j)}}} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of ${\\htmlClass{match-highlight}{M_h^{(j)}}}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{M_h^{(j)}}} = A_h^{(j)}V_h^{(j)}\n"
      ],
      "nicknames": [
        "self-attended token representations"
      ],
      "definitions": [
        "self-attended representation for token $t$ at layer $j$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.242017,
          "top": 0.238717,
          "width": 0.0336134,
          "height": 0.0178147
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804685"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804881"
        },
        {
          "type": "symbol",
          "id": "804918"
        },
        {
          "type": "symbol",
          "id": "804894"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804685"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "804909",
    "type": "symbol",
    "attributes": {
      "tex": "$M_h^{(j)}$",
      "mathml": "<msubsup><mi>M</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>M</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>M</mi>"
      ],
      "snippets": [
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations ${\\htmlClass{match-highlight}{M_h^{(j)}}}$:\n\\begin{align}\n{\\htmlClass{match-highlight}{M_h^{(j)}}} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of ${\\htmlClass{match-highlight}{M_h^{(j)}}}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{M_h^{(j)}}} = A_h^{(j)}V_h^{(j)}\n"
      ],
      "is_definition": false,
      "nicknames": [
        "self-attended token representations"
      ],
      "definitions": [
        "self-attended representation for token $t$ at layer $j$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.189916,
          "top": 0.273159,
          "width": 0.0336134,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804687"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804884"
        },
        {
          "type": "symbol",
          "id": "804917"
        },
        {
          "type": "symbol",
          "id": "804892"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804685"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "804910",
    "type": "symbol",
    "attributes": {
      "tex": "$K_h^{(j)}$",
      "mathml": "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>",
        "<mi>K</mi>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations ${\\htmlClass{match-highlight}{K_h^{(j)}}}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by ${\\htmlClass{match-highlight}{K_h^{(j)}}}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{{\\htmlClass{match-highlight}{K_h^{(j)}}}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "key representation of dimensions $T\\times d_k$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.379832,
          "top": 0.138955,
          "width": 0.0302521,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804872"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804878"
        },
        {
          "type": "symbol",
          "id": "804914"
        },
        {
          "type": "symbol",
          "id": "805399"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804911",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>V</mi>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>"
      ],
      "tex": "$V_h^{(j)}$",
      "mathml": "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V_h^{(j)}}}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V_h^{(j)}}}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V_h^{(j)}}}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V_h^{(j)}}}$."
      ],
      "is_definition": false,
      "nicknames": [
        "value representation of dimensions $T\\times d_q$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.336134,
          "top": 0.238717,
          "width": 0.0285714,
          "height": 0.0178147
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804685"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804883"
        },
        {
          "type": "symbol",
          "id": "804915"
        },
        {
          "type": "symbol",
          "id": "804891"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804912",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V_h^{(j)}}}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V_h^{(j)}}}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V_h^{(j)}}}$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V_h^{(j)}}}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively."
      ],
      "is_definition": false,
      "tex": "$V_h^{(j)}$",
      "mathml": "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<mi>V</mi>"
      ],
      "nicknames": [
        "value representation of dimensions $T\\times d_q$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.186461,
          "width": 0.0285714,
          "height": 0.0178147
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804683"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804879"
        },
        {
          "type": "symbol",
          "id": "804916"
        },
        {
          "type": "symbol",
          "id": "805400"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804913",
    "type": "symbol",
    "attributes": {
      "tex": "$V_h^{(j)}$",
      "mathml": "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<mi>V</mi>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V_h^{(j)}}}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V_h^{(j)}}}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V_h^{(j)}}}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V_h^{(j)}}}$."
      ],
      "is_definition": false,
      "nicknames": [
        "value representation of dimensions $T\\times d_q$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.302521,
          "top": 0.326603,
          "width": 0.0285714,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804692"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804886"
        },
        {
          "type": "symbol",
          "id": "804919"
        },
        {
          "type": "symbol",
          "id": "805403"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804914",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.393277,
          "top": 0.149644,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804910"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804915",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.346218,
          "top": 0.250594,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804685"
      },
      "parent": {
        "type": "symbol",
        "id": "804911"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804916",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.131092,
          "top": 0.198337,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804683"
      },
      "parent": {
        "type": "symbol",
        "id": "804912"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804917",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.205042,
          "top": 0.283848,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804687"
      },
      "parent": {
        "type": "symbol",
        "id": "804909"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804918",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.258824,
          "top": 0.250594,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804685"
      },
      "parent": {
        "type": "symbol",
        "id": "804908"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804919",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.310924,
          "top": 0.337292,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804692"
      },
      "parent": {
        "type": "symbol",
        "id": "804913"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804920",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A_{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A_{parse}}}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A_{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A_{parse}}}$ assigns the highest weight.",
        "Attention weights ${\\htmlClass{match-highlight}{A_{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "Let ${\\htmlClass{match-highlight}{A_{parse}}}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A_{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "tex": "$A_{parse}$",
      "mathml": "<msub><mi>A</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<mi>A</mi>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n"
      ],
      "is_definition": false,
      "definitions": [
        "parse attention weights at layer $i$",
        "parse parents produced by e.g. a state-of-the-art parser"
      ],
      "nicknames": [
        "attention weights",
        "attention weights",
        "attention weight from token $t$ to a candidate head $q$",
        "attention weights",
        "attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.151261,
          "top": 0.653207,
          "width": 0.0453782,
          "height": 0.0130641
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804694"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804887"
        },
        {
          "type": "symbol",
          "id": "804888"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804342"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804706"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "804921",
    "type": "symbol",
    "attributes": {
      "tex": "$K$",
      "mathml": "<mi>K</mi>",
      "mathml_near_matches": [
        "<mi>K</mi>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations ${\\htmlClass{match-highlight}{K}}_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by ${\\htmlClass{match-highlight}{K}}_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{{\\htmlClass{match-highlight}{K}}_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted ${\\htmlClass{match-highlight}{K}}_{parse}$, $Q_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between ${\\htmlClass{match-highlight}{K}}_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} {\\htmlClass{match-highlight}{K}}_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and ${\\htmlClass{match-highlight}{K}}_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, $Q_{parse}$ has dimension 500 and ${\\htmlClass{match-highlight}{K}}_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.245378,
          "top": 0.718527,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804698"
      },
      "parent": {
        "type": "symbol",
        "id": "804984"
      },
      "sentence": {
        "type": "sentence",
        "id": "804344"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804922",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.258824,
          "top": 0.724466,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804698"
      },
      "parent": {
        "type": "symbol",
        "id": "804984"
      },
      "sentence": {
        "type": "sentence",
        "id": "804344"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804923",
    "type": "symbol",
    "attributes": {
      "tex": "$Q$",
      "mathml": "<mi>Q</mi>",
      "mathml_near_matches": [
        "<mi>Q</mi>",
        "<msub><mi>Q</mi><mi>parse</mi></msub>",
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}{\\htmlClass{match-highlight}{Q}}_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, ${\\htmlClass{match-highlight}{Q}}_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and ${\\htmlClass{match-highlight}{Q}}_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}({\\htmlClass{match-highlight}{Q}}_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations ${\\htmlClass{match-highlight}{Q}}_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, ${\\htmlClass{match-highlight}{Q}}_{parse}$ has dimension 500 and $K_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.305882,
          "top": 0.71734,
          "width": 0.0117647,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804699"
      },
      "parent": {
        "type": "symbol",
        "id": "805585"
      },
      "sentence": {
        "type": "sentence",
        "id": "804344"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804924",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.319328,
          "top": 0.724466,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804699"
      },
      "parent": {
        "type": "symbol",
        "id": "805585"
      },
      "sentence": {
        "type": "sentence",
        "id": "804344"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804925",
    "type": "symbol",
    "attributes": {
      "tex": "$V$",
      "mathml": "<mi>V</mi>",
      "mathml_near_matches": [
        "<mi>V</mi>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V}}_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V}}_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations ${\\htmlClass{match-highlight}{V}}_{parse}$ as in the other attention heads.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ and $\\mathcal{P}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{\\htmlClass{match-highlight}{{V}}}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.364706,
          "top": 0.718527,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804700"
      },
      "parent": {
        "type": "symbol",
        "id": "804982"
      },
      "sentence": {
        "type": "sentence",
        "id": "804344"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804926",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.376471,
          "top": 0.724466,
          "width": 0.0319328,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804700"
      },
      "parent": {
        "type": "symbol",
        "id": "804982"
      },
      "sentence": {
        "type": "sentence",
        "id": "804344"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804927",
    "type": "symbol",
    "attributes": {
      "tex": "$parent$",
      "mathml": "<mi>parent</mi>",
      "mathml_near_matches": [
        "<mi>parent</mi>"
      ],
      "snippets": [
        "Here the key and query projections correspond to ${\\htmlClass{match-highlight}{parent}}$ and $dependent$ representations of the tokens, and we allow their dimensions to differ from the rest of the attention heads to more closely follow the implementation of \\citet{dozat2017deep}."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.433613,
          "top": 0.733967,
          "width": 0.0537815,
          "height": 0.0106888
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804701"
      },
      "sentence": {
        "type": "sentence",
        "id": "804345"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804345"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804928",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "tex": "$dependent$",
      "mathml": "<mi>dependent</mi>",
      "mathml_near_matches": [
        "<mi>dependent</mi>"
      ],
      "snippets": [
        "Here the key and query projections correspond to $parent$ and ${\\htmlClass{match-highlight}{dependent}}$ representations of the tokens, and we allow their dimensions to differ from the rest of the attention heads to more closely follow the implementation of \\citet{dozat2017deep}."
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.152941,
          "top": 0.749406,
          "width": 0.0823529,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804702"
      },
      "sentence": {
        "type": "sentence",
        "id": "804345"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804345"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804929",
    "type": "symbol",
    "attributes": {
      "tex": "$K$",
      "mathml": "<mi>K</mi>",
      "mathml_near_matches": [
        "<mi>K</mi>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations ${\\htmlClass{match-highlight}{K}}_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by ${\\htmlClass{match-highlight}{K}}_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{{\\htmlClass{match-highlight}{K}}_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted ${\\htmlClass{match-highlight}{K}}_{parse}$, $Q_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between ${\\htmlClass{match-highlight}{K}}_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} {\\htmlClass{match-highlight}{K}}_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and ${\\htmlClass{match-highlight}{K}}_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, $Q_{parse}$ has dimension 500 and ${\\htmlClass{match-highlight}{K}}_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.230252,
          "top": 0.846793,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804703"
      },
      "parent": {
        "type": "symbol",
        "id": "805588"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804930",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.245378,
          "top": 0.853919,
          "width": 0.0319328,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804703"
      },
      "parent": {
        "type": "symbol",
        "id": "805588"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804931",
    "type": "symbol",
    "attributes": {
      "tex": "$Q$",
      "mathml": "<mi>Q</mi>",
      "mathml_near_matches": [
        "<mi>Q</mi>",
        "<msub><mi>Q</mi><mi>parse</mi></msub>",
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}{\\htmlClass{match-highlight}{Q}}_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, ${\\htmlClass{match-highlight}{Q}}_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and ${\\htmlClass{match-highlight}{Q}}_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}({\\htmlClass{match-highlight}{Q}}_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations ${\\htmlClass{match-highlight}{Q}}_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, ${\\htmlClass{match-highlight}{Q}}_{parse}$ has dimension 500 and $K_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.317647,
          "top": 0.845606,
          "width": 0.0117647,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804704"
      },
      "parent": {
        "type": "symbol",
        "id": "805612"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804932",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.329412,
          "top": 0.853919,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804704"
      },
      "parent": {
        "type": "symbol",
        "id": "805612"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804933",
    "type": "symbol",
    "attributes": {
      "tex": "$hea$",
      "mathml": "<mi>hea</mi>",
      "mathml_near_matches": [
        "<mi>hea</mi>"
      ],
      "snippets": [
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{{\\htmlClass{match-highlight}{hea}}ds}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{{\\htmlClass{match-highlight}{hea}}ds} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.2,
          "top": 0.866983,
          "width": 0.0201681,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804705"
      },
      "parent": {
        "type": "symbol",
        "id": "804962"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804346"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804934",
    "type": "symbol",
    "attributes": {
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "mathml_near_matches": [
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.228571,
          "top": 0.869359,
          "width": 0.00504202,
          "height": 0.00356295
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804705"
      },
      "parent": {
        "type": "symbol",
        "id": "804962"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804935",
    "type": "symbol",
    "attributes": {
      "tex": "$\\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)$",
      "mathml": "<mrow><mi>softmax</mi><mo stretchy=\"false\">(</mo><msub><mi>Q</mi><mi>parse</mi></msub><msub><mi>U</mi><mrow><mi>hea</mi><mi>s</mi></mrow></msub><msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup><mo stretchy=\"false\">)</mo></mrow>",
      "mathml_near_matches": [
        "<mrow><mi>softmax</mi><mo stretchy=\"false\">(</mo><msub><mi>Q</mi><mi>parse</mi></msub><msub><mi>U</mi><mrow><mi>hea</mi><mi>s</mi></mrow></msub><msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>softmax</mi><mo stretchy=\"false\">(</mo><msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup><msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>softmax</mi>"
      ],
      "snippets": [
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = {\\htmlClass{match-highlight}{\\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)}}\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.216807,
          "top": 0.889549,
          "width": 0.22521,
          "height": 0.0178147
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804938"
        },
        {
          "type": "symbol",
          "id": "805604"
        },
        {
          "type": "symbol",
          "id": "804963"
        },
        {
          "type": "symbol",
          "id": "804939"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804346"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804936",
    "type": "symbol",
    "attributes": {
      "tex": "$A$",
      "mathml": "<mi>A</mi>",
      "mathml_near_matches": [
        "<mi>A</mi>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A}}_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A}}_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A}}_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A}}_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Let ${\\htmlClass{match-highlight}{A}}_{parse}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A}}_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A}}_{parse}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A}}_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A}}_{parse}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A}}_{parse}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.144538,
          "top": 0.893112,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "805418"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804937",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.156303,
          "top": 0.89905,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "805418"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804938",
    "type": "symbol",
    "attributes": {
      "tex": "$\\mathrm{softmax}$",
      "mathml": "<mi>softmax</mi>",
      "mathml_near_matches": [
        "<mi>softmax</mi>",
        "<mrow><mi>softmax</mi><mo stretchy=\"false\">(</mo><msubsup><mi>d</mi><mi>k</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msubsup><msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>softmax</mi><mo stretchy=\"false\">(</mo><msub><mi>Q</mi><mi>parse</mi></msub><msub><mi>U</mi><mrow><mi>hea</mi><mi>s</mi></mrow></msub><msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = {\\htmlClass{match-highlight}{\\mathrm{softmax}}}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = {\\htmlClass{match-highlight}{\\mathrm{softmax}}}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.216807,
          "top": 0.891924,
          "width": 0.0621849,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "804935"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804346"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804939",
    "type": "symbol",
    "attributes": {
      "tex": "$K_{parse}^T$",
      "mathml": "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>",
        "<mi>K</mi>"
      ],
      "snippets": [
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} {\\htmlClass{match-highlight}{K_{parse}^T}})\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.388235,
          "top": 0.889549,
          "width": 0.0470588,
          "height": 0.0178147
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "804935"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804944"
        },
        {
          "type": "symbol",
          "id": "804945"
        },
        {
          "type": "symbol",
          "id": "805599"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804346"
        }
      ],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804940",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "We can then multiply ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}{\\htmlClass{match-highlight}{Q}}_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, ${\\htmlClass{match-highlight}{Q}}_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and ${\\htmlClass{match-highlight}{Q}}_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}({\\htmlClass{match-highlight}{Q}}_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations ${\\htmlClass{match-highlight}{Q}}_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, ${\\htmlClass{match-highlight}{Q}}_{parse}$ has dimension 500 and $K_{parse}$ has dimension 100."
      ],
      "tex": "$Q$",
      "mathml": "<mi>Q</mi>",
      "mathml_near_matches": [
        "<mi>Q</mi>",
        "<msub><mi>Q</mi><mi>parse</mi></msub>",
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.289076,
          "top": 0.891924,
          "width": 0.0117647,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "805604"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804941",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.30084,
          "top": 0.89905,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "805604"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804942",
    "type": "symbol",
    "attributes": {
      "tex": "$hea$",
      "mathml": "<mi>hea</mi>",
      "mathml_near_matches": [
        "<mi>hea</mi>"
      ],
      "snippets": [
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{{\\htmlClass{match-highlight}{hea}}ds}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{{\\htmlClass{match-highlight}{hea}}ds} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.34958,
          "top": 0.897862,
          "width": 0.0218487,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "804963"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804346"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804943",
    "type": "symbol",
    "attributes": {
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "mathml_near_matches": [
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.379832,
          "top": 0.900238,
          "width": 0.00504202,
          "height": 0.00356295
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "804963"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804944",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<mi>K</mi>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "tex": "$K$",
      "mathml": "<mi>K</mi>",
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations ${\\htmlClass{match-highlight}{K}}_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by ${\\htmlClass{match-highlight}{K}}_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{{\\htmlClass{match-highlight}{K}}_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted ${\\htmlClass{match-highlight}{K}}_{parse}$, $Q_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between ${\\htmlClass{match-highlight}{K}}_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} {\\htmlClass{match-highlight}{K}}_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and ${\\htmlClass{match-highlight}{K}}_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, $Q_{parse}$ has dimension 500 and ${\\htmlClass{match-highlight}{K}}_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.388235,
          "top": 0.893112,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "804939"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804945",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.401681,
          "top": 0.901425,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "804939"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804946",
    "type": "symbol",
    "attributes": {
      "tex": "$V$",
      "mathml": "<mi>V</mi>",
      "mathml_near_matches": [
        "<mi>V</mi>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V}}_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V}}_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations ${\\htmlClass{match-highlight}{V}}_{parse}$ as in the other attention heads.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ and $\\mathcal{P}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{\\htmlClass{match-highlight}{{V}}}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.111639,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804707"
      },
      "parent": {
        "type": "symbol",
        "id": "804983"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804947",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.527731,
          "top": 0.118765,
          "width": 0.0319328,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804707"
      },
      "parent": {
        "type": "symbol",
        "id": "804983"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804948",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A}}_h^{(j)}$ between each pair of tokens in the sentence.",
        "Attention weights ${\\htmlClass{match-highlight}{A}}_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A}}_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A}}_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Let ${\\htmlClass{match-highlight}{A}}_{parse}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A}}_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A}}_{parse}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A}}_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A}}_{parse}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A}}_{parse}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "mathml_near_matches": [
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>A</mi>",
        "<msub><mi>A</mi><mi>parse</mi></msub>"
      ],
      "tex": "$A$",
      "mathml": "<mi>A</mi>",
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.680672,
          "top": 0.207838,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804710"
      },
      "parent": {
        "type": "symbol",
        "id": "804964"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804949",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.692437,
          "top": 0.214964,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804710"
      },
      "parent": {
        "type": "symbol",
        "id": "804964"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804950",
    "type": "symbol",
    "attributes": {
      "tex": "$P(q=\\mathrm{head}(t)$",
      "mathml": "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
      "mathml_near_matches": [
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\n{\\htmlClass{match-highlight}{P(q=\\mathrm{head}(t)}} \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.571429,
          "top": 0.252969,
          "width": 0.110924,
          "height": 0.0130641
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804713"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804959"
        },
        {
          "type": "symbol",
          "id": "805423"
        },
        {
          "type": "symbol",
          "id": "804951"
        },
        {
          "type": "symbol",
          "id": "804954"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804951",
    "type": "symbol",
    "attributes": {
      "tex": "$\\mathrm{head}$",
      "mathml": "<mi>head</mi>",
      "mathml_near_matches": [
        "<mi>head</mi>"
      ],
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q={\\htmlClass{match-highlight}{\\mathrm{head}}}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P({\\htmlClass{match-highlight}{\\mathrm{head}}}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.626891,
          "top": 0.252969,
          "width": 0.0336134,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804713"
      },
      "parent": {
        "type": "symbol",
        "id": "804950"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804952",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.840336,
          "top": 0.192399,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804708"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "804953",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.667227,
          "top": 0.224466,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804711"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "804954",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.670588,
          "top": 0.254157,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804713"
      },
      "parent": {
        "type": "symbol",
        "id": "804950"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "804955",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.8,
          "top": 0.254157,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804713"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "804956",
    "type": "symbol",
    "attributes": {
      "tex": "$q$",
      "mathml": "<mi>q</mi>",
      "mathml_near_matches": [
        "<mi>q</mi>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_{\\htmlClass{match-highlight}{q}}$, and $T\\times d_v$, respectively.",
        "Denoting the attention weight from token $t$ to a candidate head ${\\htmlClass{match-highlight}{q}}$ as $A_{parse}[t,{\\htmlClass{match-highlight}{q}}]$, we model the probability of token $t$ having parent ${\\htmlClass{match-highlight}{q}}$ as:\n\\begin{align}\nP({\\htmlClass{match-highlight}{q}}=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, {\\htmlClass{match-highlight}{q}}]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$."
      ],
      "is_definition": false,
      "nicknames": [
        "candidate head",
        "parent"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.781513,
          "top": 0.226841,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804712"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804957",
    "type": "symbol",
    "attributes": {
      "tex": "$q$",
      "mathml": "<mi>q</mi>",
      "mathml_near_matches": [
        "<mi>q</mi>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_{\\htmlClass{match-highlight}{q}}$, and $T\\times d_v$, respectively.",
        "Denoting the attention weight from token $t$ to a candidate head ${\\htmlClass{match-highlight}{q}}$ as $A_{parse}[t,{\\htmlClass{match-highlight}{q}}]$, we model the probability of token $t$ having parent ${\\htmlClass{match-highlight}{q}}$ as:\n\\begin{align}\nP({\\htmlClass{match-highlight}{q}}=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, {\\htmlClass{match-highlight}{q}}]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$."
      ],
      "is_definition": false,
      "nicknames": [
        "candidate head",
        "parent"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.638655,
          "top": 0.210214,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804709"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804958",
    "type": "symbol",
    "attributes": {
      "tex": "$q$",
      "mathml": "<mi>q</mi>",
      "mathml_near_matches": [
        "<mi>q</mi>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_{\\htmlClass{match-highlight}{q}}$, and $T\\times d_v$, respectively.",
        "Denoting the attention weight from token $t$ to a candidate head ${\\htmlClass{match-highlight}{q}}$ as $A_{parse}[t,{\\htmlClass{match-highlight}{q}}]$, we model the probability of token $t$ having parent ${\\htmlClass{match-highlight}{q}}$ as:\n\\begin{align}\nP({\\htmlClass{match-highlight}{q}}=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, {\\htmlClass{match-highlight}{q}}]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$."
      ],
      "is_definition": false,
      "nicknames": [
        "candidate head",
        "parent"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.747899,
          "top": 0.210214,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804710"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804959",
    "type": "symbol",
    "attributes": {
      "tex": "$P$",
      "mathml": "<mi>P</mi>",
      "mathml_near_matches": [
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\n{\\htmlClass{match-highlight}{P}}(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "This attention head now becomes an oracle for syntax, denoted $\\mathcal{\\htmlClass{match-highlight}{{P}}}$, providing a dependency parse to downstream layers.",
        "We compute locally-normalized probabilities using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{V}$ and $\\mathcal{\\htmlClass{match-highlight}{{P}}}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{\\htmlClass{match-highlight}{{P}}}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log {\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log {\\htmlClass{match-highlight}{P}}(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log {\\htmlClass{match-highlight}{P}}(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log {\\htmlClass{match-highlight}{P}}(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "nicknames": [
        "softmax function"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.571429,
          "top": 0.254157,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804713"
      },
      "parent": {
        "type": "symbol",
        "id": "804950"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804960",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "We then provide these representations to a bilinear transformation ${\\htmlClass{match-highlight}{U}}$ for scoring.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator ${\\htmlClass{match-highlight}{U}}_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} {\\htmlClass{match-highlight}{U}}_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T {\\htmlClass{match-highlight}{U}} s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch."
      ],
      "tex": "$U$",
      "mathml": "<mi>U</mi>",
      "mathml_near_matches": [
        "<mi>U</mi>",
        "<msub><mi>U</mi><mrow><mi>hea</mi><mi>s</mi></mrow></msub>"
      ],
      "is_definition": false,
      "nicknames": [
        "bilinear transformation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.337815,
          "top": 0.893112,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "804963"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804371"
        },
        {
          "type": "sentence",
          "id": "804372"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804371"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804961",
    "type": "symbol",
    "attributes": {
      "tex": "$U$",
      "mathml": "<mi>U</mi>",
      "mathml_near_matches": [
        "<mi>U</mi>",
        "<msub><mi>U</mi><mrow><mi>hea</mi><mi>s</mi></mrow></msub>"
      ],
      "snippets": [
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator ${\\htmlClass{match-highlight}{U}}_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} {\\htmlClass{match-highlight}{U}}_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We then provide these representations to a bilinear transformation ${\\htmlClass{match-highlight}{U}}$ for scoring.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T {\\htmlClass{match-highlight}{U}} s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch."
      ],
      "is_definition": false,
      "nicknames": [
        "bilinear transformation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.188235,
          "top": 0.86342,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804705"
      },
      "parent": {
        "type": "symbol",
        "id": "804962"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804371"
        },
        {
          "type": "sentence",
          "id": "804372"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804371"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804962",
    "type": "symbol",
    "attributes": {
      "tex": "$U_{heads}$",
      "mathml": "<msub><mi>U</mi><mrow><mi>hea</mi><mi>s</mi></mrow></msub>",
      "mathml_near_matches": [
        "<msub><mi>U</mi><mrow><mi>hea</mi><mi>s</mi></mrow></msub>",
        "<mi>U</mi>"
      ],
      "snippets": [
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator ${\\htmlClass{match-highlight}{U_{heads}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} {\\htmlClass{match-highlight}{U_{heads}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads."
      ],
      "is_definition": false,
      "nicknames": [
        "bi-affine operator"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.188235,
          "top": 0.86342,
          "width": 0.0453782,
          "height": 0.00950119
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804705"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804961"
        },
        {
          "type": "symbol",
          "id": "804933"
        },
        {
          "type": "symbol",
          "id": "804934"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804346"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804346"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804963",
    "type": "symbol",
    "attributes": {
      "tex": "$U_{heads}$",
      "mathml": "<msub><mi>U</mi><mrow><mi>hea</mi><mi>s</mi></mrow></msub>",
      "mathml_near_matches": [
        "<msub><mi>U</mi><mrow><mi>hea</mi><mi>s</mi></mrow></msub>",
        "<mi>U</mi>"
      ],
      "snippets": [
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator ${\\htmlClass{match-highlight}{U_{heads}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} {\\htmlClass{match-highlight}{U_{heads}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads."
      ],
      "is_definition": false,
      "nicknames": [
        "bi-affine operator"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.337815,
          "top": 0.893112,
          "width": 0.0470588,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "804935"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804960"
        },
        {
          "type": "symbol",
          "id": "804942"
        },
        {
          "type": "symbol",
          "id": "804943"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804346"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804346"
        }
      ],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804964",
    "type": "symbol",
    "attributes": {
      "tex": "$A_{parse}$",
      "mathml": "<msub><mi>A</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<mi>A</mi>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A_{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "Let ${\\htmlClass{match-highlight}{A_{parse}}}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A_{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A_{parse}}}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A_{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A_{parse}}}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A_{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n"
      ],
      "is_definition": false,
      "definitions": [
        "parse attention weights at layer $i$",
        "parse parents produced by e.g. a state-of-the-art parser"
      ],
      "nicknames": [
        "attention weights",
        "attention weights",
        "attention weight from token $t$ to a candidate head $q$",
        "attention weights",
        "attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.680672,
          "top": 0.207838,
          "width": 0.0453782,
          "height": 0.0130641
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804710"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804948"
        },
        {
          "type": "symbol",
          "id": "804949"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804706"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "804965",
    "type": "symbol",
    "attributes": {
      "tex": "$A$",
      "mathml": "<mi>A</mi>",
      "mathml_near_matches": [
        "<mi>A</mi>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A}}_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A}}_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A}}_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A}}_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Let ${\\htmlClass{match-highlight}{A}}_{parse}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A}}_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A}}_{parse}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A}}_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A}}_{parse}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A}}_{parse}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.747899,
          "top": 0.254157,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804713"
      },
      "parent": {
        "type": "symbol",
        "id": "805419"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804966",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.759664,
          "top": 0.260095,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804713"
      },
      "parent": {
        "type": "symbol",
        "id": "805419"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804967",
    "type": "symbol",
    "attributes": {
      "tex": "$A$",
      "mathml": "<mi>A</mi>",
      "mathml_near_matches": [
        "<mi>A</mi>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A}}_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A}}_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A}}_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A}}_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Let ${\\htmlClass{match-highlight}{A}}_{parse}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A}}_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A}}_{parse}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A}}_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A}}_{parse}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A}}_{parse}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.722689,
          "top": 0.283848,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804714"
      },
      "parent": {
        "type": "symbol",
        "id": "805421"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804968",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.734454,
          "top": 0.289786,
          "width": 0.0319328,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804714"
      },
      "parent": {
        "type": "symbol",
        "id": "805421"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804969",
    "type": "symbol",
    "attributes": {
      "tex": "$A$",
      "mathml": "<mi>A</mi>",
      "mathml_near_matches": [
        "<mi>A</mi>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A}}_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A}}_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A}}_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A}}_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Let ${\\htmlClass{match-highlight}{A}}_{parse}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A}}_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A}}_{parse}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A}}_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A}}_{parse}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A}}_{parse}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.36342,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804716"
      },
      "parent": {
        "type": "symbol",
        "id": "805014"
      },
      "sentence": {
        "type": "sentence",
        "id": "804351"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804970",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.529412,
          "top": 0.370546,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804716"
      },
      "parent": {
        "type": "symbol",
        "id": "805014"
      },
      "sentence": {
        "type": "sentence",
        "id": "804351"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804971",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>Q</mi>",
      "mathml_near_matches": [
        "<mi>Q</mi>",
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>Q</mi><mi>parse</mi></msub>"
      ],
      "tex": "$Q$",
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}{\\htmlClass{match-highlight}{Q}}_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, ${\\htmlClass{match-highlight}{Q}}_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and ${\\htmlClass{match-highlight}{Q}}_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}({\\htmlClass{match-highlight}{Q}}_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations ${\\htmlClass{match-highlight}{Q}}_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, ${\\htmlClass{match-highlight}{Q}}_{parse}$ has dimension 500 and $K_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.695798,
          "top": 0.410926,
          "width": 0.0117647,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804717"
      },
      "parent": {
        "type": "symbol",
        "id": "805587"
      },
      "sentence": {
        "type": "sentence",
        "id": "804352"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804972",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.709244,
          "top": 0.41924,
          "width": 0.0319328,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804717"
      },
      "parent": {
        "type": "symbol",
        "id": "805587"
      },
      "sentence": {
        "type": "sentence",
        "id": "804352"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804973",
    "type": "symbol",
    "attributes": {
      "tex": "$K$",
      "mathml": "<mi>K</mi>",
      "mathml_near_matches": [
        "<mi>K</mi>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations ${\\htmlClass{match-highlight}{K}}_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by ${\\htmlClass{match-highlight}{K}}_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{{\\htmlClass{match-highlight}{K}}_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted ${\\htmlClass{match-highlight}{K}}_{parse}$, $Q_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between ${\\htmlClass{match-highlight}{K}}_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} {\\htmlClass{match-highlight}{K}}_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and ${\\htmlClass{match-highlight}{K}}_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, $Q_{parse}$ has dimension 500 and ${\\htmlClass{match-highlight}{K}}_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.779832,
          "top": 0.412114,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804718"
      },
      "parent": {
        "type": "symbol",
        "id": "805038"
      },
      "sentence": {
        "type": "sentence",
        "id": "804352"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804974",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.794958,
          "top": 0.41924,
          "width": 0.0319328,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804718"
      },
      "parent": {
        "type": "symbol",
        "id": "805038"
      },
      "sentence": {
        "type": "sentence",
        "id": "804352"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804975",
    "type": "symbol",
    "attributes": {
      "tex": "$y$",
      "mathml": "<mi>y</mi>",
      "mathml_near_matches": [
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>"
      ],
      "snippets": [
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels ${\\htmlClass{match-highlight}{y}}_t^{dep}$ given by the softmax function.",
        "We compute locally-normalized probabilities using the softmax function: $P({\\htmlClass{match-highlight}{y}}_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where ${\\htmlClass{match-highlight}{y}}_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P({\\htmlClass{match-highlight}{y}}_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P({\\htmlClass{match-highlight}{y}}_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.784874,
          "top": 0.446556,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804719"
      },
      "parent": {
        "type": "symbol",
        "id": "805001"
      },
      "sentence": {
        "type": "sentence",
        "id": "804352"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804976",
    "type": "symbol",
    "attributes": {
      "tex": "$dep$",
      "mathml": "<mi>dep</mi>",
      "mathml_near_matches": [
        "<mi>dep</mi>"
      ],
      "snippets": [
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{{\\htmlClass{match-highlight}{dep}}}$ given by the softmax function.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{{\\htmlClass{match-highlight}{dep}}} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.794958,
          "top": 0.440618,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804719"
      },
      "parent": {
        "type": "symbol",
        "id": "805001"
      },
      "sentence": {
        "type": "sentence",
        "id": "804352"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804977",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A}}_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A}}_{parse}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A}}_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A}}_{parse}$ assigns the highest weight.",
        "Attention weights ${\\htmlClass{match-highlight}{A}}_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights ${\\htmlClass{match-highlight}{A}}_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = {\\htmlClass{match-highlight}{A}}_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by ${\\htmlClass{match-highlight}{A}}_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Let ${\\htmlClass{match-highlight}{A}}_{parse}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A}}_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A}}_{parse}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "tex": "$A$",
      "mathml": "<mi>A</mi>",
      "mathml_near_matches": [
        "<mi>A</mi>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.65042,
          "top": 0.573634,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804721"
      },
      "parent": {
        "type": "symbol",
        "id": "805420"
      },
      "sentence": {
        "type": "sentence",
        "id": "804355"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804978",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.662185,
          "top": 0.579572,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804721"
      },
      "parent": {
        "type": "symbol",
        "id": "805420"
      },
      "sentence": {
        "type": "sentence",
        "id": "804355"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804979",
    "type": "symbol",
    "attributes": {
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "mathml_near_matches": [
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.462185,
          "top": 0.226841,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804722"
      },
      "parent": {
        "type": "symbol",
        "id": "805006"
      },
      "sentence": {
        "type": "sentence",
        "id": "804365"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804980",
    "type": "symbol",
    "attributes": {
      "tex": "$P(y_t^{prp} \\mid \\mathcal{X})$",
      "mathml": "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
      "mathml_near_matches": [
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "We compute locally-normalized probabilities using the softmax function: ${\\htmlClass{match-highlight}{P(y_t^{prp} \\mid \\mathcal{X})}} \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log {\\htmlClass{match-highlight}{P(y_t^{prp}\\mid \\mathcal{X})}} \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.194958,
          "top": 0.30285,
          "width": 0.0890756,
          "height": 0.0142518
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804727"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805003"
        },
        {
          "type": "symbol",
          "id": "805008"
        },
        {
          "type": "symbol",
          "id": "805610"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804981",
    "type": "symbol",
    "attributes": {
      "tex": "$y$",
      "mathml": "<mi>y</mi>",
      "mathml_near_matches": [
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>"
      ],
      "snippets": [
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels ${\\htmlClass{match-highlight}{y}}_t^{dep}$ given by the softmax function.",
        "We compute locally-normalized probabilities using the softmax function: $P({\\htmlClass{match-highlight}{y}}_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where ${\\htmlClass{match-highlight}{y}}_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P({\\htmlClass{match-highlight}{y}}_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P({\\htmlClass{match-highlight}{y}}_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.215126,
          "top": 0.307601,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804727"
      },
      "parent": {
        "type": "symbol",
        "id": "805008"
      },
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804982",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<mi>V</mi>"
      ],
      "snippets": [
        "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values ${\\htmlClass{match-highlight}{V_{parse}}}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, ${\\htmlClass{match-highlight}{V_{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations ${\\htmlClass{match-highlight}{V_{parse}}}$ as in the other attention heads."
      ],
      "tex": "$V_{parse}$",
      "mathml": "<msub><mi>V</mi><mi>parse</mi></msub>",
      "is_definition": false,
      "nicknames": [
        "token values",
        "query representation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.364706,
          "top": 0.718527,
          "width": 0.0436975,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804700"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804925"
        },
        {
          "type": "symbol",
          "id": "804926"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804344"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804983",
    "type": "symbol",
    "attributes": {
      "tex": "$V_{parse}$",
      "mathml": "<msub><mi>V</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<mi>V</mi>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values ${\\htmlClass{match-highlight}{V_{parse}}}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, ${\\htmlClass{match-highlight}{V_{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations ${\\htmlClass{match-highlight}{V_{parse}}}$ as in the other attention heads."
      ],
      "is_definition": false,
      "nicknames": [
        "token values",
        "query representation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.111639,
          "width": 0.0420168,
          "height": 0.0130641
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804707"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804946"
        },
        {
          "type": "symbol",
          "id": "804947"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804984",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>",
        "<mi>K</mi>"
      ],
      "tex": "$K_{parse}$",
      "mathml": "<msub><mi>K</mi><mi>parse</mi></msub>",
      "snippets": [
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted ${\\htmlClass{match-highlight}{K_{parse}}}$, $Q_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between ${\\htmlClass{match-highlight}{K_{parse}}}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and ${\\htmlClass{match-highlight}{K_{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, $Q_{parse}$ has dimension 500 and ${\\htmlClass{match-highlight}{K_{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "nicknames": [
        "key representation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.245378,
          "top": 0.718527,
          "width": 0.0470588,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804698"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804921"
        },
        {
          "type": "symbol",
          "id": "804922"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804344"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804985",
    "type": "symbol",
    "attributes": {
      "tex": "$prp$",
      "mathml": "<mi>prp</mi>",
      "mathml_near_matches": [
        "<mi>prp</mi>"
      ],
      "snippets": [
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{{\\htmlClass{match-highlight}{prp}}} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{{\\htmlClass{match-highlight}{prp}}}$ is a label in the joint space.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{{\\htmlClass{match-highlight}{prp}}}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.22521,
          "top": 0.30285,
          "width": 0.0201681,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804727"
      },
      "parent": {
        "type": "symbol",
        "id": "805008"
      },
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804986",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "tex": "$y$",
      "mathml": "<mi>y</mi>",
      "mathml_near_matches": [
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>"
      ],
      "snippets": [
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels ${\\htmlClass{match-highlight}{y}}_t^{dep}$ given by the softmax function.",
        "We compute locally-normalized probabilities using the softmax function: $P({\\htmlClass{match-highlight}{y}}_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where ${\\htmlClass{match-highlight}{y}}_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P({\\htmlClass{match-highlight}{y}}_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P({\\htmlClass{match-highlight}{y}}_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.426891,
          "top": 0.307601,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804728"
      },
      "parent": {
        "type": "symbol",
        "id": "805007"
      },
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804987",
    "type": "symbol",
    "attributes": {
      "tex": "$prp$",
      "mathml": "<mi>prp</mi>",
      "mathml_near_matches": [
        "<mi>prp</mi>"
      ],
      "snippets": [
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{{\\htmlClass{match-highlight}{prp}}} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{{\\htmlClass{match-highlight}{prp}}}$ is a label in the joint space.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{{\\htmlClass{match-highlight}{prp}}}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.435294,
          "top": 0.30285,
          "width": 0.0218487,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804728"
      },
      "parent": {
        "type": "symbol",
        "id": "805007"
      },
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804988",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.460504,
          "top": 0.469121,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804731"
      },
      "parent": {
        "type": "symbol",
        "id": "805002"
      },
      "sentence": {
        "type": "sentence",
        "id": "804370"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804989",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.405042,
          "top": 0.486936,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804732"
      },
      "parent": {
        "type": "symbol",
        "id": "805010"
      },
      "sentence": {
        "type": "sentence",
        "id": "804370"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804990",
    "type": "symbol",
    "attributes": {
      "tex": "$r$",
      "mathml": "<mi>r</mi>",
      "mathml_near_matches": [
        "<mi>r</mi>",
        "<msub><mi>r</mi><mn>0</mn></msub>",
        "<msub><mi>r</mi><mi>t</mi></msub>"
      ],
      "snippets": [
        "Layer ${\\htmlClass{match-highlight}{r}}$ is input for a joint predicate/POS classifier.",
        "Representations from layer ${\\htmlClass{match-highlight}{r}}$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token SRL predictions with respect to each predicted predicate.",
        "Specifically, we feed the representation $s_t^{({\\htmlClass{match-highlight}{r}})}$ from a layer ${\\htmlClass{match-highlight}{r}}$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores ${\\htmlClass{match-highlight}{r}}_t$ for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{r}}_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $l{\\htmlClass{match-highlight}{r}}_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = l{\\htmlClass{match-highlight}{r}}_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "layer",
        "layer"
      ],
      "definitions": [
        "input for a joint predicate/POS classifier"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.252101,
          "top": 0.274347,
          "width": 0.00840336,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804725"
      },
      "parent": {
        "type": "symbol",
        "id": "805012"
      },
      "sentence": {
        "type": "sentence",
        "id": "804365"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        }
      ],
      "children": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "804991",
    "type": "symbol",
    "attributes": {
      "tex": "$r$",
      "mathml": "<mi>r</mi>",
      "mathml_near_matches": [
        "<mi>r</mi>",
        "<msub><mi>r</mi><mn>0</mn></msub>",
        "<msub><mi>r</mi><mi>t</mi></msub>"
      ],
      "snippets": [
        "Layer ${\\htmlClass{match-highlight}{r}}$ is input for a joint predicate/POS classifier.",
        "Representations from layer ${\\htmlClass{match-highlight}{r}}$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token SRL predictions with respect to each predicted predicate.",
        "Specifically, we feed the representation $s_t^{({\\htmlClass{match-highlight}{r}})}$ from a layer ${\\htmlClass{match-highlight}{r}}$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores ${\\htmlClass{match-highlight}{r}}_t$ for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{r}}_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $l{\\htmlClass{match-highlight}{r}}_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = l{\\htmlClass{match-highlight}{r}}_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "layer",
        "layer"
      ],
      "definitions": [
        "input for a joint predicate/POS classifier"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.243697,
          "top": 0.24228,
          "width": 0.00840336,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804723"
      },
      "sentence": {
        "type": "sentence",
        "id": "804365"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "defining_formula_equations": []
    }
  },
  {
    "id": "804992",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.789916,
          "top": 0.299287,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804715"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "804993",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.468908,
          "top": 0.230404,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804722"
      },
      "parent": {
        "type": "symbol",
        "id": "805006"
      },
      "sentence": {
        "type": "sentence",
        "id": "804365"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "804994",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.37479,
          "top": 0.271971,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804726"
      },
      "sentence": {
        "type": "sentence",
        "id": "804365"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "804995",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.467227,
          "top": 0.472684,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804731"
      },
      "parent": {
        "type": "symbol",
        "id": "805002"
      },
      "sentence": {
        "type": "sentence",
        "id": "804370"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "804996",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.794958,
          "top": 0.450119,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804719"
      },
      "parent": {
        "type": "symbol",
        "id": "805001"
      },
      "sentence": {
        "type": "sentence",
        "id": "804352"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "804997",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.77479,
          "top": 0.283848,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804714"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "804998",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.290756,
          "top": 0.276722,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804725"
      },
      "parent": {
        "type": "symbol",
        "id": "805012"
      },
      "sentence": {
        "type": "sentence",
        "id": "804365"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "804999",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.354622,
          "top": 0.308789,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804727"
      },
      "parent": {
        "type": "symbol",
        "id": "805011"
      },
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805000",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.411765,
          "top": 0.489311,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804732"
      },
      "parent": {
        "type": "symbol",
        "id": "805010"
      },
      "sentence": {
        "type": "sentence",
        "id": "804370"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805001",
    "type": "symbol",
    "attributes": {
      "tex": "$y_t^{dep}$",
      "mathml": "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>"
      ],
      "snippets": [
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels ${\\htmlClass{match-highlight}{y_t^{dep}}}$ given by the softmax function.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P({\\htmlClass{match-highlight}{y_t^{dep}}} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "nicknames": [
        "dependency labels"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.784874,
          "top": 0.440618,
          "width": 0.0302521,
          "height": 0.0154394
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804719"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804975"
        },
        {
          "type": "symbol",
          "id": "804996"
        },
        {
          "type": "symbol",
          "id": "804976"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804352"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804352"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805002",
    "type": "symbol",
    "attributes": {
      "tex": "$s_t^{(J)}$",
      "mathml": "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "First, we project each token representation ${\\htmlClass{match-highlight}{s_t^{(J)}}}$ to a predicate-specific representation $s_t^{pred}$ and a role-specific representation $s_t^{role}$."
      ],
      "is_definition": false,
      "nicknames": [
        "token representation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.460504,
          "top": 0.461995,
          "width": 0.0235294,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804731"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804988"
        },
        {
          "type": "symbol",
          "id": "804995"
        },
        {
          "type": "symbol",
          "id": "805013"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804370"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804370"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804370"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805003",
    "type": "symbol",
    "attributes": {
      "tex": "$P$",
      "mathml": "<mi>P</mi>",
      "mathml_near_matches": [
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\n{\\htmlClass{match-highlight}{P}}(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "This attention head now becomes an oracle for syntax, denoted $\\mathcal{\\htmlClass{match-highlight}{{P}}}$, providing a dependency parse to downstream layers.",
        "We compute locally-normalized probabilities using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{V}$ and $\\mathcal{\\htmlClass{match-highlight}{{P}}}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{\\htmlClass{match-highlight}{{P}}}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log {\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log {\\htmlClass{match-highlight}{P}}(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log {\\htmlClass{match-highlight}{P}}(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log {\\htmlClass{match-highlight}{P}}(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "nicknames": [
        "softmax function"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.194958,
          "top": 0.305226,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804727"
      },
      "parent": {
        "type": "symbol",
        "id": "804980"
      },
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805004",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\n{\\htmlClass{match-highlight}{P}}(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "This attention head now becomes an oracle for syntax, denoted $\\mathcal{\\htmlClass{match-highlight}{{P}}}$, providing a dependency parse to downstream layers.",
        "We compute locally-normalized probabilities using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{V}$ and $\\mathcal{\\htmlClass{match-highlight}{{P}}}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{\\htmlClass{match-highlight}{{P}}}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log {\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log {\\htmlClass{match-highlight}{P}}(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log {\\htmlClass{match-highlight}{P}}(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log {\\htmlClass{match-highlight}{P}}(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "tex": "$\\mathcal{P}$",
      "nicknames": [
        "attention head",
        "oracle for predicates and syntax",
        "oracle"
      ],
      "mathml": "<mi mathvariant=\"script\">P</mi>",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.636975,
          "top": 0.509501,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804720"
      },
      "sentence": {
        "type": "sentence",
        "id": "804354"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804369"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805005",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\n{\\htmlClass{match-highlight}{P}}(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "This attention head now becomes an oracle for syntax, denoted $\\mathcal{\\htmlClass{match-highlight}{{P}}}$, providing a dependency parse to downstream layers.",
        "We compute locally-normalized probabilities using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{V}$ and $\\mathcal{\\htmlClass{match-highlight}{{P}}}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{\\htmlClass{match-highlight}{{P}}}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log {\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log {\\htmlClass{match-highlight}{P}}(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log {\\htmlClass{match-highlight}{P}}(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log {\\htmlClass{match-highlight}{P}}(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "tex": "$\\mathcal{P}$",
      "nicknames": [
        "attention head",
        "oracle for predicates and syntax",
        "oracle"
      ],
      "mathml": "<mi mathvariant=\"script\">P</mi>",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.336134,
          "top": 0.448931,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804730"
      },
      "sentence": {
        "type": "sentence",
        "id": "804369"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804369"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805006",
    "type": "symbol",
    "attributes": {
      "tex": "$s_t^{(r)}$",
      "mathml": "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s_t^{(r)}}}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$."
      ],
      "is_definition": false,
      "nicknames": [
        "representation from a layer $r$ preceding the syntactically-informed layer $p$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.462185,
          "top": 0.219715,
          "width": 0.0218487,
          "height": 0.0166271
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804722"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804979"
        },
        {
          "type": "symbol",
          "id": "804993"
        },
        {
          "type": "symbol",
          "id": "805406"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804365"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805007",
    "type": "symbol",
    "attributes": {
      "tex": "$y_t^{prp}$",
      "mathml": "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>"
      ],
      "snippets": [
        "We compute locally-normalized probabilities using the softmax function: $P({\\htmlClass{match-highlight}{y_t^{prp}}} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where ${\\htmlClass{match-highlight}{y_t^{prp}}}$ is a label in the joint space.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P({\\htmlClass{match-highlight}{y_t^{prp}}}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "definitions": [
        "label in the joint space"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.426891,
          "top": 0.30285,
          "width": 0.0302521,
          "height": 0.0130641
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804728"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804986"
        },
        {
          "type": "symbol",
          "id": "805422"
        },
        {
          "type": "symbol",
          "id": "804987"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805008",
    "type": "symbol",
    "attributes": {
      "tex": "$y_t^{prp}$",
      "mathml": "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>"
      ],
      "snippets": [
        "We compute locally-normalized probabilities using the softmax function: $P({\\htmlClass{match-highlight}{y_t^{prp}}} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where ${\\htmlClass{match-highlight}{y_t^{prp}}}$ is a label in the joint space.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P({\\htmlClass{match-highlight}{y_t^{prp}}}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "definitions": [
        "label in the joint space"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.215126,
          "top": 0.30285,
          "width": 0.0302521,
          "height": 0.0130641
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804727"
      },
      "parent": {
        "type": "symbol",
        "id": "804980"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804981"
        },
        {
          "type": "symbol",
          "id": "805412"
        },
        {
          "type": "symbol",
          "id": "804985"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        }
      ],
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805009",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>V</mi>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V}}_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V}}_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations ${\\htmlClass{match-highlight}{V}}_{parse}$ as in the other attention heads.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ and $\\mathcal{P}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{\\htmlClass{match-highlight}{{V}}}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$."
      ],
      "is_definition": false,
      "tex": "$\\mathcal{V}$",
      "mathml": "<mi mathvariant=\"script\">V</mi>",
      "nicknames": [
        "oracle for predicates and syntax"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.289076,
          "top": 0.448931,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804729"
      },
      "sentence": {
        "type": "sentence",
        "id": "804369"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804369"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805010",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>"
      ],
      "tex": "$s_t^{pred}$",
      "mathml": "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
      "snippets": [
        "First, we project each token representation $s_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s_t^{pred}}}$ and a role-specific representation $s_t^{role}$."
      ],
      "is_definition": false,
      "nicknames": [
        "predicate-specific representation",
        "role-specific representation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.405042,
          "top": 0.47981,
          "width": 0.0336134,
          "height": 0.0154394
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804732"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804989"
        },
        {
          "type": "symbol",
          "id": "805000"
        },
        {
          "type": "symbol",
          "id": "805015"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804370"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804370"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804370"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805011",
    "type": "symbol",
    "attributes": {
      "tex": "$r_t$",
      "mathml": "<msub><mi>r</mi><mi>t</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>r</mi><mi>t</mi></msub>",
        "<msub><mi>r</mi><mn>0</mn></msub>",
        "<mi>r</mi>"
      ],
      "snippets": [
        "Specifically, we feed the representation $s_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores ${\\htmlClass{match-highlight}{r_t}}$ for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{r_t}})$, where $y_t^{prp}$ is a label in the joint space."
      ],
      "is_definition": false,
      "nicknames": [
        "per-class scores for token $t$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.346218,
          "top": 0.307601,
          "width": 0.0117647,
          "height": 0.00712589
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804727"
      },
      "parent": {
        "type": "symbol",
        "id": "94891"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805407"
        },
        {
          "type": "symbol",
          "id": "804999"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805012",
    "type": "symbol",
    "attributes": {
      "tex": "$r_t$",
      "mathml": "<msub><mi>r</mi><mi>t</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>r</mi><mi>t</mi></msub>",
        "<msub><mi>r</mi><mn>0</mn></msub>",
        "<mi>r</mi>"
      ],
      "snippets": [
        "Specifically, we feed the representation $s_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores ${\\htmlClass{match-highlight}{r_t}}$ for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{r_t}})$, where $y_t^{prp}$ is a label in the joint space."
      ],
      "is_definition": false,
      "nicknames": [
        "per-class scores for token $t$"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.252101,
          "top": 0.274347,
          "width": 0.0420168,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804725"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804990"
        },
        {
          "type": "symbol",
          "id": "804998"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804365"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805013",
    "type": "symbol",
    "attributes": {
      "tex": "$J$",
      "mathml": "<mi>J</mi>",
      "mathml_near_matches": [
        "<mi>J</mi>"
      ],
      "snippets": [
        "Word embeddings are input to ${\\htmlClass{match-highlight}{J}}$ layers of multi-head self-attention.",
        "We feed this token representation as input to a series of ${\\htmlClass{match-highlight}{J}}$ residual multi-head self-attention layers with feed-forward connections.",
        "First, we project each token representation $s_t^{({\\htmlClass{match-highlight}{J}})}$ to a predicate-specific representation $s_t^{pred}$ and a role-specific representation $s_t^{role}$."
      ],
      "is_definition": false,
      "nicknames": [
        "number of layers of multi-head self-attention",
        "number residual multi-head self-attention layers with feed-forward connections"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.47395,
          "top": 0.461995,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804731"
      },
      "parent": {
        "type": "symbol",
        "id": "805002"
      },
      "sentence": {
        "type": "sentence",
        "id": "804370"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804292"
        },
        {
          "type": "sentence",
          "id": "804324"
        },
        {
          "type": "sentence",
          "id": "804370"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804292"
        },
        {
          "type": "sentence",
          "id": "804324"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805014",
    "type": "symbol",
    "attributes": {
      "tex": "$A_{parse}$",
      "mathml": "<msub><mi>A</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<mi>A</mi>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A_{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "Let ${\\htmlClass{match-highlight}{A_{parse}}}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A_{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A_{parse}}}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A_{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A_{parse}}}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A_{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n"
      ],
      "is_definition": false,
      "definitions": [
        "parse attention weights at layer $i$",
        "parse parents produced by e.g. a state-of-the-art parser"
      ],
      "nicknames": [
        "attention weights",
        "attention weights",
        "attention weight from token $t$ to a candidate head $q$",
        "attention weights",
        "attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.517647,
          "top": 0.36342,
          "width": 0.0453782,
          "height": 0.0130641
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804716"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804969"
        },
        {
          "type": "symbol",
          "id": "804970"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804351"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804706"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "805015",
    "type": "symbol",
    "attributes": {
      "tex": "$pred$",
      "mathml": "<mi>pred</mi>",
      "mathml_near_matches": [
        "<mi>pred</mi>"
      ],
      "snippets": [
        "First, we project each token representation $s_t^{(J)}$ to a predicate-specific representation $s_t^{{\\htmlClass{match-highlight}{pred}}}$ and a role-specific representation $s_t^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{{\\htmlClass{match-highlight}{pred}}})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.411765,
          "top": 0.47981,
          "width": 0.0268908,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804732"
      },
      "parent": {
        "type": "symbol",
        "id": "805010"
      },
      "sentence": {
        "type": "sentence",
        "id": "804370"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805016",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "tex": "$s_t^{role}$",
      "mathml": "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
      "snippets": [
        "First, we project each token representation $s_t^{(J)}$ to a predicate-specific representation $s_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s_t^{role}}}$.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U {\\htmlClass{match-highlight}{s_t^{role}}}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.32605,
          "top": 0.497625,
          "width": 0.0302521,
          "height": 0.0142518
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804733"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805017"
        },
        {
          "type": "symbol",
          "id": "805413"
        },
        {
          "type": "symbol",
          "id": "805060"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804370"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805017",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.32605,
          "top": 0.502375,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804733"
      },
      "parent": {
        "type": "symbol",
        "id": "805016"
      },
      "sentence": {
        "type": "sentence",
        "id": "804370"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805018",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.413445,
          "top": 0.534442,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804735"
      },
      "parent": {
        "type": "symbol",
        "id": "805043"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805019",
    "type": "symbol",
    "attributes": {
      "tex": "$ft$",
      "mathml": "<mi>ft</mi>",
      "mathml_near_matches": [
        "<mi>ft</mi>"
      ],
      "snippets": [
        "So, the role label scores $s_{{\\htmlClass{match-highlight}{ft}}}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{{\\htmlClass{match-highlight}{ft}}} = (s_f^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{{\\htmlClass{match-highlight}{ft}}}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{{\\htmlClass{match-highlight}{ft}}})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores $s_{{\\htmlClass{match-highlight}{ft}}}$ and the transition probabilities given by the training data.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{{\\htmlClass{match-highlight}{ft}}}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.421849,
          "top": 0.535629,
          "width": 0.010084,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804735"
      },
      "parent": {
        "type": "symbol",
        "id": "805043"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805020",
    "type": "symbol",
    "attributes": {
      "tex": "$s_f^{pred}$",
      "mathml": "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = ({\\htmlClass{match-highlight}{s_f^{pred}}})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.280672,
          "top": 0.590261,
          "width": 0.0352941,
          "height": 0.0178147
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804740"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805024"
        },
        {
          "type": "symbol",
          "id": "805053"
        },
        {
          "type": "symbol",
          "id": "805025"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805021",
    "type": "symbol",
    "attributes": {
      "tex": "$s_t^{role}$",
      "mathml": "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "First, we project each token representation $s_t^{(J)}$ to a predicate-specific representation $s_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s_t^{role}}}$.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U {\\htmlClass{match-highlight}{s_t^{role}}}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.351261,
          "top": 0.590261,
          "width": 0.0302521,
          "height": 0.0154394
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804740"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805026"
        },
        {
          "type": "symbol",
          "id": "805037"
        },
        {
          "type": "symbol",
          "id": "805059"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805022",
    "type": "symbol",
    "attributes": {
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "mathml_near_matches": [
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.226891,
          "top": 0.5962,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804740"
      },
      "parent": {
        "type": "symbol",
        "id": "805046"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805023",
    "type": "symbol",
    "attributes": {
      "tex": "$ft$",
      "mathml": "<mi>ft</mi>",
      "mathml_near_matches": [
        "<mi>ft</mi>"
      ],
      "snippets": [
        "So, the role label scores $s_{{\\htmlClass{match-highlight}{ft}}}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{{\\htmlClass{match-highlight}{ft}}} = (s_f^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{{\\htmlClass{match-highlight}{ft}}}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{{\\htmlClass{match-highlight}{ft}}})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores $s_{{\\htmlClass{match-highlight}{ft}}}$ and the transition probabilities given by the training data.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{{\\htmlClass{match-highlight}{ft}}}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.235294,
          "top": 0.597387,
          "width": 0.010084,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804740"
      },
      "parent": {
        "type": "symbol",
        "id": "805046"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805024",
    "type": "symbol",
    "attributes": {
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "mathml_near_matches": [
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.280672,
          "top": 0.5962,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804740"
      },
      "parent": {
        "type": "symbol",
        "id": "805020"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805025",
    "type": "symbol",
    "attributes": {
      "tex": "$pred$",
      "mathml": "<mi>pred</mi>",
      "mathml_near_matches": [
        "<mi>pred</mi>"
      ],
      "snippets": [
        "First, we project each token representation $s_t^{(J)}$ to a predicate-specific representation $s_t^{{\\htmlClass{match-highlight}{pred}}}$ and a role-specific representation $s_t^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{{\\htmlClass{match-highlight}{pred}}})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.287395,
          "top": 0.590261,
          "width": 0.0285714,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804740"
      },
      "parent": {
        "type": "symbol",
        "id": "805020"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805026",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.351261,
          "top": 0.5962,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804740"
      },
      "parent": {
        "type": "symbol",
        "id": "805021"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805027",
    "type": "symbol",
    "attributes": {
      "tex": "$P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X})$",
      "mathml": "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
      "mathml_near_matches": [
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: ${\\htmlClass{match-highlight}{P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X})}} \\propto \\exp(s_{ft})$."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.685273,
          "width": 0.134454,
          "height": 0.0166271
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804743"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805047"
        },
        {
          "type": "symbol",
          "id": "805028"
        },
        {
          "type": "symbol",
          "id": "805044"
        },
        {
          "type": "symbol",
          "id": "805050"
        },
        {
          "type": "symbol",
          "id": "805607"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804373"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805028",
    "type": "symbol",
    "attributes": {
      "tex": "$y_{ft}^{role}$",
      "mathml": "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>"
      ],
      "snippets": [
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P({\\htmlClass{match-highlight}{y_{ft}^{role}}}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P({\\htmlClass{match-highlight}{y_{ft}^{role}}}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.142857,
          "top": 0.685273,
          "width": 0.0319328,
          "height": 0.0166271
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804743"
      },
      "parent": {
        "type": "symbol",
        "id": "805027"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805029"
        },
        {
          "type": "symbol",
          "id": "805030"
        },
        {
          "type": "symbol",
          "id": "805058"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805029",
    "type": "symbol",
    "attributes": {
      "tex": "$y$",
      "mathml": "<mi>y</mi>",
      "mathml_near_matches": [
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>"
      ],
      "snippets": [
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels ${\\htmlClass{match-highlight}{y}}_t^{dep}$ given by the softmax function.",
        "We compute locally-normalized probabilities using the softmax function: $P({\\htmlClass{match-highlight}{y}}_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where ${\\htmlClass{match-highlight}{y}}_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P({\\htmlClass{match-highlight}{y}}_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P({\\htmlClass{match-highlight}{y}}_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.142857,
          "top": 0.690024,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804743"
      },
      "parent": {
        "type": "symbol",
        "id": "805028"
      },
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805030",
    "type": "symbol",
    "attributes": {
      "tex": "$ft$",
      "mathml": "<mi>ft</mi>",
      "mathml_near_matches": [
        "<mi>ft</mi>"
      ],
      "snippets": [
        "So, the role label scores $s_{{\\htmlClass{match-highlight}{ft}}}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{{\\htmlClass{match-highlight}{ft}}} = (s_f^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{{\\htmlClass{match-highlight}{ft}}}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{{\\htmlClass{match-highlight}{ft}}})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores $s_{{\\htmlClass{match-highlight}{ft}}}$ and the transition probabilities given by the training data.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{{\\htmlClass{match-highlight}{ft}}}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.152941,
          "top": 0.692399,
          "width": 0.010084,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804743"
      },
      "parent": {
        "type": "symbol",
        "id": "805028"
      },
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805031",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "tex": "$s$",
      "snippets": [
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$."
      ],
      "mathml": "<mi>s</mi>",
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.321008,
          "top": 0.690024,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804743"
      },
      "parent": {
        "type": "symbol",
        "id": "805042"
      },
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805032",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>ft</mi>"
      ],
      "tex": "$ft$",
      "mathml": "<mi>ft</mi>",
      "snippets": [
        "So, the role label scores $s_{{\\htmlClass{match-highlight}{ft}}}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{{\\htmlClass{match-highlight}{ft}}} = (s_f^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{{\\htmlClass{match-highlight}{ft}}}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{{\\htmlClass{match-highlight}{ft}}})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores $s_{{\\htmlClass{match-highlight}{ft}}}$ and the transition probabilities given by the training data.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{{\\htmlClass{match-highlight}{ft}}}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.329412,
          "top": 0.690024,
          "width": 0.010084,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804743"
      },
      "parent": {
        "type": "symbol",
        "id": "805042"
      },
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805033",
    "type": "symbol",
    "attributes": {
      "tex": "$s$",
      "mathml": "<mi>s</mi>",
      "mathml_near_matches": [
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer ${\\htmlClass{match-highlight}{s}}_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\n{\\htmlClass{match-highlight}{s}}_t^{(j)} = LN({\\htmlClass{match-highlight}{s}}_t^{(j-1)} + T^{(j)}({\\htmlClass{match-highlight}{s}}_t^{(j-1)}))\n\\end{align}\ngives our final token representations ${\\htmlClass{match-highlight}{s}}_t^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{head{\\htmlClass{match-highlight}{s}}}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{head{\\htmlClass{match-highlight}{s}}} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Specifically, we feed the representation ${\\htmlClass{match-highlight}{s}}_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "First, we project each token representation ${\\htmlClass{match-highlight}{s}}_t^{(J)}$ to a predicate-specific representation ${\\htmlClass{match-highlight}{s}}_t^{pred}$ and a role-specific representation ${\\htmlClass{match-highlight}{s}}_t^{role}$.",
        "So, the role label scores ${\\htmlClass{match-highlight}{s}}_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s}}_{ft} = ({\\htmlClass{match-highlight}{s}}_f^{pred})^T U {\\htmlClass{match-highlight}{s}}_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s}}_{ft})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s}}_{ft}$ and the transition probabilities given by the training data."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.357983,
          "top": 0.73753,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804744"
      },
      "parent": {
        "type": "symbol",
        "id": "805425"
      },
      "sentence": {
        "type": "sentence",
        "id": "804374"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805034",
    "type": "symbol",
    "attributes": {
      "tex": "$ft$",
      "mathml": "<mi>ft</mi>",
      "mathml_near_matches": [
        "<mi>ft</mi>"
      ],
      "snippets": [
        "So, the role label scores $s_{{\\htmlClass{match-highlight}{ft}}}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{{\\htmlClass{match-highlight}{ft}}} = (s_f^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{{\\htmlClass{match-highlight}{ft}}}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{{\\htmlClass{match-highlight}{ft}}})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores $s_{{\\htmlClass{match-highlight}{ft}}}$ and the transition probabilities given by the training data.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{{\\htmlClass{match-highlight}{ft}}}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.366387,
          "top": 0.738717,
          "width": 0.010084,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804744"
      },
      "parent": {
        "type": "symbol",
        "id": "805425"
      },
      "sentence": {
        "type": "sentence",
        "id": "804374"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805035",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "tex": "${T}$",
      "mathml": "<mi>T</mi>",
      "mathml_near_matches": [
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^{\\htmlClass{match-highlight}{T}})\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^{\\htmlClass{match-highlight}{T}})\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^{\\htmlClass{match-highlight}{T}} U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.563025,
          "top": 0.178147,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805036",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "snippets": [
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.193277,
          "top": 0.671021,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804741"
      },
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805037",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "tex": "$t$",
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.357983,
          "top": 0.599762,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804740"
      },
      "parent": {
        "type": "symbol",
        "id": "805021"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805038",
    "type": "symbol",
    "attributes": {
      "tex": "$K_{parse}$",
      "mathml": "<msub><mi>K</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>",
        "<mi>K</mi>"
      ],
      "snippets": [
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted ${\\htmlClass{match-highlight}{K_{parse}}}$, $Q_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between ${\\htmlClass{match-highlight}{K_{parse}}}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and ${\\htmlClass{match-highlight}{K_{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, $Q_{parse}$ has dimension 500 and ${\\htmlClass{match-highlight}{K_{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "nicknames": [
        "key representation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.779832,
          "top": 0.412114,
          "width": 0.0470588,
          "height": 0.0130641
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804718"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804973"
        },
        {
          "type": "symbol",
          "id": "804974"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804352"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805039",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "tex": "$t$",
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": true,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.583193,
          "top": 0.186461,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805040",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.236975,
          "top": 0.548694,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804736"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805041",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>P</mi>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{\\htmlClass{match-highlight}{{P}}}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{\\htmlClass{match-highlight}{{P}}}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "tex": "$\\mathcal{P}$",
      "nicknames": [
        "attention head",
        "oracle for predicates and syntax",
        "oracle"
      ],
      "mathml": "<mi mathvariant=\"script\">P</mi>",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.339496,
          "top": 0.846793,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804746"
      },
      "parent": {
        "type": "symbol",
        "id": "805057"
      },
      "sentence": {
        "type": "sentence",
        "id": "804377"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804369"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805042",
    "type": "symbol",
    "attributes": {
      "tex": "$s_{ft}$",
      "mathml": "<msub><mi>s</mi><mi>ft</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "So, the role label scores ${\\htmlClass{match-highlight}{s_{ft}}}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s_{ft}}} = (s_f^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s_{ft}}})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s_{ft}}}$ and the transition probabilities given by the training data."
      ],
      "defining_formulas": [
        "                    \n{\\htmlClass{match-highlight}{s_{ft}}} = (s_f^{pred})^T U s_t^{role}\n"
      ],
      "is_definition": false,
      "nicknames": [
        "role label scores for the token at index $t$ with respect to the predicate at index $f$",
        "unary scores"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.321008,
          "top": 0.690024,
          "width": 0.0184874,
          "height": 0.00950119
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804743"
      },
      "parent": {
        "type": "symbol",
        "id": "94943"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805031"
        },
        {
          "type": "symbol",
          "id": "805032"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804740"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "definition_sentences": []
    }
  },
  {
    "id": "805043",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "tex": "$s_{ft}$",
      "mathml": "<msub><mi>s</mi><mi>ft</mi></msub>",
      "snippets": [
        "So, the role label scores ${\\htmlClass{match-highlight}{s_{ft}}}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s_{ft}}} = (s_f^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s_{ft}}})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s_{ft}}}$ and the transition probabilities given by the training data."
      ],
      "defining_formulas": [
        "                    \n{\\htmlClass{match-highlight}{s_{ft}}} = (s_f^{pred})^T U s_t^{role}\n"
      ],
      "is_definition": false,
      "nicknames": [
        "role label scores for the token at index $t$ with respect to the predicate at index $f$",
        "unary scores"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.413445,
          "top": 0.534442,
          "width": 0.0184874,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804735"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805018"
        },
        {
          "type": "symbol",
          "id": "805019"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804740"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805044",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\n{\\htmlClass{match-highlight}{P}}(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "This attention head now becomes an oracle for syntax, denoted $\\mathcal{\\htmlClass{match-highlight}{{P}}}$, providing a dependency parse to downstream layers.",
        "We compute locally-normalized probabilities using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{V}$ and $\\mathcal{\\htmlClass{match-highlight}{{P}}}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{\\htmlClass{match-highlight}{{P}}}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log {\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log {\\htmlClass{match-highlight}{P}}(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log {\\htmlClass{match-highlight}{P}}(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log {\\htmlClass{match-highlight}{P}}(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "tex": "$\\mathcal{P}$",
      "nicknames": [
        "attention head",
        "oracle for predicates and syntax",
        "oracle"
      ],
      "mathml": "<mi mathvariant=\"script\">P</mi>",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.193277,
          "top": 0.687648,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804743"
      },
      "parent": {
        "type": "symbol",
        "id": "805027"
      },
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804369"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805045",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\n{\\htmlClass{match-highlight}{P}}(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "This attention head now becomes an oracle for syntax, denoted $\\mathcal{\\htmlClass{match-highlight}{{P}}}$, providing a dependency parse to downstream layers.",
        "We compute locally-normalized probabilities using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{V}$ and $\\mathcal{\\htmlClass{match-highlight}{{P}}}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{\\htmlClass{match-highlight}{{P}}}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log {\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log {\\htmlClass{match-highlight}{P}}(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log {\\htmlClass{match-highlight}{P}}(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log {\\htmlClass{match-highlight}{P}}(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "tex": "$\\mathcal{P}$",
      "nicknames": [
        "attention head",
        "oracle for predicates and syntax",
        "oracle"
      ],
      "mathml": "<mi mathvariant=\"script\">P</mi>",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.173109,
          "top": 0.846793,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804745"
      },
      "sentence": {
        "type": "sentence",
        "id": "804377"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804369"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805046",
    "type": "symbol",
    "attributes": {
      "defining_formulas": [
        "                    \n{\\htmlClass{match-highlight}{s_{ft}}} = (s_f^{pred})^T U s_t^{role}\n"
      ],
      "is_definition": true,
      "tex": "$s_{ft}$",
      "mathml": "<msub><mi>s</mi><mi>ft</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "So, the role label scores ${\\htmlClass{match-highlight}{s_{ft}}}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s_{ft}}} = (s_f^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s_{ft}}})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s_{ft}}}$ and the transition probabilities given by the training data."
      ],
      "nicknames": [
        "role label scores for the token at index $t$ with respect to the predicate at index $f$",
        "unary scores"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.226891,
          "top": 0.5962,
          "width": 0.0184874,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804740"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805022"
        },
        {
          "type": "symbol",
          "id": "805023"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804740"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805047",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "tex": "$P$",
      "mathml": "<mi>P</mi>",
      "mathml_near_matches": [
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\n{\\htmlClass{match-highlight}{P}}(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "This attention head now becomes an oracle for syntax, denoted $\\mathcal{\\htmlClass{match-highlight}{{P}}}$, providing a dependency parse to downstream layers.",
        "We compute locally-normalized probabilities using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{V}$ and $\\mathcal{\\htmlClass{match-highlight}{{P}}}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{\\htmlClass{match-highlight}{{P}}}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log {\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log {\\htmlClass{match-highlight}{P}}(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log {\\htmlClass{match-highlight}{P}}(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log {\\htmlClass{match-highlight}{P}}(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "nicknames": [
        "softmax function"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.687648,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804743"
      },
      "parent": {
        "type": "symbol",
        "id": "805027"
      },
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805048",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<mi>V</mi>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{\\htmlClass{match-highlight}{{V}}}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{\\htmlClass{match-highlight}{{V}}}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "tex": "$\\mathcal{V}$",
      "mathml": "<mi mathvariant=\"script\">V</mi>",
      "nicknames": [
        "oracle for predicates and syntax"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.203361,
          "top": 0.86342,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804748"
      },
      "parent": {
        "type": "symbol",
        "id": "805056"
      },
      "sentence": {
        "type": "sentence",
        "id": "804377"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804369"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805049",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V}}_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V}}_h^{(j)}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations ${\\htmlClass{match-highlight}{V}}_{parse}$ as in the other attention heads.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ and $\\mathcal{P}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{\\htmlClass{match-highlight}{{V}}}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$."
      ],
      "mathml_near_matches": [
        "<mi>V</mi>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "is_definition": false,
      "tex": "$\\mathcal{V}$",
      "mathml": "<mi mathvariant=\"script\">V</mi>",
      "nicknames": [
        "oracle for predicates and syntax"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.415126,
          "top": 0.846793,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804747"
      },
      "sentence": {
        "type": "sentence",
        "id": "804377"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804369"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805050",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>V</mi>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by ${\\htmlClass{match-highlight}{V}}_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}{\\htmlClass{match-highlight}{V}}_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in ${\\htmlClass{match-highlight}{V}}_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, ${\\htmlClass{match-highlight}{V}}_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations ${\\htmlClass{match-highlight}{V}}_{parse}$ as in the other attention heads.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ and $\\mathcal{P}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{\\htmlClass{match-highlight}{{V}}}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{\\htmlClass{match-highlight}{{V}}}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$."
      ],
      "is_definition": false,
      "tex": "$\\mathcal{V}$",
      "mathml": "<mi mathvariant=\"script\">V</mi>",
      "nicknames": [
        "oracle for predicates and syntax"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.216807,
          "top": 0.687648,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804743"
      },
      "parent": {
        "type": "symbol",
        "id": "805027"
      },
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804369"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805051",
    "type": "symbol",
    "attributes": {
      "tex": "$U$",
      "mathml": "<mi>U</mi>",
      "mathml_near_matches": [
        "<mi>U</mi>",
        "<msub><mi>U</mi><mrow><mi>hea</mi><mi>s</mi></mrow></msub>"
      ],
      "snippets": [
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator ${\\htmlClass{match-highlight}{U}}_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} {\\htmlClass{match-highlight}{U}}_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We then provide these representations to a bilinear transformation ${\\htmlClass{match-highlight}{U}}$ for scoring.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T {\\htmlClass{match-highlight}{U}} s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch."
      ],
      "is_definition": false,
      "nicknames": [
        "bilinear transformation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.336134,
          "top": 0.593824,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804740"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804371"
        },
        {
          "type": "sentence",
          "id": "804372"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804371"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805052",
    "type": "symbol",
    "attributes": {
      "tex": "$U$",
      "mathml": "<mi>U</mi>",
      "mathml_near_matches": [
        "<mi>U</mi>",
        "<msub><mi>U</mi><mrow><mi>hea</mi><mi>s</mi></mrow></msub>"
      ],
      "snippets": [
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator ${\\htmlClass{match-highlight}{U}}_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} {\\htmlClass{match-highlight}{U}}_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We then provide these representations to a bilinear transformation ${\\htmlClass{match-highlight}{U}}$ for scoring.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T {\\htmlClass{match-highlight}{U}} s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch."
      ],
      "is_definition": false,
      "nicknames": [
        "bilinear transformation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.122689,
          "top": 0.532067,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804734"
      },
      "sentence": {
        "type": "sentence",
        "id": "804371"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804371"
        },
        {
          "type": "sentence",
          "id": "804372"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804371"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805053",
    "type": "symbol",
    "attributes": {
      "tex": "$f$",
      "mathml": "<mi>f</mi>",
      "mathml_near_matches": [
        "<mi>f</mi>"
      ],
      "snippets": [
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index ${\\htmlClass{match-highlight}{f}}$ (i.e. token $t$ and frame ${\\htmlClass{match-highlight}{f}}$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_{\\htmlClass{match-highlight}{f}}^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame ${\\htmlClass{match-highlight}{f}}$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{{\\htmlClass{match-highlight}{f}}=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{t=1}^T\\Big[ \\sum_{{\\htmlClass{match-highlight}{f}}=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "index",
        "frame",
        "frame"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.289076,
          "top": 0.598575,
          "width": 0.00504202,
          "height": 0.00950119
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804740"
      },
      "parent": {
        "type": "symbol",
        "id": "805020"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805054",
    "type": "symbol",
    "attributes": {
      "tex": "$f$",
      "mathml": "<mi>f</mi>",
      "mathml_near_matches": [
        "<mi>f</mi>"
      ],
      "snippets": [
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index ${\\htmlClass{match-highlight}{f}}$ (i.e. token $t$ and frame ${\\htmlClass{match-highlight}{f}}$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_{\\htmlClass{match-highlight}{f}}^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame ${\\htmlClass{match-highlight}{f}}$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{{\\htmlClass{match-highlight}{f}}=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{t=1}^T\\Big[ \\sum_{{\\htmlClass{match-highlight}{f}}=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "index",
        "frame",
        "frame"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.168067,
          "top": 0.562945,
          "width": 0.00840336,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804737"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805055",
    "type": "symbol",
    "attributes": {
      "tex": "$f$",
      "mathml": "<mi>f</mi>",
      "mathml_near_matches": [
        "<mi>f</mi>"
      ],
      "snippets": [
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index ${\\htmlClass{match-highlight}{f}}$ (i.e. token $t$ and frame ${\\htmlClass{match-highlight}{f}}$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_{\\htmlClass{match-highlight}{f}}^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame ${\\htmlClass{match-highlight}{f}}$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{{\\htmlClass{match-highlight}{f}}=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{t=1}^T\\Big[ \\sum_{{\\htmlClass{match-highlight}{f}}=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "index",
        "frame",
        "frame"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.352941,
          "top": 0.562945,
          "width": 0.00840336,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804739"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805056",
    "type": "symbol",
    "attributes": {
      "tex": "${V}_G$",
      "mathml": "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<mi>V</mi>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{\\htmlClass{match-highlight}{{V}_G}}$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{\\htmlClass{match-highlight}{{V}_G}}, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "nicknames": [
        "gold predicates"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.203361,
          "top": 0.86342,
          "width": 0.0201681,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804748"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805048"
        },
        {
          "type": "symbol",
          "id": "805437"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804377"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805057",
    "type": "symbol",
    "attributes": {
      "tex": "${P}_G$",
      "mathml": "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{\\htmlClass{match-highlight}{{P}_G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}_G}}, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{\\htmlClass{match-highlight}{{P}_G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "nicknames": [
        "gold parse"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.339496,
          "top": 0.846793,
          "width": 0.0235294,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804746"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805041"
        },
        {
          "type": "symbol",
          "id": "805415"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804377"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805058",
    "type": "symbol",
    "attributes": {
      "tex": "$role$",
      "mathml_near_matches": [
        "<mi>role</mi>"
      ],
      "is_definition": false,
      "mathml": "<mi>role</mi>",
      "nicknames": [
        "representations",
        "embeddings"
      ],
      "snippets": [
        "Contextually encoded tokens are projected to distinct \\emph{predicate} and **\\emph{role}** embeddings (\\S\\ref{sec:srl}), and each predicted predicate is scored with the sequence's role representations using a bilinear model.",
        "First, we project each token representation $s_t^{(J)}$ to a predicate-specific representation $s_t^{pred}$ and a role-specific representation $s_t^{{\\htmlClass{match-highlight}{role}}}$.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_t^{{\\htmlClass{match-highlight}{role}}}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{{\\htmlClass{match-highlight}{role}}}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{{\\htmlClass{match-highlight}{role}}}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "The size of $predicate$ and ${\\htmlClass{match-highlight}{role}}$ representations and the representation used for joint part-of-speech/predicate classification is 200."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.152941,
          "top": 0.685273,
          "width": 0.0218487,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804743"
      },
      "parent": {
        "type": "symbol",
        "id": "805028"
      },
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804636"
        },
        {
          "type": "sentence",
          "id": "804312"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804312"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804636"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805059",
    "type": "symbol",
    "attributes": {
      "tex": "$role$",
      "mathml_near_matches": [
        "<mi>role</mi>"
      ],
      "is_definition": false,
      "mathml": "<mi>role</mi>",
      "nicknames": [
        "representations",
        "embeddings"
      ],
      "snippets": [
        "Contextually encoded tokens are projected to distinct \\emph{predicate} and **\\emph{role}** embeddings (\\S\\ref{sec:srl}), and each predicted predicate is scored with the sequence's role representations using a bilinear model.",
        "First, we project each token representation $s_t^{(J)}$ to a predicate-specific representation $s_t^{pred}$ and a role-specific representation $s_t^{{\\htmlClass{match-highlight}{role}}}$.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_t^{{\\htmlClass{match-highlight}{role}}}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{{\\htmlClass{match-highlight}{role}}}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{{\\htmlClass{match-highlight}{role}}}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "The size of $predicate$ and ${\\htmlClass{match-highlight}{role}}$ representations and the representation used for joint part-of-speech/predicate classification is 200."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.357983,
          "top": 0.590261,
          "width": 0.0235294,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804740"
      },
      "parent": {
        "type": "symbol",
        "id": "805021"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804636"
        },
        {
          "type": "sentence",
          "id": "804312"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804312"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804636"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805060",
    "type": "symbol",
    "attributes": {
      "tex": "$role$",
      "mathml_near_matches": [
        "<mi>role</mi>"
      ],
      "is_definition": false,
      "mathml": "<mi>role</mi>",
      "nicknames": [
        "representations",
        "embeddings"
      ],
      "snippets": [
        "Contextually encoded tokens are projected to distinct \\emph{predicate} and **\\emph{role}** embeddings (\\S\\ref{sec:srl}), and each predicted predicate is scored with the sequence's role representations using a bilinear model.",
        "First, we project each token representation $s_t^{(J)}$ to a predicate-specific representation $s_t^{pred}$ and a role-specific representation $s_t^{{\\htmlClass{match-highlight}{role}}}$.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_t^{{\\htmlClass{match-highlight}{role}}}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{{\\htmlClass{match-highlight}{role}}}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{{\\htmlClass{match-highlight}{role}}}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "The size of $predicate$ and ${\\htmlClass{match-highlight}{role}}$ representations and the representation used for joint part-of-speech/predicate classification is 200."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.332773,
          "top": 0.497625,
          "width": 0.0235294,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804733"
      },
      "parent": {
        "type": "symbol",
        "id": "805016"
      },
      "sentence": {
        "type": "sentence",
        "id": "804370"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804636"
        },
        {
          "type": "sentence",
          "id": "804312"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804312"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804636"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805061",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>F</mi>"
      ],
      "mathml": "<mi>F</mi>",
      "tex": "$F$",
      "snippets": [
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^{\\htmlClass{match-highlight}{F}} \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.631933,
          "top": 0.155582,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805062",
    "type": "symbol",
    "attributes": {
      "tex": "$P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})$",
      "mathml": "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
      "mathml_near_matches": [
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log {\\htmlClass{match-highlight}{P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})}} \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.680672,
          "top": 0.166271,
          "width": 0.156303,
          "height": 0.0166271
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805097"
        },
        {
          "type": "symbol",
          "id": "805067"
        },
        {
          "type": "symbol",
          "id": "805104"
        },
        {
          "type": "symbol",
          "id": "805093"
        },
        {
          "type": "symbol",
          "id": "805603"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805063",
    "type": "symbol",
    "attributes": {
      "tex": "$P(y_t^{prp}\\mid \\mathcal{X})$",
      "mathml": "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
      "mathml_near_matches": [
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "We compute locally-normalized probabilities using the softmax function: ${\\htmlClass{match-highlight}{P(y_t^{prp} \\mid \\mathcal{X})}} \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log {\\htmlClass{match-highlight}{P(y_t^{prp}\\mid \\mathcal{X})}} \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.670588,
          "top": 0.203088,
          "width": 0.087395,
          "height": 0.0130641
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805094"
        },
        {
          "type": "symbol",
          "id": "805100"
        },
        {
          "type": "symbol",
          "id": "805601"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805064",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "tex": "$P(\\mathrm{head}(t)$",
      "mathml": "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
      "snippets": [
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log {\\htmlClass{match-highlight}{P(\\mathrm{head}(t)}}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.692437,
          "top": 0.22209,
          "width": 0.0756303,
          "height": 0.0130641
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805098"
        },
        {
          "type": "symbol",
          "id": "805069"
        },
        {
          "type": "symbol",
          "id": "805089"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805065",
    "type": "symbol",
    "attributes": {
      "tex": "$\\lambda_2$",
      "mathml": "<msub><mi>λ</mi><mn>2</mn></msub>",
      "mathml_near_matches": [
        "<msub><mi>λ</mi><mn>2</mn></msub>",
        "<msub><mi>λ</mi><mn>1</mn></msub>",
        "<mi>λ</mi>"
      ],
      "snippets": [
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ {\\htmlClass{match-highlight}{\\lambda_2}} \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and ${\\htmlClass{match-highlight}{\\lambda_2}}$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.643698,
          "top": 0.248219,
          "width": 0.0168067,
          "height": 0.0106888
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805070"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805066",
    "type": "symbol",
    "attributes": {
      "tex": "$P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})$",
      "mathml": "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
      "mathml_near_matches": [
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>"
      ],
      "snippets": [
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log {\\htmlClass{match-highlight}{P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})}} \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.692437,
          "top": 0.244656,
          "width": 0.121008,
          "height": 0.0154394
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805099"
        },
        {
          "type": "symbol",
          "id": "805092"
        },
        {
          "type": "symbol",
          "id": "805105"
        },
        {
          "type": "symbol",
          "id": "805602"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805067",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>"
      ],
      "snippets": [
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P({\\htmlClass{match-highlight}{y_{ft}^{role}}}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P({\\htmlClass{match-highlight}{y_{ft}^{role}}}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "tex": "$y_{ft}^{role}$",
      "mathml": "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>",
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.70084,
          "top": 0.166271,
          "width": 0.0319328,
          "height": 0.0166271
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805062"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805071"
        },
        {
          "type": "symbol",
          "id": "805072"
        },
        {
          "type": "symbol",
          "id": "805107"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805068",
    "type": "symbol",
    "attributes": {
      "tex": "$\\lambda$",
      "mathml": "<mi>λ</mi>",
      "mathml_near_matches": [
        "<mi>λ</mi>",
        "<msub><mi>λ</mi><mn>1</mn></msub>",
        "<msub><mi>λ</mi><mn>2</mn></msub>"
      ],
      "snippets": [
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ {\\htmlClass{match-highlight}{\\lambda}}_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ {\\htmlClass{match-highlight}{\\lambda}}_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere ${\\htmlClass{match-highlight}{\\lambda}}_1$ and ${\\htmlClass{match-highlight}{\\lambda}}_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.643698,
          "top": 0.223278,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805102"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805069",
    "type": "symbol",
    "attributes": {
      "tex": "$\\mathrm{head}$",
      "mathml": "<mi>head</mi>",
      "mathml_near_matches": [
        "<mi>head</mi>"
      ],
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q={\\htmlClass{match-highlight}{\\mathrm{head}}}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P({\\htmlClass{match-highlight}{\\mathrm{head}}}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.714286,
          "top": 0.22209,
          "width": 0.0336134,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805064"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805070",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "tex": "$\\lambda$",
      "mathml": "<mi>λ</mi>",
      "mathml_near_matches": [
        "<mi>λ</mi>",
        "<msub><mi>λ</mi><mn>1</mn></msub>",
        "<msub><mi>λ</mi><mn>2</mn></msub>"
      ],
      "snippets": [
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ {\\htmlClass{match-highlight}{\\lambda}}_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ {\\htmlClass{match-highlight}{\\lambda}}_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere ${\\htmlClass{match-highlight}{\\lambda}}_1$ and ${\\htmlClass{match-highlight}{\\lambda}}_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.643698,
          "top": 0.248219,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805065"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805071",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>",
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>"
      ],
      "tex": "$y$",
      "mathml": "<mi>y</mi>",
      "snippets": [
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels ${\\htmlClass{match-highlight}{y}}_t^{dep}$ given by the softmax function.",
        "We compute locally-normalized probabilities using the softmax function: $P({\\htmlClass{match-highlight}{y}}_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where ${\\htmlClass{match-highlight}{y}}_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P({\\htmlClass{match-highlight}{y}}_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P({\\htmlClass{match-highlight}{y}}_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.70084,
          "top": 0.171021,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805067"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805072",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>ft</mi>"
      ],
      "snippets": [
        "So, the role label scores $s_{{\\htmlClass{match-highlight}{ft}}}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{{\\htmlClass{match-highlight}{ft}}} = (s_f^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{{\\htmlClass{match-highlight}{ft}}}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{{\\htmlClass{match-highlight}{ft}}})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores $s_{{\\htmlClass{match-highlight}{ft}}}$ and the transition probabilities given by the training data.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{{\\htmlClass{match-highlight}{ft}}}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "tex": "$ft$",
      "mathml": "<mi>ft</mi>",
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.710924,
          "top": 0.173397,
          "width": 0.010084,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805067"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805073",
    "type": "symbol",
    "attributes": {
      "tex": "$y$",
      "mathml": "<mi>y</mi>",
      "mathml_near_matches": [
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>"
      ],
      "snippets": [
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels ${\\htmlClass{match-highlight}{y}}_t^{dep}$ given by the softmax function.",
        "We compute locally-normalized probabilities using the softmax function: $P({\\htmlClass{match-highlight}{y}}_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where ${\\htmlClass{match-highlight}{y}}_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P({\\htmlClass{match-highlight}{y}}_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P({\\htmlClass{match-highlight}{y}}_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.690756,
          "top": 0.206651,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805100"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805074",
    "type": "symbol",
    "attributes": {
      "tex": "$prp$",
      "mathml": "<mi>prp</mi>",
      "mathml_near_matches": [
        "<mi>prp</mi>"
      ],
      "snippets": [
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{{\\htmlClass{match-highlight}{prp}}} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{{\\htmlClass{match-highlight}{prp}}}$ is a label in the joint space.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{{\\htmlClass{match-highlight}{prp}}}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.70084,
          "top": 0.203088,
          "width": 0.0201681,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805100"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805075",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>"
      ],
      "mathml": "<mi>y</mi>",
      "snippets": [
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels ${\\htmlClass{match-highlight}{y}}_t^{dep}$ given by the softmax function.",
        "We compute locally-normalized probabilities using the softmax function: $P({\\htmlClass{match-highlight}{y}}_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where ${\\htmlClass{match-highlight}{y}}_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P({\\htmlClass{match-highlight}{y}}_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P({\\htmlClass{match-highlight}{y}}_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P({\\htmlClass{match-highlight}{y}}_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "tex": "$y$",
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.712605,
          "top": 0.250594,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805092"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805076",
    "type": "symbol",
    "attributes": {
      "tex": "$dep$",
      "mathml": "<mi>dep</mi>",
      "mathml_near_matches": [
        "<mi>dep</mi>"
      ],
      "snippets": [
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{{\\htmlClass{match-highlight}{dep}}}$ given by the softmax function.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{{\\htmlClass{match-highlight}{dep}}} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.722689,
          "top": 0.244656,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805092"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805077",
    "type": "symbol",
    "attributes": {
      "tex": "$\\lambda$",
      "mathml": "<mi>λ</mi>",
      "mathml_near_matches": [
        "<mi>λ</mi>",
        "<msub><mi>λ</mi><mn>1</mn></msub>",
        "<msub><mi>λ</mi><mn>2</mn></msub>"
      ],
      "snippets": [
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ {\\htmlClass{match-highlight}{\\lambda}}_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ {\\htmlClass{match-highlight}{\\lambda}}_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere ${\\htmlClass{match-highlight}{\\lambda}}_1$ and ${\\htmlClass{match-highlight}{\\lambda}}_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.568067,
          "top": 0.280285,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804751"
      },
      "parent": {
        "type": "symbol",
        "id": "805103"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805078",
    "type": "symbol",
    "attributes": {
      "mathml": "<msub><mi>λ</mi><mn>2</mn></msub>",
      "tex": "$\\lambda_2$",
      "mathml_near_matches": [
        "<msub><mi>λ</mi><mn>2</mn></msub>",
        "<msub><mi>λ</mi><mn>1</mn></msub>",
        "<mi>λ</mi>"
      ],
      "snippets": [
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ {\\htmlClass{match-highlight}{\\lambda_2}} \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and ${\\htmlClass{match-highlight}{\\lambda_2}}$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.623529,
          "top": 0.280285,
          "width": 0.0151261,
          "height": 0.0106888
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804752"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805079"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805079",
    "type": "symbol",
    "attributes": {
      "tex": "$\\lambda$",
      "mathml": "<mi>λ</mi>",
      "mathml_near_matches": [
        "<mi>λ</mi>",
        "<msub><mi>λ</mi><mn>1</mn></msub>",
        "<msub><mi>λ</mi><mn>2</mn></msub>"
      ],
      "snippets": [
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ {\\htmlClass{match-highlight}{\\lambda}}_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ {\\htmlClass{match-highlight}{\\lambda}}_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere ${\\htmlClass{match-highlight}{\\lambda}}_1$ and ${\\htmlClass{match-highlight}{\\lambda}}_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.623529,
          "top": 0.280285,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804752"
      },
      "parent": {
        "type": "symbol",
        "id": "805078"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805080",
    "type": "symbol",
    "attributes": {
      "tex": "${E}$",
      "mathml": "<mi>E</mi>",
      "mathml_near_matches": [
        "<mi>E</mi>"
      ],
      "snippets": [
        "3}{*}{WSJ} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 96.48 & 94.40",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 97.80 & 96.28 & 93.65",
        "3}{*}{Brown} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 92.56 & 88.52",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 95.77 & 93.36 & 88.75",
        "3}{*}{CoNLL-12} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 94.99 & 92.59",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 98.11 & 94.84 & 92.23",
        "Subscript $G$ denotes GloVe and ${\\htmlClass{match-highlight}{E}}$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo (${\\htmlClass{match-highlight}{E}}$) embeddings."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.67563,
          "top": 0.10095,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804753"
      },
      "sentence": {
        "type": "sentence",
        "id": "804498"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804498"
        },
        {
          "type": "sentence",
          "id": "804500"
        },
        {
          "type": "sentence",
          "id": "804501"
        },
        {
          "type": "sentence",
          "id": "804503"
        },
        {
          "type": "sentence",
          "id": "804504"
        },
        {
          "type": "sentence",
          "id": "804506"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805081",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "snippets": [
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe (${\\htmlClass{match-highlight}{G}}$) and ELMo ($E$) embeddings.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_{\\htmlClass{match-highlight}{G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_{\\htmlClass{match-highlight}{G}}$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{V}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.92 & 94.92 & 91.87",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 94.26 & 90.31 & 85.82",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.81 & 93.35 & 90.42",
        "Subscript ${\\htmlClass{match-highlight}{G}}$ denotes GloVe and $E$ ELMo embeddings.",
        "The difference in parse accuracy between LISA$_{\\htmlClass{match-highlight}{G}}$ and D\\&M likely explains the large increase in SRL performance we see from decoding with D\\&M parses in that setting."
      ],
      "tex": "${G}$",
      "mathml": "<mi>G</mi>",
      "mathml_near_matches": [
        "<mi>G</mi>"
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.672269,
          "top": 0.11639,
          "width": 0.010084,
          "height": 0.00712589
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804754"
      },
      "sentence": {
        "type": "sentence",
        "id": "804499"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804499"
        },
        {
          "type": "sentence",
          "id": "804502"
        },
        {
          "type": "sentence",
          "id": "804505"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804512"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805082",
    "type": "symbol",
    "attributes": {
      "tex": "${E}$",
      "mathml": "<mi>E</mi>",
      "mathml_near_matches": [
        "<mi>E</mi>"
      ],
      "snippets": [
        "3}{*}{WSJ} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 96.48 & 94.40",
        "3}{*}{Brown} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 92.56 & 88.52",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 95.77 & 93.36 & 88.75",
        "3}{*}{CoNLL-12} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 94.99 & 92.59",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 97.80 & 96.28 & 93.65",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 98.11 & 94.84 & 92.23",
        "Subscript $G$ denotes GloVe and ${\\htmlClass{match-highlight}{E}}$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo (${\\htmlClass{match-highlight}{E}}$) embeddings."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.67395,
          "top": 0.133017,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804755"
      },
      "sentence": {
        "type": "sentence",
        "id": "804500"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804498"
        },
        {
          "type": "sentence",
          "id": "804500"
        },
        {
          "type": "sentence",
          "id": "804501"
        },
        {
          "type": "sentence",
          "id": "804503"
        },
        {
          "type": "sentence",
          "id": "804504"
        },
        {
          "type": "sentence",
          "id": "804506"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805083",
    "type": "symbol",
    "attributes": {
      "tex": "${E}$",
      "mathml": "<mi>E</mi>",
      "mathml_near_matches": [
        "<mi>E</mi>"
      ],
      "snippets": [
        "3}{*}{WSJ} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 96.48 & 94.40",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 97.80 & 96.28 & 93.65",
        "3}{*}{Brown} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 92.56 & 88.52",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 95.77 & 93.36 & 88.75",
        "3}{*}{CoNLL-12} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 94.99 & 92.59",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 98.11 & 94.84 & 92.23",
        "Subscript $G$ denotes GloVe and ${\\htmlClass{match-highlight}{E}}$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo (${\\htmlClass{match-highlight}{E}}$) embeddings."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.67563,
          "top": 0.149644,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804756"
      },
      "sentence": {
        "type": "sentence",
        "id": "804501"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804498"
        },
        {
          "type": "sentence",
          "id": "804500"
        },
        {
          "type": "sentence",
          "id": "804501"
        },
        {
          "type": "sentence",
          "id": "804503"
        },
        {
          "type": "sentence",
          "id": "804504"
        },
        {
          "type": "sentence",
          "id": "804506"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805084",
    "type": "symbol",
    "attributes": {
      "tex": "${G}$",
      "mathml": "<mi>G</mi>",
      "mathml_near_matches": [
        "<mi>G</mi>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_{\\htmlClass{match-highlight}{G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_{\\htmlClass{match-highlight}{G}}$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{V}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.92 & 94.92 & 91.87",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 94.26 & 90.31 & 85.82",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.81 & 93.35 & 90.42",
        "Subscript ${\\htmlClass{match-highlight}{G}}$ denotes GloVe and $E$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe (${\\htmlClass{match-highlight}{G}}$) and ELMo ($E$) embeddings.",
        "The difference in parse accuracy between LISA$_{\\htmlClass{match-highlight}{G}}$ and D\\&M likely explains the large increase in SRL performance we see from decoding with D\\&M parses in that setting."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.672269,
          "top": 0.165083,
          "width": 0.010084,
          "height": 0.00712589
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804757"
      },
      "sentence": {
        "type": "sentence",
        "id": "804502"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804499"
        },
        {
          "type": "sentence",
          "id": "804502"
        },
        {
          "type": "sentence",
          "id": "804505"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804512"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805085",
    "type": "symbol",
    "attributes": {
      "tex": "${E}$",
      "mathml": "<mi>E</mi>",
      "mathml_near_matches": [
        "<mi>E</mi>"
      ],
      "snippets": [
        "3}{*}{WSJ} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 96.48 & 94.40",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 97.80 & 96.28 & 93.65",
        "3}{*}{Brown} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 92.56 & 88.52",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 95.77 & 93.36 & 88.75",
        "3}{*}{CoNLL-12} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 94.99 & 92.59",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 98.11 & 94.84 & 92.23",
        "Subscript $G$ denotes GloVe and ${\\htmlClass{match-highlight}{E}}$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo (${\\htmlClass{match-highlight}{E}}$) embeddings."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.67395,
          "top": 0.18171,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804758"
      },
      "sentence": {
        "type": "sentence",
        "id": "804503"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804498"
        },
        {
          "type": "sentence",
          "id": "804500"
        },
        {
          "type": "sentence",
          "id": "804501"
        },
        {
          "type": "sentence",
          "id": "804503"
        },
        {
          "type": "sentence",
          "id": "804504"
        },
        {
          "type": "sentence",
          "id": "804506"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805086",
    "type": "symbol",
    "attributes": {
      "tex": "${E}$",
      "mathml": "<mi>E</mi>",
      "mathml_near_matches": [
        "<mi>E</mi>"
      ],
      "snippets": [
        "3}{*}{WSJ} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 96.48 & 94.40",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 97.80 & 96.28 & 93.65",
        "3}{*}{Brown} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 92.56 & 88.52",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 95.77 & 93.36 & 88.75",
        "3}{*}{CoNLL-12} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 94.99 & 92.59",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 98.11 & 94.84 & 92.23",
        "Subscript $G$ denotes GloVe and ${\\htmlClass{match-highlight}{E}}$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo (${\\htmlClass{match-highlight}{E}}$) embeddings."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.67563,
          "top": 0.198337,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804759"
      },
      "sentence": {
        "type": "sentence",
        "id": "804504"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804498"
        },
        {
          "type": "sentence",
          "id": "804500"
        },
        {
          "type": "sentence",
          "id": "804501"
        },
        {
          "type": "sentence",
          "id": "804503"
        },
        {
          "type": "sentence",
          "id": "804504"
        },
        {
          "type": "sentence",
          "id": "804506"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805087",
    "type": "symbol",
    "attributes": {
      "tex": "${G}$",
      "mathml": "<mi>G</mi>",
      "mathml_near_matches": [
        "<mi>G</mi>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_{\\htmlClass{match-highlight}{G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_{\\htmlClass{match-highlight}{G}}$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{V}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.92 & 94.92 & 91.87",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 94.26 & 90.31 & 85.82",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.81 & 93.35 & 90.42",
        "Subscript ${\\htmlClass{match-highlight}{G}}$ denotes GloVe and $E$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe (${\\htmlClass{match-highlight}{G}}$) and ELMo ($E$) embeddings.",
        "The difference in parse accuracy between LISA$_{\\htmlClass{match-highlight}{G}}$ and D\\&M likely explains the large increase in SRL performance we see from decoding with D\\&M parses in that setting."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.672269,
          "top": 0.213777,
          "width": 0.010084,
          "height": 0.00712589
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804760"
      },
      "sentence": {
        "type": "sentence",
        "id": "804505"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804499"
        },
        {
          "type": "sentence",
          "id": "804502"
        },
        {
          "type": "sentence",
          "id": "804505"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804512"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805088",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "tex": "${E}$",
      "mathml": "<mi>E</mi>",
      "mathml_near_matches": [
        "<mi>E</mi>"
      ],
      "snippets": [
        "3}{*}{WSJ} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 96.48 & 94.40",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 97.80 & 96.28 & 93.65",
        "3}{*}{Brown} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 92.56 & 88.52",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 95.77 & 93.36 & 88.75",
        "3}{*}{CoNLL-12} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 94.99 & 92.59",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 98.11 & 94.84 & 92.23",
        "Subscript $G$ denotes GloVe and ${\\htmlClass{match-highlight}{E}}$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo (${\\htmlClass{match-highlight}{E}}$) embeddings."
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.67395,
          "top": 0.230404,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804761"
      },
      "sentence": {
        "type": "sentence",
        "id": "804506"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804498"
        },
        {
          "type": "sentence",
          "id": "804500"
        },
        {
          "type": "sentence",
          "id": "804501"
        },
        {
          "type": "sentence",
          "id": "804503"
        },
        {
          "type": "sentence",
          "id": "804504"
        },
        {
          "type": "sentence",
          "id": "804506"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805089",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.756303,
          "top": 0.223278,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805064"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805090",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.721008,
          "top": 0.254157,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805092"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805091",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "mathml": "<mi>t</mi>",
      "tex": "$t$",
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.69916,
          "top": 0.210214,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805100"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805092",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>"
      ],
      "snippets": [
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels ${\\htmlClass{match-highlight}{y_t^{dep}}}$ given by the softmax function.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P({\\htmlClass{match-highlight}{y_t^{dep}}} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "tex": "$y_t^{dep}$",
      "mathml": "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
      "is_definition": false,
      "nicknames": [
        "dependency labels"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.712605,
          "top": 0.244656,
          "width": 0.0302521,
          "height": 0.0154394
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805066"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805075"
        },
        {
          "type": "symbol",
          "id": "805090"
        },
        {
          "type": "symbol",
          "id": "805076"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804352"
        }
      ],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805093",
    "type": "symbol",
    "attributes": {
      "tex": "${V}_G$",
      "mathml": "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<mi>V</mi>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{\\htmlClass{match-highlight}{{V}_G}}$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{\\htmlClass{match-highlight}{{V}_G}}, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "nicknames": [
        "gold predicates"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.786555,
          "top": 0.168646,
          "width": 0.0184874,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805062"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805101"
        },
        {
          "type": "symbol",
          "id": "805434"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805094",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "tex": "$P$",
      "mathml": "<mi>P</mi>",
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\n{\\htmlClass{match-highlight}{P}}(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "This attention head now becomes an oracle for syntax, denoted $\\mathcal{\\htmlClass{match-highlight}{{P}}}$, providing a dependency parse to downstream layers.",
        "We compute locally-normalized probabilities using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{V}$ and $\\mathcal{\\htmlClass{match-highlight}{{P}}}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{\\htmlClass{match-highlight}{{P}}}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log {\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log {\\htmlClass{match-highlight}{P}}(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log {\\htmlClass{match-highlight}{P}}(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log {\\htmlClass{match-highlight}{P}}(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "nicknames": [
        "softmax function"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.670588,
          "top": 0.204276,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805063"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805095",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{\\htmlClass{match-highlight}{{P}}}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{\\htmlClass{match-highlight}{{P}}}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "tex": "$\\mathcal{P}$",
      "nicknames": [
        "attention head",
        "oracle for predicates and syntax",
        "oracle"
      ],
      "mathml": "<mi mathvariant=\"script\">P</mi>",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.751261,
          "top": 0.168646,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805104"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804369"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805096",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{\\htmlClass{match-highlight}{{P}}}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{\\htmlClass{match-highlight}{{P}}}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "tex": "$\\mathcal{P}$",
      "nicknames": [
        "attention head",
        "oracle for predicates and syntax",
        "oracle"
      ],
      "mathml": "<mi mathvariant=\"script\">P</mi>",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.759664,
          "top": 0.248219,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805105"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804369"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805097",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>"
      ],
      "tex": "$P$",
      "mathml": "<mi>P</mi>",
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\n{\\htmlClass{match-highlight}{P}}(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "This attention head now becomes an oracle for syntax, denoted $\\mathcal{\\htmlClass{match-highlight}{{P}}}$, providing a dependency parse to downstream layers.",
        "We compute locally-normalized probabilities using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{V}$ and $\\mathcal{\\htmlClass{match-highlight}{{P}}}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{\\htmlClass{match-highlight}{{P}}}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log {\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log {\\htmlClass{match-highlight}{P}}(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log {\\htmlClass{match-highlight}{P}}(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log {\\htmlClass{match-highlight}{P}}(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "nicknames": [
        "softmax function"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.680672,
          "top": 0.168646,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805062"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805098",
    "type": "symbol",
    "attributes": {
      "tex": "$P$",
      "mathml": "<mi>P</mi>",
      "mathml_near_matches": [
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\n{\\htmlClass{match-highlight}{P}}(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "This attention head now becomes an oracle for syntax, denoted $\\mathcal{\\htmlClass{match-highlight}{{P}}}$, providing a dependency parse to downstream layers.",
        "We compute locally-normalized probabilities using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{V}$ and $\\mathcal{\\htmlClass{match-highlight}{{P}}}$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{\\htmlClass{match-highlight}{{P}}}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log {\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log {\\htmlClass{match-highlight}{P}}(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log {\\htmlClass{match-highlight}{P}}(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log {\\htmlClass{match-highlight}{P}}(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "nicknames": [
        "softmax function"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.692437,
          "top": 0.223278,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805064"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805099",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "tex": "$P$",
      "mathml": "<mi>P</mi>",
      "snippets": [
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}}},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\n{\\htmlClass{match-highlight}{P}}(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "This attention head now becomes an oracle for syntax, denoted $\\mathcal{\\htmlClass{match-highlight}{{P}}}$, providing a dependency parse to downstream layers.",
        "We compute locally-normalized probabilities using the softmax function: ${\\htmlClass{match-highlight}{P}}(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We score each predicate\n%\\footnote{CoNLL-2012 contains only single-word predicates. In CoNLL-2005, some predicates are multi-word verbs, such as ``sign up.'' In this case, we drop the particle.} \nagainst each token in the sequence using a bilinear operation, producing per-label scores for each token for each predicate, with predicates and syntax determined by oracles $\\mathcal{V}$ and $\\mathcal{\\htmlClass{match-highlight}{{P}}}$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{\\htmlClass{match-highlight}{{P}}}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log {\\htmlClass{match-highlight}{P}}(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log {\\htmlClass{match-highlight}{P}}(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log {\\htmlClass{match-highlight}{P}}(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log {\\htmlClass{match-highlight}{P}}(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "nicknames": [
        "softmax function"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.692437,
          "top": 0.248219,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805066"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804354"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804369"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805100",
    "type": "symbol",
    "attributes": {
      "tex": "$y_t^{prp}$",
      "mathml": "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
      "mathml_near_matches": [
        "<msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup>",
        "<msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup>",
        "<mi>y</mi>",
        "<msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup>"
      ],
      "snippets": [
        "We compute locally-normalized probabilities using the softmax function: $P({\\htmlClass{match-highlight}{y_t^{prp}}} \\mid \\mathcal{X}) \\propto \\exp(r_t)$, where ${\\htmlClass{match-highlight}{y_t^{prp}}}$ is a label in the joint space.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P({\\htmlClass{match-highlight}{y_t^{prp}}}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "definitions": [
        "label in the joint space"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.690756,
          "top": 0.203088,
          "width": 0.0302521,
          "height": 0.0130641
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805063"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805073"
        },
        {
          "type": "symbol",
          "id": "805091"
        },
        {
          "type": "symbol",
          "id": "805074"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804366"
        }
      ],
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805101",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<mi>V</mi>",
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{\\htmlClass{match-highlight}{{V}}}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{\\htmlClass{match-highlight}{{V}}}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "tex": "$\\mathcal{V}$",
      "mathml": "<mi mathvariant=\"script\">V</mi>",
      "nicknames": [
        "oracle for predicates and syntax"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.786555,
          "top": 0.168646,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805093"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804369"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805102",
    "type": "symbol",
    "attributes": {
      "tex": "$\\lambda_1$",
      "mathml": "<msub><mi>λ</mi><mn>1</mn></msub>",
      "mathml_near_matches": [
        "<msub><mi>λ</mi><mn>1</mn></msub>",
        "<msub><mi>λ</mi><mn>2</mn></msub>",
        "<mi>λ</mi>"
      ],
      "snippets": [
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ {\\htmlClass{match-highlight}{\\lambda_1}} \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere ${\\htmlClass{match-highlight}{\\lambda_1}}$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "definitions": [
        "penalty on the syntactic attention loss",
        "penalty on the syntactic attention loss"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.643698,
          "top": 0.223278,
          "width": 0.0151261,
          "height": 0.0106888
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805068"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805103",
    "type": "symbol",
    "attributes": {
      "tex": "$\\lambda_1$",
      "mathml": "<msub><mi>λ</mi><mn>1</mn></msub>",
      "mathml_near_matches": [
        "<msub><mi>λ</mi><mn>1</mn></msub>",
        "<msub><mi>λ</mi><mn>2</mn></msub>",
        "<mi>λ</mi>"
      ],
      "snippets": [
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ {\\htmlClass{match-highlight}{\\lambda_1}} \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere ${\\htmlClass{match-highlight}{\\lambda_1}}$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "definitions": [
        "penalty on the syntactic attention loss",
        "penalty on the syntactic attention loss"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.568067,
          "top": 0.280285,
          "width": 0.0134454,
          "height": 0.0106888
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804751"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805077"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805104",
    "type": "symbol",
    "attributes": {
      "tex": "${P}_G$",
      "mathml": "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{\\htmlClass{match-highlight}{{P}_G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}_G}}, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{\\htmlClass{match-highlight}{{P}_G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "nicknames": [
        "gold parse"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.751261,
          "top": 0.168646,
          "width": 0.0235294,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805062"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805095"
        },
        {
          "type": "symbol",
          "id": "805435"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805105",
    "type": "symbol",
    "attributes": {
      "tex": "${P}_G$",
      "mathml": "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><mi>q</mi><mo>=</mo><mi>head</mi><mo stretchy=\"false\">(</mo><mi>t</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>prp</mi></msubsup><mo>∣</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>ft</mi><mi>role</mi></msubsup><mo>∣</mo><mi>P</mi><mo separator=\"true\">,</mo><mi>V</mi><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>",
        "<mi mathvariant=\"script\">P</mi>",
        "<mrow><mi>P</mi><mo stretchy=\"false\">(</mo><msubsup><mi>y</mi><mi>t</mi><mi>dep</mi></msubsup><mo>∣</mo><msub><mi mathvariant=\"script\">P</mi><mi>G</mi></msub><mo separator=\"true\">,</mo><mi>X</mi><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{\\htmlClass{match-highlight}{{P}_G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{\\htmlClass{match-highlight}{{P}_G}}, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{\\htmlClass{match-highlight}{{P}_G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "nicknames": [
        "gold parse"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.759664,
          "top": 0.248219,
          "width": 0.0218487,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805066"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805096"
        },
        {
          "type": "symbol",
          "id": "805436"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805106",
    "type": "symbol",
    "attributes": {
      "tex": "$E$",
      "mathml": "<mi>E</mi>",
      "mathml_near_matches": [
        "<mi>E</mi>"
      ],
      "snippets": [
        "3}{*}{WSJ} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 96.48 & 94.40",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 97.80 & 96.28 & 93.65",
        "3}{*}{Brown} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 92.56 & 88.52",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 95.77 & 93.36 & 88.75",
        "3}{*}{CoNLL-12} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 94.99 & 92.59",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 98.11 & 94.84 & 92.23",
        "Subscript $G$ denotes GloVe and ${\\htmlClass{match-highlight}{E}}$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo (${\\htmlClass{match-highlight}{E}}$) embeddings."
      ],
      "is_definition": false,
      "definitions": [
        "ELMo embeddings"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.721008,
          "top": 0.30285,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804763"
      },
      "sentence": {
        "type": "sentence",
        "id": "804509"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804498"
        },
        {
          "type": "sentence",
          "id": "804500"
        },
        {
          "type": "sentence",
          "id": "804501"
        },
        {
          "type": "sentence",
          "id": "804503"
        },
        {
          "type": "sentence",
          "id": "804504"
        },
        {
          "type": "sentence",
          "id": "804506"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804509"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805107",
    "type": "symbol",
    "attributes": {
      "tex": "$role$",
      "mathml_near_matches": [
        "<mi>role</mi>"
      ],
      "is_definition": false,
      "mathml": "<mi>role</mi>",
      "nicknames": [
        "representations",
        "embeddings"
      ],
      "snippets": [
        "Contextually encoded tokens are projected to distinct \\emph{predicate} and **\\emph{role}** embeddings (\\S\\ref{sec:srl}), and each predicted predicate is scored with the sequence's role representations using a bilinear model.",
        "First, we project each token representation $s_t^{(J)}$ to a predicate-specific representation $s_t^{pred}$ and a role-specific representation $s_t^{{\\htmlClass{match-highlight}{role}}}$.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_t^{{\\htmlClass{match-highlight}{role}}}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{{\\htmlClass{match-highlight}{role}}}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{{\\htmlClass{match-highlight}{role}}}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "The size of $predicate$ and ${\\htmlClass{match-highlight}{role}}$ representations and the representation used for joint part-of-speech/predicate classification is 200."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.710924,
          "top": 0.166271,
          "width": 0.0218487,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805067"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804636"
        },
        {
          "type": "sentence",
          "id": "804312"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804312"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804636"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805108",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>Δ</mi>",
      "mathml_near_matches": [
        "<mi>Δ</mi>"
      ],
      "snippets": [
        "llll}\nCoNLL-2005 & Greedy F1 & Viterbi F1 & ${\\htmlClass{match-highlight}{\\Delta}}$ F1",
        "CoNLL-2012 & Greedy F1 & Viterbi F1 & ${\\htmlClass{match-highlight}{\\Delta}}$ F1"
      ],
      "tex": "$\\Delta$",
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.445378,
          "top": 0.0760095,
          "width": 0.0117647,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804769"
      },
      "sentence": {
        "type": "sentence",
        "id": "804566"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804571"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805109",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>Δ</mi>",
      "mathml_near_matches": [
        "<mi>Δ</mi>"
      ],
      "snippets": [
        "llll}\nCoNLL-2005 & Greedy F1 & Viterbi F1 & ${\\htmlClass{match-highlight}{\\Delta}}$ F1",
        "CoNLL-2012 & Greedy F1 & Viterbi F1 & ${\\htmlClass{match-highlight}{\\Delta}}$ F1"
      ],
      "is_definition": false,
      "tex": "$\\Delta$",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.445378,
          "top": 0.160333,
          "width": 0.0117647,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804770"
      },
      "sentence": {
        "type": "sentence",
        "id": "804571"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804571"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805110",
    "type": "symbol",
    "attributes": {
      "tex": "$l$",
      "mathml": "<mi>l</mi>",
      "mathml_near_matches": [
        "<mi>l</mi>"
      ],
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate ${\\htmlClass{match-highlight}{l}}r_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = {\\htmlClass{match-highlight}{l}}r_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.803361,
          "top": 0.350356,
          "width": 0.00504202,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804772"
      },
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805111",
    "type": "symbol",
    "attributes": {
      "tex": "$l$",
      "mathml": "<mi>l</mi>",
      "mathml_near_matches": [
        "<mi>l</mi>"
      ],
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate ${\\htmlClass{match-highlight}{l}}r_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = {\\htmlClass{match-highlight}{l}}r_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.571429,
          "top": 0.412114,
          "width": 0.00504202,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804774"
      },
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805112",
    "type": "symbol",
    "attributes": {
      "tex": "$(step^{-0.5},  step\\cdot warm^{-1.5})$",
      "mathml": "<mrow><mi>min</mi><mo>⁡</mo><mo stretchy=\"false\">(</mo><mi>ste</mi><msup><mi>p</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msup><mo separator=\"true\">,</mo><mi>step</mi><mo>⋅</mo><mi>war</mi><msup><mi>m</mi><mrow><mo>−</mo><mn>1.5</mn></mrow></msup><mo stretchy=\"false\">)</mo></mrow>",
      "mathml_near_matches": [
        "<mrow><mi>min</mi><mo>⁡</mo><mo stretchy=\"false\">(</mo><mi>ste</mi><msup><mi>p</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msup><mo separator=\"true\">,</mo><mi>step</mi><mo>⋅</mo><mi>war</mi><msup><mi>m</mi><mrow><mo>−</mo><mn>1.5</mn></mrow></msup><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min{\\htmlClass{match-highlight}{(step^{-0.5},  step\\cdot warm^{-1.5})}}\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.640336,
          "top": 0.413302,
          "width": 0.203361,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804774"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805113"
        },
        {
          "type": "symbol",
          "id": "805138"
        },
        {
          "type": "symbol",
          "id": "805142"
        },
        {
          "type": "symbol",
          "id": "805114"
        },
        {
          "type": "symbol",
          "id": "805115"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805113",
    "type": "symbol",
    "attributes": {
      "tex": "$ste$",
      "mathml": "<mi>ste</mi>",
      "mathml_near_matches": [
        "<mi>ste</mi>"
      ],
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min({\\htmlClass{match-highlight}{ste}}p^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.645378,
          "top": 0.413302,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804774"
      },
      "parent": {
        "type": "symbol",
        "id": "805112"
      },
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805114",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>war</mi>",
      "mathml_near_matches": [
        "<mi>war</mi>"
      ],
      "tex": "$war$",
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(step^{-0.5},  step\\cdot {\\htmlClass{match-highlight}{war}}m^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.761345,
          "top": 0.415677,
          "width": 0.0319328,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804774"
      },
      "parent": {
        "type": "symbol",
        "id": "805112"
      },
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805115",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "tex": "$m$",
      "mathml": "<msup><mi>m</mi><mrow><mo>−</mo><mn>1.5</mn></mrow></msup>",
      "mathml_near_matches": [
        "<msup><mi>m</mi><mrow><mo>−</mo><mn>1.5</mn></mrow></msup>",
        "<mi>m</mi>"
      ],
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(step^{-0.5},  step\\cdot war{\\htmlClass{match-highlight}{m}}^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.793277,
          "top": 0.415677,
          "width": 0.0151261,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804774"
      },
      "parent": {
        "type": "symbol",
        "id": "805112"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805116"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805116",
    "type": "symbol",
    "attributes": {
      "tex": "$m$",
      "mathml": "<mi>m</mi>",
      "mathml_near_matches": [
        "<mi>m</mi>",
        "<msup><mi>m</mi><mrow><mo>−</mo><mn>1.5</mn></mrow></msup>"
      ],
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(step^{-0.5},  step\\cdot war{\\htmlClass{match-highlight}{m}}^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.793277,
          "top": 0.415677,
          "width": 0.0151261,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804774"
      },
      "parent": {
        "type": "symbol",
        "id": "805115"
      },
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805117",
    "type": "symbol",
    "attributes": {
      "mathml": "<msub><mi>β</mi><mn>1</mn></msub>",
      "tex": "$\\beta_1$",
      "mathml_near_matches": [
        "<msub><mi>β</mi><mn>1</mn></msub>",
        "<mi>β</mi>",
        "<msub><mi>β</mi><mn>2</mn></msub>"
      ],
      "snippets": [
        "In all of our experiments we used initial learning rate 0.04, ${\\htmlClass{match-highlight}{\\beta_1}}=0.9$, $\\beta_2=0.98$, $\\epsilon=1\\times10^{-12}$ and dropout rates of 0.1 everywhere."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{\\beta_1}}=0.9"
      ],
      "is_definition": true,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.588235,
          "top": 0.585511,
          "width": 0.0168067,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804776"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805118"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804633"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804776"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804633"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805118",
    "type": "symbol",
    "attributes": {
      "tex": "$\\beta$",
      "mathml": "<mi>β</mi>",
      "mathml_near_matches": [
        "<mi>β</mi>",
        "<msub><mi>β</mi><mn>2</mn></msub>",
        "<msub><mi>β</mi><mn>1</mn></msub>"
      ],
      "snippets": [
        "In all of our experiments we used initial learning rate 0.04, ${\\htmlClass{match-highlight}{\\beta}}_1=0.9$, ${\\htmlClass{match-highlight}{\\beta}}_2=0.98$, $\\epsilon=1\\times10^{-12}$ and dropout rates of 0.1 everywhere."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.588235,
          "top": 0.585511,
          "width": 0.010084,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804776"
      },
      "parent": {
        "type": "symbol",
        "id": "805117"
      },
      "sentence": {
        "type": "sentence",
        "id": "804633"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804633"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805119",
    "type": "symbol",
    "attributes": {
      "tex": "$\\beta_2$",
      "mathml": "<msub><mi>β</mi><mn>2</mn></msub>",
      "mathml_near_matches": [
        "<msub><mi>β</mi><mn>2</mn></msub>",
        "<mi>β</mi>",
        "<msub><mi>β</mi><mn>1</mn></msub>"
      ],
      "snippets": [
        "In all of our experiments we used initial learning rate 0.04, $\\beta_1=0.9$, ${\\htmlClass{match-highlight}{\\beta_2}}=0.98$, $\\epsilon=1\\times10^{-12}$ and dropout rates of 0.1 everywhere."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{\\beta_2}}=0.98"
      ],
      "is_definition": true,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.663866,
          "top": 0.585511,
          "width": 0.0168067,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804777"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805120"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804633"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804777"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804633"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805120",
    "type": "symbol",
    "attributes": {
      "tex": "$\\beta$",
      "mathml": "<mi>β</mi>",
      "mathml_near_matches": [
        "<mi>β</mi>",
        "<msub><mi>β</mi><mn>2</mn></msub>",
        "<msub><mi>β</mi><mn>1</mn></msub>"
      ],
      "snippets": [
        "In all of our experiments we used initial learning rate 0.04, ${\\htmlClass{match-highlight}{\\beta}}_1=0.9$, ${\\htmlClass{match-highlight}{\\beta}}_2=0.98$, $\\epsilon=1\\times10^{-12}$ and dropout rates of 0.1 everywhere."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.663866,
          "top": 0.585511,
          "width": 0.010084,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804777"
      },
      "parent": {
        "type": "symbol",
        "id": "805119"
      },
      "sentence": {
        "type": "sentence",
        "id": "804633"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804633"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805121",
    "type": "symbol",
    "attributes": {
      "tex": "$\\epsilon$",
      "mathml": "<mi>ϵ</mi>",
      "mathml_near_matches": [
        "<mi>ϵ</mi>"
      ],
      "snippets": [
        "In all of our experiments we used initial learning rate 0.04, $\\beta_1=0.9$, $\\beta_2=0.98$, ${\\htmlClass{match-highlight}{\\epsilon}}=1\\times10^{-12}$ and dropout rates of 0.1 everywhere."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{\\epsilon}}=1\\times10^{-12}"
      ],
      "is_definition": true,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.74958,
          "top": 0.589074,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804778"
      },
      "sentence": {
        "type": "sentence",
        "id": "804633"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804778"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804633"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805122",
    "type": "symbol",
    "attributes": {
      "tex": "$Q$",
      "mathml": "<mi>Q</mi>",
      "mathml_near_matches": [
        "<mi>Q</mi>",
        "<msub><mi>Q</mi><mi>parse</mi></msub>",
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply ${\\htmlClass{match-highlight}{Q}}_h^{(j)}$ by $K_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}{\\htmlClass{match-highlight}{Q}}_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, ${\\htmlClass{match-highlight}{Q}}_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and ${\\htmlClass{match-highlight}{Q}}_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}({\\htmlClass{match-highlight}{Q}}_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations ${\\htmlClass{match-highlight}{Q}}_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, ${\\htmlClass{match-highlight}{Q}}_{parse}$ has dimension 500 and $K_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.715966,
          "top": 0.666271,
          "width": 0.0117647,
          "height": 0.0118765
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804779"
      },
      "parent": {
        "type": "symbol",
        "id": "805605"
      },
      "sentence": {
        "type": "sentence",
        "id": "804635"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805123",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.729412,
          "top": 0.674584,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804779"
      },
      "parent": {
        "type": "symbol",
        "id": "805605"
      },
      "sentence": {
        "type": "sentence",
        "id": "804635"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805124",
    "type": "symbol",
    "attributes": {
      "tex": "$K$",
      "mathml": "<mi>K</mi>",
      "mathml_near_matches": [
        "<mi>K</mi>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations ${\\htmlClass{match-highlight}{K}}_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by ${\\htmlClass{match-highlight}{K}}_h^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{{\\htmlClass{match-highlight}{K}}_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow $t$ of $M_h^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted ${\\htmlClass{match-highlight}{K}}_{parse}$, $Q_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between ${\\htmlClass{match-highlight}{K}}_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} {\\htmlClass{match-highlight}{K}}_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and ${\\htmlClass{match-highlight}{K}}_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, $Q_{parse}$ has dimension 500 and ${\\htmlClass{match-highlight}{K}}_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.584874,
          "top": 0.684085,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804780"
      },
      "parent": {
        "type": "symbol",
        "id": "805611"
      },
      "sentence": {
        "type": "sentence",
        "id": "804635"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805125",
    "type": "symbol",
    "attributes": {
      "tex": "$parse$",
      "mathml": "<mi>parse</mi>",
      "mathml_near_matches": [
        "<mi>parse</mi>"
      ],
      "snippets": [
        "Attention weights $A_{{\\htmlClass{match-highlight}{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Let $A_{{\\htmlClass{match-highlight}{parse}}}$ be the parse attention weights, at layer $i$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{{\\htmlClass{match-highlight}{parse}}}$, $Q_{{\\htmlClass{match-highlight}{parse}}}$, $V_{{\\htmlClass{match-highlight}{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{{\\htmlClass{match-highlight}{parse}}}$ and $Q_{{\\htmlClass{match-highlight}{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{{\\htmlClass{match-highlight}{parse}}} = \\mathrm{softmax}(Q_{{\\htmlClass{match-highlight}{parse}}} U_{heads} K_{{\\htmlClass{match-highlight}{parse}}}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{{\\htmlClass{match-highlight}{parse}}}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{{\\htmlClass{match-highlight}{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{{\\htmlClass{match-highlight}{parse}}}[t, q]\n\\end{align}\nusing the attention weights $A_{{\\htmlClass{match-highlight}{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention $A_{{\\htmlClass{match-highlight}{parse}}}$ assigns the highest weight.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{{\\htmlClass{match-highlight}{parse}}}$ and $K_{{\\htmlClass{match-highlight}{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting $A_{{\\htmlClass{match-highlight}{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser.",
        "In the syntactically-informed attention head, $Q_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 500 and $K_{{\\htmlClass{match-highlight}{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.598319,
          "top": 0.690024,
          "width": 0.0336134,
          "height": 0.00593824
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804780"
      },
      "parent": {
        "type": "symbol",
        "id": "805611"
      },
      "sentence": {
        "type": "sentence",
        "id": "804635"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804355"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805126",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.236975,
          "top": 0.130641,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804282"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805127",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.215126,
          "top": 0.489311,
          "width": 0.0352941,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804267"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805128",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.421849,
          "top": 0.631829,
          "width": 0.0352941,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804270"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805129",
    "type": "term",
    "attributes": {
      "snippets": [
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.278992,
          "top": 0.347981,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804265"
      },
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805130",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.638655,
          "top": 0.365796,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804276"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805131",
    "type": "term",
    "attributes": {
      "snippets": [
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.423529,
          "top": 0.446556,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804266"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805132",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.154622,
          "top": 0.305226,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804264"
      },
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805133",
    "type": "term",
    "attributes": {
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.823529,
          "top": 0.720903,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804279"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805134",
    "type": "term",
    "attributes": {
      "snippets": [
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.297479,
          "top": 0.849169,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804274"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805135",
    "type": "term",
    "attributes": {
      "snippets": [
        "Some work has incorporated syntax into neural models for **SRL**.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "name": "SRL",
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.379832,
          "top": 0.617577,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804269"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805136",
    "type": "term",
    "attributes": {
      "snippets": [
        "Some work has incorporated syntax into neural models for **SRL**.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.379832,
          "top": 0.631829,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804270"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805137",
    "type": "term",
    "attributes": {
      "snippets": [
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.368067,
          "top": 0.730404,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804271"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805138",
    "type": "symbol",
    "attributes": {
      "tex": "$p$",
      "mathml": "<msup><mi>p</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msup>",
      "mathml_near_matches": [
        "<msup><mi>p</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msup>",
        "<msub><mi>p</mi><mi>t</mi></msub>",
        "<mi>p</mi>"
      ],
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(ste{\\htmlClass{match-highlight}{p}}^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "syntactically-informed layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.667227,
          "top": 0.415677,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804774"
      },
      "parent": {
        "type": "symbol",
        "id": "805112"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805139"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804293"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805139",
    "type": "symbol",
    "attributes": {
      "tex": "$p$",
      "mathml": "<mi>p</mi>",
      "mathml_near_matches": [
        "<mi>p</mi>",
        "<msub><mi>p</mi><mi>t</mi></msub>",
        "<msup><mi>p</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msup>"
      ],
      "snippets": [
        "In layer ${\\htmlClass{match-highlight}{p}}$ one attention head is trained to attend to parse parents (Figure \\ref{attention-fig}).",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding ${\\htmlClass{match-highlight}{p}}_t$ following previous work \\citep{he2017deep}.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{({\\htmlClass{match-highlight}{p}})}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Specifically, we feed the representation $s_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer ${\\htmlClass{match-highlight}{p}}$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(ste{\\htmlClass{match-highlight}{p}}^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "syntactically-informed layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.667227,
          "top": 0.415677,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804774"
      },
      "parent": {
        "type": "symbol",
        "id": "805138"
      },
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804293"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804293"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805140",
    "type": "symbol",
    "attributes": {
      "tex": "$r$",
      "mathml": "<mi>r</mi>",
      "mathml_near_matches": [
        "<mi>r</mi>",
        "<msub><mi>r</mi><mn>0</mn></msub>",
        "<msub><mi>r</mi><mi>t</mi></msub>"
      ],
      "snippets": [
        "Layer ${\\htmlClass{match-highlight}{r}}$ is input for a joint predicate/POS classifier.",
        "Representations from layer ${\\htmlClass{match-highlight}{r}}$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token SRL predictions with respect to each predicted predicate.",
        "Specifically, we feed the representation $s_t^{({\\htmlClass{match-highlight}{r}})}$ from a layer ${\\htmlClass{match-highlight}{r}}$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores ${\\htmlClass{match-highlight}{r}}_t$ for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{r}}_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $l{\\htmlClass{match-highlight}{r}}_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = l{\\htmlClass{match-highlight}{r}}_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "layer",
        "layer"
      ],
      "definitions": [
        "input for a joint predicate/POS classifier"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.808403,
          "top": 0.353919,
          "width": 0.00840336,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804772"
      },
      "parent": {
        "type": "symbol",
        "id": "805148"
      },
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        }
      ],
      "children": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805141",
    "type": "symbol",
    "attributes": {
      "tex": "$r$",
      "mathml": "<mi>r</mi>",
      "mathml_near_matches": [
        "<mi>r</mi>",
        "<msub><mi>r</mi><mn>0</mn></msub>",
        "<msub><mi>r</mi><mi>t</mi></msub>"
      ],
      "snippets": [
        "Layer ${\\htmlClass{match-highlight}{r}}$ is input for a joint predicate/POS classifier.",
        "Representations from layer ${\\htmlClass{match-highlight}{r}}$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token SRL predictions with respect to each predicted predicate.",
        "Specifically, we feed the representation $s_t^{({\\htmlClass{match-highlight}{r}})}$ from a layer ${\\htmlClass{match-highlight}{r}}$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores ${\\htmlClass{match-highlight}{r}}_t$ for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{r}}_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $l{\\htmlClass{match-highlight}{r}}_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = l{\\htmlClass{match-highlight}{r}}_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "layer",
        "layer"
      ],
      "definitions": [
        "input for a joint predicate/POS classifier"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.576471,
          "top": 0.415677,
          "width": 0.00840336,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804774"
      },
      "parent": {
        "type": "symbol",
        "id": "805149"
      },
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        }
      ],
      "children": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805142",
    "type": "symbol",
    "attributes": {
      "tex": "$step$",
      "mathml": "<mi>step</mi>",
      "mathml_near_matches": [
        "<mi>step</mi>"
      ],
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step ${\\htmlClass{match-highlight}{step}}$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(step^{-0.5},  {\\htmlClass{match-highlight}{step}}\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "current training step"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.715966,
          "top": 0.413302,
          "width": 0.0319328,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804774"
      },
      "parent": {
        "type": "symbol",
        "id": "805112"
      },
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805143",
    "type": "symbol",
    "attributes": {
      "tex": "$step$",
      "mathml": "<mi>step</mi>",
      "mathml_near_matches": [
        "<mi>step</mi>"
      ],
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step ${\\htmlClass{match-highlight}{step}}$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(step^{-0.5},  {\\htmlClass{match-highlight}{step}}\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "current training step"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.670588,
          "top": 0.368171,
          "width": 0.0302521,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804773"
      },
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805144",
    "type": "symbol",
    "attributes": {
      "tex": "$warm$",
      "mathml": "<mi>warm</mi>",
      "mathml_near_matches": [
        "<mi>warm</mi>"
      ],
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first ${\\htmlClass{match-highlight}{warm}}$ training steps, then decays it proportionally to the inverse square root of the step number.",
        "We train with ${\\htmlClass{match-highlight}{warm}}=8000$ warmup steps and clip gradient norms to 1."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{warm}}=8000"
      ],
      "is_definition": false,
      "nicknames": [
        "first number of training steps"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.551261,
          "top": 0.460808,
          "width": 0.0470588,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804775"
      },
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804783"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        },
        {
          "type": "sentence",
          "id": "804637"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805145",
    "type": "symbol",
    "attributes": {
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{warm}}=8000"
      ],
      "is_definition": true,
      "tex": "$warm$",
      "mathml": "<mi>warm</mi>",
      "mathml_near_matches": [
        "<mi>warm</mi>"
      ],
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first ${\\htmlClass{match-highlight}{warm}}$ training steps, then decays it proportionally to the inverse square root of the step number.",
        "We train with ${\\htmlClass{match-highlight}{warm}}=8000$ warmup steps and clip gradient norms to 1."
      ],
      "nicknames": [
        "first number of training steps"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.77479,
          "top": 0.733967,
          "width": 0.0470588,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804783"
      },
      "sentence": {
        "type": "sentence",
        "id": "804637"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804783"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        },
        {
          "type": "sentence",
          "id": "804637"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805146",
    "type": "symbol",
    "attributes": {
      "tex": "$lr$",
      "mathml": "<mi>lr</mi>",
      "mathml_near_matches": [
        "<mi>lr</mi>"
      ],
      "snippets": [
        "We additionally vary the learning rate ${\\htmlClass{match-highlight}{lr}}$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\n{\\htmlClass{match-highlight}{lr}} = lr_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{lr}} = lr_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n"
      ],
      "is_definition": true,
      "nicknames": [
        "learning rate"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.531092,
          "top": 0.412114,
          "width": 0.0151261,
          "height": 0.00950119
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804774"
      },
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804774"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805147",
    "type": "symbol",
    "attributes": {
      "tex": "$lr$",
      "mathml": "<mi>lr</mi>",
      "mathml_near_matches": [
        "<mi>lr</mi>"
      ],
      "snippets": [
        "We additionally vary the learning rate ${\\htmlClass{match-highlight}{lr}}$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\n{\\htmlClass{match-highlight}{lr}} = lr_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{lr}} = lr_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n"
      ],
      "is_definition": false,
      "nicknames": [
        "learning rate"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.868908,
          "top": 0.334917,
          "width": 0.0134454,
          "height": 0.00950119
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804771"
      },
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804774"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805148",
    "type": "symbol",
    "attributes": {
      "tex": "$r_0$",
      "mathml": "<msub><mi>r</mi><mn>0</mn></msub>",
      "mathml_near_matches": [
        "<msub><mi>r</mi><mn>0</mn></msub>",
        "<msub><mi>r</mi><mi>t</mi></msub>",
        "<mi>r</mi>"
      ],
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $l{\\htmlClass{match-highlight}{r_0}}$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = l{\\htmlClass{match-highlight}{r_0}} \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "initial learning rate"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.808403,
          "top": 0.353919,
          "width": 0.0151261,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804772"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805140"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805149",
    "type": "symbol",
    "attributes": {
      "tex": "$r_0$",
      "mathml": "<msub><mi>r</mi><mn>0</mn></msub>",
      "mathml_near_matches": [
        "<msub><mi>r</mi><mn>0</mn></msub>",
        "<msub><mi>r</mi><mi>t</mi></msub>",
        "<mi>r</mi>"
      ],
      "snippets": [
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $l{\\htmlClass{match-highlight}{r_0}}$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = l{\\htmlClass{match-highlight}{r_0}} \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "initial learning rate"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.576471,
          "top": 0.415677,
          "width": 0.0151261,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804774"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805141"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804630"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805150",
    "type": "term",
    "attributes": {
      "snippets": [
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.282353,
          "top": 0.631829,
          "width": 0.0890756,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804270"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805151",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 94.26 & 90.31 & 85.82",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.81 & 93.35 & 90.42",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_{\\htmlClass{match-highlight}{G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_{\\htmlClass{match-highlight}{G}}$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{V}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.92 & 94.92 & 91.87",
        "Subscript ${\\htmlClass{match-highlight}{G}}$ denotes GloVe and $E$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe (${\\htmlClass{match-highlight}{G}}$) and ELMo ($E$) embeddings.",
        "The difference in parse accuracy between LISA$_{\\htmlClass{match-highlight}{G}}$ and D\\&M likely explains the large increase in SRL performance we see from decoding with D\\&M parses in that setting."
      ],
      "tex": "$G$",
      "mathml": "<mi>G</mi>",
      "mathml_near_matches": [
        "<mi>G</mi>"
      ],
      "is_definition": false,
      "definitions": [
        "GloVe embeddings"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.557983,
          "top": 0.539192,
          "width": 0.010084,
          "height": 0.00712589
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804766"
      },
      "sentence": {
        "type": "sentence",
        "id": "804512"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804499"
        },
        {
          "type": "sentence",
          "id": "804502"
        },
        {
          "type": "sentence",
          "id": "804505"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804512"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804509"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805152",
    "type": "symbol",
    "attributes": {
      "tex": "$E$",
      "mathml": "<mi>E</mi>",
      "mathml_near_matches": [
        "<mi>E</mi>"
      ],
      "snippets": [
        "3}{*}{WSJ} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 96.48 & 94.40",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 97.80 & 96.28 & 93.65",
        "3}{*}{Brown} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 92.56 & 88.52",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 95.77 & 93.36 & 88.75",
        "3}{*}{CoNLL-12} & D\\&M$_{\\htmlClass{match-highlight}{{E}}}$ & --- & 94.99 & 92.59",
        "& LISA$_{\\htmlClass{match-highlight}{{E}}}$ & 98.11 & 94.84 & 92.23",
        "Subscript $G$ denotes GloVe and ${\\htmlClass{match-highlight}{E}}$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo (${\\htmlClass{match-highlight}{E}}$) embeddings."
      ],
      "is_definition": false,
      "definitions": [
        "ELMo embeddings"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.307563,
          "top": 0.815915,
          "width": 0.0117647,
          "height": 0.00831354
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804765"
      },
      "sentence": {
        "type": "sentence",
        "id": "804510"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804498"
        },
        {
          "type": "sentence",
          "id": "804500"
        },
        {
          "type": "sentence",
          "id": "804501"
        },
        {
          "type": "sentence",
          "id": "804503"
        },
        {
          "type": "sentence",
          "id": "804504"
        },
        {
          "type": "sentence",
          "id": "804506"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804509"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805153",
    "type": "symbol",
    "attributes": {
      "tex": "$predicate$",
      "mathml_near_matches": [
        "<mi>predicate</mi>"
      ],
      "is_definition": false,
      "mathml": "<mi>predicate</mi>",
      "nicknames": [
        "embeddings",
        "representations"
      ],
      "snippets": [
        "The size of ${\\htmlClass{match-highlight}{predicate}}$ and $role$ representations and the representation used for joint part-of-speech/predicate classification is 200."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.698337,
          "width": 0.0739496,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804781"
      },
      "sentence": {
        "type": "sentence",
        "id": "804636"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804312"
        },
        {
          "type": "sentence",
          "id": "804636"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804636"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805154",
    "type": "symbol",
    "attributes": {
      "tex": "$role$",
      "mathml_near_matches": [
        "<mi>role</mi>"
      ],
      "is_definition": false,
      "mathml": "<mi>role</mi>",
      "nicknames": [
        "representations",
        "embeddings"
      ],
      "snippets": [
        "Contextually encoded tokens are projected to distinct \\emph{predicate} and **\\emph{role}** embeddings (\\S\\ref{sec:srl}), and each predicted predicate is scored with the sequence's role representations using a bilinear model.",
        "First, we project each token representation $s_t^{(J)}$ to a predicate-specific representation $s_t^{pred}$ and a role-specific representation $s_t^{{\\htmlClass{match-highlight}{role}}}$.",
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_t^{{\\htmlClass{match-highlight}{role}}}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{{\\htmlClass{match-highlight}{role}}}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{{\\htmlClass{match-highlight}{role}}}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "The size of $predicate$ and ${\\htmlClass{match-highlight}{role}}$ representations and the representation used for joint part-of-speech/predicate classification is 200."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.631933,
          "top": 0.698337,
          "width": 0.0302521,
          "height": 0.00950119
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804782"
      },
      "sentence": {
        "type": "sentence",
        "id": "804636"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804636"
        },
        {
          "type": "sentence",
          "id": "804312"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804312"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804636"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805155",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+D\\&M** & 83.37 & 83.58 & +0.21",
        "**+D\\&M** & 76.33\t& 79.65 &\t75.62 &\t66.55",
        "**+D\\&M** &85.83 &\t84.51 &\t85.17 && {\\bf 87.13} & 86.67 & {\\bf 86.90} && {\\bf 79.02} & 77.49 & {\\bf 78.25"
      ],
      "name": "+D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.14958,
          "top": 0.293349,
          "width": 0.0521008,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804417"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804417"
        },
        {
          "type": "sentence",
          "id": "804568"
        },
        {
          "type": "sentence",
          "id": "804593"
        }
      ],
      "definition_sentences": []
    }
  },
  {
    "id": "805156",
    "type": "term",
    "attributes": {
      "name": "PoE",
      "definitions": [
        "ensemble model from He et al. (2017)"
      ],
      "definition_texs": [
        "ensemble model from He et al. (2017)"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "\\citet{he2017deep} **PoE** & 81.8 &  81.2 & 81.5 & & 82.0 & 83.4 & 82.7 && 69.7 &  70.5 & 70.1",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf **PoE**}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA})."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.252101,
          "top": 0.112827,
          "width": 0.0285714,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804406"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804406"
        },
        {
          "type": "sentence",
          "id": "804425"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805157",
    "type": "term",
    "attributes": {
      "snippets": [
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97",
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 84.35 & 82.14 & 83.23"
      ],
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.132773,
          "top": 0.261283,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804415"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805158",
    "type": "term",
    "attributes": {
      "snippets": [
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.132773,
          "top": 0.144893,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804408"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805159",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.14958,
          "top": 0.17696,
          "width": 0.0521008,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804410"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805160",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+Gold**} & \\emph{88.22} & \\emph{86.53} & \\emph{87.36",
        "**+Gold**} & \\emph{79.61} & \\emph{78.38} & \\emph{81.41} & \\emph{80.47",
        "**+Gold**} & \\emph{86.57} &\t\\emph{86.81} &\t\\emph{+0.24",
        "**+Gold**} & \\emph{87.57} & \\emph{85.32} & \\emph{86.43",
        "**+Gold**} & \\emph{87.91} & \\emph{85.73} & \\emph{86.81} && --- & --- & --- && --- & --- & ---",
        "**+Gold**} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf **+Gold**}), to provide an upper bound for the benefit that syntax could have for SRL using LISA.",
        "**+Gold**} & \\emph{85.94} &\t\\emph{86.43} &\t\\emph{+0.49",
        "**+Gold**} & \\emph{76.71} & \\emph{80.67} & \\emph{86.03} & \\emph{72.22",
        "**+Gold**} & \\emph{89.11} &\t\\emph{89.38} & \t\\emph{89.25"
      ],
      "name": "+Gold",
      "definitions": [
        "gold syntactic parses"
      ],
      "definition_texs": [
        "gold syntactic parses"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.151261,
          "top": 0.193587,
          "width": 0.0487395,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804411"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804411"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804472"
        },
        {
          "type": "sentence",
          "id": "804479"
        },
        {
          "type": "sentence",
          "id": "804530"
        },
        {
          "type": "sentence",
          "id": "804569"
        },
        {
          "type": "sentence",
          "id": "804574"
        },
        {
          "type": "sentence",
          "id": "804594"
        },
        {
          "type": "sentence",
          "id": "804606"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804430"
        }
      ]
    }
  },
  {
    "id": "805161",
    "type": "term",
    "attributes": {
      "definitions": [
        "gold syntactic parses"
      ],
      "definition_texs": [
        "gold syntactic parses"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+Gold**} & \\emph{87.91} & \\emph{85.73} & \\emph{86.81} && --- & --- & --- && --- & --- & ---",
        "**+Gold**} & \\emph{86.57} &\t\\emph{86.81} &\t\\emph{+0.24",
        "**+Gold**} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf **+Gold**}), to provide an upper bound for the benefit that syntax could have for SRL using LISA.",
        "**+Gold**} & \\emph{87.57} & \\emph{85.32} & \\emph{86.43",
        "**+Gold**} & \\emph{88.22} & \\emph{86.53} & \\emph{87.36",
        "**+Gold**} & \\emph{79.61} & \\emph{78.38} & \\emph{81.41} & \\emph{80.47",
        "**+Gold**} & \\emph{85.94} &\t\\emph{86.43} &\t\\emph{+0.49",
        "**+Gold**} & \\emph{76.71} & \\emph{80.67} & \\emph{86.03} & \\emph{72.22",
        "**+Gold**} & \\emph{89.11} &\t\\emph{89.38} & \t\\emph{89.25"
      ],
      "name": "+Gold",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.151261,
          "top": 0.309976,
          "width": 0.0487395,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804418"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804411"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804472"
        },
        {
          "type": "sentence",
          "id": "804479"
        },
        {
          "type": "sentence",
          "id": "804530"
        },
        {
          "type": "sentence",
          "id": "804569"
        },
        {
          "type": "sentence",
          "id": "804574"
        },
        {
          "type": "sentence",
          "id": "804594"
        },
        {
          "type": "sentence",
          "id": "804606"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804430"
        }
      ]
    }
  },
  {
    "id": "805162",
    "type": "term",
    "attributes": {
      "snippets": [
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.435294,
          "top": 0.622328,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804311"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805163",
    "type": "term",
    "attributes": {
      "snippets": [
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.684034,
          "top": 0.224466,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804319"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805164",
    "type": "term",
    "attributes": {
      "snippets": [
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.151261,
          "top": 0.0950119,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804362"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805165",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.752941,
          "top": 0.688836,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804387"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805166",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.571429,
          "top": 0.865796,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804390"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805167",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.410084,
          "top": 0.111639,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804391"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805168",
    "type": "term",
    "attributes": {
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.236975,
          "top": 0.293349,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804394"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805169",
    "type": "term",
    "attributes": {
      "snippets": [
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.764706,
          "top": 0.402613,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804295"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805170",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.568067,
          "top": 0.539192,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804302"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805171",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.364706,
          "top": 0.882423,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804316"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805172",
    "type": "term",
    "attributes": {
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.677311,
          "top": 0.47981,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804384"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805173",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.783193,
          "top": 0.846793,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804362"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805174",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.160333,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804392"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805175",
    "type": "term",
    "attributes": {
      "name": "SGD",
      "definitions": [
        "stochastic gradient descent"
      ],
      "definition_texs": [
        "stochastic gradient descent"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "We train the model using Nadam \\citep{dozat2016incorporating} **SGD** combined with the learning rate schedule in \\citet{vaswani2017attention}.",
        "We train the model using the Nadam \\citep{dozat2016incorporating} algorithm for adaptive stochastic gradient descent (**SGD**), which combines Adam \\citep{kingma2014adam} **SGD** with Nesterov momentum \\citep{nesterov1983method}.",
        "We train the model using the Nadam \\citep{dozat2016incorporating} algorithm for adaptive stochastic gradient descent (**SGD**), which combines Adam \\citep{kingma2014adam} **SGD** with Nesterov momentum \\citep{nesterov1983method}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.328979,
          "width": 0.0336134,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804379"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804379"
        },
        {
          "type": "sentence",
          "id": "804629"
        },
        {
          "type": "sentence",
          "id": "804629"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804629"
        }
      ]
    }
  },
  {
    "id": "805176",
    "type": "term",
    "attributes": {
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.788235,
          "top": 0.897862,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804309"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805177",
    "type": "term",
    "attributes": {
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.685714,
          "top": 0.865796,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804309"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805178",
    "type": "term",
    "attributes": {
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.65042,
          "top": 0.718527,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804359"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805179",
    "type": "term",
    "attributes": {
      "snippets": [
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.660504,
          "top": 0.830166,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804362"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805180",
    "type": "term",
    "attributes": {
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.423529,
          "top": 0.524941,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804310"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805181",
    "type": "term",
    "attributes": {
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.752941,
          "top": 0.7981,
          "width": 0.0285714,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804361"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805182",
    "type": "term",
    "attributes": {
      "snippets": [
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.122689,
          "top": 0.589074,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804310"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805183",
    "type": "term",
    "attributes": {
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.32605,
          "top": 0.0950119,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804362"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805184",
    "type": "term",
    "attributes": {
      "name": "MTL",
      "definitions": [
        "multi-task learning"
      ],
      "definition_texs": [
        "multi-task learning"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (**MTL**) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint POS and predicate detection objective.",
        "In addition to **MTL**, we regularize our model using dropout \\citep{srivastava2014dropout}.",
        "**MTL** \\citep{caruana1993multitask} is popular in NLP, and others have proposed **MTL** models which incorporate subsets of the tasks we do \\citep{collobert2011natural, zhang2016stack, hashimoto2017joint, peng2017deep, swayamdipta2017}, and we build off work that investigates where and when to combine different tasks to achieve the best results \\citep{sogaard2016deep, bingel2017identifying, alonso2017when}.",
        "**MTL** \\citep{caruana1993multitask} is popular in NLP, and others have proposed **MTL** models which incorporate subsets of the tasks we do \\citep{collobert2011natural, zhang2016stack, hashimoto2017joint, peng2017deep, swayamdipta2017}, and we build off work that investigates where and when to combine different tasks to achieve the best results \\citep{sogaard2016deep, bingel2017identifying, alonso2017when}.",
        "Our approach may be interpreted as an extension of teacher forcing \\citep{williams1989learning} to **MTL**."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.687395,
          "top": 0.862233,
          "width": 0.0352941,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804362"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804380"
        },
        {
          "type": "sentence",
          "id": "804398"
        },
        {
          "type": "sentence",
          "id": "804398"
        },
        {
          "type": "sentence",
          "id": "804401"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804350"
        }
      ]
    }
  },
  {
    "id": "805185",
    "type": "term",
    "attributes": {
      "name": "MTL",
      "definitions": [
        "multi-task learning"
      ],
      "definition_texs": [
        "multi-task learning"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (**MTL**) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint POS and predicate detection objective.",
        "In addition to **MTL**, we regularize our model using dropout \\citep{srivastava2014dropout}.",
        "**MTL** \\citep{caruana1993multitask} is popular in NLP, and others have proposed **MTL** models which incorporate subsets of the tasks we do \\citep{collobert2011natural, zhang2016stack, hashimoto2017joint, peng2017deep, swayamdipta2017}, and we build off work that investigates where and when to combine different tasks to achieve the best results \\citep{sogaard2016deep, bingel2017identifying, alonso2017when}.",
        "**MTL** \\citep{caruana1993multitask} is popular in NLP, and others have proposed **MTL** models which incorporate subsets of the tasks we do \\citep{collobert2011natural, zhang2016stack, hashimoto2017joint, peng2017deep, swayamdipta2017}, and we build off work that investigates where and when to combine different tasks to achieve the best results \\citep{sogaard2016deep, bingel2017identifying, alonso2017when}.",
        "Our approach may be interpreted as an extension of teacher forcing \\citep{williams1989learning} to **MTL**."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.141176,
          "top": 0.490499,
          "width": 0.0352941,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804398"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804380"
        },
        {
          "type": "sentence",
          "id": "804398"
        },
        {
          "type": "sentence",
          "id": "804398"
        },
        {
          "type": "sentence",
          "id": "804401"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804350"
        }
      ]
    }
  },
  {
    "id": "805186",
    "type": "term",
    "attributes": {
      "name": "MTL",
      "definitions": [
        "multi-task learning"
      ],
      "definition_texs": [
        "multi-task learning"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (**MTL**) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint POS and predicate detection objective.",
        "In addition to **MTL**, we regularize our model using dropout \\citep{srivastava2014dropout}.",
        "**MTL** \\citep{caruana1993multitask} is popular in NLP, and others have proposed **MTL** models which incorporate subsets of the tasks we do \\citep{collobert2011natural, zhang2016stack, hashimoto2017joint, peng2017deep, swayamdipta2017}, and we build off work that investigates where and when to combine different tasks to achieve the best results \\citep{sogaard2016deep, bingel2017identifying, alonso2017when}.",
        "**MTL** \\citep{caruana1993multitask} is popular in NLP, and others have proposed **MTL** models which incorporate subsets of the tasks we do \\citep{collobert2011natural, zhang2016stack, hashimoto2017joint, peng2017deep, swayamdipta2017}, and we build off work that investigates where and when to combine different tasks to achieve the best results \\citep{sogaard2016deep, bingel2017identifying, alonso2017when}.",
        "Our approach may be interpreted as an extension of teacher forcing \\citep{williams1989learning} to **MTL**."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.783193,
          "top": 0.344418,
          "width": 0.0352941,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804380"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804380"
        },
        {
          "type": "sentence",
          "id": "804398"
        },
        {
          "type": "sentence",
          "id": "804398"
        },
        {
          "type": "sentence",
          "id": "804401"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804350"
        }
      ]
    }
  },
  {
    "id": "805187",
    "type": "term",
    "attributes": {
      "name": "MTL",
      "definitions": [
        "multi-task learning"
      ],
      "definition_texs": [
        "multi-task learning"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (**MTL**) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint POS and predicate detection objective.",
        "In addition to **MTL**, we regularize our model using dropout \\citep{srivastava2014dropout}.",
        "**MTL** \\citep{caruana1993multitask} is popular in NLP, and others have proposed **MTL** models which incorporate subsets of the tasks we do \\citep{collobert2011natural, zhang2016stack, hashimoto2017joint, peng2017deep, swayamdipta2017}, and we build off work that investigates where and when to combine different tasks to achieve the best results \\citep{sogaard2016deep, bingel2017identifying, alonso2017when}.",
        "**MTL** \\citep{caruana1993multitask} is popular in NLP, and others have proposed **MTL** models which incorporate subsets of the tasks we do \\citep{collobert2011natural, zhang2016stack, hashimoto2017joint, peng2017deep, swayamdipta2017}, and we build off work that investigates where and when to combine different tasks to achieve the best results \\citep{sogaard2016deep, bingel2017identifying, alonso2017when}.",
        "Our approach may be interpreted as an extension of teacher forcing \\citep{williams1989learning} to **MTL**."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.289076,
          "top": 0.507126,
          "width": 0.0352941,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804398"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804380"
        },
        {
          "type": "sentence",
          "id": "804398"
        },
        {
          "type": "sentence",
          "id": "804398"
        },
        {
          "type": "sentence",
          "id": "804401"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804350"
        }
      ]
    }
  },
  {
    "id": "805188",
    "type": "term",
    "attributes": {
      "sources": [
        "human-annotation"
      ],
      "name": "MTL",
      "definitions": [
        "multi-task learning"
      ],
      "definition_texs": [
        "multi-task learning"
      ],
      "snippets": [
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (**MTL**) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint POS and predicate detection objective.",
        "In addition to **MTL**, we regularize our model using dropout \\citep{srivastava2014dropout}.",
        "**MTL** \\citep{caruana1993multitask} is popular in NLP, and others have proposed **MTL** models which incorporate subsets of the tasks we do \\citep{collobert2011natural, zhang2016stack, hashimoto2017joint, peng2017deep, swayamdipta2017}, and we build off work that investigates where and when to combine different tasks to achieve the best results \\citep{sogaard2016deep, bingel2017identifying, alonso2017when}.",
        "**MTL** \\citep{caruana1993multitask} is popular in NLP, and others have proposed **MTL** models which incorporate subsets of the tasks we do \\citep{collobert2011natural, zhang2016stack, hashimoto2017joint, peng2017deep, swayamdipta2017}, and we build off work that investigates where and when to combine different tasks to achieve the best results \\citep{sogaard2016deep, bingel2017identifying, alonso2017when}.",
        "Our approach may be interpreted as an extension of teacher forcing \\citep{williams1989learning} to **MTL**."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.272269,
          "top": 0.882423,
          "width": 0.0352941,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804401"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804380"
        },
        {
          "type": "sentence",
          "id": "804398"
        },
        {
          "type": "sentence",
          "id": "804398"
        },
        {
          "type": "sentence",
          "id": "804401"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804350"
        }
      ]
    }
  },
  {
    "id": "805189",
    "type": "term",
    "attributes": {
      "snippets": [
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.346218,
          "top": 0.552257,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804286"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805190",
    "type": "term",
    "attributes": {
      "snippets": [
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.388235,
          "top": 0.60095,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804287"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805191",
    "type": "term",
    "attributes": {
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.72605,
          "top": 0.138955,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804420"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805192",
    "type": "term",
    "attributes": {
      "snippets": [
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.253782,
          "top": 0.887173,
          "width": 0.0823529,
          "height": 0.00712589
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "805446"
      }
    }
  },
  {
    "id": "805193",
    "type": "term",
    "attributes": {
      "name": "WSJ",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "Wall Street Journal"
      ],
      "definition_texs": [
        "Wall Street Journal"
      ],
      "snippets": [
        "On CoNLL-2005 with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the **WSJ** test set by 2.5 F1 points absolute.",
        "llll}\n**WSJ** Test & P & R & F1",
        "The gap in SRL F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on **WSJ**, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with D\\&M parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both **WSJ** and Brown.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on **WSJ** using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser.",
        "llll}\n**WSJ** Dev & P & R & F1"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.421849,
          "top": 0.648456,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804287"
      },
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "804433"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804600"
        }
      ]
    }
  },
  {
    "id": "805194",
    "type": "term",
    "attributes": {
      "snippets": [
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.388235,
          "top": 0.809976,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "805447"
      }
    }
  },
  {
    "id": "805195",
    "type": "term",
    "attributes": {
      "snippets": [
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.495798,
          "top": 0.337292,
          "width": 0.097479,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "805431"
      }
    }
  },
  {
    "id": "805196",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.680672,
          "top": 0.882423,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804462"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805197",
    "type": "term",
    "attributes": {
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.806723,
          "top": 0.817102,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804461"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805198",
    "type": "term",
    "attributes": {
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.62521,
          "top": 0.833729,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804430"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805199",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.152941,
          "top": 0.56057,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804444"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805200",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.257143,
          "top": 0.801663,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804451"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805201",
    "type": "term",
    "attributes": {
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.152941,
          "top": 0.445368,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804437"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805202",
    "type": "term",
    "attributes": {
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.294118,
          "top": 0.769596,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804451"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805203",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.347899,
          "top": 0.865796,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804452"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805204",
    "type": "term",
    "attributes": {
      "name": "PoE",
      "definitions": [
        "ensemble model from He et al. (2017)"
      ],
      "definition_texs": [
        "ensemble model from He et al. (2017)"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "\\citet{he2017deep} **PoE** & 81.8 &  81.2 & 81.5 & & 82.0 & 83.4 & 82.7 && 69.7 &  70.5 & 70.1",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf **PoE**}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA})."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.630252,
          "top": 0.509501,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804425"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804406"
        },
        {
          "type": "sentence",
          "id": "804425"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805205",
    "type": "term",
    "attributes": {
      "snippets": [
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.761345,
          "top": 0.752969,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804460"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805206",
    "type": "term",
    "attributes": {
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.243468,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804476"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805207",
    "type": "term",
    "attributes": {
      "snippets": [
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.84874,
          "top": 0.541568,
          "width": 0.0218487,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804425"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805208",
    "type": "term",
    "attributes": {
      "snippets": [
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.152941,
          "top": 0.428741,
          "width": 0.0218487,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804436"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805209",
    "type": "term",
    "attributes": {
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.129454,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804469"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805210",
    "type": "term",
    "attributes": {
      "name": "SA",
      "snippets": [
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.152941,
          "top": 0.545131,
          "width": 0.0218487,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804443"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805211",
    "type": "term",
    "attributes": {
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.312605,
          "top": 0.785036,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804451"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805212",
    "type": "term",
    "attributes": {
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.289076,
          "top": 0.73753,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804450"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805213",
    "type": "term",
    "attributes": {
      "snippets": [
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.756303,
          "top": 0.882423,
          "width": 0.0420168,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804462"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805214",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.70084,
          "top": 0.817102,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804461"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805215",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.563025,
          "top": 0.687648,
          "width": 0.0537815,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804428"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805216",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.789916,
          "top": 0.395487,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804454"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805217",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.786555,
          "top": 0.427553,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804454"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805218",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.356303,
          "top": 0.672209,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804464"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805219",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.173109,
          "top": 0.16152,
          "width": 0.0521008,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804471"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805220",
    "type": "term",
    "attributes": {
      "snippets": [
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.784874,
          "top": 0.654394,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804458"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805221",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.169748,
          "top": 0.460808,
          "width": 0.0537815,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804438"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805222",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings.",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.169748,
          "top": 0.577197,
          "width": 0.0537815,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804445"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805223",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.159664,
          "top": 0.801663,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804451"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805224",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.247059,
          "top": 0.865796,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804452"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805225",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+Gold**} & \\emph{89.11} &\t\\emph{89.38} & \t\\emph{89.25",
        "**+Gold**} & \\emph{87.91} & \\emph{85.73} & \\emph{86.81} && --- & --- & --- && --- & --- & ---",
        "**+Gold**} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf **+Gold**}), to provide an upper bound for the benefit that syntax could have for SRL using LISA.",
        "**+Gold**} & \\emph{87.57} & \\emph{85.32} & \\emph{86.43",
        "**+Gold**} & \\emph{88.22} & \\emph{86.53} & \\emph{87.36",
        "**+Gold**} & \\emph{79.61} & \\emph{78.38} & \\emph{81.41} & \\emph{80.47",
        "**+Gold**} & \\emph{86.57} &\t\\emph{86.81} &\t\\emph{+0.24",
        "**+Gold**} & \\emph{85.94} &\t\\emph{86.43} &\t\\emph{+0.49",
        "**+Gold**} & \\emph{76.71} & \\emph{80.67} & \\emph{86.03} & \\emph{72.22"
      ],
      "name": "+Gold",
      "definitions": [
        "gold syntactic parses"
      ],
      "definition_texs": [
        "gold syntactic parses"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.17479,
          "top": 0.178147,
          "width": 0.0470588,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804472"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804411"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804472"
        },
        {
          "type": "sentence",
          "id": "804479"
        },
        {
          "type": "sentence",
          "id": "804530"
        },
        {
          "type": "sentence",
          "id": "804569"
        },
        {
          "type": "sentence",
          "id": "804574"
        },
        {
          "type": "sentence",
          "id": "804594"
        },
        {
          "type": "sentence",
          "id": "804606"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804430"
        }
      ]
    }
  },
  {
    "id": "805226",
    "type": "term",
    "attributes": {
      "name": "+Gold",
      "definitions": [
        "gold syntactic parses"
      ],
      "definition_texs": [
        "gold syntactic parses"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+Gold**} & \\emph{87.91} & \\emph{85.73} & \\emph{86.81} && --- & --- & --- && --- & --- & ---",
        "**+Gold**} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf **+Gold**}), to provide an upper bound for the benefit that syntax could have for SRL using LISA.",
        "**+Gold**} & \\emph{87.57} & \\emph{85.32} & \\emph{86.43",
        "**+Gold**} & \\emph{88.22} & \\emph{86.53} & \\emph{87.36",
        "**+Gold**} & \\emph{79.61} & \\emph{78.38} & \\emph{81.41} & \\emph{80.47",
        "**+Gold**} & \\emph{86.57} &\t\\emph{86.81} &\t\\emph{+0.24",
        "**+Gold**} & \\emph{85.94} &\t\\emph{86.43} &\t\\emph{+0.49",
        "**+Gold**} & \\emph{76.71} & \\emph{80.67} & \\emph{86.03} & \\emph{72.22",
        "**+Gold**} & \\emph{89.11} &\t\\emph{89.38} & \t\\emph{89.25"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.694118,
          "top": 0.801663,
          "width": 0.0487395,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804430"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804411"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804472"
        },
        {
          "type": "sentence",
          "id": "804479"
        },
        {
          "type": "sentence",
          "id": "804530"
        },
        {
          "type": "sentence",
          "id": "804569"
        },
        {
          "type": "sentence",
          "id": "804574"
        },
        {
          "type": "sentence",
          "id": "804594"
        },
        {
          "type": "sentence",
          "id": "804606"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804430"
        }
      ]
    }
  },
  {
    "id": "805227",
    "type": "term",
    "attributes": {
      "snippets": [
        "Some work has incorporated syntax into neural models for **SRL**.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.764706,
          "top": 0.882423,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804432"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805228",
    "type": "term",
    "attributes": {
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.25042,
          "top": 0.769596,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804450"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805229",
    "type": "term",
    "attributes": {
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "snippets": [
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing."
      ],
      "name": "SRL",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.633613,
          "top": 0.622328,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804427"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805230",
    "type": "term",
    "attributes": {
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.85042,
          "top": 0.37886,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804454"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805231",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.542857,
          "top": 0.833729,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804430"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805232",
    "type": "term",
    "attributes": {
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "part-of-speech"
      ],
      "name": "POS",
      "snippets": [
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.176471,
          "top": 0.769596,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804450"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805233",
    "type": "term",
    "attributes": {
      "snippets": [
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.583193,
          "top": 0.849169,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804462"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805234",
    "type": "term",
    "attributes": {
      "snippets": [
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.121008,
          "top": 0.704276,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804449"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805235",
    "type": "term",
    "attributes": {
      "snippets": [
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.388235,
          "top": 0.604513,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804446"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805236",
    "type": "term",
    "attributes": {
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.640336,
          "top": 0.573634,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804426"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805237",
    "type": "term",
    "attributes": {
      "name": "WSJ",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "Wall Street Journal"
      ],
      "definition_texs": [
        "Wall Street Journal"
      ],
      "snippets": [
        "On CoNLL-2005 with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the **WSJ** test set by 2.5 F1 points absolute.",
        "llll}\n**WSJ** Test & P & R & F1",
        "The gap in SRL F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on **WSJ**, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with D\\&M parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both **WSJ** and Brown.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on **WSJ** using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser.",
        "llll}\n**WSJ** Dev & P & R & F1"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.694118,
          "top": 0.686461,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804458"
      },
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "804433"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804600"
        }
      ]
    }
  },
  {
    "id": "805238",
    "type": "term",
    "attributes": {
      "name": "WSJ",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "Wall Street Journal"
      ],
      "definition_texs": [
        "Wall Street Journal"
      ],
      "snippets": [
        "On CoNLL-2005 with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the **WSJ** test set by 2.5 F1 points absolute.",
        "llll}\n**WSJ** Test & P & R & F1",
        "The gap in SRL F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on **WSJ**, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with D\\&M parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both **WSJ** and Brown.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on **WSJ** using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser.",
        "llll}\n**WSJ** Dev & P & R & F1"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.542857,
          "top": 0.475059,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804454"
      },
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "804433"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804600"
        }
      ]
    }
  },
  {
    "id": "805239",
    "type": "term",
    "attributes": {
      "name": "WSJ",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "Wall Street Journal"
      ],
      "definition_texs": [
        "Wall Street Journal"
      ],
      "snippets": [
        "On CoNLL-2005 with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the **WSJ** test set by 2.5 F1 points absolute.",
        "llll}\n**WSJ** Test & P & R & F1",
        "The gap in SRL F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on **WSJ**, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with D\\&M parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both **WSJ** and Brown.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on **WSJ** using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser.",
        "llll}\n**WSJ** Dev & P & R & F1"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.152941,
          "top": 0.377672,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "805433"
      },
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "804433"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804600"
        }
      ]
    }
  },
  {
    "id": "805240",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.132773,
          "top": 0.129454,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804528"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805241",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.752941,
          "top": 0.68171,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804524"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805242",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 83.6 &\t83.74\t& 83.67",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-)."
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.221849,
          "top": 0.205463,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804531"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805243",
    "type": "term",
    "attributes": {
      "snippets": [
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.843697,
          "top": 0.84323,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804535"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805244",
    "type": "term",
    "attributes": {
      "snippets": [
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.231933,
          "top": 0.509501,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804537"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805245",
    "type": "term",
    "attributes": {
      "name": "LAS",
      "definitions": [
        "labeled attachment scores"
      ],
      "definition_texs": [
        "labeled attachment scores"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "llrrr} \t\t\nData & Model & POS & UAS & **LAS**",
        "We first report the labeled and unlabeled attachment scores (**LAS**, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average **LAS**), leading to higher SRL F1 by about 1.5 average F1."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.218487,
          "top": 0.78266,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804510"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804539"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804510"
        }
      ]
    }
  },
  {
    "id": "805246",
    "type": "term",
    "attributes": {
      "name": "UAS",
      "definitions": [
        "unlabeled attachment scores"
      ],
      "definition_texs": [
        "unlabeled attachment scores"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "llrrr} \t\t\nData & Model & POS & **UAS** & LAS",
        "We first report the labeled and unlabeled attachment scores (LAS, **UAS**) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Still, LISA's GloVe **UAS** is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 **UAS** on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser.",
        "Still, LISA's GloVe **UAS** is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 **UAS** on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.30084,
          "top": 0.887173,
          "width": 0.0268908,
          "height": 0.00712589
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "94353"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "94353"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804510"
        }
      ]
    }
  },
  {
    "id": "805247",
    "type": "term",
    "attributes": {
      "name": "UAS",
      "definitions": [
        "unlabeled attachment scores"
      ],
      "definition_texs": [
        "unlabeled attachment scores"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "llrrr} \t\t\nData & Model & POS & **UAS** & LAS",
        "We first report the labeled and unlabeled attachment scores (LAS, **UAS**) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Still, LISA's GloVe **UAS** is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 **UAS** on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser.",
        "Still, LISA's GloVe **UAS** is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 **UAS** on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.262185,
          "top": 0.78266,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804510"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "94353"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804510"
        }
      ]
    }
  },
  {
    "id": "805248",
    "type": "term",
    "attributes": {
      "snippets": [
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.132773,
          "top": 0.112827,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804527"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805249",
    "type": "term",
    "attributes": {
      "snippets": [
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.473872,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804490"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805250",
    "type": "term",
    "attributes": {
      "snippets": [
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.642017,
          "top": 0.61639,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804522"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805251",
    "type": "term",
    "attributes": {
      "snippets": [
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.376485,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804484"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805252",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.14958,
          "top": 0.144893,
          "width": 0.0521008,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804529"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805253",
    "type": "term",
    "attributes": {
      "snippets": [
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.173109,
          "top": 0.275534,
          "width": 0.0521008,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804478"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805254",
    "type": "term",
    "attributes": {
      "snippets": [
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.615126,
          "top": 0.535629,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804512"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805255",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.173109,
          "top": 0.408551,
          "width": 0.0521008,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804486"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805256",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.173109,
          "top": 0.505938,
          "width": 0.0521008,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804492"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805257",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.554622,
          "top": 0.567696,
          "width": 0.0420168,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804512"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805258",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.54958,
          "top": 0.85867,
          "width": 0.0420168,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804535"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805259",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.445378,
          "top": 0.815915,
          "width": 0.0420168,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804511"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805260",
    "type": "term",
    "attributes": {
      "name": "+Gold",
      "definitions": [
        "gold syntactic parses"
      ],
      "definition_texs": [
        "gold syntactic parses"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+Gold**} & \\emph{87.91} & \\emph{85.73} & \\emph{86.81} && --- & --- & --- && --- & --- & ---",
        "**+Gold**} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf **+Gold**}), to provide an upper bound for the benefit that syntax could have for SRL using LISA.",
        "**+Gold**} & \\emph{87.57} & \\emph{85.32} & \\emph{86.43",
        "**+Gold**} & \\emph{88.22} & \\emph{86.53} & \\emph{87.36",
        "**+Gold**} & \\emph{79.61} & \\emph{78.38} & \\emph{81.41} & \\emph{80.47",
        "**+Gold**} & \\emph{86.57} &\t\\emph{86.81} &\t\\emph{+0.24",
        "**+Gold**} & \\emph{85.94} &\t\\emph{86.43} &\t\\emph{+0.49",
        "**+Gold**} & \\emph{76.71} & \\emph{80.67} & \\emph{86.03} & \\emph{72.22",
        "**+Gold**} & \\emph{89.11} &\t\\emph{89.38} & \t\\emph{89.25"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.17479,
          "top": 0.292162,
          "width": 0.0470588,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804479"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804411"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804472"
        },
        {
          "type": "sentence",
          "id": "804479"
        },
        {
          "type": "sentence",
          "id": "804530"
        },
        {
          "type": "sentence",
          "id": "804569"
        },
        {
          "type": "sentence",
          "id": "804574"
        },
        {
          "type": "sentence",
          "id": "804594"
        },
        {
          "type": "sentence",
          "id": "804606"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804430"
        }
      ]
    }
  },
  {
    "id": "805261",
    "type": "term",
    "attributes": {
      "name": "+Gold",
      "definitions": [
        "gold syntactic parses"
      ],
      "definition_texs": [
        "gold syntactic parses"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+Gold**} & \\emph{87.91} & \\emph{85.73} & \\emph{86.81} && --- & --- & --- && --- & --- & ---",
        "**+Gold**} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf **+Gold**}), to provide an upper bound for the benefit that syntax could have for SRL using LISA.",
        "**+Gold**} & \\emph{87.57} & \\emph{85.32} & \\emph{86.43",
        "**+Gold**} & \\emph{88.22} & \\emph{86.53} & \\emph{87.36",
        "**+Gold**} & \\emph{79.61} & \\emph{78.38} & \\emph{81.41} & \\emph{80.47",
        "**+Gold**} & \\emph{86.57} &\t\\emph{86.81} &\t\\emph{+0.24",
        "**+Gold**} & \\emph{85.94} &\t\\emph{86.43} &\t\\emph{+0.49",
        "**+Gold**} & \\emph{76.71} & \\emph{80.67} & \\emph{86.03} & \\emph{72.22",
        "**+Gold**} & \\emph{89.11} &\t\\emph{89.38} & \t\\emph{89.25"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.151261,
          "top": 0.16152,
          "width": 0.0487395,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804530"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804411"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804472"
        },
        {
          "type": "sentence",
          "id": "804479"
        },
        {
          "type": "sentence",
          "id": "804530"
        },
        {
          "type": "sentence",
          "id": "804569"
        },
        {
          "type": "sentence",
          "id": "804574"
        },
        {
          "type": "sentence",
          "id": "804594"
        },
        {
          "type": "sentence",
          "id": "804606"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804430"
        }
      ]
    }
  },
  {
    "id": "805262",
    "type": "term",
    "attributes": {
      "snippets": [
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.579832,
          "top": 0.286223,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804508"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805263",
    "type": "term",
    "attributes": {
      "snippets": [
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.588235,
          "top": 0.552257,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804512"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805264",
    "type": "term",
    "attributes": {
      "snippets": [
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.694118,
          "top": 0.761283,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804524"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805265",
    "type": "term",
    "attributes": {
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "snippets": [
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.823529,
          "top": 0.826603,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804535"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805266",
    "type": "term",
    "attributes": {
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "name": "SRL",
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.247059,
          "top": 0.188836,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804531"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805267",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.636975,
          "top": 0.811164,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804534"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805268",
    "type": "term",
    "attributes": {
      "snippets": [
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.598319,
          "top": 0.270784,
          "width": 0.0285714,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804508"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805269",
    "type": "term",
    "attributes": {
      "definition_texs": [
        "LISA"
      ],
      "sources": [
        "human-annotation"
      ],
      "name": "L",
      "definitions": [
        "LISA"
      ],
      "snippets": [
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (**L**) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (**L**) and D\\&M (D) parses were correct (+) or incorrect (-)."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.27395,
          "top": 0.205463,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804531"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804595"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804531"
        }
      ]
    }
  },
  {
    "id": "805270",
    "type": "term",
    "attributes": {
      "name": "D",
      "definitions": [
        "D&M"
      ],
      "definition_texs": [
        "D&M"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (**D**) parses were completely correct (+) or incorrect (--).",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (**D**) parses were correct (+) or incorrect (-)."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.379832,
          "top": 0.205463,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804531"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804595"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804531"
        }
      ]
    }
  },
  {
    "id": "805271",
    "type": "term",
    "attributes": {
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.747899,
          "top": 0.60095,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804521"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805272",
    "type": "term",
    "attributes": {
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.147899,
          "top": 0.799287,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804510"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805273",
    "type": "term",
    "attributes": {
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.327731,
          "top": 0.188836,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804531"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805274",
    "type": "term",
    "attributes": {
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.563025,
          "top": 0.461995,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804519"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805275",
    "type": "term",
    "attributes": {
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.515966,
          "top": 0.665083,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804523"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805276",
    "type": "term",
    "attributes": {
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.715966,
          "top": 0.0771971,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "805440"
      }
    }
  },
  {
    "id": "805277",
    "type": "term",
    "attributes": {
      "sources": [
        "human-annotation"
      ],
      "name": "WSJ",
      "definitions": [
        "Wall Street Journal"
      ],
      "definition_texs": [
        "Wall Street Journal"
      ],
      "snippets": [
        "On CoNLL-2005 with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the **WSJ** test set by 2.5 F1 points absolute.",
        "llll}\n**WSJ** Test & P & R & F1",
        "The gap in SRL F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on **WSJ**, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with D\\&M parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both **WSJ** and Brown.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on **WSJ** using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser.",
        "llll}\n**WSJ** Dev & P & R & F1"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.371429,
          "top": 0.887173,
          "width": 0.0252101,
          "height": 0.00712589
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "94353"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "804433"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804600"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805278",
    "type": "term",
    "attributes": {
      "name": "UAS",
      "definitions": [
        "unlabeled attachment scores"
      ],
      "definition_texs": [
        "unlabeled attachment scores"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "llrrr} \t\t\nData & Model & POS & **UAS** & LAS",
        "We first report the labeled and unlabeled attachment scores (LAS, **UAS**) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Still, LISA's GloVe **UAS** is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 **UAS** on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser.",
        "Still, LISA's GloVe **UAS** is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 **UAS** on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.122689,
          "top": 0.847981,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "94353"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804510"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "805439"
      }
    }
  },
  {
    "id": "805279",
    "type": "term",
    "attributes": {
      "name": "UAS",
      "definitions": [
        "unlabeled attachment scores"
      ],
      "definition_texs": [
        "unlabeled attachment scores"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "llrrr} \t\t\nData & Model & POS & **UAS** & LAS",
        "We first report the labeled and unlabeled attachment scores (LAS, **UAS**) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Still, LISA's GloVe **UAS** is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 **UAS** on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser.",
        "Still, LISA's GloVe **UAS** is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 **UAS** on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.77479,
          "top": 0.0771971,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "94353"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804510"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "805440"
      }
    }
  },
  {
    "id": "805280",
    "type": "term",
    "attributes": {
      "name": "LAS",
      "definitions": [
        "labeled attachment scores"
      ],
      "definition_texs": [
        "labeled attachment scores"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "llrrr} \t\t\nData & Model & POS & UAS & **LAS**",
        "We first report the labeled and unlabeled attachment scores (**LAS**, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average **LAS**), leading to higher SRL F1 by about 1.5 average F1."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.836975,
          "top": 0.0771971,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804539"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804510"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "805440"
      }
    }
  },
  {
    "id": "805281",
    "type": "term",
    "attributes": {
      "snippets": [
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (**+**) or incorrect (-).",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (**+**) or incorrect (--)."
      ],
      "name": "+",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "parses were completely correct"
      ],
      "definition_texs": [
        "parses were completely correct"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.268908,
          "top": 0.224466,
          "width": 0.010084,
          "height": 0.00593824
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804531"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804595"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804531"
        }
      ]
    }
  },
  {
    "id": "805282",
    "type": "term",
    "attributes": {
      "name": "E",
      "definitions": [
        "ELMo embeddings"
      ],
      "definition_texs": [
        "ELMo embeddings"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "This work was supported in part by an IBM PhD Fellowship Award to **E**.S., in part by the Center for Intelligent Information Retrieval, and in part by the National Science Foundation under Grant Nos."
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.537815,
          "top": 0.785036,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804558"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804558"
        }
      ],
      "definition_sentences": []
    }
  },
  {
    "id": "805283",
    "type": "term",
    "attributes": {
      "name": "+D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** &85.83 &\t84.51 &\t85.17 && {\\bf 87.13} & 86.67 & {\\bf 86.90} && {\\bf 79.02} & 77.49 & {\\bf 78.25",
        "**+D\\&M** & 83.37 & 83.58 & +0.21",
        "**+D\\&M** & 76.33\t& 79.65 &\t75.62 &\t66.55"
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.14958,
          "top": 0.112827,
          "width": 0.0521008,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804568"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804417"
        },
        {
          "type": "sentence",
          "id": "804568"
        },
        {
          "type": "sentence",
          "id": "804593"
        }
      ],
      "definition_sentences": []
    }
  },
  {
    "id": "805284",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+D\\&M** & 76.33\t& 79.65 &\t75.62 &\t66.55",
        "**+D\\&M** &85.83 &\t84.51 &\t85.17 && {\\bf 87.13} & 86.67 & {\\bf 86.90} && {\\bf 79.02} & 77.49 & {\\bf 78.25",
        "**+D\\&M** & 83.37 & 83.58 & +0.21"
      ],
      "name": "+D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.546219,
          "top": 0.359857,
          "width": 0.0537815,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804593"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804417"
        },
        {
          "type": "sentence",
          "id": "804568"
        },
        {
          "type": "sentence",
          "id": "804593"
        }
      ],
      "definition_sentences": []
    }
  },
  {
    "id": "805285",
    "type": "term",
    "attributes": {
      "name": "-",
      "definitions": [
        "parses were completely incorrect"
      ],
      "definition_texs": [
        "parses were completely incorrect"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (**-**)."
      ],
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.694118,
          "top": 0.440618,
          "width": 0.00504202,
          "height": 0.00118765
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804595"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804595"
        }
      ],
      "definition_sentences": []
    }
  },
  {
    "id": "805286",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.132773,
          "top": 0.0961995,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804567"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805287",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.186555,
          "top": 0.63658,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804577"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805288",
    "type": "term",
    "attributes": {
      "snippets": [
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.373109,
          "top": 0.769596,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804586"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805289",
    "type": "term",
    "attributes": {
      "name": "LAS",
      "definitions": [
        "labeled attachment scores"
      ],
      "definition_texs": [
        "labeled attachment scores"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "llrrr} \t\t\nData & Model & POS & UAS & **LAS**",
        "We first report the labeled and unlabeled attachment scores (**LAS**, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average **LAS**), leading to higher SRL F1 by about 1.5 average F1."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.361345,
          "top": 0.637767,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804539"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804539"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804510"
        }
      ]
    }
  },
  {
    "id": "805290",
    "type": "term",
    "attributes": {
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.280672,
          "top": 0.557007,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804538"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805291",
    "type": "term",
    "attributes": {
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "snippets": [
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.54958,
          "top": 0.800475,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804603"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805292",
    "type": "term",
    "attributes": {
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.272269,
          "top": 0.524941,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804537"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805293",
    "type": "term",
    "attributes": {
      "snippets": [
        "**SA** & 79.29 & 75.14\t& 75.97 &\t75.08",
        "**SA** and LISA with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**SA** &  83.52 & 81.28 & 82.39 &&  84.17 &\t83.28 &\t83.72 && 72.98 & 70.1 & 71.51",
        "**SA** &  85.78\t& 84.74\t& 85.26 &&  86.21 &\t85.98 &\t86.09 && 77.1 &\t75.61 &\t76.35",
        "We compare our {\\bf LISA} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf **SA**}).",
        "**SA** & 84.7 & 84.24 & 84.47",
        "**SA** & 73.89 & 72.39 & 73.13",
        "For models using GloVe embeddings, our syntax-free **SA** model already achieves a new state-of-the-art by jointly predicting predicates, POS and SRL.",
        "LISA with its own parses performs comparably to **SA**, but when supplied with D\\&M parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "We observe performance similar to that observed on ConLL-2005: Using GloVe embeddings our **SA** baseline already out-performs \\citet{he2018jointly} by nearly 1.5 F1.",
        "**SA** & 82.32 & 79.76 & 81.02",
        "**SA** & 84.35 & 82.14 & 83.23",
        "**SA** & 82.55 & 80.02 & 81.26",
        "**SA** & {\\bf 84.39} & 82.21 & 83.28",
        "Here there is little difference between any of the models, with LISA models tending to perform slightly better than **SA**.",
        "Both parsers make mistakes on the majority of sentences (57\\%), difficult sentences where **SA** also performs the worst.",
        "**SA** & 76.12 & 75.97 & 82.25 &\t65.78",
        "**SA** & 83.12 &\t82.81 &\t82.97"
      ],
      "name": "SA",
      "definitions": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "definition_texs": [
        "a version of our own self-attention model which does not incorporate syntactic information"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.529412,
          "top": 0.327791,
          "width": 0.0218487,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804591"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804408"
        },
        {
          "type": "sentence",
          "id": "804415"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804436"
        },
        {
          "type": "sentence",
          "id": "804443"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804460"
        },
        {
          "type": "sentence",
          "id": "804469"
        },
        {
          "type": "sentence",
          "id": "804476"
        },
        {
          "type": "sentence",
          "id": "804484"
        },
        {
          "type": "sentence",
          "id": "804490"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804527"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804538"
        },
        {
          "type": "sentence",
          "id": "804591"
        },
        {
          "type": "sentence",
          "id": "804603"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804425"
        }
      ]
    }
  },
  {
    "id": "805294",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.344538,
          "top": 0.622328,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804539"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805295",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.235294,
          "top": 0.63658,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804577"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805296",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.445378,
          "top": 0.573634,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804539"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805297",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.14958,
          "top": 0.195962,
          "width": 0.0521008,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804573"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805298",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.721008,
          "top": 0.41924,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804595"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805299",
    "type": "term",
    "attributes": {
      "snippets": [
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.171429,
          "top": 0.685273,
          "width": 0.0420168,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804578"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805300",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.566387,
          "top": 0.832542,
          "width": 0.0521008,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804605"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805301",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.280672,
          "top": 0.17696,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804614"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805302",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+Gold**} & \\emph{86.57} &\t\\emph{86.81} &\t\\emph{+0.24",
        "**+Gold**} & \\emph{87.57} & \\emph{85.32} & \\emph{86.43",
        "**+Gold**} & \\emph{88.22} & \\emph{86.53} & \\emph{87.36",
        "**+Gold**} & \\emph{79.61} & \\emph{78.38} & \\emph{81.41} & \\emph{80.47",
        "**+Gold**} & \\emph{87.91} & \\emph{85.73} & \\emph{86.81} && --- & --- & --- && --- & --- & ---",
        "**+Gold**} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf **+Gold**}), to provide an upper bound for the benefit that syntax could have for SRL using LISA.",
        "**+Gold**} & \\emph{85.94} &\t\\emph{86.43} &\t\\emph{+0.49",
        "**+Gold**} & \\emph{76.71} & \\emph{80.67} & \\emph{86.03} & \\emph{72.22",
        "**+Gold**} & \\emph{89.11} &\t\\emph{89.38} & \t\\emph{89.25"
      ],
      "name": "+Gold",
      "definitions": [
        "gold syntactic parses"
      ],
      "definition_texs": [
        "gold syntactic parses"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.151261,
          "top": 0.212589,
          "width": 0.0487395,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804574"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804411"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804472"
        },
        {
          "type": "sentence",
          "id": "804479"
        },
        {
          "type": "sentence",
          "id": "804530"
        },
        {
          "type": "sentence",
          "id": "804569"
        },
        {
          "type": "sentence",
          "id": "804574"
        },
        {
          "type": "sentence",
          "id": "804594"
        },
        {
          "type": "sentence",
          "id": "804606"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804430"
        }
      ]
    }
  },
  {
    "id": "805303",
    "type": "term",
    "attributes": {
      "snippets": [
        "**+Gold**} & \\emph{85.94} &\t\\emph{86.43} &\t\\emph{+0.49",
        "**+Gold**} & \\emph{87.91} & \\emph{85.73} & \\emph{86.81} && --- & --- & --- && --- & --- & ---",
        "**+Gold**} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf **+Gold**}), to provide an upper bound for the benefit that syntax could have for SRL using LISA.",
        "**+Gold**} & \\emph{87.57} & \\emph{85.32} & \\emph{86.43",
        "**+Gold**} & \\emph{88.22} & \\emph{86.53} & \\emph{87.36",
        "**+Gold**} & \\emph{79.61} & \\emph{78.38} & \\emph{81.41} & \\emph{80.47",
        "**+Gold**} & \\emph{86.57} &\t\\emph{86.81} &\t\\emph{+0.24",
        "**+Gold**} & \\emph{76.71} & \\emph{80.67} & \\emph{86.03} & \\emph{72.22",
        "**+Gold**} & \\emph{89.11} &\t\\emph{89.38} & \t\\emph{89.25"
      ],
      "name": "+Gold",
      "definitions": [
        "gold syntactic parses"
      ],
      "definition_texs": [
        "gold syntactic parses"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.568067,
          "top": 0.849169,
          "width": 0.0470588,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804606"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804411"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804472"
        },
        {
          "type": "sentence",
          "id": "804479"
        },
        {
          "type": "sentence",
          "id": "804530"
        },
        {
          "type": "sentence",
          "id": "804569"
        },
        {
          "type": "sentence",
          "id": "804574"
        },
        {
          "type": "sentence",
          "id": "804594"
        },
        {
          "type": "sentence",
          "id": "804606"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804430"
        }
      ]
    }
  },
  {
    "id": "805304",
    "type": "term",
    "attributes": {
      "name": "+Gold",
      "definitions": [
        "gold syntactic parses"
      ],
      "definition_texs": [
        "gold syntactic parses"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+Gold**} & \\emph{87.91} & \\emph{85.73} & \\emph{86.81} && --- & --- & --- && --- & --- & ---",
        "**+Gold**} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf **+Gold**}), to provide an upper bound for the benefit that syntax could have for SRL using LISA.",
        "**+Gold**} & \\emph{87.57} & \\emph{85.32} & \\emph{86.43",
        "**+Gold**} & \\emph{88.22} & \\emph{86.53} & \\emph{87.36",
        "**+Gold**} & \\emph{79.61} & \\emph{78.38} & \\emph{81.41} & \\emph{80.47",
        "**+Gold**} & \\emph{86.57} &\t\\emph{86.81} &\t\\emph{+0.24",
        "**+Gold**} & \\emph{85.94} &\t\\emph{86.43} &\t\\emph{+0.49",
        "**+Gold**} & \\emph{76.71} & \\emph{80.67} & \\emph{86.03} & \\emph{72.22",
        "**+Gold**} & \\emph{89.11} &\t\\emph{89.38} & \t\\emph{89.25"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.151261,
          "top": 0.128266,
          "width": 0.0487395,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804569"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804411"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804472"
        },
        {
          "type": "sentence",
          "id": "804479"
        },
        {
          "type": "sentence",
          "id": "804530"
        },
        {
          "type": "sentence",
          "id": "804569"
        },
        {
          "type": "sentence",
          "id": "804574"
        },
        {
          "type": "sentence",
          "id": "804594"
        },
        {
          "type": "sentence",
          "id": "804606"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804430"
        }
      ]
    }
  },
  {
    "id": "805305",
    "type": "term",
    "attributes": {
      "name": "+Gold",
      "definitions": [
        "gold syntactic parses"
      ],
      "definition_texs": [
        "gold syntactic parses"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+Gold**} & \\emph{87.91} & \\emph{85.73} & \\emph{86.81} && --- & --- & --- && --- & --- & ---",
        "**+Gold**} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf **+Gold**}), to provide an upper bound for the benefit that syntax could have for SRL using LISA.",
        "**+Gold**} & \\emph{87.57} & \\emph{85.32} & \\emph{86.43",
        "**+Gold**} & \\emph{88.22} & \\emph{86.53} & \\emph{87.36",
        "**+Gold**} & \\emph{79.61} & \\emph{78.38} & \\emph{81.41} & \\emph{80.47",
        "**+Gold**} & \\emph{86.57} &\t\\emph{86.81} &\t\\emph{+0.24",
        "**+Gold**} & \\emph{85.94} &\t\\emph{86.43} &\t\\emph{+0.49",
        "**+Gold**} & \\emph{76.71} & \\emph{80.67} & \\emph{86.03} & \\emph{72.22",
        "**+Gold**} & \\emph{89.11} &\t\\emph{89.38} & \t\\emph{89.25"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.547899,
          "top": 0.375297,
          "width": 0.0487395,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804594"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804411"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804472"
        },
        {
          "type": "sentence",
          "id": "804479"
        },
        {
          "type": "sentence",
          "id": "804530"
        },
        {
          "type": "sentence",
          "id": "804569"
        },
        {
          "type": "sentence",
          "id": "804574"
        },
        {
          "type": "sentence",
          "id": "804594"
        },
        {
          "type": "sentence",
          "id": "804606"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804430"
        }
      ]
    }
  },
  {
    "id": "805306",
    "type": "term",
    "attributes": {
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.721008,
          "top": 0.571259,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804554"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805307",
    "type": "term",
    "attributes": {
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.17479,
          "top": 0.654394,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804539"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805308",
    "type": "term",
    "attributes": {
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "snippets": [
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.642017,
          "top": 0.4038,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804595"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805309",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.793277,
          "top": 0.438242,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804548"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805310",
    "type": "term",
    "attributes": {
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.262185,
          "top": 0.73753,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804585"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805311",
    "type": "term",
    "attributes": {
      "snippets": [
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.751261,
          "top": 0.688836,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804611"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805312",
    "type": "term",
    "attributes": {
      "sources": [
        "human-annotation"
      ],
      "name": "PP",
      "definitions": [
        "prepositional phrase"
      ],
      "definition_texs": [
        "prepositional phrase"
      ],
      "snippets": [
        "\\citet{he2017deep} also point out that these errors are due mainly to prepositional phrase (**PP**) attachment mistakes.",
        "Though the number of corrections decreases substantially across phrase types, the proportion of corrections attributed to **PPs** remains the same (approx.",
        "50\\%) even after providing the correct **PP** attachment to the model, indicating that **PP** span boundary mistakes are a fundamental difficulty for SRL.",
        "50\\%) even after providing the correct **PP** attachment to the model, indicating that **PP** span boundary mistakes are a fundamental difficulty for SRL."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.757983,
          "top": 0.406176,
          "width": 0.0184874,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804548"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804545"
        },
        {
          "type": "sentence",
          "id": "804547"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804548"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "805324"
        }
      ]
    }
  },
  {
    "id": "805313",
    "type": "term",
    "attributes": {
      "name": "L",
      "definitions": [
        "LISA"
      ],
      "definition_texs": [
        "LISA"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (**L**) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (**L**) and D\\&M (D) parses were correct (+) or incorrect (-)."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.668908,
          "top": 0.41924,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804595"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804595"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804531"
        }
      ]
    }
  },
  {
    "id": "805314",
    "type": "term",
    "attributes": {
      "name": "D",
      "definitions": [
        "D&M"
      ],
      "definition_texs": [
        "D&M"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (**D**) parses were completely correct (+) or incorrect (--).",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (**D**) parses were correct (+) or incorrect (-)."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.77479,
          "top": 0.41924,
          "width": 0.010084,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804595"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804595"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804531"
        }
      ]
    }
  },
  {
    "id": "805315",
    "type": "term",
    "attributes": {
      "sources": [
        "human-annotation"
      ],
      "name": "CoNLL-2005",
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.588235,
          "top": 0.223278,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804583"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805316",
    "type": "term",
    "attributes": {
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.305882,
          "top": 0.41924,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804542"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805317",
    "type": "term",
    "attributes": {
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.515966,
          "top": 0.893112,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804607"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805318",
    "type": "term",
    "attributes": {
      "snippets": [
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (**+**) or incorrect (-).",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (**+**) or incorrect (--)."
      ],
      "name": "+",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "parses were completely correct"
      ],
      "definition_texs": [
        "parses were completely correct"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.578151,
          "top": 0.438242,
          "width": 0.010084,
          "height": 0.00593824
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804595"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804595"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804531"
        }
      ]
    }
  },
  {
    "id": "805319",
    "type": "term",
    "attributes": {
      "snippets": [
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.121008,
          "top": 0.584323,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804565"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805320",
    "type": "term",
    "attributes": {
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.72605,
          "top": 0.542755,
          "width": 0.097479,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804599"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805321",
    "type": "term",
    "attributes": {
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.240336,
          "top": 0.0795724,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804611"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805322",
    "type": "term",
    "attributes": {
      "name": "WSJ",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "Wall Street Journal"
      ],
      "definition_texs": [
        "Wall Street Journal"
      ],
      "snippets": [
        "On CoNLL-2005 with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the **WSJ** test set by 2.5 F1 points absolute.",
        "llll}\n**WSJ** Test & P & R & F1",
        "The gap in SRL F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on **WSJ**, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with D\\&M parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both **WSJ** and Brown.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on **WSJ** using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser.",
        "llll}\n**WSJ** Dev & P & R & F1"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.54958,
          "top": 0.749406,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "804433"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804600"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "805445"
      }
    }
  },
  {
    "id": "805323",
    "type": "term",
    "attributes": {
      "snippets": [
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.131092,
          "top": 0.0771971,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "805432"
      }
    }
  },
  {
    "id": "805324",
    "type": "sentence",
    "attributes": {
      "text": "He et al. (2017) also point out that these errors are due mainly to prepositional phrase (PP) attachment mistakes.",
      "tex": "\\cite{he2017} also point out that these errors are due mainly to prepositional phrase (PP) attachment mistakes.",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 8,
          "left": 0.516083,
          "top": 0.305767,
          "width": 0.254046,
          "height": 0.014968
        },
        {
          "source": "human-annotation",
          "page": 8,
          "left": 0.516083,
          "top": 0.289753,
          "width": 0.36391,
          "height": 0.014968
        }
      ],
      "tex_start": null,
      "tex_end": null
    },
    "relationships": {}
  },
  {
    "id": "805325",
    "type": "term",
    "attributes": {
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.712605,
          "top": 0.395487,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804454"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805326",
    "type": "term",
    "attributes": {
      "definition_texs": [
        "type of span boundary error"
      ],
      "sources": [
        "human-annotation"
      ],
      "name": "Fix Span Boundary",
      "definitions": [
        "type of span boundary error"
      ],
      "snippets": [
        "In Figure \\ref{errors-fig} we see that much of the performance gap between the gold and predicted parses is due to span boundary errors (\\emph{Merge Spans}, \\emph{Split Spans} and \\emph{**Fix Span Boundary**}), which supports the hypothesis proposed by \\citet{he2017deep} that incorporating syntax could be particularly helpful for resolving these errors."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.154622,
          "top": 0.814727,
          "width": 0.144538,
          "height": 0.0106888
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804544"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804544"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804544"
        }
      ]
    }
  },
  {
    "id": "805327",
    "type": "term",
    "attributes": {
      "name": "linguistically-informed self-attention",
      "definitions": [
        "a variation of self-attention that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL"
      ],
      "definition_texs": [
        "a variation of self-attention that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**Linguistically-Informed Self-Attention** for Semantic Role Labeling",
        "In this work, we present **linguistically-informed self-attention** (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "In response, we propose \\emph{**linguistically-informed self-attention**} (LISA): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "We present **linguistically-informed self-attention:** a multi-task neural network model that effectively incorporates rich linguistic information for semantic role labeling."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.208403,
          "top": 0.389549,
          "width": 0.248739,
          "height": 0.0106888
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804266"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804256"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804553"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        }
      ]
    }
  },
  {
    "id": "805328",
    "type": "term",
    "attributes": {
      "name": "linguistically-informed self-attention",
      "definitions": [
        "a variation of self-attention that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL"
      ],
      "definition_texs": [
        "a variation of self-attention that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**Linguistically-Informed Self-Attention** for Semantic Role Labeling",
        "In this work, we present **linguistically-informed self-attention** (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "In response, we propose \\emph{**linguistically-informed self-attention**} (LISA): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "We present **linguistically-informed self-attention:** a multi-task neural network model that effectively incorporates rich linguistic information for semantic role labeling."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.608403,
          "top": 0.507126,
          "width": 0.272269,
          "height": 0.0106888
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804553"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804256"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804553"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        }
      ]
    }
  },
  {
    "id": "805329",
    "type": "term",
    "attributes": {
      "name": "linguistically-informed self-attention",
      "definitions": [
        "a variation of self-attention that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL"
      ],
      "definition_texs": [
        "a variation of self-attention that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**Linguistically-Informed Self-Attention** for Semantic Role Labeling",
        "In this work, we present **linguistically-informed self-attention** (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "In response, we propose \\emph{**linguistically-informed self-attention**} (LISA): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "We present **linguistically-informed self-attention:** a multi-task neural network model that effectively incorporates rich linguistic information for semantic role labeling."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.159664,
          "top": 0.0855107,
          "width": 0.394958,
          "height": 0.0154394
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804256"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804256"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804553"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        }
      ]
    }
  },
  {
    "id": "805330",
    "type": "term",
    "attributes": {
      "name": "linguistically-informed self-attention",
      "definitions": [
        "a variation of self-attention that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL"
      ],
      "definition_texs": [
        "a variation of self-attention that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**Linguistically-Informed Self-Attention** for Semantic Role Labeling",
        "In this work, we present **linguistically-informed self-attention** (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "In response, we propose \\emph{**linguistically-informed self-attention**} (LISA): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "We present **linguistically-informed self-attention:** a multi-task neural network model that effectively incorporates rich linguistic information for semantic role labeling."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.130641,
          "width": 0.0957983,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.319328,
          "top": 0.115202,
          "width": 0.169748,
          "height": 0.0106888
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804282"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804256"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804553"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        }
      ]
    }
  },
  {
    "id": "805331",
    "type": "term",
    "attributes": {
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.154622,
          "top": 0.4038,
          "width": 0.0352941,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804266"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805332",
    "type": "term",
    "attributes": {
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.640336,
          "top": 0.491686,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804455"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805333",
    "type": "term",
    "attributes": {
      "snippets": [
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.14958,
          "top": 0.758907,
          "width": 0.0352941,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804272"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805334",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.517647,
          "top": 0.671021,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804458"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805335",
    "type": "term",
    "attributes": {
      "snippets": [
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.605042,
          "top": 0.654394,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804458"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805336",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.695798,
          "top": 0.427553,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804454"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805337",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.778151,
          "top": 0.785036,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804461"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805338",
    "type": "term",
    "attributes": {
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.490499,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804491"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805339",
    "type": "term",
    "attributes": {
      "snippets": [
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.62521,
          "top": 0.401425,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804517"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805340",
    "type": "term",
    "attributes": {
      "snippets": [
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "sources": [
        "human-annotation"
      ],
      "name": "LISA",
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.146081,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804470"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805341",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.62521,
          "top": 0.368171,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804515"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805342",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.391924,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804485"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805343",
    "type": "term",
    "attributes": {
      "snippets": [
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.62521,
          "top": 0.418052,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804518"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805344",
    "type": "term",
    "attributes": {
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.356303,
          "top": 0.192399,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804615"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805345",
    "type": "term",
    "attributes": {
      "snippets": [
        "**D\\&M** achieves the best scores.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.385986,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804617"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805346",
    "type": "term",
    "attributes": {
      "snippets": [
        "Some work has incorporated syntax into neural models for **SRL**.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.416807,
          "top": 0.209026,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804615"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805347",
    "type": "term",
    "attributes": {
      "snippets": [
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.601681,
          "top": 0.192399,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804627"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805348",
    "type": "term",
    "attributes": {
      "name": "SGD",
      "definitions": [
        "stochastic gradient descent"
      ],
      "definition_texs": [
        "stochastic gradient descent"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "We train the model using Nadam \\citep{dozat2016incorporating} **SGD** combined with the learning rate schedule in \\citet{vaswani2017attention}.",
        "We train the model using the Nadam \\citep{dozat2016incorporating} algorithm for adaptive stochastic gradient descent (**SGD**), which combines Adam \\citep{kingma2014adam} **SGD** with Nesterov momentum \\citep{nesterov1983method}.",
        "We train the model using the Nadam \\citep{dozat2016incorporating} algorithm for adaptive stochastic gradient descent (**SGD**), which combines Adam \\citep{kingma2014adam} **SGD** with Nesterov momentum \\citep{nesterov1983method}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.52437,
          "top": 0.304038,
          "width": 0.0336134,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804629"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804379"
        },
        {
          "type": "sentence",
          "id": "804629"
        },
        {
          "type": "sentence",
          "id": "804629"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804629"
        }
      ]
    }
  },
  {
    "id": "805349",
    "type": "term",
    "attributes": {
      "name": "SGD",
      "definitions": [
        "stochastic gradient descent"
      ],
      "definition_texs": [
        "stochastic gradient descent"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "We train the model using Nadam \\citep{dozat2016incorporating} **SGD** combined with the learning rate schedule in \\citet{vaswani2017attention}.",
        "We train the model using the Nadam \\citep{dozat2016incorporating} algorithm for adaptive stochastic gradient descent (**SGD**), which combines Adam \\citep{kingma2014adam} **SGD** with Nesterov momentum \\citep{nesterov1983method}.",
        "We train the model using the Nadam \\citep{dozat2016incorporating} algorithm for adaptive stochastic gradient descent (**SGD**), which combines Adam \\citep{kingma2014adam} **SGD** with Nesterov momentum \\citep{nesterov1983method}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.566387,
          "top": 0.319477,
          "width": 0.0336134,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804629"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804379"
        },
        {
          "type": "sentence",
          "id": "804629"
        },
        {
          "type": "sentence",
          "id": "804629"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804629"
        }
      ]
    }
  },
  {
    "id": "805350",
    "type": "term",
    "attributes": {
      "name": "PTB",
      "definitions": [
        "Penn TreeBank"
      ],
      "definition_texs": [
        "Penn TreeBank"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in **PTB** parsing.",
        "The CoNLL-2005 data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (**PTB**) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.556303,
          "top": 0.0950119,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804625"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805351",
    "type": "term",
    "attributes": {
      "definition_texs": [
        "Penn TreeBank"
      ],
      "sources": [
        "human-annotation"
      ],
      "name": "PTB",
      "definitions": [
        "Penn TreeBank"
      ],
      "snippets": [
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in **PTB** parsing.",
        "The CoNLL-2005 data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (**PTB**) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.455462,
          "top": 0.256532,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804615"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805352",
    "type": "term",
    "attributes": {
      "definitions": [
        "type of span boundary error",
        "a correction to model predictions that merges adjacent predicted spans into a gold span"
      ],
      "name": "Merge Spans",
      "definition_texs": [
        "a correction to model predictions that merges adjacent predicted spans into a gold span",
        "type of span boundary error"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Following \\citet{he2017deep}, we next apply a series of corrections to model predictions in order to understand which error types the gold parse resolves: e.g. \\emph{Fix Labels} fixes labels on spans matching gold boundaries, and \\emph{**Merge Spans**} merges adjacent predicted spans into a gold span.\\footnote{Refer to \\citet{he2017deep} for a detailed explanation of the different error types.",
        "In Figure \\ref{errors-fig} we see that much of the performance gap between the gold and predicted parses is due to span boundary errors (\\emph{**Merge Spans**}, \\emph{Split Spans} and \\emph{Fix Span Boundary}), which supports the hypothesis proposed by \\citet{he2017deep} that incorporating syntax could be particularly helpful for resolving these errors."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.393277,
          "top": 0.733967,
          "width": 0.0941176,
          "height": 0.0106888
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804540"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804540"
        },
        {
          "type": "sentence",
          "id": "804544"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804540"
        },
        {
          "type": "sentence",
          "id": "804544"
        }
      ]
    }
  },
  {
    "id": "805353",
    "type": "term",
    "attributes": {
      "name": "Merge Spans",
      "definitions": [
        "a correction to model predictions that merges adjacent predicted spans into a gold span",
        "type of span boundary error"
      ],
      "definition_texs": [
        "a correction to model predictions that merges adjacent predicted spans into a gold span",
        "type of span boundary error"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Following \\citet{he2017deep}, we next apply a series of corrections to model predictions in order to understand which error types the gold parse resolves: e.g. \\emph{Fix Labels} fixes labels on spans matching gold boundaries, and \\emph{**Merge Spans**} merges adjacent predicted spans into a gold span.\\footnote{Refer to \\citet{he2017deep} for a detailed explanation of the different error types.",
        "In Figure \\ref{errors-fig} we see that much of the performance gap between the gold and predicted parses is due to span boundary errors (\\emph{**Merge Spans**}, \\emph{Split Spans} and \\emph{Fix Span Boundary}), which supports the hypothesis proposed by \\citet{he2017deep} that incorporating syntax could be particularly helpful for resolving these errors."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.302521,
          "top": 0.799287,
          "width": 0.0941176,
          "height": 0.0106888
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804544"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804540"
        },
        {
          "type": "sentence",
          "id": "804544"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804540"
        },
        {
          "type": "sentence",
          "id": "804544"
        }
      ]
    }
  },
  {
    "id": "805354",
    "type": "term",
    "attributes": {
      "name": "Fix Labels",
      "definitions": [
        "a correction to model predictions that fixes labels on spans matching gold boundaries"
      ],
      "definition_texs": [
        "a correction to model predictions that fixes labels on spans matching gold boundaries"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Following \\citet{he2017deep}, we next apply a series of corrections to model predictions in order to understand which error types the gold parse resolves: e.g. \\emph{**Fix Labels**} fixes labels on spans matching gold boundaries, and \\emph{Merge Spans} merges adjacent predicted spans into a gold span.\\footnote{Refer to \\citet{he2017deep} for a detailed explanation of the different error types."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.289076,
          "top": 0.718527,
          "width": 0.0789916,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804540"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804540"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804540"
        }
      ]
    }
  },
  {
    "id": "805355",
    "type": "term",
    "attributes": {
      "name": "Split Spans",
      "definitions": [
        "type of span boundary error"
      ],
      "definition_texs": [
        "type of span boundary error"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In Figure \\ref{errors-fig} we see that much of the performance gap between the gold and predicted parses is due to span boundary errors (\\emph{Merge Spans}, \\emph{**Split Spans**} and \\emph{Fix Span Boundary}), which supports the hypothesis proposed by \\citet{he2017deep} that incorporating syntax could be particularly helpful for resolving these errors."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.406723,
          "top": 0.799287,
          "width": 0.0806723,
          "height": 0.0106888
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804544"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804544"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804544"
        }
      ]
    }
  },
  {
    "id": "805356",
    "type": "term",
    "attributes": {
      "snippets": [
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.420168,
          "top": 0.288599,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804616"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805357",
    "type": "term",
    "attributes": {
      "name": "PP",
      "definitions": [
        "prepositional phrase"
      ],
      "definition_texs": [
        "prepositional phrase"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "\\citet{he2017deep} also point out that these errors are due mainly to prepositional phrase (**PP**) attachment mistakes.",
        "Though the number of corrections decreases substantially across phrase types, the proportion of corrections attributed to **PPs** remains the same (approx.",
        "50\\%) even after providing the correct **PP** attachment to the model, indicating that **PP** span boundary mistakes are a fundamental difficulty for SRL.",
        "50\\%) even after providing the correct **PP** attachment to the model, indicating that **PP** span boundary mistakes are a fundamental difficulty for SRL."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.579832,
          "top": 0.309976,
          "width": 0.0184874,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804545"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "805324"
        },
        {
          "type": "sentence",
          "id": "804547"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804548"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "805324"
        }
      ]
    }
  },
  {
    "id": "805358",
    "type": "term",
    "attributes": {
      "name": "PP",
      "definitions": [
        "prepositional phrase"
      ],
      "definition_texs": [
        "prepositional phrase"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "\\citet{he2017deep} also point out that these errors are due mainly to prepositional phrase (**PP**) attachment mistakes.",
        "Though the number of corrections decreases substantially across phrase types, the proportion of corrections attributed to **PPs** remains the same (approx.",
        "50\\%) even after providing the correct **PP** attachment to the model, indicating that **PP** span boundary mistakes are a fundamental difficulty for SRL.",
        "50\\%) even after providing the correct **PP** attachment to the model, indicating that **PP** span boundary mistakes are a fundamental difficulty for SRL."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.611765,
          "top": 0.389549,
          "width": 0.0252101,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804547"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804545"
        },
        {
          "type": "sentence",
          "id": "804547"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804548"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "805324"
        }
      ]
    }
  },
  {
    "id": "805359",
    "type": "term",
    "attributes": {
      "name": "PP",
      "definitions": [
        "prepositional phrase"
      ],
      "definition_texs": [
        "prepositional phrase"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "\\citet{he2017deep} also point out that these errors are due mainly to prepositional phrase (**PP**) attachment mistakes.",
        "Though the number of corrections decreases substantially across phrase types, the proportion of corrections attributed to **PPs** remains the same (approx.",
        "50\\%) even after providing the correct **PP** attachment to the model, indicating that **PP** span boundary mistakes are a fundamental difficulty for SRL.",
        "50\\%) even after providing the correct **PP** attachment to the model, indicating that **PP** span boundary mistakes are a fundamental difficulty for SRL."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.712605,
          "top": 0.422803,
          "width": 0.0184874,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804548"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804545"
        },
        {
          "type": "sentence",
          "id": "804547"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804548"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "805324"
        }
      ]
    }
  },
  {
    "id": "805360",
    "type": "term",
    "attributes": {
      "snippets": [
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.42521,
          "top": 0.17696,
          "width": 0.0621849,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804614"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805361",
    "type": "term",
    "attributes": {
      "snippets": [
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.192399,
          "width": 0.0352941,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804614"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805362",
    "type": "term",
    "attributes": {
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.29916,
          "top": 0.224466,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804615"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805363",
    "type": "term",
    "attributes": {
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.157983,
          "top": 0.767221,
          "width": 0.0991597,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804625"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805364",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.260095,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804477"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805365",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.705882,
          "top": 0.61639,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804522"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805366",
    "type": "term",
    "attributes": {
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.611765,
          "top": 0.539192,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804303"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805367",
    "type": "term",
    "attributes": {
      "snippets": [
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.312605,
          "top": 0.224466,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804393"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805368",
    "type": "term",
    "attributes": {
      "snippets": [
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.122689,
          "top": 0.685273,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804578"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805369",
    "type": "term",
    "attributes": {
      "snippets": [
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.64874,
          "top": 0.555819,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804554"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805370",
    "type": "term",
    "attributes": {
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.132773,
          "top": 0.179335,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804572"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805371",
    "type": "term",
    "attributes": {
      "snippets": [
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.529412,
          "top": 0.34323,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804592"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805372",
    "type": "term",
    "attributes": {
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.54958,
          "top": 0.817102,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804604"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805373",
    "type": "term",
    "attributes": {
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.616807,
          "top": 0.41924,
          "width": 0.0386555,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804595"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805374",
    "type": "term",
    "attributes": {
      "snippets": [
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.132773,
          "top": 0.27791,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804416"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805375",
    "type": "term",
    "attributes": {
      "snippets": [
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.132773,
          "top": 0.16152,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804409"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805376",
    "type": "term",
    "attributes": {
      "snippets": [
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.668908,
          "top": 0.461995,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804425"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805377",
    "type": "term",
    "attributes": {
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "& **LISA** & 95.5 &  91.9 &  93.7",
        "CoNLL-12 & **LISA** & 99.8 & 94.7 &\t97.2",
        "In this work, we present linguistically-informed self-attention (**LISA**): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and SRL.",
        "Unlike previous models which require significant pre-processing to prepare linguistic features, **LISA** can incorporate syntax using merely raw tokens as input, encoding the sequence only once to simultaneously perform parsing, predicate detection and role labeling for all predicates.",
        "In experiments on CoNLL-2005 SRL, **LISA** achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "**LISA** also out-performs the state-of-the-art with contextually-encoded (ELMo) word representations, by nearly 1.0 F1 on news and more than 2.0 F1 on out-of-domain text.",
        "In response, we propose \\emph{linguistically-informed self-attention} (**LISA**): a model that combines multi-task learning \\citep{caruana1993multitask} with stacked layers of multi-head self-attention \\citep{vaswani2017attention}; the model is trained to: (1) jointly predict parts of speech and predicates; (2) perform parsing; and (3) attend to syntactic parse parents, while (4) assigning semantic role labels.",
        "**LISA** achieves this by combining: (1) A new technique of supervising neural attention to predict syntactic dependencies with (2) multi-task learning across four related tasks.",
        "We find that **LISA** obtains further accuracy increases when provided with ELMo word representations, especially on out-of-domain data.",
        "**LISA** &  83.1 & 81.39 &  82.24 && 84.07 & 83.16 & 83.61 && 73.32 & 70.56 & 71.91",
        "**LISA** &  {\\bf 86.07} & 84.64 & {\\bf 85.35} && 86.69 & 86.42 & 86.55 && 78.95 & 77.17 &\t78.05",
        "We compare our {\\bf **LISA**} models to four strong baselines: For experiments using predicted predicates, we compare to \\citet{he2018jointly} and the ensemble model ({\\bf PoE}) from \\citet{he2017deep}, as well as a version of our own self-attention model which does not incorporate syntactic information ({\\bf SA}).",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for SRL using **LISA**.",
        "**LISA** & 84.72 &\t84.57\t& 84.64",
        "**LISA** & 74.77 & 74.32 &\t74.55",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "**LISA** with its own parses performs comparably to SA, but when supplied with D\\&M parses **LISA** out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "On the out-of-domain Brown test set, **LISA** also performs comparably to its syntax-free counterpart with its own parses, but with D\\&M parses **LISA** performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using **LISA** and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but **LISA** with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "In both settings **LISA** leverages domain-agnostic syntactic information rather than over-fitting to the newswire training data which leads to high performance even on out-of-domain text.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "Here **LISA** still excels: with D\\&M parses, **LISA** out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "With its own parses, **LISA** slightly under-performs our syntax-free model, but when provided with stronger D\\&M parses **LISA** out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with **LISA** and D\\&M parses.",
        "**LISA** & 81.77 & 79.65 & 80.70",
        "**LISA** & {\\bf 84.19} & 82.56 & {\\bf 83.37",
        "**LISA** &  81.86 &\t79.56 &\t80.70",
        "**LISA** & 83.97 & 82.29 & 83.12",
        "& **LISA** & 98.9 &  97.9 & 98.4",
        "SA and **LISA** with and without ELMo attain comparable scores so we report only LISA+GloVe.",
        "**LISA** attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for SRL predicate detection.",
        "**LISA** & 79.51 &\t74.33 &\t79.69 &\t75.00",
        "Average SRL F1 on CoNLL-2005 for sentences where **LISA** (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of **LISA** and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "Here there is little difference between any of the models, with **LISA** models tending to perform slightly better than SA.",
        "**LISA** out-performs the state-of-the-art on two benchmark SRL datasets, including out-of-domain.",
        "**LISA** & 81.99 & 82.24 & +0.25",
        "**LISA** & 80.11\t& 80.70\t & +0.59",
        "First, we compare the impact of Viterbi decoding with **LISA**, D\\&M, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for **LISA**, D\\&M and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "In Figure \\ref{fig:length} we see that providing **LISA** with gold parses is particularly helpful for sentences longer than 10 tokens.",
        "**LISA** & 76.37 &\t72.38 &\t85.50 &\t65.10",
        "Average SRL F1 on CoNLL-2012 for sentences where **LISA** (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "**LISA** & 83.6 &\t83.74\t& 83.67"
      ],
      "name": "LISA",
      "definitions": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "definition_texs": [
        "linguistically-informed self-attention",
        "linguistically-informed self-attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.29916,
          "top": 0.833729,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804452"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804267"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804272"
        },
        {
          "type": "sentence",
          "id": "804282"
        },
        {
          "type": "sentence",
          "id": "804303"
        },
        {
          "type": "sentence",
          "id": "804393"
        },
        {
          "type": "sentence",
          "id": "804409"
        },
        {
          "type": "sentence",
          "id": "804416"
        },
        {
          "type": "sentence",
          "id": "804425"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804437"
        },
        {
          "type": "sentence",
          "id": "804444"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804455"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804470"
        },
        {
          "type": "sentence",
          "id": "804477"
        },
        {
          "type": "sentence",
          "id": "804485"
        },
        {
          "type": "sentence",
          "id": "804491"
        },
        {
          "type": "sentence",
          "id": "804515"
        },
        {
          "type": "sentence",
          "id": "804517"
        },
        {
          "type": "sentence",
          "id": "804518"
        },
        {
          "type": "sentence",
          "id": "804522"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804528"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804537"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804567"
        },
        {
          "type": "sentence",
          "id": "804572"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804586"
        },
        {
          "type": "sentence",
          "id": "804592"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804604"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804282"
        }
      ]
    }
  },
  {
    "id": "805378",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "sources": [
        "human-annotation"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.840336,
          "top": 0.503563,
          "width": 0.0420168,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "94353"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805379",
    "type": "term",
    "attributes": {
      "name": "D&M",
      "definitions": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "definition_texs": [
        "parses predicted by Dozat and Manning (2017), the winner of the 2017 CoNLL shared task"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "**+D\\&M** & {\\bf 84.59} & {\\bf 82.59} &\t{\\bf 83.58} && {\\bf 85.53} & {\\bf 84.45} & {\\bf 84.99} && {\\bf 75.8} & {\\bf 73.54} & {\\bf 74.66",
        "We demonstrate that our models benefit from injecting state-of-the-art predicted parses at test time ({\\bf **+D\\&M**}) by fixing the attention to parses predicted by \\citet{dozat2017deep}, the winner of the 2017 CoNLL shared task \\citep{zeman2017conll} which we re-train using ELMo embeddings.",
        "**+D\\&M** & {\\bf 86.02} &\t{\\bf 86.05} &\t{\\bf 86.04",
        "**+D\\&M** & {\\bf 76.65} & {\\bf 76.44} & {\\bf 76.54",
        "LISA with its own parses performs comparably to SA, but when supplied with **D\\&M** parses LISA out-performs the previous state-of-the-art by 2.5 F1 points.",
        "On the out-of-domain Brown test set, LISA also performs comparably to its syntax-free counterpart with its own parses, but with **D\\&M** parses LISA performs exceptionally well, more than 3.5 F1 points higher than \\citet{he2018jointly}.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "The gap in SRL F1 between models using LISA and **D\\&M** parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with **D\\&M** parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with **D\\&M** parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both WSJ and Brown.",
        "With its own parses, LISA slightly under-performs our syntax-free model, but when provided with stronger **D\\&M** parses LISA out-performs the state-of-the-art by more than 2.5 F1.",
        "Like CoNLL-2005, ELMo representations improve all models and close the F1 gap between models supplied with LISA and **D\\&M** parses.",
        "This suggests that for this challenging dataset, ELMo already encodes much of the information available in the **D\\&M** parses.",
        "**+D\\&M** & {\\bf 82.97} & {\\bf 81.14} &\t{\\bf 82.05",
        "**+D\\&M** & 84.09 & 82.65 & 83.36",
        "**+D\\&M** & {\\bf 83.3} & {\\bf 81.38} &\t{\\bf 82.33",
        "**+D\\&M** & 84.14 & 82.64 & {\\bf 83.38",
        "**D\\&M** achieves the best scores.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on WSJ using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone **D\\&M** parser.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "The difference in parse accuracy between LISA$_G$ and **D\\&M** likely explains the large increase in SRL performance we see from decoding with **D\\&M** parses in that setting.",
        "**+D\\&M** & 79.03 &\t76.96 &\t77.73 &\t76.52",
        "Average SRL F1 on CoNLL-2005 for sentences where LISA (L) and **D\\&M** (D) parses were completely correct (+) or incorrect (--).",
        "Table \\ref{tab:parse-srl-by-sents} lists average SRL F1 (across sentences) for the four conditions of LISA and **D\\&M** parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "These examples are likely where gold and **D\\&M** parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the **D\\&M** parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher SRL F1 by about 1.5 average F1.",
        "**+D\\&M** & 81.55 &\t82.05 & +0.50",
        "First, we compare the impact of Viterbi decoding with LISA, **D\\&M**, and gold syntax trees (Table \\ref{viterbi-table}), finding the same trends across both datasets.",
        "We find that Viterbi has nearly the same impact for LISA, **D\\&M** and gold parses: Gold parses provide little improvement over predicted parses in terms of BIO label consistency.",
        "Average SRL F1 on CoNLL-2012 for sentences where LISA (L) and **D\\&M** (D) parses were correct (+) or incorrect (-).",
        "**+D\\&M** & {\\bf 85.04} &\t{\\bf 85.51} &\t{\\bf 85.27",
        "We train distinct **D\\&M** parsers for CoNLL-2005 and CoNLL-2012.",
        "Our **D\\&M** parsers are trained and validated using the same SRL data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We use the pre-trained ELMo models\\footnote{\\protect\\url{https://github.com/allenai/bilm-tf}} and learn task-specific combinations of the ELMo representations which are provided as input instead of GloVe embeddings to the **D\\&M** parser with otherwise default settings."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.32605,
          "top": 0.205463,
          "width": 0.0403361,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804531"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804410"
        },
        {
          "type": "sentence",
          "id": "804428"
        },
        {
          "type": "sentence",
          "id": "804438"
        },
        {
          "type": "sentence",
          "id": "804445"
        },
        {
          "type": "sentence",
          "id": "804451"
        },
        {
          "type": "sentence",
          "id": "804452"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "804461"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804464"
        },
        {
          "type": "sentence",
          "id": "804471"
        },
        {
          "type": "sentence",
          "id": "804478"
        },
        {
          "type": "sentence",
          "id": "804486"
        },
        {
          "type": "sentence",
          "id": "804492"
        },
        {
          "type": "sentence",
          "id": "804511"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804529"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804573"
        },
        {
          "type": "sentence",
          "id": "804577"
        },
        {
          "type": "sentence",
          "id": "804578"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804605"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804617"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804428"
        }
      ]
    }
  },
  {
    "id": "805380",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.611765,
          "top": 0.640143,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804386"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805381",
    "type": "term",
    "attributes": {
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.704202,
          "top": 0.65677,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804279"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805382",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.685714,
          "top": 0.897862,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804281"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805383",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.85042,
          "top": 0.446556,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804276"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805384",
    "type": "term",
    "attributes": {
      "snippets": [
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.277311,
          "top": 0.388361,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804284"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805385",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.282353,
          "top": 0.0795724,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804391"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805386",
    "type": "term",
    "attributes": {
      "name": "SRL",
      "definitions": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "definition_texs": [
        "semantic role labeling",
        "semantic role labeling"
      ],
      "sources": [
        "human-annotation",
        "human-annotation"
      ],
      "snippets": [
        "Current state-of-the-art semantic role labeling (**SRL**) uses a deep neural network with no explicit linguistic features.",
        "However, prior work has shown that gold syntax trees can dramatically improve **SRL** decoding, suggesting the possibility of increased accuracy from explicit modeling of syntax.",
        "In this work, we present linguistically-informed self-attention (LISA): a neural network model that combines multi-head self-attention with multi-task learning across dependency parsing, part-of-speech tagging, predicate detection and **SRL**.",
        "Moreover, if a high-quality syntactic parse is already available, it can be beneficially injected at test time without re-training our **SRL** model.",
        "In experiments on CoNLL-2005 **SRL**, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "On ConLL-2012 English **SRL** we also show an improvement of more than 2.5 F1.",
        "Semantic role labeling (**SRL**) extracts a high-level representation of meaning from a sentence, labeling e.g.\\ \\emph{who} did \\emph{what} to \\emph{whom}.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "Though syntax was long considered an obvious prerequisite for **SRL** systems \\citep{levin1993english,punyakanok2008importance}, recently deep neural network architectures have surpassed syntactically-informed models \\citep{zhou2015end, marcheggiani2017simple, he2017deep, tan2018deep, he2018jointly}, achieving state-of-the art **SRL** performance with no explicit modeling of syntax.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, POS and **SRL**.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "\\citet{he2017deep} indicate that many of the errors made by a syntax-free neural network on **SRL** are tied to certain syntactic confusions such as prepositional phrase attachment, and show that while constrained inference using a relatively low-accuracy predicted parse can provide small improvements in **SRL** accuracy, providing a gold-quality parse leads to substantial gains.",
        "These works suggest that though syntax has the potential to improve neural network **SRL** models, we have not yet designed an architecture which maximizes the benefits of auxiliary syntactic information.",
        "Though prior work re-encodes each sentence to predict each desired task and again with respect to each predicate to perform **SRL**,\n%in our model predicates are not known \\emph{a priori} so \nwe more efficiently encode each sentence only once, predict its predicates, part-of-speech tags and labeled syntactic parse, then predict the semantic roles for all predicates in the sentence in parallel.",
        "Representations from layer $r$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token **SRL** predictions with respect to each predicted predicate.",
        "Our goal is to design an efficient neural network model which makes use of linguistic information as effectively as possible in order to perform end-to-end **SRL**.",
        "Though typical models, which re-encode the sentence for each predicate, can simplify **SRL** to token-wise tagging, our joint model requires a different approach to classify roles with respect to each predicate.",
        "The basis for our model is a multi-head self-attention token encoder, recently shown to achieve state-of-the-art performance on **SRL** \\citep{tan2018deep}, and which provides a natural mechanism for incorporating syntax, as described in \\S\\ref{sec:syntax-attn}.",
        "In the standard setting these token representations are initialized to pre-trained word embeddings, but we also experiment with supplying pre-trained ELMo representations combined with task-specific learned parameters, which have been shown to substantially improve performance of other **SRL** models \\citep{peters2018deep}.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "While much previous work employs a pipelined approach to both POS tagging for dependency parsing and predicate detection for **SRL**, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our **SRL** model with a joint POS and predicate detection objective.",
        "Early approaches to **SRL** \\citep{pradhan2005semantic,surdeanu2007combination,johansson2008dependency,toutanova2008global} focused on developing rich sets of linguistic features as input to a linear model, often combined with complex constrained inference e.g. with an ILP \\citep{punyakanok2008importance}.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{sutton2005joint} modeled syntactic parsing and **SRL** jointly, and \\citet{lewis2015joint} jointly modeled **SRL** and CCG parsing.",
        "\\citet{collobert2011natural} were among the first to use a neural network model for **SRL**, a CNN over word embeddings which failed to out-perform non-neural models.",
        "\\citet{zhou2015end}, \\citet{marcheggiani2017simple} and \\citet{he2017deep} all use variants of deep LSTMs with constrained decoding, while \\citet{tan2018deep} apply self-attention to obtain state-of-the-art **SRL** with gold predicates.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Like this work, \\citet{he2017deep} present end-to-end experiments, predicting predicates using an LSTM, and \\citet{he2018jointly} jointly predict **SRL** spans and predicates in a model based on that of \\citet{lee2017end}, obtaining state-of-the-art predicted predicate **SRL**.",
        "Concurrent to this work, \\citet{peters2018deep} and \\citet{he2018jointly} report significant gains on PropBank **SRL** by training a wide LSTM language model and using a task-specific transformation of its hidden representations (ELMo) as a deep, and computationally expensive, alternative to typical word embeddings.",
        "Some work has incorporated syntax into neural models for **SRL**.",
        "In these experiments we also compare to \\citet{tan2018deep}, the previous state-of-the art **SRL** model using gold predicates and standard embeddings.",
        "We also evaluate our model using the gold syntactic parse at test time ({\\bf +Gold}), to provide an upper bound for the benefit that syntax could have for **SRL** using LISA.",
        "In \\S\\ref{sec:analysis} we perform further analysis comparing **SRL** models using gold and predicted parses.",
        "The gap in **SRL** F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on WSJ, and more than 2.0 F1 higher on Brown.",
        "Parsing (labeled and unlabeled attachment) and POS accuracies attained by the models used in **SRL** experiments on test datasets.",
        "The difference in parse accuracy between LISA$_G$ and D\\&M likely explains the large increase in **SRL** performance we see from decoding with D\\&M parses in that setting.",
        "LISA attains high predicate detection scores, above 97 F1, on both in-domain datasets, and out-performs \\citet{he2017deep} by 1.5-2 F1 points even on the out-of-domain Brown test set, suggesting that multi-task learning works well for **SRL** predicate detection.",
        "Average **SRL** F1 on CoNLL-2005 for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "First we assess **SRL** F1 on sentences divided by parse accuracy.",
        "Table \\ref{tab:parse-srl-by-sents} lists average **SRL** F1 (across sentences) for the four conditions of LISA and D\\&M parses being correct or not ({\\bf L$\\pm$}, {\\bf D$\\pm$}).",
        "These examples are likely where gold and D\\&M parses improve the most over other models in overall F1: Though both parsers fail to correctly parse the entire sentence, the D\\&M parser is less wrong (87.5 vs. 85.7 average LAS), leading to higher **SRL** F1 by about 1.5 average F1.",
        "50\\%) even after providing the correct PP attachment to the model, indicating that PP span boundary mistakes are a fundamental difficulty for **SRL**.",
        "LISA out-performs the state-of-the-art on two benchmark **SRL** datasets, including out-of-domain.",
        "We also assess **SRL** F1 as a function of sentence length and distance from span to predicate.",
        "Average **SRL** F1 on CoNLL-2012 for sentences where LISA (L) and D\\&M (D) parses were correct (+) or incorrect (-).",
        "We evaluate the **SRL** performance of our models using the \\texttt{srl-eval.pl} script provided by the CoNLL-2005 shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "Our D\\&M parsers are trained and validated using the same **SRL** data splits, except that for CoNLL-2005 section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "We obtain 105 **SRL** labels including continuations after encoding predicate argument segment boundaries with BIO tags."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.638655,
          "top": 0.65677,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804386"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804265"
        },
        {
          "type": "sentence",
          "id": "804266"
        },
        {
          "type": "sentence",
          "id": "804269"
        },
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804271"
        },
        {
          "type": "sentence",
          "id": "804274"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804276"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804279"
        },
        {
          "type": "sentence",
          "id": "804281"
        },
        {
          "type": "sentence",
          "id": "804284"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804302"
        },
        {
          "type": "sentence",
          "id": "804311"
        },
        {
          "type": "sentence",
          "id": "804316"
        },
        {
          "type": "sentence",
          "id": "804319"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804384"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804386"
        },
        {
          "type": "sentence",
          "id": "804387"
        },
        {
          "type": "sentence",
          "id": "804390"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804391"
        },
        {
          "type": "sentence",
          "id": "804392"
        },
        {
          "type": "sentence",
          "id": "804394"
        },
        {
          "type": "sentence",
          "id": "804427"
        },
        {
          "type": "sentence",
          "id": "804430"
        },
        {
          "type": "sentence",
          "id": "804432"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804512"
        },
        {
          "type": "sentence",
          "id": "804524"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804534"
        },
        {
          "type": "sentence",
          "id": "804535"
        },
        {
          "type": "sentence",
          "id": "804539"
        },
        {
          "type": "sentence",
          "id": "804548"
        },
        {
          "type": "sentence",
          "id": "804554"
        },
        {
          "type": "sentence",
          "id": "804585"
        },
        {
          "type": "sentence",
          "id": "804595"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804627"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804264"
        },
        {
          "type": "sentence",
          "id": "804274"
        }
      ]
    }
  },
  {
    "id": "805387",
    "type": "term",
    "attributes": {
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.263866,
          "top": 0.160333,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804364"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805388",
    "type": "term",
    "attributes": {
      "snippets": [
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.428571,
          "top": 0.175772,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804364"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805389",
    "type": "term",
    "attributes": {
      "name": "POS",
      "definitions": [
        "part-of-speech"
      ],
      "definition_texs": [
        "part-of-speech"
      ],
      "sources": [
        "human-annotation"
      ],
      "snippets": [
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "Our model predicts predicates and integrates part-of-speech (**POS**) information into earlier layers by re-purposing representations closer to the input to predict predicate and **POS** tags using hard parameter sharing (\\S\\ref{sec:MTL}).",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We simplify optimization and benefit from shared statistical strength derived from highly correlated **POS** and predicates by treating tagging and predicate detection as a single task, performing multi-class classification into the joint Cartesian product space of **POS** and predicate labels.",
        "We also share the parameters of lower layers in our model to predict **POS** tags and predicates.",
        "Since we also train our model to predict syntactic dependencies, it is beneficial to give the model knowledge of **POS** information.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "While much previous work employs a pipelined approach to both **POS** tagging for dependency parsing and predicate detection for SRL, we take a multi-task learning (MTL) approach \\citep{caruana1993multitask}, sharing the parameters of earlier layers in our SRL model with a joint **POS** and predicate detection objective.",
        "Since **POS** is a strong predictor of predicates\\footnote{All predicates in CoNLL-2005 are verbs; CoNLL-2012 includes some nominal predicates.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "and the complexity of training a multi-task model increases with the number of tasks, we combine **POS** tagging and predicate detection into a joint label space: For each **POS** tag {\\sc tag} which is observed co-occurring with a predicate, we add a label of the form {\\sc tag:predicate}.",
        "For models using GloVe embeddings, our syntax-free SA model already achieves a new state-of-the-art by jointly predicting predicates, **POS** and SRL.",
        "**\n\\subsection{Parsing, POS and predicate detection \\label{sec:parse-pos-results}}**",
        "llrrr} \t\t\nData & Model & **POS** & UAS & LAS",
        "Parsing (labeled and unlabeled attachment) and **POS** accuracies attained by the models used in SRL experiments on test datasets.",
        "We use Stanford dependencies v3.5 \\citep{deMarneffe2008} and **POS** tags from the Stanford CoreNLP \\texttt{left3words} model \\citep{toutanova2003feature}."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.310924,
          "top": 0.111639,
          "width": 0.0302521,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804363"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804309"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804310"
        },
        {
          "type": "sentence",
          "id": "804359"
        },
        {
          "type": "sentence",
          "id": "804361"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804362"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804364"
        },
        {
          "type": "sentence",
          "id": "804450"
        },
        {
          "type": "sentence",
          "id": "804496"
        },
        {
          "type": "sentence",
          "id": "804497"
        },
        {
          "type": "sentence",
          "id": "804508"
        },
        {
          "type": "sentence",
          "id": "804616"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804309"
        }
      ]
    }
  },
  {
    "id": "805390",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.722689,
          "top": 0.707838,
          "width": 0.00672269,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804670"
      },
      "sentence": {
        "type": "sentence",
        "id": "804331"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805391",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.747899,
          "top": 0.498812,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804826"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805392",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.433492,
          "width": 0.00672269,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804658"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805393",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.534454,
          "top": 0.546318,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804665"
      },
      "parent": {
        "type": "symbol",
        "id": "804828"
      },
      "sentence": {
        "type": "sentence",
        "id": "804326"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805394",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.603361,
          "top": 0.450119,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804660"
      },
      "parent": {
        "type": "symbol",
        "id": "804820"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805395",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.586555,
          "top": 0.497625,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804663"
      },
      "parent": {
        "type": "symbol",
        "id": "804818"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805396",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.870588,
          "top": 0.739905,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804673"
      },
      "parent": {
        "type": "symbol",
        "id": "804865"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805397",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>j</mi>",
      "tex": "$j$",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.805042,
          "top": 0.77791,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804678"
      },
      "parent": {
        "type": "symbol",
        "id": "804860"
      },
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805398",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.870588,
          "top": 0.7981,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804681"
      },
      "parent": {
        "type": "symbol",
        "id": "804851"
      },
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805399",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.4,
          "top": 0.14133,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804910"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805400",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.141176,
          "top": 0.188836,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804683"
      },
      "parent": {
        "type": "symbol",
        "id": "804912"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805401",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.322689,
          "top": 0.241093,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804685"
      },
      "parent": {
        "type": "symbol",
        "id": "804901"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805402",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.242017,
          "top": 0.293349,
          "width": 0.00672269,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804689"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805403",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.321008,
          "top": 0.328979,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804692"
      },
      "parent": {
        "type": "symbol",
        "id": "804913"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805404",
    "type": "symbol",
    "attributes": {
      "tex": "$j$",
      "mathml": "<mi>j</mi>",
      "mathml_near_matches": [
        "<mi>j</mi>"
      ],
      "snippets": [
        "Denoting the ${\\htmlClass{match-highlight}{j}}$th self-attention layer as $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$, the output of that layer $s_t^{({\\htmlClass{match-highlight}{j}})}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{({\\htmlClass{match-highlight}{j}})} = LN(s_t^{({\\htmlClass{match-highlight}{j}}-1)} + T^{({\\htmlClass{match-highlight}{j}})}(s_t^{({\\htmlClass{match-highlight}{j}}-1)}))\n\\end{align}\ngives our final token representations $s_t^{({\\htmlClass{match-highlight}{j}})}$.",
        "Each $T^{({\\htmlClass{match-highlight}{j}})}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{({\\htmlClass{match-highlight}{j}}-1)}$ of $T$ token representations at layer ${\\htmlClass{match-highlight}{j}}-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{({\\htmlClass{match-highlight}{j}})}$, $V_h^{({\\htmlClass{match-highlight}{j}})}$ and $Q_h^{({\\htmlClass{match-highlight}{j}})}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_h^{({\\htmlClass{match-highlight}{j}})}$ by $K_h^{({\\htmlClass{match-highlight}{j}})}$ to obtain a $T\\times T$ matrix of attention weights $A_h^{({\\htmlClass{match-highlight}{j}})}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{({\\htmlClass{match-highlight}{j}})} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{({\\htmlClass{match-highlight}{j}})}{K_h^{({\\htmlClass{match-highlight}{j}})}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{({\\htmlClass{match-highlight}{j}})}$ for each token to obtain the self-attended token representations $M_h^{({\\htmlClass{match-highlight}{j}})}$:\n\\begin{align}\nM_h^{({\\htmlClass{match-highlight}{j}})} = A_h^{({\\htmlClass{match-highlight}{j}})}V_h^{({\\htmlClass{match-highlight}{j}})}\n\\end{align}\nRow $t$ of $M_h^{({\\htmlClass{match-highlight}{j}})}$, the self-attended representation for token $t$ at layer ${\\htmlClass{match-highlight}{j}}$, is thus the weighted sum with respect to $t$ (with weights given by $A_h^{({\\htmlClass{match-highlight}{j}})}$) over the token representations in $V_h^{({\\htmlClass{match-highlight}{j}})}$.",
        "We add the output of the feed-forward to the initial representation and apply layer normalization to give the final output of self-attention layer ${\\htmlClass{match-highlight}{j}}$, as in Eqn."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "index of self-attention layer",
        "layer",
        "self-attention layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.364706,
          "top": 0.14133,
          "width": 0.00504202,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804859"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804336"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805405",
    "type": "symbol",
    "attributes": {
      "tex": "$p$",
      "mathml": "<mi>p</mi>",
      "mathml_near_matches": [
        "<mi>p</mi>",
        "<msub><mi>p</mi><mi>t</mi></msub>",
        "<msup><mi>p</mi><mrow><mo>−</mo><mn>0.5</mn></mrow></msup>"
      ],
      "snippets": [
        "In layer ${\\htmlClass{match-highlight}{p}}$ one attention head is trained to attend to parse parents (Figure \\ref{attention-fig}).",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding ${\\htmlClass{match-highlight}{p}}_t$ following previous work \\citep{he2017deep}.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{({\\htmlClass{match-highlight}{p}})}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + T^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Specifically, we feed the representation $s_t^{(r)}$ from a layer $r$ preceding the syntactically-informed layer ${\\htmlClass{match-highlight}{p}}$ to a linear classifier to produce per-class scores $r_t$ for token $t$.",
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $lr_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = lr_0 \\cdot \\min(ste{\\htmlClass{match-highlight}{p}}^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "syntactically-informed layer"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.245378,
          "top": 0.258907,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804724"
      },
      "sentence": {
        "type": "sentence",
        "id": "804365"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804293"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804293"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805406",
    "type": "symbol",
    "attributes": {
      "tex": "$r$",
      "mathml": "<mi>r</mi>",
      "mathml_near_matches": [
        "<mi>r</mi>",
        "<msub><mi>r</mi><mn>0</mn></msub>",
        "<msub><mi>r</mi><mi>t</mi></msub>"
      ],
      "snippets": [
        "Layer ${\\htmlClass{match-highlight}{r}}$ is input for a joint predicate/POS classifier.",
        "Representations from layer ${\\htmlClass{match-highlight}{r}}$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token SRL predictions with respect to each predicted predicate.",
        "Specifically, we feed the representation $s_t^{({\\htmlClass{match-highlight}{r}})}$ from a layer ${\\htmlClass{match-highlight}{r}}$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores ${\\htmlClass{match-highlight}{r}}_t$ for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{r}}_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $l{\\htmlClass{match-highlight}{r}}_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = l{\\htmlClass{match-highlight}{r}}_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "layer",
        "layer"
      ],
      "definitions": [
        "input for a joint predicate/POS classifier"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.47395,
          "top": 0.22209,
          "width": 0.00504202,
          "height": 0.00356295
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804722"
      },
      "parent": {
        "type": "symbol",
        "id": "805006"
      },
      "sentence": {
        "type": "sentence",
        "id": "804365"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        }
      ],
      "children": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805407",
    "type": "symbol",
    "attributes": {
      "tex": "$r$",
      "mathml": "<mi>r</mi>",
      "mathml_near_matches": [
        "<mi>r</mi>",
        "<msub><mi>r</mi><mn>0</mn></msub>",
        "<msub><mi>r</mi><mi>t</mi></msub>"
      ],
      "snippets": [
        "Layer ${\\htmlClass{match-highlight}{r}}$ is input for a joint predicate/POS classifier.",
        "Representations from layer ${\\htmlClass{match-highlight}{r}}$ corresponding to predicted predicates are passed to  a bilinear operation scoring distinct predicate and role representations to produce per-token SRL predictions with respect to each predicted predicate.",
        "Specifically, we feed the representation $s_t^{({\\htmlClass{match-highlight}{r}})}$ from a layer ${\\htmlClass{match-highlight}{r}}$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores ${\\htmlClass{match-highlight}{r}}_t$ for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{r}}_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We additionally vary the learning rate $lr$ as a function of an initial learning rate $l{\\htmlClass{match-highlight}{r}}_0$ and the current training step $step$ as described in \\citet{vaswani2017attention} using the following function:\n\\begin{align}\nlr = l{\\htmlClass{match-highlight}{r}}_0 \\cdot \\min(step^{-0.5},  step\\cdot warm^{-1.5})\n\\end{align}\nwhich increases the learning rate linearly for the first $warm$ training steps, then decays it proportionally to the inverse square root of the step number."
      ],
      "is_definition": false,
      "nicknames": [
        "layer",
        "layer",
        "layer"
      ],
      "definitions": [
        "input for a joint predicate/POS classifier"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.346218,
          "top": 0.307601,
          "width": 0.00840336,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804727"
      },
      "parent": {
        "type": "symbol",
        "id": "805011"
      },
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804630"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        },
        {
          "type": "sentence",
          "id": "804295"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804294"
        }
      ],
      "children": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805408",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.65042,
          "top": 0.352732,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804656"
      },
      "sentence": {
        "type": "sentence",
        "id": "804323"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805409",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "tex": "$t$",
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.84874,
          "top": 0.476247,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804662"
      },
      "parent": {
        "type": "symbol",
        "id": "804817"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805410",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.808403,
          "top": 0.536817,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804664"
      },
      "parent": {
        "type": "symbol",
        "id": "804819"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805411",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.734454,
          "top": 0.207838,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804710"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805412",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.223529,
          "top": 0.309976,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804727"
      },
      "parent": {
        "type": "symbol",
        "id": "805008"
      },
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805413",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.332773,
          "top": 0.505938,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804733"
      },
      "parent": {
        "type": "symbol",
        "id": "805016"
      },
      "sentence": {
        "type": "sentence",
        "id": "804370"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805414",
    "type": "symbol",
    "attributes": {
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.260504,
          "top": 0.564133,
          "width": 0.00672269,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804738"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805415",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe (${\\htmlClass{match-highlight}{G}}$) and ELMo ($E$) embeddings.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_{\\htmlClass{match-highlight}{G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_{\\htmlClass{match-highlight}{G}}$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{V}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.92 & 94.92 & 91.87",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 94.26 & 90.31 & 85.82",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.81 & 93.35 & 90.42",
        "Subscript ${\\htmlClass{match-highlight}{G}}$ denotes GloVe and $E$ ELMo embeddings.",
        "The difference in parse accuracy between LISA$_{\\htmlClass{match-highlight}{G}}$ and D\\&M likely explains the large increase in SRL performance we see from decoding with D\\&M parses in that setting."
      ],
      "tex": "$G$",
      "mathml": "<mi>G</mi>",
      "mathml_near_matches": [
        "<mi>G</mi>"
      ],
      "is_definition": false,
      "definitions": [
        "GloVe embeddings"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.352941,
          "top": 0.850356,
          "width": 0.010084,
          "height": 0.00712589
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804746"
      },
      "parent": {
        "type": "symbol",
        "id": "805057"
      },
      "sentence": {
        "type": "sentence",
        "id": "804377"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804499"
        },
        {
          "type": "sentence",
          "id": "804502"
        },
        {
          "type": "sentence",
          "id": "804505"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804512"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804509"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805416",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>G</mi>",
      "mathml_near_matches": [
        "<mi>G</mi>"
      ],
      "tex": "$G$",
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_{\\htmlClass{match-highlight}{G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_{\\htmlClass{match-highlight}{G}}$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{V}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.92 & 94.92 & 91.87",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 94.26 & 90.31 & 85.82",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.81 & 93.35 & 90.42",
        "Subscript ${\\htmlClass{match-highlight}{G}}$ denotes GloVe and $E$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe (${\\htmlClass{match-highlight}{G}}$) and ELMo ($E$) embeddings.",
        "The difference in parse accuracy between LISA$_{\\htmlClass{match-highlight}{G}}$ and D\\&M likely explains the large increase in SRL performance we see from decoding with D\\&M parses in that setting."
      ],
      "is_definition": false,
      "definitions": [
        "GloVe embeddings"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.563025,
          "top": 0.301663,
          "width": 0.0117647,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804762"
      },
      "sentence": {
        "type": "sentence",
        "id": "804509"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804499"
        },
        {
          "type": "sentence",
          "id": "804502"
        },
        {
          "type": "sentence",
          "id": "804505"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804512"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804509"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805417",
    "type": "symbol",
    "attributes": {
      "tex": "$G$",
      "mathml": "<mi>G</mi>",
      "mathml_near_matches": [
        "<mi>G</mi>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_{\\htmlClass{match-highlight}{G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_{\\htmlClass{match-highlight}{G}}$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{V}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.92 & 94.92 & 91.87",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 94.26 & 90.31 & 85.82",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.81 & 93.35 & 90.42",
        "Subscript ${\\htmlClass{match-highlight}{G}}$ denotes GloVe and $E$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe (${\\htmlClass{match-highlight}{G}}$) and ELMo ($E$) embeddings.",
        "The difference in parse accuracy between LISA$_{\\htmlClass{match-highlight}{G}}$ and D\\&M likely explains the large increase in SRL performance we see from decoding with D\\&M parses in that setting."
      ],
      "is_definition": false,
      "definitions": [
        "GloVe embeddings"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.183193,
          "top": 0.814727,
          "width": 0.0117647,
          "height": 0.00950119
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804764"
      },
      "sentence": {
        "type": "sentence",
        "id": "804510"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804499"
        },
        {
          "type": "sentence",
          "id": "804502"
        },
        {
          "type": "sentence",
          "id": "804505"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804512"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804509"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805418",
    "type": "symbol",
    "attributes": {
      "mathml": "<msub><mi>A</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<mi>A</mi>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A_{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "Let ${\\htmlClass{match-highlight}{A_{parse}}}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A_{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A_{parse}}}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A_{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A_{parse}}}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A_{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "tex": "$A_{parse}$",
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n"
      ],
      "is_definition": true,
      "definitions": [
        "parse attention weights at layer $i$",
        "parse parents produced by e.g. a state-of-the-art parser"
      ],
      "nicknames": [
        "attention weights",
        "attention weights",
        "attention weight from token $t$ to a candidate head $q$",
        "attention weights",
        "attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.144538,
          "top": 0.893112,
          "width": 0.0453782,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804936"
        },
        {
          "type": "symbol",
          "id": "804937"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804706"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "805419",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>A</mi>",
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "tex": "$A_{parse}$",
      "mathml": "<msub><mi>A</mi><mi>parse</mi></msub>",
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A_{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "Let ${\\htmlClass{match-highlight}{A_{parse}}}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A_{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A_{parse}}}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A_{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A_{parse}}}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A_{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n"
      ],
      "is_definition": false,
      "definitions": [
        "parse attention weights at layer $i$",
        "parse parents produced by e.g. a state-of-the-art parser"
      ],
      "nicknames": [
        "attention weights",
        "attention weights",
        "attention weight from token $t$ to a candidate head $q$",
        "attention weights",
        "attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.747899,
          "top": 0.254157,
          "width": 0.0453782,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804713"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804965"
        },
        {
          "type": "symbol",
          "id": "804966"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804706"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "805420",
    "type": "symbol",
    "attributes": {
      "tex": "$A_{parse}$",
      "mathml": "<msub><mi>A</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<mi>A</mi>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A_{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "Let ${\\htmlClass{match-highlight}{A_{parse}}}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A_{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A_{parse}}}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A_{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A_{parse}}}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A_{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n"
      ],
      "is_definition": false,
      "definitions": [
        "parse attention weights at layer $i$",
        "parse parents produced by e.g. a state-of-the-art parser"
      ],
      "nicknames": [
        "attention weights",
        "attention weights",
        "attention weight from token $t$ to a candidate head $q$",
        "attention weights",
        "attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.65042,
          "top": 0.573634,
          "width": 0.0453782,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804721"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804977"
        },
        {
          "type": "symbol",
          "id": "804978"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804355"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804706"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "805421",
    "type": "symbol",
    "attributes": {
      "snippets": [
        "Attention weights ${\\htmlClass{match-highlight}{A_{parse}}}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values $V_{parse}$.",
        "Let ${\\htmlClass{match-highlight}{A_{parse}}}$ be the parse attention weights, at layer $i$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\n{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as ${\\htmlClass{match-highlight}{A_{parse}}}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{X}) = {\\htmlClass{match-highlight}{A_{parse}}}[t, q]\n\\end{align}\nusing the attention weights ${\\htmlClass{match-highlight}{A_{parse}}}[t]$ as the distribution over possible heads for token $t$.",
        "where each token's parent is the token to which the attention ${\\htmlClass{match-highlight}{A_{parse}}}$ assigns the highest weight.",
        "This model not only predicts its own dependency arcs, but allows for the injection of auxiliary parse information at test time by simply setting ${\\htmlClass{match-highlight}{A_{parse}}}$ to the parse parents produced by e.g.\\ a state-of-the-art parser."
      ],
      "tex": "$A_{parse}$",
      "mathml": "<msub><mi>A</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>A</mi><mi>parse</mi></msub>",
        "<mi>A</mi>",
        "<msubsup><mi>A</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "defining_formulas": [
        "{\\htmlClass{match-highlight}{A_{parse}}} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n"
      ],
      "is_definition": false,
      "definitions": [
        "parse attention weights at layer $i$",
        "parse parents produced by e.g. a state-of-the-art parser"
      ],
      "nicknames": [
        "attention weights",
        "attention weights",
        "attention weight from token $t$ to a candidate head $q$",
        "attention weights",
        "attention"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.722689,
          "top": 0.283848,
          "width": 0.0436975,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804714"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804967"
        },
        {
          "type": "symbol",
          "id": "804968"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804706"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804351"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804342"
        },
        {
          "type": "sentence",
          "id": "804355"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      }
    }
  },
  {
    "id": "805422",
    "type": "symbol",
    "attributes": {
      "is_definition": false,
      "tex": "$t$",
      "mathml": "<mi>t</mi>",
      "mathml_near_matches": [
        "<mi>t</mi>"
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[ \\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of $T$ token representations $x_{\\htmlClass{match-highlight}{t}}$.",
        "For experiments with gold predicates, we concatenate a predicate indicator embedding $p_{\\htmlClass{match-highlight}{t}}$ following previous work \\citep{he2017deep}.",
        "We then add a positional encoding vector computed as a deterministic sinusoidal function of ${\\htmlClass{match-highlight}{t}}$, since the self-attention has no innate notion of token position.",
        "Denoting the $j$th self-attention layer as $T^{(j)}(\\cdot)$, the output of that layer $s_{\\htmlClass{match-highlight}{t}}^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_{\\htmlClass{match-highlight}{t}}^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_{\\htmlClass{match-highlight}{t}}^{(j)} = LN(s_{\\htmlClass{match-highlight}{t}}^{(j-1)} + T^{(j)}(s_{\\htmlClass{match-highlight}{t}}^{(j-1)}))\n\\end{align}\ngives our final token representations $s_{\\htmlClass{match-highlight}{t}}^{(j)}$.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_h^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_h^{(j)}{K_h^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_h^{(j)}$ for each token to obtain the self-attended token representations $M_h^{(j)}$:\n\\begin{align}\nM_h^{(j)} = A_h^{(j)}V_h^{(j)}\n\\end{align}\nRow ${\\htmlClass{match-highlight}{t}}$ of $M_h^{(j)}$, the self-attended representation for token ${\\htmlClass{match-highlight}{t}}$ at layer $j$, is thus the weighted sum with respect to ${\\htmlClass{match-highlight}{t}}$ (with weights given by $A_h^{(j)}$) over the token representations in $V_h^{(j)}$.",
        "Denoting the attention weight from token ${\\htmlClass{match-highlight}{t}}$ to a candidate head $q$ as $A_{parse}[{\\htmlClass{match-highlight}{t}},q]$, we model the probability of token ${\\htmlClass{match-highlight}{t}}$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}({\\htmlClass{match-highlight}{t}}) \\mid \\mathcal{X}) = A_{parse}[{\\htmlClass{match-highlight}{t}}, q]\n\\end{align}\nusing the attention weights $A_{parse}[{\\htmlClass{match-highlight}{t}}]$ as the distribution over possible heads for token ${\\htmlClass{match-highlight}{t}}$.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_{\\htmlClass{match-highlight}{t}}^{dep}$ given by the softmax function.",
        "Specifically, we feed the representation $s_{\\htmlClass{match-highlight}{t}}^{(r)}$ from a layer $r$ preceding the syntactically-informed layer $p$ to a linear classifier to produce per-class scores $r_{\\htmlClass{match-highlight}{t}}$ for token ${\\htmlClass{match-highlight}{t}}$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_{\\htmlClass{match-highlight}{t}}^{prp} \\mid \\mathcal{X}) \\propto \\exp(r_{\\htmlClass{match-highlight}{t}})$, where $y_{\\htmlClass{match-highlight}{t}}^{prp}$ is a label in the joint space.",
        "First, we project each token representation $s_{\\htmlClass{match-highlight}{t}}^{(J)}$ to a predicate-specific representation $s_{\\htmlClass{match-highlight}{t}}^{pred}$ and a role-specific representation $s_{\\htmlClass{match-highlight}{t}}^{role}$.",
        "So, the role label scores $s_{ft}$ for the token at index ${\\htmlClass{match-highlight}{t}}$ with respect to the predicate at index $f$ (i.e. token ${\\htmlClass{match-highlight}{t}}$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_f^{pred})^T U s_{\\htmlClass{match-highlight}{t}}^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token ${\\htmlClass{match-highlight}{t}}$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{{\\htmlClass{match-highlight}{t}}=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_{\\htmlClass{match-highlight}{t}}^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}({\\htmlClass{match-highlight}{t}})\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_{\\htmlClass{match-highlight}{t}}^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "nicknames": [
        "row of $M_h^{(j)}$",
        "token",
        "index",
        "token",
        "token",
        "token",
        "token",
        "token"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.435294,
          "top": 0.309976,
          "width": 0.00336134,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804728"
      },
      "parent": {
        "type": "symbol",
        "id": "805007"
      },
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804320"
        },
        {
          "type": "sentence",
          "id": "804323"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804365"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804370"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804334"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804365"
        }
      ],
      "children": [],
      "definition_sentences": []
    }
  },
  {
    "id": "805423",
    "type": "symbol",
    "attributes": {
      "tex": "$q$",
      "mathml": "<mi>q</mi>",
      "mathml_near_matches": [
        "<mi>q</mi>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_{\\htmlClass{match-highlight}{q}}$, and $T\\times d_v$, respectively.",
        "Denoting the attention weight from token $t$ to a candidate head ${\\htmlClass{match-highlight}{q}}$ as $A_{parse}[t,{\\htmlClass{match-highlight}{q}}]$, we model the probability of token $t$ having parent ${\\htmlClass{match-highlight}{q}}$ as:\n\\begin{align}\nP({\\htmlClass{match-highlight}{q}}=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, {\\htmlClass{match-highlight}{q}}]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$."
      ],
      "is_definition": false,
      "nicknames": [
        "candidate head",
        "parent"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.591597,
          "top": 0.256532,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804713"
      },
      "parent": {
        "type": "symbol",
        "id": "804950"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805424",
    "type": "symbol",
    "attributes": {
      "tex": "$q$",
      "mathml": "<mi>q</mi>",
      "mathml_near_matches": [
        "<mi>q</mi>"
      ],
      "snippets": [
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_{\\htmlClass{match-highlight}{q}}$, and $T\\times d_v$, respectively.",
        "Denoting the attention weight from token $t$ to a candidate head ${\\htmlClass{match-highlight}{q}}$ as $A_{parse}[t,{\\htmlClass{match-highlight}{q}}]$, we model the probability of token $t$ having parent ${\\htmlClass{match-highlight}{q}}$ as:\n\\begin{align}\nP({\\htmlClass{match-highlight}{q}}=\\mathrm{head}(t) \\mid \\mathcal{X}) = A_{parse}[t, {\\htmlClass{match-highlight}{q}}]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$."
      ],
      "is_definition": false,
      "nicknames": [
        "candidate head",
        "parent"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.815126,
          "top": 0.256532,
          "width": 0.00840336,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804713"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804348"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805425",
    "type": "symbol",
    "attributes": {
      "tex": "$s_{ft}$",
      "mathml": "<msub><mi>s</mi><mi>ft</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>s</mi><mi>ft</mi></msub>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>J</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>s</mi>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo>−</mo><mn>1</mn><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mi>role</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>r</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msubsup><mi>s</mi><mi>f</mi><mi>pred</mi></msubsup>",
        "<msubsup><mi>s</mi><mi>t</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "So, the role label scores ${\\htmlClass{match-highlight}{s_{ft}}}$ for the token at index $t$ with respect to the predicate at index $f$ (i.e. token $t$ and frame $f$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\n{\\htmlClass{match-highlight}{s_{ft}}} = (s_f^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp({\\htmlClass{match-highlight}{s_{ft}}})$.",
        "At test time, we perform constrained decoding using the Viterbi algorithm to emit valid sequences of BIO tags, using unary scores ${\\htmlClass{match-highlight}{s_{ft}}}$ and the transition probabilities given by the training data."
      ],
      "defining_formulas": [
        "                    \n{\\htmlClass{match-highlight}{s_{ft}}} = (s_f^{pred})^T U s_t^{role}\n"
      ],
      "is_definition": false,
      "nicknames": [
        "role label scores for the token at index $t$ with respect to the predicate at index $f$",
        "unary scores"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.357983,
          "top": 0.73753,
          "width": 0.0184874,
          "height": 0.0106888
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804744"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805033"
        },
        {
          "type": "symbol",
          "id": "805034"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804374"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804740"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804374"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805426",
    "type": "symbol",
    "attributes": {
      "tex": "$f$",
      "mathml": "<mi>f</mi>",
      "mathml_near_matches": [
        "<mi>f</mi>"
      ],
      "snippets": [
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index ${\\htmlClass{match-highlight}{f}}$ (i.e. token $t$ and frame ${\\htmlClass{match-highlight}{f}}$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_{\\htmlClass{match-highlight}{f}}^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame ${\\htmlClass{match-highlight}{f}}$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{{\\htmlClass{match-highlight}{f}}=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{t=1}^T\\Big[ \\sum_{{\\htmlClass{match-highlight}{f}}=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": false,
      "nicknames": [
        "index",
        "frame",
        "frame"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.272269,
          "top": 0.669834,
          "width": 0.00840336,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804742"
      },
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805427",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>f</mi>",
      "mathml_near_matches": [
        "<mi>f</mi>"
      ],
      "tex": "$f$",
      "snippets": [
        "So, the role label scores $s_{ft}$ for the token at index $t$ with respect to the predicate at index ${\\htmlClass{match-highlight}{f}}$ (i.e. token $t$ and frame ${\\htmlClass{match-highlight}{f}}$) are given by:\n\\begin{align}\n\\label{eqn:bilinear}\ns_{ft} = (s_{\\htmlClass{match-highlight}{f}}^{pred})^T U s_t^{role}\n\\end{align}\nwhich can be computed in parallel across all semantic frames in an entire minibatch.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame ${\\htmlClass{match-highlight}{f}}$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{X}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{{\\htmlClass{match-highlight}{f}}=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "defining_formulas": [
        "\\frac{1}{T}\\sum_{t=1}^T\\Big[ \\sum_{{\\htmlClass{match-highlight}{f}}=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X})           \\\\ %\\label{eqn:srl-term} \\\\ \n + \\log P(y_t^{prp}\\mid \\mathcal{X})           \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n + \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X})           \\\\ %\\label{eqn:head-term}\\\\\n + \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X})                      \\Big]\n"
      ],
      "is_definition": true,
      "nicknames": [
        "index",
        "frame",
        "frame"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.62521,
          "top": 0.186461,
          "width": 0.00504202,
          "height": 0.00950119
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "defining_formula_equations": [
        {
          "type": "equation",
          "id": "804750"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804373"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": []
    }
  },
  {
    "id": "805428",
    "type": "term",
    "attributes": {
      "snippets": [
        "Predicate detection precision, recall and F1 on **CoNLL-2005** and CoNLL-2012 test sets.",
        "**CoNLL-2005** F1 score as a function of the distance of the predicate from the argument span.",
        "In experiments on **CoNLL-2005** SRL, LISA achieves new state-of-the-art performance for a model using predicted predicates and standard word embeddings, attaining 2.5 F1 absolute higher than the previous state-of-the-art on newswire and more than 3.5 F1 on out-of-domain data, nearly 10\\% reduction in error.",
        "In experiments on the **CoNLL-2005** and CoNLL-2012 datasets we show that our linguistically-informed models out-perform the syntax-free state-of-the-art.",
        "On **CoNLL-2005** with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the WSJ test set by 2.5 F1 points absolute.",
        "Our models also show improvements when using contextually-encoded word representations \\citep{peters2018deep}, obtaining nearly 1.0 F1 higher than the state-of-the-art on **CoNLL-2005** news and more than 2.0 F1 improvement on out-of-domain text.\\footnote{Our implementation in TensorFlow \\citep{abadi2015tensorflow} is available at : \\protect\\url{http://github.com/strubell/LISA",
        "Since POS is a strong predictor of predicates\\footnote{All predicates in **CoNLL-2005** are verbs; CoNLL-2012 includes some nominal predicates.",
        "+Gold} & \\emph{88.51} & \\emph{86.77} & \\emph{87.63} && --- & --- & --- && --- & --- & ---\n\\end{tabular}\n% \\caption{Precision, recall and F1 on the CoNLL-2005 development and test sets. Our model out-performs the baseline both in- and out-of-domain. \\label{tab:conll05-results}}\n\\caption{Precision, recall and F1 on the **CoNLL-2005** development and test sets.",
        "We present results on the **CoNLL-2005** shared task \\citep{carreras2005introduction} and the CoNLL-2012 English subset of OntoNotes 5.0 \\citep{pradhan2013towards}, achieving state-of-the-art results for a single model with predicted predicates on both corpora.",
        "To compare to more prior work, we present additional results on **CoNLL-2005** with models given gold predicates at test time.",
        "Precision, recall and F1 on **CoNLL-2005** with gold predicates.",
        "Table~\\ref{tab:conll05-results} lists precision, recall and F1 on the **CoNLL-2005** development and test sets using predicted predicates.",
        "Like **CoNLL-2005**, ELMo representations improve all models and close the F1 gap between models supplied with LISA and D\\&M parses.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the **CoNLL-2005** and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe ($G$) and ELMo ($E$) embeddings.",
        "In Table~\\ref{tab:preds} we present predicate detection precision, recall and F1 on the **CoNLL-2005** and 2012 test sets.",
        "We compare to \\citet{he2017deep} on **CoNLL-2005**, the only cited work reporting comparable predicate detection F1.",
        "Average SRL F1 on **CoNLL-2005** for sentences where LISA (L) and D\\&M (D) parses were completely correct (+) or incorrect (--).",
        "Performance of **CoNLL-2005** models after performing corrections from \\citet{he2017deep}.",
        "All experiments in this section are performed on **CoNLL-2005** development data unless stated otherwise.",
        "llll}\n**CoNLL-2005** & Greedy F1 & Viterbi F1 & $\\Delta$ F1",
        "Table \\ref{tab:conll05-gold-pred-dev} lists development scores on the **CoNLL-2005** dataset with predicted predicates, which follow the same trends as the test data.",
        "Precision, recall and F1 on the **CoNLL-2005** development set with gold predicates.",
        "We evaluate the SRL performance of our models using the \\texttt{srl-eval.pl} script provided by the **CoNLL-2005** shared task,\\footnote{\\protect\\url{http://www.lsi.upc.es/~srlconll/srl-eval.pl}} which computes segment-level precision, recall and F1 score.",
        "We train distinct D\\&M parsers for **CoNLL-2005** and CoNLL-2012.",
        "Our D\\&M parsers are trained and validated using the same SRL data splits, except that for **CoNLL-2005** section 22 is used for development (rather than 24), as this section is typically used for validation in PTB parsing.",
        "** \n\n\\subsubsection{CoNLL-2005}\n**",
        "The **CoNLL-2005** data \\citep{carreras2005introduction} is based on the original PropBank corpus \\citep{palmer2005proposition}, which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) \\citep{marcus1993building} with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus \\citep{francis1964manual}."
      ],
      "name": "CoNLL-2005",
      "sources": [
        "human-annotation"
      ],
      "definitions": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "definition_texs": [
        "dataset (Carreras and Màrquez, 2005) based on the original PropBank corpus (Palmer et al., 2005), which labels the Wall Street Journal portion of the Penn TreeBank corpus (PTB) (Marcus et al., 1993) with predicate-argument structures, plus a challenging out-of-domain test set derived from the Brown corpus (Francis and Kučera, 1964)"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.820168,
          "top": 0.876485,
          "width": 0.0621849,
          "height": 0.00831354
        }
      ],
      "term_type": null,
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "804607"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804270"
        },
        {
          "type": "sentence",
          "id": "804286"
        },
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "94130"
        },
        {
          "type": "sentence",
          "id": "804363"
        },
        {
          "type": "sentence",
          "id": "804418"
        },
        {
          "type": "sentence",
          "id": "804420"
        },
        {
          "type": "sentence",
          "id": "804426"
        },
        {
          "type": "sentence",
          "id": "804446"
        },
        {
          "type": "sentence",
          "id": "804449"
        },
        {
          "type": "sentence",
          "id": "804462"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804519"
        },
        {
          "type": "sentence",
          "id": "804521"
        },
        {
          "type": "sentence",
          "id": "804523"
        },
        {
          "type": "sentence",
          "id": "804531"
        },
        {
          "type": "sentence",
          "id": "804542"
        },
        {
          "type": "sentence",
          "id": "804565"
        },
        {
          "type": "sentence",
          "id": "804566"
        },
        {
          "type": "sentence",
          "id": "804583"
        },
        {
          "type": "sentence",
          "id": "804599"
        },
        {
          "type": "sentence",
          "id": "804607"
        },
        {
          "type": "sentence",
          "id": "804611"
        },
        {
          "type": "sentence",
          "id": "804614"
        },
        {
          "type": "sentence",
          "id": "804615"
        },
        {
          "type": "sentence",
          "id": "804624"
        },
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ]
    }
  },
  {
    "id": "805429",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>T</mi>"
      ],
      "is_definition": false,
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.631933,
          "top": 0.799287,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804680"
      },
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805430",
    "type": "sentence",
    "attributes": {
      "text": "Dev & WSJ Test & Brown Test",
      "tex": "Dev & WSJ Test & Brown Test",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 6,
          "left": 0.365797,
          "top": 0.0742656,
          "width": 0.47121,
          "height": 0.0139567
        }
      ],
      "tex_start": null,
      "tex_end": null
    },
    "relationships": {}
  },
  {
    "id": "805431",
    "type": "sentence",
    "attributes": {
      "text": "Precision, recall and F1 on the CoNLL-2005 development and test sets.",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 6,
          "left": 0.268535,
          "top": 0.334346,
          "width": 0.518931,
          "height": 0.0144186
        }
      ],
      "tex": null,
      "tex_start": null,
      "tex_end": null
    },
    "relationships": {}
  },
  {
    "id": "805432",
    "type": "sentence",
    "attributes": {
      "text": "CoNLL-2005Greedy F1Viterbi F1∆F1",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 12,
          "left": 0.444299,
          "top": 0.0765552,
          "width": 0.0387464,
          "height": 0.016657
        },
        {
          "source": "human-annotation",
          "page": 12,
          "left": 0.35059,
          "top": 0.0765552,
          "width": 0.0738811,
          "height": 0.0144186
        },
        {
          "source": "human-annotation",
          "page": 12,
          "left": 0.253789,
          "top": 0.0765552,
          "width": 0.0769531,
          "height": 0.0144186
        },
        {
          "source": "human-annotation",
          "page": 12,
          "left": 0.135342,
          "top": 0.0765552,
          "width": 0.0986016,
          "height": 0.0144186
        }
      ],
      "tex": null,
      "tex_start": null,
      "tex_end": null
    },
    "relationships": {}
  },
  {
    "id": "805433",
    "type": "sentence",
    "attributes": {
      "text": "WSJ Test & P & R & F1",
      "tex": "WSJ Test & P & R & F1",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 6,
          "left": 0.15119,
          "top": 0.374158,
          "width": 0.284532,
          "height": 0.0133628
        }
      ],
      "tex_start": null,
      "tex_end": null
    },
    "relationships": {}
  },
  {
    "id": "805434",
    "type": "symbol",
    "attributes": {
      "tex": "$G$",
      "mathml": "<mi>G</mi>",
      "mathml_near_matches": [
        "<mi>G</mi>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_{\\htmlClass{match-highlight}{G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_{\\htmlClass{match-highlight}{G}}$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{V}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.92 & 94.92 & 91.87",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 94.26 & 90.31 & 85.82",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.81 & 93.35 & 90.42",
        "Subscript ${\\htmlClass{match-highlight}{G}}$ denotes GloVe and $E$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe (${\\htmlClass{match-highlight}{G}}$) and ELMo ($E$) embeddings.",
        "The difference in parse accuracy between LISA$_{\\htmlClass{match-highlight}{G}}$ and D\\&M likely explains the large increase in SRL performance we see from decoding with D\\&M parses in that setting."
      ],
      "is_definition": false,
      "definitions": [
        "GloVe embeddings"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.794958,
          "top": 0.172209,
          "width": 0.010084,
          "height": 0.00712589
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805093"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804499"
        },
        {
          "type": "sentence",
          "id": "804502"
        },
        {
          "type": "sentence",
          "id": "804505"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804512"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804509"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805435",
    "type": "symbol",
    "attributes": {
      "tex": "$G$",
      "mathml": "<mi>G</mi>",
      "mathml_near_matches": [
        "<mi>G</mi>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_{\\htmlClass{match-highlight}{G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_{\\htmlClass{match-highlight}{G}}$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{V}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.92 & 94.92 & 91.87",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 94.26 & 90.31 & 85.82",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.81 & 93.35 & 90.42",
        "Subscript ${\\htmlClass{match-highlight}{G}}$ denotes GloVe and $E$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe (${\\htmlClass{match-highlight}{G}}$) and ELMo ($E$) embeddings.",
        "The difference in parse accuracy between LISA$_{\\htmlClass{match-highlight}{G}}$ and D\\&M likely explains the large increase in SRL performance we see from decoding with D\\&M parses in that setting."
      ],
      "is_definition": false,
      "definitions": [
        "GloVe embeddings"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.764706,
          "top": 0.172209,
          "width": 0.010084,
          "height": 0.00712589
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805104"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804499"
        },
        {
          "type": "sentence",
          "id": "804502"
        },
        {
          "type": "sentence",
          "id": "804505"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804512"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804509"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805436",
    "type": "symbol",
    "attributes": {
      "mathml": "<mi>G</mi>",
      "mathml_near_matches": [
        "<mi>G</mi>"
      ],
      "tex": "$G$",
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_{\\htmlClass{match-highlight}{G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_{\\htmlClass{match-highlight}{G}}$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{V}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.92 & 94.92 & 91.87",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 94.26 & 90.31 & 85.82",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.81 & 93.35 & 90.42",
        "Subscript ${\\htmlClass{match-highlight}{G}}$ denotes GloVe and $E$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe (${\\htmlClass{match-highlight}{G}}$) and ELMo ($E$) embeddings.",
        "The difference in parse accuracy between LISA$_{\\htmlClass{match-highlight}{G}}$ and D\\&M likely explains the large increase in SRL performance we see from decoding with D\\&M parses in that setting."
      ],
      "is_definition": false,
      "definitions": [
        "GloVe embeddings"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.771429,
          "top": 0.251781,
          "width": 0.010084,
          "height": 0.00712589
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805105"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804499"
        },
        {
          "type": "sentence",
          "id": "804502"
        },
        {
          "type": "sentence",
          "id": "804505"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804512"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804509"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805437",
    "type": "symbol",
    "attributes": {
      "tex": "$G$",
      "mathml": "<mi>G</mi>",
      "mathml_near_matches": [
        "<mi>G</mi>"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_{\\htmlClass{match-highlight}{G}}$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_{\\htmlClass{match-highlight}{G}}$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{X}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{V}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_{\\htmlClass{match-highlight}{G}}, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss.",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.92 & 94.92 & 91.87",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 94.26 & 90.31 & 85.82",
        "& LISA$_{\\htmlClass{match-highlight}{{G}}}$ & 96.81 & 93.35 & 90.42",
        "Subscript ${\\htmlClass{match-highlight}{G}}$ denotes GloVe and $E$ ELMo embeddings.",
        "We first report the labeled and unlabeled attachment scores (LAS, UAS) of our parsing models on the CoNLL-2005 and 2012 test sets (Table~\\ref{parsing-numbers}) with GloVe (${\\htmlClass{match-highlight}{G}}$) and ELMo ($E$) embeddings.",
        "The difference in parse accuracy between LISA$_{\\htmlClass{match-highlight}{G}}$ and D\\&M likely explains the large increase in SRL performance we see from decoding with D\\&M parses in that setting."
      ],
      "is_definition": false,
      "definitions": [
        "GloVe embeddings"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.213445,
          "top": 0.866983,
          "width": 0.010084,
          "height": 0.00712589
        }
      ],
      "nicknames": [],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804748"
      },
      "parent": {
        "type": "symbol",
        "id": "805056"
      },
      "sentence": {
        "type": "sentence",
        "id": "804377"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        },
        {
          "type": "sentence",
          "id": "804499"
        },
        {
          "type": "sentence",
          "id": "804502"
        },
        {
          "type": "sentence",
          "id": "804505"
        },
        {
          "type": "sentence",
          "id": "804509"
        },
        {
          "type": "sentence",
          "id": "804510"
        },
        {
          "type": "sentence",
          "id": "804512"
        }
      ],
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804509"
        }
      ],
      "children": [],
      "nickname_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805438",
    "type": "sentence",
    "attributes": {
      "tex": "WSJ",
      "text": "WSJ",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 7,
          "left": 0.525387,
          "top": 0.109872,
          "width": 0.0398975,
          "height": 0.012175
        }
      ],
      "tex_start": null,
      "tex_end": null
    },
    "relationships": {}
  },
  {
    "id": "805439",
    "type": "sentence",
    "attributes": {
      "text": "Still,  LISA’s  GloVeUAS  is  comparable  to  popular  off-the-shelf  de-pendency parsers such as spaCy,5and with ELMo",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 7,
          "left": 0.12542,
          "top": 0.854906,
          "width": 0.362349,
          "height": 0.0158794
        },
        {
          "source": "human-annotation",
          "page": 7,
          "left": 0.12542,
          "top": 0.840407,
          "width": 0.362354,
          "height": 0.0144186
        },
        {
          "source": "human-annotation",
          "page": 7,
          "left": 0.33648,
          "top": 0.82444,
          "width": 0.151304,
          "height": 0.0144186
        }
      ],
      "tex": null,
      "tex_start": null,
      "tex_end": null
    },
    "relationships": {}
  },
  {
    "id": "805440",
    "type": "sentence",
    "attributes": {
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 7,
          "left": 0.5273,
          "top": 0.0742687,
          "width": 0.347645,
          "height": 0.0131978
        }
      ],
      "text": null,
      "tex": null,
      "tex_start": null,
      "tex_end": null
    },
    "relationships": {}
  },
  {
    "id": "805441",
    "type": "sentence",
    "attributes": {
      "text": "WSJ",
      "tex": "WSJ",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 7,
          "left": 0.522867,
          "top": 0.358123,
          "width": 0.0377976,
          "height": 0.0112841
        }
      ],
      "tex_start": null,
      "tex_end": null
    },
    "relationships": {}
  },
  {
    "id": "805442",
    "type": "term",
    "attributes": {
      "name": "WSJ",
      "term_type": "Dataset",
      "definitions": [
        "Wall Street Journal"
      ],
      "definition_texs": [
        "Wall Street Journal"
      ],
      "snippets": [
        "On CoNLL-2005 with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the **WSJ** test set by 2.5 F1 points absolute.",
        "llll}\n**WSJ** Test & P & R & F1",
        "The gap in SRL F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on **WSJ**, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with D\\&M parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both **WSJ** and Brown.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on **WSJ** using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser.",
        "llll}\n**WSJ** Dev & P & R & F1"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 6,
          "left": 0.551215,
          "top": 0.0742377,
          "width": 0.0383226,
          "height": 0.0126204
        }
      ],
      "sources": [],
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "805430"
      },
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "804433"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804600"
        }
      ]
    }
  },
  {
    "id": "805443",
    "type": "term",
    "attributes": {
      "name": "WSJ",
      "definitions": [
        "Wall Street Journal"
      ],
      "definition_texs": [
        "Wall Street Journal"
      ],
      "snippets": [
        "On CoNLL-2005 with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the **WSJ** test set by 2.5 F1 points absolute.",
        "llll}\n**WSJ** Test & P & R & F1",
        "The gap in SRL F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on **WSJ**, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with D\\&M parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both **WSJ** and Brown.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on **WSJ** using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser.",
        "llll}\n**WSJ** Dev & P & R & F1"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 7,
          "left": 0.527486,
          "top": 0.110466,
          "width": 0.0365377,
          "height": 0.011878
        }
      ],
      "term_type": null,
      "sources": [],
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "805438"
      },
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "804433"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804600"
        }
      ]
    }
  },
  {
    "id": "805444",
    "type": "term",
    "attributes": {
      "name": "WSJ",
      "definitions": [
        "Wall Street Journal"
      ],
      "definition_texs": [
        "Wall Street Journal"
      ],
      "snippets": [
        "On CoNLL-2005 with predicted predicates and standard word embeddings, our single model out-performs the previous state-of-the-art model on the **WSJ** test set by 2.5 F1 points absolute.",
        "llll}\n**WSJ** Test & P & R & F1",
        "The gap in SRL F1 between models using LISA and D\\&M parses is smaller due to LISA's improved parsing accuracy (see \\S\\ref{sec:parse-pos-results}), but LISA with D\\&M parses still achieves the highest F1: nearly 1.0 absolute F1 higher than the previous state-of-the art on **WSJ**, and more than 2.0 F1 higher on Brown.",
        "Here LISA still excels: with D\\&M parses, LISA out-performs the previous state-of-the-art by more than 2 F1 on both **WSJ** and Brown.",
        "Still, LISA's GloVe UAS is comparable to popular off-the-shelf dependency parsers such as spaCy,\\footnote{spaCy reports 94.48 UAS on **WSJ** using Stanford dependencies v3.3: \\protect\\url{https://spacy.io/usage/facts-figures}} and with ELMo embeddings comparable to the standalone D\\&M parser.",
        "llll}\n**WSJ** Dev & P & R & F1"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 7,
          "left": 0.525387,
          "top": 0.358717,
          "width": 0.0344378,
          "height": 0.0103933
        }
      ],
      "term_type": null,
      "sources": [],
      "tags": []
    },
    "relationships": {
      "sentence": {
        "type": "sentence",
        "id": "805441"
      },
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804625"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804287"
        },
        {
          "type": "sentence",
          "id": "804433"
        },
        {
          "type": "sentence",
          "id": "804454"
        },
        {
          "type": "sentence",
          "id": "804458"
        },
        {
          "type": "sentence",
          "id": "94353"
        },
        {
          "type": "sentence",
          "id": "804600"
        }
      ]
    }
  },
  {
    "id": "805445",
    "type": "sentence",
    "attributes": {
      "text": "WSJ & P & R & F1",
      "tex": "WSJ & P & R & F1",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 12,
          "left": 0.545965,
          "top": 0.747156,
          "width": 0.283482,
          "height": 0.012175
        }
      ],
      "tex_start": null,
      "tex_end": null
    },
    "relationships": {}
  },
  {
    "id": "805446",
    "type": "sentence",
    "attributes": {
      "text": "All predicates in CoNLL-2005 are verbs; CoNLL-2012includes some nominal predicates.",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 4,
          "left": 0.123802,
          "top": 0.893565,
          "width": 0.205955,
          "height": 0.0121123
        },
        {
          "source": "human-annotation",
          "page": 4,
          "left": 0.150707,
          "top": 0.88179,
          "width": 0.337024,
          "height": 0.0121123
        }
      ],
      "tex": null,
      "tex_start": null,
      "tex_end": null
    },
    "relationships": {}
  },
  {
    "id": "805447",
    "type": "sentence",
    "attributes": {
      "text": "Our   models   also   show   improvements   whenusing  contextually-encoded  word  representations(Peters  et  al.,  2018),   obtaining  nearly  1.0  F1higher  than  the  state-of-the-art  on  CoNLL-2005news  and  more  than  2.0  F1  improvement  onout-of-domain text.",
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "human-annotation",
          "page": 1,
          "left": 0.12542,
          "top": 0.834731,
          "width": 0.14058,
          "height": 0.0144186
        },
        {
          "source": "human-annotation",
          "page": 1,
          "left": 0.12542,
          "top": 0.818765,
          "width": 0.362358,
          "height": 0.0144186
        },
        {
          "source": "human-annotation",
          "page": 1,
          "left": 0.12542,
          "top": 0.802798,
          "width": 0.362359,
          "height": 0.0144186
        },
        {
          "source": "human-annotation",
          "page": 1,
          "left": 0.12542,
          "top": 0.786831,
          "width": 0.362357,
          "height": 0.0144186
        },
        {
          "source": "human-annotation",
          "page": 1,
          "left": 0.12542,
          "top": 0.770872,
          "width": 0.362352,
          "height": 0.0144186
        },
        {
          "source": "human-annotation",
          "page": 1,
          "left": 0.12542,
          "top": 0.754906,
          "width": 0.362354,
          "height": 0.0144186
        }
      ],
      "tex": null,
      "tex_start": null,
      "tex_end": null
    },
    "relationships": {}
  },
  {
    "id": "805448",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.865546,
          "top": 0.806413,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804681"
      },
      "parent": {
        "type": "symbol",
        "id": "804851"
      },
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805449",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.359664,
          "top": 0.149644,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804859"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805450",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.317647,
          "top": 0.250594,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804685"
      },
      "parent": {
        "type": "symbol",
        "id": "804901"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805451",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.863866,
          "top": 0.787411,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804679"
      },
      "parent": {
        "type": "symbol",
        "id": "804863"
      },
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805452",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.398319,
          "top": 0.31829,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804691"
      },
      "parent": {
        "type": "symbol",
        "id": "804900"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805453",
    "type": "symbol",
    "attributes": {
      "tex": "$h$",
      "mathml": "<mi>h</mi>",
      "mathml_near_matches": [
        "<mi>h</mi>"
      ],
      "snippets": [
        "For each attention head ${\\htmlClass{match-highlight}{h}}$, we project this matrix into distinct key, value and query representations $K_{\\htmlClass{match-highlight}{h}}^{(j)}$, $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ and $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ of dimensions $T\\times d_k$, $T\\times d_q$, and $T\\times d_v$, respectively.",
        "We can then multiply $Q_{\\htmlClass{match-highlight}{h}}^{(j)}$ by $K_{\\htmlClass{match-highlight}{h}}^{(j)}$ to obtain a $T\\times T$ matrix of attention weights $A_{\\htmlClass{match-highlight}{h}}^{(j)}$ between each pair of tokens in the sentence.",
        "Following \\citet{vaswani2017attention} we perform scaled dot-product attention: We scale the weights by the inverse square root of their embedding dimension and normalize with the softmax function to produce a distinct distribution for each token over all the tokens in the sentence:\n\\begin{align}\nA_{\\htmlClass{match-highlight}{h}}^{(j)} = \\mathrm{softmax}(d_{k}^{-0.5}Q_{\\htmlClass{match-highlight}{h}}^{(j)}{K_{\\htmlClass{match-highlight}{h}}^{(j)}}^T)\n\\end{align}\n% \\begin{align}\n% M_h^{(j)} = Q_h^{(j)}{K_h^{(j)}}^T\n% \\end{align}\nThese attention weights are then multiplied by $V_{\\htmlClass{match-highlight}{h}}^{(j)}$ for each token to obtain the self-attended token representations $M_{\\htmlClass{match-highlight}{h}}^{(j)}$:\n\\begin{align}\nM_{\\htmlClass{match-highlight}{h}}^{(j)} = A_{\\htmlClass{match-highlight}{h}}^{(j)}V_{\\htmlClass{match-highlight}{h}}^{(j)}\n\\end{align}\nRow $t$ of $M_{\\htmlClass{match-highlight}{h}}^{(j)}$, the self-attended representation for token $t$ at layer $j$, is thus the weighted sum with respect to $t$ (with weights given by $A_{\\htmlClass{match-highlight}{h}}^{(j)}$) over the token representations in $V_{\\htmlClass{match-highlight}{h}}^{(j)}$."
      ],
      "is_definition": false,
      "nicknames": [
        "attention head"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.285714,
          "top": 0.21734,
          "width": 0.00672269,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804684"
      },
      "parent": {
        "type": "symbol",
        "id": "804907"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804334"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805454",
    "type": "citation",
    "attributes": {
      "paper_id": "b1d7f596fc34fd6cc6bbfc22a083bca8d2d38f14",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.606723,
          "top": 0.317102,
          "width": 0.27563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.333729,
          "width": 0.12605,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805455",
    "type": "citation",
    "attributes": {
      "paper_id": "29219d826ead654f2b863de6eceb69811850b7d4",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.216807,
          "top": 0.667458,
          "width": 0.156303,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805456",
    "type": "citation",
    "attributes": {
      "paper_id": "367f2c63a6f6a10b3b64b8729d601e69337ee3cc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.213445,
          "top": 0.412114,
          "width": 0.129412,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805457",
    "type": "citation",
    "attributes": {
      "paper_id": "8495259ca47c938fbfc6a0a71633b27e907d998b",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.813445,
          "top": 0.414489,
          "width": 0.0689076,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.431116,
          "width": 0.122689,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805458",
    "type": "citation",
    "attributes": {
      "paper_id": "8495259ca47c938fbfc6a0a71633b27e907d998b",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.684034,
          "top": 0.801663,
          "width": 0.191597,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805459",
    "type": "citation",
    "attributes": {
      "paper_id": "c3a3c163f25b9181f1fb7e71a32482a7393d2088",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.613445,
          "top": 0.57601,
          "width": 0.221849,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805460",
    "type": "citation",
    "attributes": {
      "paper_id": "c3a3c163f25b9181f1fb7e71a32482a7393d2088",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.653782,
          "top": 0.752969,
          "width": 0.221849,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805461",
    "type": "citation",
    "attributes": {
      "paper_id": "c3a3c163f25b9181f1fb7e71a32482a7393d2088",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.226891,
          "top": 0.325416,
          "width": 0.22521,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805462",
    "type": "citation",
    "attributes": {
      "paper_id": "c3a3c163f25b9181f1fb7e71a32482a7393d2088",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.438242,
          "width": 0.119328,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.388235,
          "top": 0.421615,
          "width": 0.097479,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805463",
    "type": "citation",
    "attributes": {
      "paper_id": "0b44fcbeea9415d400c5f5789d6b892b6f98daff",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.610084,
          "top": 0.0950119,
          "width": 0.146218,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805464",
    "type": "citation",
    "attributes": {
      "paper_id": "f66821598f4db7a6a2f54a6a4ae43e391649f4c1",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.517647,
          "top": 0.412114,
          "width": 0.110924,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.754622,
          "top": 0.395487,
          "width": 0.127731,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805465",
    "type": "citation",
    "attributes": {
      "paper_id": "f66821598f4db7a6a2f54a6a4ae43e391649f4c1",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.127731,
          "top": 0.288599,
          "width": 0.243697,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805466",
    "type": "citation",
    "attributes": {
      "paper_id": "8d3a318b62d2e970122da35b2a2e70a5d12cc16f",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.519328,
          "top": 0.336105,
          "width": 0.0336134,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.815126,
          "top": 0.319477,
          "width": 0.0672269,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805467",
    "type": "citation",
    "attributes": {
      "paper_id": "99d2dcdcf4cf05facaa101a48c7e31d140b4736d",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.129412,
          "top": 0.624703,
          "width": 0.136134,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805468",
    "type": "citation",
    "attributes": {
      "paper_id": "99d2dcdcf4cf05facaa101a48c7e31d140b4736d",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.161345,
          "top": 0.799287,
          "width": 0.142857,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805469",
    "type": "citation",
    "attributes": {
      "paper_id": "84069287da0a6b488b8c933f3cb5be759cb6237e",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.409739,
          "width": 0.0369748,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.778151,
          "top": 0.393112,
          "width": 0.104202,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805470",
    "type": "citation",
    "attributes": {
      "paper_id": "4908fc4d7f58383170c085fe8238a868e9a901f9",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.168067,
          "top": 0.555819,
          "width": 0.117647,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805471",
    "type": "citation",
    "attributes": {
      "paper_id": "f37e1b62a767a307c046404ca96bc140b3e68cb5",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.251781,
          "width": 0.147899,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.85042,
          "top": 0.235154,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805472",
    "type": "citation",
    "attributes": {
      "paper_id": "f37e1b62a767a307c046404ca96bc140b3e68cb5",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.515966,
          "top": 0.688836,
          "width": 0.0857143,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.801681,
          "top": 0.672209,
          "width": 0.0806723,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805473",
    "type": "citation",
    "attributes": {
      "paper_id": "3febb2bed8865945e7fddc99efd791887bb7e14f",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.129412,
          "top": 0.793349,
          "width": 0.146218,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805474",
    "type": "citation",
    "attributes": {
      "paper_id": "3febb2bed8865945e7fddc99efd791887bb7e14f",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.239905,
          "width": 0.0369748,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.789916,
          "top": 0.224466,
          "width": 0.092437,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805475",
    "type": "citation",
    "attributes": {
      "paper_id": "3febb2bed8865945e7fddc99efd791887bb7e14f",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.284034,
          "top": 0.128266,
          "width": 0.136134,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805476",
    "type": "citation",
    "attributes": {
      "paper_id": "3febb2bed8865945e7fddc99efd791887bb7e14f",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.556303,
          "top": 0.283848,
          "width": 0.131092,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805477",
    "type": "citation",
    "attributes": {
      "paper_id": "c92970286c535992a86539b761357761e97a37ee",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.52437,
          "top": 0.186461,
          "width": 0.151261,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805478",
    "type": "citation",
    "attributes": {
      "paper_id": "c92970286c535992a86539b761357761e97a37ee",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.263866,
          "top": 0.496437,
          "width": 0.159664,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805479",
    "type": "citation",
    "attributes": {
      "paper_id": "1ae5c1646ea445a670fe6cc8bf72b589dd9f6e5c",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.72437,
          "top": 0.47981,
          "width": 0.152941,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805480",
    "type": "citation",
    "attributes": {
      "paper_id": "b5c6f0d18fd783536b4e6c2205d75b7c4477c6d2",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.85042,
          "top": 0.365796,
          "width": 0.0319328,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.382423,
          "width": 0.156303,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805481",
    "type": "citation",
    "attributes": {
      "paper_id": "b5c6f0d18fd783536b4e6c2205d75b7c4477c6d2",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.794958,
          "top": 0.559382,
          "width": 0.087395,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.57601,
          "width": 0.0823529,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805482",
    "type": "citation",
    "attributes": {
      "paper_id": "79ab3c49903ec8cb339437ccf5cf998607fc313e",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.305882,
          "top": 0.769596,
          "width": 0.119328,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805483",
    "type": "citation",
    "attributes": {
      "paper_id": "70d3d2e0a8f34d6c3cb7890e249e2ed6a574ce50",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.678992,
          "top": 0.56057,
          "width": 0.169748,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805484",
    "type": "citation",
    "attributes": {
      "paper_id": "70d3d2e0a8f34d6c3cb7890e249e2ed6a574ce50",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.280672,
          "top": 0.293349,
          "width": 0.173109,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805485",
    "type": "citation",
    "attributes": {
      "paper_id": "03ad06583c9721855ccd82c3d969a01360218d86",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.221849,
          "top": 0.603325,
          "width": 0.218487,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805486",
    "type": "citation",
    "attributes": {
      "paper_id": "34f25a8704614163c4095b3ee2fc969b60de4698",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.764706,
          "top": 0.361045,
          "width": 0.117647,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.376485,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805487",
    "type": "citation",
    "attributes": {
      "paper_id": "dee93d4481ac590f6debcd2816f1f8fd27b627d9",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.495249,
          "width": 0.168067,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805488",
    "type": "citation",
    "attributes": {
      "paper_id": "48b4524a3b1207157b1b2f87885c434c96fc7a19",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.623515,
          "width": 0.215126,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805489",
    "type": "citation",
    "attributes": {
      "paper_id": "b836405eebd5722b7782ea994c598e2991474850",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.295798,
          "top": 0.555819,
          "width": 0.179832,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805490",
    "type": "citation",
    "attributes": {
      "paper_id": "af9b9235a68307c782d14d4bf12cb80d662d247f",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.616807,
          "top": 0.57601,
          "width": 0.166387,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805491",
    "type": "citation",
    "attributes": {
      "paper_id": "af9b9235a68307c782d14d4bf12cb80d662d247f",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.687395,
          "top": 0.769596,
          "width": 0.164706,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805492",
    "type": "citation",
    "attributes": {
      "paper_id": "6ed376a26045ff0048ec2b216785d396960d6ed1",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.764706,
          "top": 0.431116,
          "width": 0.112605,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805493",
    "type": "citation",
    "attributes": {
      "paper_id": "6ed376a26045ff0048ec2b216785d396960d6ed1",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.121008,
          "top": 0.897862,
          "width": 0.0352941,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.410084,
          "top": 0.882423,
          "width": 0.0756303,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805494",
    "type": "citation",
    "attributes": {
      "paper_id": "6ed376a26045ff0048ec2b216785d396960d6ed1",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.849169,
          "width": 0.0823529,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.855462,
          "top": 0.833729,
          "width": 0.0268908,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805495",
    "type": "citation",
    "attributes": {
      "paper_id": "6ed376a26045ff0048ec2b216785d396960d6ed1",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.663866,
          "top": 0.605701,
          "width": 0.112605,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805496",
    "type": "citation",
    "attributes": {
      "paper_id": "6ed376a26045ff0048ec2b216785d396960d6ed1",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.151261,
          "top": 0.412114,
          "width": 0.114286,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805497",
    "type": "citation",
    "attributes": {
      "paper_id": "6ed376a26045ff0048ec2b216785d396960d6ed1",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.151261,
          "top": 0.528504,
          "width": 0.114286,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805498",
    "type": "citation",
    "attributes": {
      "paper_id": "6ed376a26045ff0048ec2b216785d396960d6ed1",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.547899,
          "top": 0.783848,
          "width": 0.112605,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805499",
    "type": "citation",
    "attributes": {
      "paper_id": "7ed7a41c275f2870b840a5e6c3eaec8888c9480c",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.564706,
          "top": 0.511876,
          "width": 0.169748,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805500",
    "type": "citation",
    "attributes": {
      "paper_id": "eb42a490cf4f186d3383c92963817d100afd81e2",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.127731,
          "top": 0.320665,
          "width": 0.166387,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805501",
    "type": "citation",
    "attributes": {
      "paper_id": "825655fbfc32bedb93781fa14eb0c07e2f0abf5b",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.301663,
          "width": 0.0369748,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.806723,
          "top": 0.285036,
          "width": 0.0756303,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805502",
    "type": "citation",
    "attributes": {
      "paper_id": "204e3073870fae3d05bcbc2f6a8e263d9b72e776",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.121008,
          "top": 0.179335,
          "width": 0.0352941,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.383193,
          "top": 0.162708,
          "width": 0.102521,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805503",
    "type": "citation",
    "attributes": {
      "paper_id": "204e3073870fae3d05bcbc2f6a8e263d9b72e776",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.517647,
          "top": 0.654394,
          "width": 0.0403361,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.768067,
          "top": 0.637767,
          "width": 0.114286,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805504",
    "type": "citation",
    "attributes": {
      "paper_id": "204e3073870fae3d05bcbc2f6a8e263d9b72e776",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.712605,
          "top": 0.0950119,
          "width": 0.146218,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805505",
    "type": "citation",
    "attributes": {
      "paper_id": "204e3073870fae3d05bcbc2f6a8e263d9b72e776",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.57479,
          "top": 0.831354,
          "width": 0.154622,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805506",
    "type": "citation",
    "attributes": {
      "paper_id": "204e3073870fae3d05bcbc2f6a8e263d9b72e776",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.344418,
          "width": 0.146218,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805507",
    "type": "citation",
    "attributes": {
      "paper_id": "204e3073870fae3d05bcbc2f6a8e263d9b72e776",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.384798,
          "width": 0.0823529,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.823529,
          "top": 0.368171,
          "width": 0.0571429,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805508",
    "type": "citation",
    "attributes": {
      "paper_id": "999f0acfac28215db2e4c69ff42711fd4f56511d",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.606723,
          "top": 0.317102,
          "width": 0.27563,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.333729,
          "width": 0.12605,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805509",
    "type": "citation",
    "attributes": {
      "paper_id": "9405d0388f90ba1432ef13c21309d8363860e22e",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.29916,
          "top": 0.619952,
          "width": 0.176471,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805510",
    "type": "citation",
    "attributes": {
      "paper_id": "6789e0dbd294cccb3b7dd4e001c9e8ba4813f334",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.849169,
          "width": 0.127731,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.436975,
          "top": 0.833729,
          "width": 0.0504202,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805511",
    "type": "citation",
    "attributes": {
      "paper_id": "ad90fa2a6e97e87dfcea36cbcffabdfe62f57e6f",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.653782,
          "top": 0.333729,
          "width": 0.210084,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805512",
    "type": "citation",
    "attributes": {
      "paper_id": "df137487e20ba7c6e1e2b9a1e749f2a578b5ad99",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.436975,
          "top": 0.785036,
          "width": 0.0504202,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.801663,
          "width": 0.0789916,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805513",
    "type": "citation",
    "attributes": {
      "paper_id": "d0be39ee052d246ae99c082a565aba25b811be2d",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.62521,
          "top": 0.393112,
          "width": 0.139496,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805514",
    "type": "citation",
    "attributes": {
      "paper_id": "6396ab37641d36be4c26420e58adeb8665914c3b",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.317102,
          "width": 0.0789916,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.835294,
          "top": 0.301663,
          "width": 0.0470588,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805515",
    "type": "citation",
    "attributes": {
      "paper_id": "1b02204b210f822dabf8d68b7e3ea7ac14ee1268",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.457143,
          "top": 0.603325,
          "width": 0.0302521,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.619952,
          "width": 0.166387,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805516",
    "type": "citation",
    "attributes": {
      "paper_id": "25e7efa59a5cf68e0fc9401e4c6fa7b2bfe3f1ae",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.566387,
          "top": 0.154394,
          "width": 0.233613,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805517",
    "type": "citation",
    "attributes": {
      "paper_id": "25e7efa59a5cf68e0fc9401e4c6fa7b2bfe3f1ae",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.121008,
          "top": 0.783848,
          "width": 0.0352941,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.312605,
          "top": 0.767221,
          "width": 0.17479,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805518",
    "type": "citation",
    "attributes": {
      "paper_id": "9464d15f4f8d578f93332db4aa1c9c182fd51735",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.27395,
          "top": 0.147268,
          "width": 0.107563,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805519",
    "type": "citation",
    "attributes": {
      "paper_id": "9464d15f4f8d578f93332db4aa1c9c182fd51735",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.816807,
          "top": 0.862233,
          "width": 0.0655462,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805520",
    "type": "citation",
    "attributes": {
      "paper_id": "9464d15f4f8d578f93332db4aa1c9c182fd51735",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.12437,
          "top": 0.0795724,
          "width": 0.0319328,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805521",
    "type": "citation",
    "attributes": {
      "paper_id": "9464d15f4f8d578f93332db4aa1c9c182fd51735",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.191597,
          "top": 0.490499,
          "width": 0.107563,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805522",
    "type": "citation",
    "attributes": {
      "paper_id": "ed02ce4a53407d460eff371f506bfea1e13187d2",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.785036,
          "width": 0.092437,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.440336,
          "top": 0.769596,
          "width": 0.0470588,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805523",
    "type": "citation",
    "attributes": {
      "paper_id": "df2cf1f95d7a37d073b6c01ee8143f1b15fdf9e9",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.563025,
          "top": 0.301663,
          "width": 0.122689,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805524",
    "type": "citation",
    "attributes": {
      "paper_id": "11aa6801c417dd97552737c587fd8d7f480d10ab",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.416807,
          "top": 0.817102,
          "width": 0.0705882,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.833729,
          "width": 0.092437,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805525",
    "type": "citation",
    "attributes": {
      "paper_id": "bc1022b031dc6c7019696492e8116598097a8c12",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.534454,
          "top": 0.672209,
          "width": 0.157983,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805526",
    "type": "citation",
    "attributes": {
      "paper_id": "bc1022b031dc6c7019696492e8116598097a8c12",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.539192,
          "width": 0.0319328,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.373109,
          "top": 0.523753,
          "width": 0.112605,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805527",
    "type": "citation",
    "attributes": {
      "paper_id": "3c9d9f3c6f7508f4e29730924529dc993c27cddc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.129412,
          "top": 0.769596,
          "width": 0.163025,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805528",
    "type": "citation",
    "attributes": {
      "paper_id": "d44efdc542f2cc5e196f04bc76bc783bfd7084af",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.788235,
          "top": 0.312352,
          "width": 0.087395,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805529",
    "type": "citation",
    "attributes": {
      "paper_id": "d44efdc542f2cc5e196f04bc76bc783bfd7084af",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.789916,
          "top": 0.271971,
          "width": 0.0857143,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805530",
    "type": "citation",
    "attributes": {
      "paper_id": "8cbef23c9ee2ae7c35cc691a0c1d713a6377c9f2",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.840336,
          "top": 0.460808,
          "width": 0.0420168,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.515966,
          "top": 0.476247,
          "width": 0.144538,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805531",
    "type": "citation",
    "attributes": {
      "paper_id": "8cbef23c9ee2ae7c35cc691a0c1d713a6377c9f2",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.337815,
          "top": 0.62114,
          "width": 0.14958,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.122689,
          "top": 0.637767,
          "width": 0.0403361,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805532",
    "type": "citation",
    "attributes": {
      "paper_id": "8cbef23c9ee2ae7c35cc691a0c1d713a6377c9f2",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.245378,
          "top": 0.7981,
          "width": 0.193277,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805533",
    "type": "citation",
    "attributes": {
      "paper_id": "8cbef23c9ee2ae7c35cc691a0c1d713a6377c9f2",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.615126,
          "top": 0.704276,
          "width": 0.189916,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805534",
    "type": "citation",
    "attributes": {
      "paper_id": "c6b8c1728e6d2572b16ca2bfa5c3c82bb0fd8be6",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.672269,
          "top": 0.720903,
          "width": 0.168067,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805535",
    "type": "citation",
    "attributes": {
      "paper_id": "d4bd0035fe14832626279e6c3c72b73c21c7f5d8",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.230252,
          "top": 0.833729,
          "width": 0.193277,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805536",
    "type": "citation",
    "attributes": {
      "paper_id": "ade0c116120b54b57a91da51235108b75c28375a",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.359664,
          "top": 0.539192,
          "width": 0.12605,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.555819,
          "width": 0.0352941,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805537",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.517647,
          "top": 0.446556,
          "width": 0.114286,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805538",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.82521,
          "top": 0.897862,
          "width": 0.0571429,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805539",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.122689,
          "top": 0.0795724,
          "width": 0.0403361,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805540",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.143705,
          "width": 0.0840336,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.467227,
          "top": 0.128266,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805541",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.678992,
          "top": 0.494062,
          "width": 0.109244,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805542",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.132773,
          "top": 0.128266,
          "width": 0.105882,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805543",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.132773,
          "top": 0.244656,
          "width": 0.105882,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805544",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.152941,
          "top": 0.396675,
          "width": 0.107563,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805545",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.152941,
          "top": 0.511876,
          "width": 0.107563,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805546",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.672269,
          "top": 0.769596,
          "width": 0.121008,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805547",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.161345,
          "top": 0.897862,
          "width": 0.112605,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805548",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.112827,
          "width": 0.105882,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805549",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.226841,
          "width": 0.105882,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805550",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.359857,
          "width": 0.105882,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805551",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.156303,
          "top": 0.457245,
          "width": 0.105882,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805552",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 12,
          "left": 0.54958,
          "top": 0.768409,
          "width": 0.105882,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805553",
    "type": "citation",
    "attributes": {
      "paper_id": "7442a18a55f257a68f21d0cbb8b1395f8915a452",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.428571,
          "top": 0.431116,
          "width": 0.0571429,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.122689,
          "top": 0.447743,
          "width": 0.0403361,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805554",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.65042,
          "top": 0.431116,
          "width": 0.104202,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805555",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.57601,
          "width": 0.0840336,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.863866,
          "top": 0.56057,
          "width": 0.0201681,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805556",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.768067,
          "top": 0.624703,
          "width": 0.107563,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805557",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.636975,
          "top": 0.817102,
          "width": 0.114286,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805558",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.709244,
          "top": 0.271971,
          "width": 0.104202,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805559",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.57479,
          "top": 0.733967,
          "width": 0.110924,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805560",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.554622,
          "top": 0.817102,
          "width": 0.122689,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805561",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.517647,
          "top": 0.882423,
          "width": 0.112605,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805562",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.712605,
          "top": 0.509501,
          "width": 0.104202,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805563",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 6,
          "left": 0.132773,
          "top": 0.112827,
          "width": 0.105882,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805564",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.62521,
          "top": 0.352732,
          "width": 0.105882,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805565",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.62521,
          "top": 0.384798,
          "width": 0.105882,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805566",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.744538,
          "top": 0.649644,
          "width": 0.107563,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805567",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.815126,
          "top": 0.713777,
          "width": 0.0672269,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 7,
          "left": 0.517647,
          "top": 0.729216,
          "width": 0.0403361,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805568",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.364706,
          "top": 0.435867,
          "width": 0.110924,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805569",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.223529,
          "top": 0.669834,
          "width": 0.117647,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805570",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.278992,
          "top": 0.831354,
          "width": 0.104202,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805571",
    "type": "citation",
    "attributes": {
      "paper_id": "a4dd3beea286a20c4e4f66436875932d597190bc",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.27395,
          "top": 0.86342,
          "width": 0.104202,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 8,
          "left": 0.201681,
          "top": 0.887173,
          "width": 0.0857143,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805572",
    "type": "citation",
    "attributes": {
      "paper_id": "2791e0d36ba23763195ac984453d61dbaff555da",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.511876,
          "width": 0.0369748,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.70084,
          "top": 0.495249,
          "width": 0.181513,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805573",
    "type": "citation",
    "attributes": {
      "paper_id": "a6cb366736791bcccc5c8639de5a8f9636bf87e8",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.515966,
          "top": 0.319477,
          "width": 0.0369748,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.761345,
          "top": 0.304038,
          "width": 0.121008,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805574",
    "type": "citation",
    "attributes": {
      "paper_id": "eec3a236ecd185712ce65fb336141f8656eea13d",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.52437,
          "top": 0.785036,
          "width": 0.252101,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805575",
    "type": "citation",
    "attributes": {
      "paper_id": "c3a3c163f25b9181f1fb7e71a32482a7393d2088",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.752941,
          "top": 0.801663,
          "width": 0.129412,
          "height": 0.0106888
        },
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.515966,
          "top": 0.817102,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805576",
    "type": "citation",
    "attributes": {
      "paper_id": "8ae1af4a424f5e464d46903bc3d18fe1cf1434ff",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.307563,
          "top": 0.0950119,
          "width": 0.112605,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805577",
    "type": "citation",
    "attributes": {
      "paper_id": "6cbc1eb25f4ab29a613418b3b0740e74141a0f17",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.74958,
          "top": 0.365796,
          "width": 0.087395,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805578",
    "type": "citation",
    "attributes": {
      "paper_id": "5894c9fbe9d14be08a48e34d0467da4213b6399c",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 1,
          "left": 0.373109,
          "top": 0.875297,
          "width": 0.109244,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805579",
    "type": "citation",
    "attributes": {
      "paper_id": "5894c9fbe9d14be08a48e34d0467da4213b6399c",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.741176,
          "top": 0.640143,
          "width": 0.134454,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805580",
    "type": "citation",
    "attributes": {
      "paper_id": "ce9a21b93ba29d4145a8ef6bf401e77f261848de",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.423529,
          "top": 0.865796,
          "width": 0.0638655,
          "height": 0.00831354
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.121008,
          "top": 0.882423,
          "width": 0.121008,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805581",
    "type": "citation",
    "attributes": {
      "paper_id": "9fbeebb98f479405dadd912e95796ba0256b74ac",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.786555,
          "top": 0.719715,
          "width": 0.0957983,
          "height": 0.00950119
        },
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.515966,
          "top": 0.736342,
          "width": 0.0369748,
          "height": 0.00831354
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805582",
    "type": "citation",
    "attributes": {
      "paper_id": "0c133f79b23e8c680891d2e49a66f0e3d37f1466",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 5,
          "left": 0.169748,
          "top": 0.539192,
          "width": 0.176471,
          "height": 0.0106888
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805583",
    "type": "citation",
    "attributes": {
      "paper_id": "c34e41312b47f60986458759d5cc546c2b53f748",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 0,
          "left": 0.653782,
          "top": 0.414489,
          "width": 0.146218,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805584",
    "type": "citation",
    "attributes": {
      "paper_id": "c34e41312b47f60986458759d5cc546c2b53f748",
      "version": 0,
      "source": "tex-pipeline",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.515966,
          "top": 0.801663,
          "width": 0.14958,
          "height": 0.00950119
        }
      ]
    },
    "relationships": {}
  },
  {
    "id": "805585",
    "type": "symbol",
    "attributes": {
      "nicknames": [
        "query representation"
      ],
      "tex": "$Q_{parse}$",
      "mathml": "<msub><mi>Q</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>Q</mi><mi>parse</mi></msub>",
        "<mi>Q</mi>",
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, ${\\htmlClass{match-highlight}{Q_{parse}}}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and ${\\htmlClass{match-highlight}{Q_{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}({\\htmlClass{match-highlight}{Q_{parse}}} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations ${\\htmlClass{match-highlight}{Q_{parse}}}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, ${\\htmlClass{match-highlight}{Q_{parse}}}$ has dimension 500 and $K_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.305882,
          "top": 0.71734,
          "width": 0.0470588,
          "height": 0.0130641
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804699"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804923"
        },
        {
          "type": "symbol",
          "id": "804924"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804344"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805586",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>V</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msub><mi>V</mi><mi>parse</mi></msub>",
        "<mi mathvariant=\"script\">V</mi>",
        "<msub><mi mathvariant=\"script\">V</mi><mi>G</mi></msub>",
        "<mi>V</mi>"
      ],
      "tex": "$V_{parse}$",
      "mathml": "<msub><mi>V</mi><mi>parse</mi></msub>",
      "snippets": [
        "Attention weights $A_{parse}$ heavily weight the token's syntactic governor, \\emph{saw}, in a weighted average over the token values ${\\htmlClass{match-highlight}{V_{parse}}}$.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, $Q_{parse}$, ${\\htmlClass{match-highlight}{V_{parse}}}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations ${\\htmlClass{match-highlight}{V_{parse}}}$ as in the other attention heads."
      ],
      "is_definition": false,
      "nicknames": [
        "token values",
        "query representation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.156303,
          "top": 0.385986,
          "width": 0.0436975,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804651"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804786"
        },
        {
          "type": "symbol",
          "id": "804787"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804299"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804299"
        },
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805587",
    "type": "symbol",
    "attributes": {
      "nicknames": [
        "query representation"
      ],
      "snippets": [
        "In the syntactically-informed attention head, ${\\htmlClass{match-highlight}{Q_{parse}}}$ has dimension 500 and $K_{parse}$ has dimension 100.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, ${\\htmlClass{match-highlight}{Q_{parse}}}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and ${\\htmlClass{match-highlight}{Q_{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}({\\htmlClass{match-highlight}{Q_{parse}}} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations ${\\htmlClass{match-highlight}{Q_{parse}}}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function."
      ],
      "is_definition": false,
      "tex": "$Q_{parse}$",
      "mathml": "<msub><mi>Q</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>Q</mi><mi>parse</mi></msub>",
        "<mi>Q</mi>",
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.695798,
          "top": 0.410926,
          "width": 0.0453782,
          "height": 0.0142518
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804717"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804971"
        },
        {
          "type": "symbol",
          "id": "804972"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804352"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805588",
    "type": "symbol",
    "attributes": {
      "tex": "$K_{parse}$",
      "mathml": "<msub><mi>K</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<mi>K</mi>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>"
      ],
      "snippets": [
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted ${\\htmlClass{match-highlight}{K_{parse}}}$, $Q_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between ${\\htmlClass{match-highlight}{K_{parse}}}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and ${\\htmlClass{match-highlight}{K_{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, $Q_{parse}$ has dimension 500 and ${\\htmlClass{match-highlight}{K_{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "nicknames": [
        "key representation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.230252,
          "top": 0.846793,
          "width": 0.0470588,
          "height": 0.0130641
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804703"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804929"
        },
        {
          "type": "symbol",
          "id": "804930"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805589",
    "type": "symbol",
    "attributes": {
      "definitions": [
        "sequence of token repesentations"
      ],
      "nicknames": [
        "input sequence"
      ],
      "tex": "${X}$",
      "mathml": "<mi>X</mi>",
      "mathml_near_matches": [
        "<mi>X</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$ of $T$ token representations $x_t$.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.788235,
          "top": 0.223278,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "defining_formula_equations": []
    }
  },
  {
    "id": "805590",
    "type": "symbol",
    "attributes": {
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "mathml_near_matches": [
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "is_definition": false,
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.831933,
          "top": 0.761283,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804677"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805591",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>"
      ],
      "is_definition": false,
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.719328,
          "top": 0.433492,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804659"
      },
      "parent": {
        "type": "symbol",
        "id": "804789"
      },
      "sentence": {
        "type": "sentence",
        "id": "804325"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805592",
    "type": "symbol",
    "attributes": {
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "mathml_near_matches": [
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "is_definition": false,
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.746219,
          "top": 0.761283,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804676"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805593",
    "type": "symbol",
    "attributes": {
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "mathml_near_matches": [
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "is_definition": false,
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.870588,
          "top": 0.111639,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804653"
      },
      "sentence": {
        "type": "sentence",
        "id": "804318"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805594",
    "type": "symbol",
    "attributes": {
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "mathml_near_matches": [
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "is_definition": false,
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.689076,
          "top": 0.761283,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804675"
      },
      "sentence": {
        "type": "sentence",
        "id": "804332"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805595",
    "type": "symbol",
    "attributes": {
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "mathml_near_matches": [
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "is_definition": false,
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.598319,
          "top": 0.799287,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804680"
      },
      "sentence": {
        "type": "sentence",
        "id": "804333"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805596",
    "type": "symbol",
    "attributes": {
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "mathml_near_matches": [
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "is_definition": false,
      "diagram_label": "SKIP",
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.413445,
          "top": 0.135392,
          "width": 0.010084,
          "height": 0.00593824
        }
      ],
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804682"
      },
      "parent": {
        "type": "symbol",
        "id": "804872"
      },
      "sentence": {
        "type": "sentence",
        "id": "804334"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805597",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>",
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>"
      ],
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "is_definition": false,
      "diagram_label": "SKIP",
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.32437,
          "top": 0.590261,
          "width": 0.010084,
          "height": 0.00593824
        }
      ],
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804740"
      },
      "sentence": {
        "type": "sentence",
        "id": "804372"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805598",
    "type": "symbol",
    "attributes": {
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "mathml_near_matches": [
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "is_definition": false,
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.589916,
          "top": 0.155582,
          "width": 0.010084,
          "height": 0.00593824
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805599",
    "type": "symbol",
    "attributes": {
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "mathml_near_matches": [
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "is_definition": false,
      "diagram_label": "SKIP",
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.403361,
          "top": 0.889549,
          "width": 0.010084,
          "height": 0.00593824
        }
      ],
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "804939"
      },
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805600",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<mi>T</mi>",
        "<msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup>",
        "<mrow><msup><mi>T</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msup><mo stretchy=\"false\">(</mo><mo>⋅</mo><mo stretchy=\"false\">)</mo></mrow>"
      ],
      "tex": "$T$",
      "mathml": "<mi>T</mi>",
      "is_definition": false,
      "nicknames": [
        "number of token representations",
        "dimension"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{X}$ of ${\\htmlClass{match-highlight}{T}}$ token representations $x_t$.",
        "Denoting the $j$th self-attention layer as ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$, the output of that layer $s_t^{(j)}$, and $LN(\\cdot)$ layer normalization, the following recurrence applied to initial input $c_t^{(p)}$:\n\\begin{align}\n\\label{eqn:overall}\n% s_t^{(1)} = LN(c_t^{(p)} + T^{(1)}(c_t^{(p)})) \\\\\n% \\vdots \\nonumber \\\\\ns_t^{(j)} = LN(s_t^{(j-1)} + {\\htmlClass{match-highlight}{T}}^{(j)}(s_t^{(j-1)}))\n\\end{align}\ngives our final token representations $s_t^{(j)}$.",
        "Each ${\\htmlClass{match-highlight}{T}}^{(j)}(\\cdot)$ consists of:",
        "Specifically, consider the matrix $S^{(j-1)}$ of ${\\htmlClass{match-highlight}{T}}$ token representations at layer $j-1$.",
        "For each attention head $h$, we project this matrix into distinct key, value and query representations $K_h^{(j)}$, $V_h^{(j)}$ and $Q_h^{(j)}$ of dimensions ${\\htmlClass{match-highlight}{T}}\\times d_k$, ${\\htmlClass{match-highlight}{T}}\\times d_q$, and ${\\htmlClass{match-highlight}{T}}\\times d_v$, respectively.",
        "We can then multiply $Q_h^{(j)}$ by $K_h^{(j)}$ to obtain a ${\\htmlClass{match-highlight}{T}}\\times {\\htmlClass{match-highlight}{T}}$ matrix of attention weights $A_h^{(j)}$ between each pair of tokens in the sentence.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{\\htmlClass{match-highlight}{{T}}}\\sum_{t=1}^{\\htmlClass{match-highlight}{T}}\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{X}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{X}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.515966,
          "top": 0.546318,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804665"
      },
      "parent": {
        "type": "symbol",
        "id": "804828"
      },
      "sentence": {
        "type": "sentence",
        "id": "804326"
      },
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804332"
        }
      ],
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804325"
        },
        {
          "type": "sentence",
          "id": "804326"
        },
        {
          "type": "sentence",
          "id": "804331"
        },
        {
          "type": "sentence",
          "id": "804332"
        },
        {
          "type": "sentence",
          "id": "804333"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804372"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805601",
    "type": "symbol",
    "attributes": {
      "definitions": [
        "sequence of token repesentations"
      ],
      "nicknames": [
        "input sequence"
      ],
      "mathml": "<mi>X</mi>",
      "mathml_near_matches": [
        "<mi>X</mi>"
      ],
      "tex": "${X}$",
      "snippets": [
        "The input to the network is a sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$ of $T$ token representations $x_t$.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.739496,
          "top": 0.204276,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805063"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805602",
    "type": "symbol",
    "attributes": {
      "definitions": [
        "sequence of token repesentations"
      ],
      "nicknames": [
        "input sequence"
      ],
      "snippets": [
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$.",
        "The input to the network is a sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$ of $T$ token representations $x_t$.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(s_{ft})$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "tex": "${X}$",
      "mathml": "<mi>X</mi>",
      "mathml_near_matches": [
        "<mi>X</mi>"
      ],
      "is_definition": false,
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.793277,
          "top": 0.248219,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805066"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805603",
    "type": "symbol",
    "attributes": {
      "definitions": [
        "sequence of token repesentations"
      ],
      "nicknames": [
        "input sequence"
      ],
      "tex": "${X}$",
      "mathml": "<mi>X</mi>",
      "mathml_near_matches": [
        "<mi>X</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$ of $T$ token representations $x_t$.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.816807,
          "top": 0.168646,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "equation": {
        "type": "equation",
        "id": "804750"
      },
      "parent": {
        "type": "symbol",
        "id": "805062"
      },
      "sentence": {
        "type": "sentence",
        "id": "804378"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805604",
    "type": "symbol",
    "attributes": {
      "nicknames": [
        "query representation"
      ],
      "is_definition": false,
      "snippets": [
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations ${\\htmlClass{match-highlight}{Q_{parse}}}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, ${\\htmlClass{match-highlight}{Q_{parse}}}$ has dimension 500 and $K_{parse}$ has dimension 100.",
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, ${\\htmlClass{match-highlight}{Q_{parse}}}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and ${\\htmlClass{match-highlight}{Q_{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}({\\htmlClass{match-highlight}{Q_{parse}}} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads."
      ],
      "tex": "$Q_{parse}$",
      "mathml": "<msub><mi>Q</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>Q</mi><mi>parse</mi></msub>",
        "<mi>Q</mi>",
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.289076,
          "top": 0.891924,
          "width": 0.0453782,
          "height": 0.0130641
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804706"
      },
      "parent": {
        "type": "symbol",
        "id": "804935"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804940"
        },
        {
          "type": "symbol",
          "id": "804941"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805605",
    "type": "symbol",
    "attributes": {
      "nicknames": [
        "query representation"
      ],
      "tex": "$Q_{parse}$",
      "mathml": "<msub><mi>Q</mi><mi>parse</mi></msub>",
      "mathml_near_matches": [
        "<msub><mi>Q</mi><mi>parse</mi></msub>",
        "<mi>Q</mi>",
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>"
      ],
      "snippets": [
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, ${\\htmlClass{match-highlight}{Q_{parse}}}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and ${\\htmlClass{match-highlight}{Q_{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}({\\htmlClass{match-highlight}{Q_{parse}}} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations ${\\htmlClass{match-highlight}{Q_{parse}}}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, ${\\htmlClass{match-highlight}{Q_{parse}}}$ has dimension 500 and $K_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.715966,
          "top": 0.666271,
          "width": 0.0470588,
          "height": 0.0142518
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804779"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805122"
        },
        {
          "type": "symbol",
          "id": "805123"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804635"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805606",
    "type": "symbol",
    "attributes": {
      "definitions": [
        "sequence of token repesentations"
      ],
      "nicknames": [
        "input sequence"
      ],
      "tex": "${X}$",
      "mathml": "<mi>X</mi>",
      "mathml_near_matches": [
        "<mi>X</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$ of $T$ token representations $x_t$.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 2,
          "left": 0.830252,
          "top": 0.111639,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "equation": {
        "type": "equation",
        "id": "804652"
      },
      "sentence": {
        "type": "sentence",
        "id": "804318"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "defining_formula_equations": []
    }
  },
  {
    "id": "805607",
    "type": "symbol",
    "attributes": {
      "definitions": [
        "sequence of token repesentations"
      ],
      "nicknames": [
        "input sequence"
      ],
      "tex": "${X}$",
      "mathml": "<mi>X</mi>",
      "mathml_near_matches": [
        "<mi>X</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$ of $T$ token representations $x_t$.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.238655,
          "top": 0.687648,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "equation": {
        "type": "equation",
        "id": "804743"
      },
      "parent": {
        "type": "symbol",
        "id": "805027"
      },
      "sentence": {
        "type": "sentence",
        "id": "804373"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805608",
    "type": "symbol",
    "attributes": {
      "definitions": [
        "sequence of token repesentations"
      ],
      "nicknames": [
        "input sequence"
      ],
      "snippets": [
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(s_{ft})$.",
        "The input to the network is a sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$ of $T$ token representations $x_t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "tex": "${X}$",
      "mathml": "<mi>X</mi>",
      "mathml_near_matches": [
        "<mi>X</mi>"
      ],
      "is_definition": false,
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.830252,
          "top": 0.111639,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "equation": {
        "type": "equation",
        "id": "804749"
      },
      "sentence": {
        "type": "sentence",
        "id": "804377"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "defining_formula_equations": []
    }
  },
  {
    "id": "805609",
    "type": "symbol",
    "attributes": {
      "definitions": [
        "sequence of token repesentations"
      ],
      "nicknames": [
        "input sequence"
      ],
      "tex": "${X}$",
      "mathml": "<mi>X</mi>",
      "mathml_near_matches": [
        "<mi>X</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$ of $T$ token representations $x_t$.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.70084,
          "top": 0.254157,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "equation": {
        "type": "equation",
        "id": "804713"
      },
      "sentence": {
        "type": "sentence",
        "id": "804348"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "defining_formula_equations": []
    }
  },
  {
    "id": "805610",
    "type": "symbol",
    "attributes": {
      "definitions": [
        "sequence of token repesentations"
      ],
      "nicknames": [
        "input sequence"
      ],
      "tex": "${X}$",
      "mathml": "<mi>X</mi>",
      "mathml_near_matches": [
        "<mi>X</mi>"
      ],
      "snippets": [
        "The input to the network is a sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$ of $T$ token representations $x_t$.",
        "Denoting the attention weight from token $t$ to a candidate head $q$ as $A_{parse}[t,q]$, we model the probability of token $t$ having parent $q$ as:\n\\begin{align}\nP(q=\\mathrm{head}(t) \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) = A_{parse}[t, q]\n\\end{align}\nusing the attention weights $A_{parse}[t]$ as the distribution over possible heads for token $t$.",
        "We compute locally-normalized probabilities using the softmax function: $P(y_t^{prp} \\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(r_t)$, where $y_t^{prp}$ is a label in the joint space.",
        "We calculate a locally normalized distribution over role labels for token $t$ in frame $f$ using the softmax function: $P(y_{ft}^{role}\\mid \\mathcal{P},\\mathcal{V}, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\propto \\exp(s_{ft})$.",
        "In order to maximize our model's ability to leverage syntax, during training we clamp $\\mathcal{P}$ to the gold parse ($\\mathcal{P}_G$) and $\\mathcal{V}$ to gold predicates $\\mathcal{V}_G$ when passing parse and predicate representations to later layers, whereas syntactic head prediction and joint predicate/POS prediction are conditioned only on the input sequence $\\mathcal{\\htmlClass{match-highlight}{{X}}}$.",
        "The overall objective is thus:\n%while still training $A_{parse}$ to predict syntactic heads. Similarly, we condition on gold predicates $\\mathcal{V}_G$ during training. \n\\begin{align}\n\\frac{1}{T}\\sum_{t=1}^T\\Big[&\\sum_{f=1}^F \\log P(y_{ft}^{role}\\mid \\mathcal{P}_G, \\mathcal{V}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:srl-term} \\\\ \n&+ \\log P(y_t^{prp}\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:predpos-term}\\\\ \n% &+ \\lambda\\sum_{q=1}^T \\log P(q=\\mathrm{head}(t)\\mid \\mathcal{X}) \\Big]\n&+ \\lambda_1 \\log P(\\mathrm{head}(t)\\mid \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\nonumber \\\\ %\\label{eqn:head-term}\\\\\n&+ \\lambda_2 \\log P(y_t^{dep} \\mid \\mathcal{P}_G, \\mathcal{\\htmlClass{match-highlight}{{X}}}) \\label{eqn:rel-term} \\Big]\n\\end{align}\nwhere $\\lambda_1$ and $\\lambda_2$ are penalties on the syntactic attention loss."
      ],
      "is_definition": false,
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 4,
          "left": 0.263866,
          "top": 0.305226,
          "width": 0.0134454,
          "height": 0.00831354
        }
      ],
      "diagram_label": null,
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "definition_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804377"
        }
      ],
      "equation": {
        "type": "equation",
        "id": "804727"
      },
      "parent": {
        "type": "symbol",
        "id": "804980"
      },
      "sentence": {
        "type": "sentence",
        "id": "804366"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804318"
        },
        {
          "type": "sentence",
          "id": "804348"
        },
        {
          "type": "sentence",
          "id": "804366"
        },
        {
          "type": "sentence",
          "id": "804373"
        },
        {
          "type": "sentence",
          "id": "804377"
        },
        {
          "type": "sentence",
          "id": "804378"
        }
      ],
      "children": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805611",
    "type": "symbol",
    "attributes": {
      "mathml_near_matches": [
        "<msubsup><mi>K</mi><mi>parse</mi><mi>T</mi></msubsup>",
        "<msub><mi>K</mi><mi>parse</mi></msub>",
        "<msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<msup><msubsup><mi>K</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup><mi>T</mi></msup>",
        "<mi>K</mi>"
      ],
      "tex": "$K_{parse}$",
      "mathml": "<msub><mi>K</mi><mi>parse</mi></msub>",
      "snippets": [
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted ${\\htmlClass{match-highlight}{K_{parse}}}$, $Q_{parse}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between ${\\htmlClass{match-highlight}{K_{parse}}}$ and $Q_{parse}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}(Q_{parse} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations $Q_{parse}$ and ${\\htmlClass{match-highlight}{K_{parse}}}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, $Q_{parse}$ has dimension 500 and ${\\htmlClass{match-highlight}{K_{parse}}}$ has dimension 100."
      ],
      "is_definition": false,
      "nicknames": [
        "key representation"
      ],
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 13,
          "left": 0.584874,
          "top": 0.684085,
          "width": 0.0470588,
          "height": 0.0118765
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804780"
      },
      "children": [
        {
          "type": "symbol",
          "id": "805124"
        },
        {
          "type": "symbol",
          "id": "805125"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804635"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  },
  {
    "id": "805612",
    "type": "symbol",
    "attributes": {
      "nicknames": [
        "query representation"
      ],
      "mathml_near_matches": [
        "<msubsup><mi>Q</mi><mi>h</mi><mrow><mo stretchy=\"false\">(</mo><mi>j</mi><mo stretchy=\"false\">)</mo></mrow></msubsup>",
        "<mi>Q</mi>",
        "<msub><mi>Q</mi><mi>parse</mi></msub>"
      ],
      "tex": "$Q_{parse}$",
      "mathml": "<msub><mi>Q</mi><mi>parse</mi></msub>",
      "snippets": [
        "As with the other attention heads, we project $S^{(i-1)}$ into key, value and query representations, denoted $K_{parse}$, ${\\htmlClass{match-highlight}{Q_{parse}}}$, $V_{parse}$.",
        "Unlike the other attention heads which use a dot product to score key-query pairs, we score the compatibility between $K_{parse}$ and ${\\htmlClass{match-highlight}{Q_{parse}}}$ using a bi-affine operator $U_{heads}$ to obtain attention weights:\n\\begin{align}\nA_{parse} = \\mathrm{softmax}({\\htmlClass{match-highlight}{Q_{parse}}} U_{heads} K_{parse}^T)\n\\end{align}\nThese attention weights are used to compose a weighted average of the value representations $V_{parse}$ as in the other attention heads.",
        "We also predict dependency labels using per-class bi-affine operations between parent and dependent representations ${\\htmlClass{match-highlight}{Q_{parse}}}$ and $K_{parse}$ to produce per-label scores, with locally normalized probabilities over dependency labels $y_t^{dep}$ given by the softmax function.",
        "In the syntactically-informed attention head, ${\\htmlClass{match-highlight}{Q_{parse}}}$ has dimension 500 and $K_{parse}$ has dimension 100."
      ],
      "is_definition": false,
      "version": 0,
      "source": "human-annotation",
      "bounding_boxes": [
        {
          "source": "tex-pipeline",
          "page": 3,
          "left": 0.317647,
          "top": 0.845606,
          "width": 0.0453782,
          "height": 0.0142518
        }
      ],
      "diagram_label": null,
      "definitions": [],
      "defining_formulas": [],
      "passages": []
    },
    "relationships": {
      "equation": {
        "type": "equation",
        "id": "804704"
      },
      "children": [
        {
          "type": "symbol",
          "id": "804931"
        },
        {
          "type": "symbol",
          "id": "804932"
        }
      ],
      "sentence": {
        "type": "sentence",
        "id": "804346"
      },
      "snippet_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        },
        {
          "type": "sentence",
          "id": "804346"
        },
        {
          "type": "sentence",
          "id": "804352"
        },
        {
          "type": "sentence",
          "id": "804635"
        }
      ],
      "nickname_sentences": [
        {
          "type": "sentence",
          "id": "804344"
        }
      ],
      "parent": {
        "type": "symbol",
        "id": null
      },
      "definition_sentences": [],
      "defining_formula_equations": []
    }
  }
]